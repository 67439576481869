import {Card, CardTitle, Col, Container, Row} from "reactstrap";
import Announcements from "../../../components/Sections/Announcements";
import TableApp from "../../../components/Datatable/TableApp";
import React, {useContext, useEffect, useState} from "react";
import EntitiesContext from "../../../app/context";
import ReportFilters from "../../../components/Forms/ReportFilters";
import strings from "../../../app/translations";

export default function ReportsMain({type}) {

    const {state/*,dispatch*/} = useContext(EntitiesContext);
    //console.log(type);
    //console.log(state[type].objects);
    state[type].objects = Array.from(state[type].objects);

    const [objects, setObjects] = useState(state[type].objects.map(object => {
        //console.log(object);
        object.width = "80px";
        if (object.data) {
            object.data.action = '';
            // object.data.action = state.actionButton(object,false,true,false);
            return object.data;
        }
        return object;
    }));

    const setColumns = () => {
        let columns = state[type].columns;
        if (type==='users' && window.reactInit.user_role === 'manager') {
            columns = columns.filter(column => {
                return column.data !== 'company';
            });
        }
       columns = columns.filter(column => {
            return column.data !== 'action';
        });
        return columns;
    };

    const columns = setColumns();
    const currentItem = state.currentItem;


    useEffect(() => {
            setObjects(state[type].objects.map(object => {
                // console.log('object');
                // console.log(object);
                return object.data
            }));
        }
        , [state]);


    return (
        <div className="section text-center align-page-header" style={{backgroundColor: "transparent"}}>
            <div className="">
                <div className="custom-column row">
                    <Announcements/>
                    <Row className="custom-column">
                        <Col className="ml-auto mr-auto offset-lg-1" lg="12">
                            <Row style={{display: "block"}}>
                                <Col sm="12">
                                    {currentItem.length === 0 &&
                                    <Card body className="card-plain">
                                        <CardTitle><h3
                                            className="text-danger ml-auto mr-auto">{state[type].cardTitle}</h3>
                                        </CardTitle>
                                        {strings.here_is_a_list} {state[type].singleTitle}
                                        <div className='criteria'>
                                            <h3 className="text-danger mb-1"></h3>
                                            <ReportFilters/>
                                            <div className="col-md-12">
                                                <TableApp
                                                    names={objects} columns={columns}/>
                                            </div>
                                        </div>
                                    </Card>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="section section-cards section-dark text-center" id="enroll-now"
                 style={{paddingTop: "15rem"}}>
                <Container>
                    <Row id="participation-form">
                        <Col className="ml-auto mr-auto " lg="8">

                        </Col>
                    </Row>
                </Container>

                <div className="footer register-footer text-center">
                    <h6>
                        © {new Date().getFullYear()}, made with{" "}
                        <i className="fa fa-heart heart"/> by WIDE Services
                    </h6>
                </div>
            </div>
        </div>)
}
