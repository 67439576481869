/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useContext} from "react";
// reactstrap components
import {Button, Card, Col, Container, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row} from "reactstrap";
import axios from "axios";
import RegistrationFormDetails from "../../components/Forms/shop/RegistrationFormDetails";
import strings from "../../app/translations";
import EntitiesContext from "../../app/context";
import {useForm} from "react-hook-form";

// core components
function handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    console.log(data);

    axios({method:'post',
        url:"login",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': window.reactInit.csrf
        },
        data: data,
    }).then(function (response) {
        //handle success
        console.log(response);
        console.log(response.status);
        if (response.status===204||response.status===200) {
            window.location = "/index";
        }
    })
        .catch(function (response) {
            //handle error
            console.log('error');
            console.log(response);
        });
}

function SectionLogin({route,showModal}) {
    const {state, dispatch} = useContext(EntitiesContext);
    const {register, handleSubmit, watch, errors} = useForm();
    const onSubmit = (data, event) => {
        console.log('data sent in handlesubmit');
        //console.log(event);
        state.handleSubmit(data, route, showModal, event);
    };
    return (
        <>
                                <h3 className="title text-center" style={{color: 'white'}}>{strings.welcome}</h3>
                                <div className="social-line text-center">
                                    <Button
                                        className="btn-neutral btn-just-icon mt-0"
                                        color="facebook"
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                    >
                                        <i className="fa fa-facebook-square"/>
                                    </Button>
                                    <Button
                                        className="btn-neutral btn-just-icon mt-0 ml-1"
                                        color="google"
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                    >
                                        <i className="fa fa-google-plus"/>
                                    </Button>
                                    <Button
                                        className="btn-neutral btn-just-icon mt-0 ml-1"
                                        color="twitter"
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                    >
                                        <i className="fa fa-twitter"/>
                                    </Button>
                                </div>
                                <Form className="register-form text-white mb-6" onSubmit={handleSubmit(onSubmit)} method="POST">
                                    <div className="form-row">
                                        <FormGroup className="col-md-12">
                                            <label>Email</label>
                                            <Input placeholder="Email" type="text" name="email"/>
                                        </FormGroup>
                                    </div>
                                    <div className="form-row">
                                        <FormGroup className="col-md-12">
                                            <label>{strings.password}</label>
                                            <Input placeholder={strings.password} type="password" name="password"/>
                                        </FormGroup>
                                    </div>
                                    <div className="forgot">
                                        <Button
                                            className="btn-link"
                                            color="danger"
                                            href="/password/reset"
                                            // onClick={e => e.preventDefault()}
                                        >{strings.forgot_password}
                                        </Button>
                                    </div>
                                    <div className="actions col-12 mb-5">
                                        <Button className="btn-round col-4 btn-lg" color="tuv_red">
                                            {strings.login}
                                        </Button>
                                        <Button
                                            className="btn-round offset-2 col-6"
                                            outline
                                            color="neutral"
                                            href="/register-page#individual"
                                            size="lg"
                                            target="_blank"
                                        >{strings.registration}
                                        </Button>
                                    </div>
                                </Form>
            {" "}
        </>
    );
}
export default SectionLogin;
