import React, {useContext} from "react";
import {Button, Card, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import RegistrationForm from "../../../components/Forms/shop/RegistrationForm";
import SectionLogin from "../../index-sections/SectionLogin";
import Modal from "../../../components/Modals/Modal";
import EntitiesContext from "../../../app/context";
import strings from "../../../app/translations";

export default function LoginMain({setUserRole}) {
    const {state, dispatch} = useContext(EntitiesContext);
    const [modalShow,setModalShow] = React.useState(false);
    const [message,setMessage] = React.useState([]);
    const [action,setAction] = React.useState('');
    const route = '/login';
    function showModal(data){
        let msg = [];



        let act = (data.errors)?('error'):{link:"/index",label: strings.continue};
        setAction(act);
        if(data.errors){
            Object.values(data.errors).map(i=>i.map(m=>{return msg.push(m)}));
            setModalShow(true);
            $('#appModal').modal({show:true});

        }else{
            msg.push(strings.login_success);
            window.location.href = "/index";
        }

        setMessage(msg);

        console.log('show');
        console.log(msg);

    }
    return (
        <Container>
        <Row>
            <Col className="ml-auto mr-auto " lg="5" style={{marginTop: '2em'}}>
                <SectionLogin route={route} showModal={showModal}/>
                <Modal action={action} message={message} title={strings.login_status}/>
            </Col>
        </Row>
    </Container>
    )
}
