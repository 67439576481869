import React from 'react';
import LoadingOverlay from 'react-loading-overlay';

function OverlayLoader({active, children, text, background, className, padding, margin})
{
    return (
        <LoadingOverlay
            active = {active}
            spinner
            className = {className}
            classNamePrefix = 'OverlayLoader'
            text = {text}
            styles={{
                wrapper: (base) => ({
                    ...base,
                    padding: padding,
                    margin: margin,
                }),
                overlay: (base) => ({
                    ...base,
                    background: background??'rgba(0,0,0,0.3)',
                    borderRadius: '10px'
                }),
                content: (base) => ({
                    ...base,
                    color: 'rgb(255,255,255)',
                    background: 'rgba(0,0,0,0.7)',
                    padding: '20px',
                    borderRadius: '10px'
                })
            }}
        >
            {children}
        </LoadingOverlay>)

}

export default OverlayLoader;
