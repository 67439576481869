import React, {useContext, useEffect, useState} from "react";
import {Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import TableApp from "../Datatable/TableApp";

import EntitiesContext from "../../app/context";
import CompanyForm from "../Forms/admin/CompanyForm";
import strings from "../../app/translations";

function ManageCompanies() {
    const {state/*,dispatch*/} = useContext(EntitiesContext);

    //const objects = state.companies.objects.map(object => object.data);

    const [objects, setObjects] = useState(state.companies.objects.map(object => {
        // console.log('object:');
        // console.log(object);
        object.data.action = state.actionButton(object,true,false,false);
        return object.data;
    }));

    // console.log("state objs");
    // console.log(objects);
    const columns = state.companies.columns;
    // console.log("columns");
    // console.log(columns);
    // console.log("objects");
    // console.log(objects);
    const tableWidth = state.companies.tableWidth;
    const [sectionTab, setSectionTab] = React.useState('1');

    useEffect(() => {
            // console.log("state.companies use effect");
            // console.log(objects);
            setObjects(state.companies.objects.map(object => {
                // console.log(object);
                return object.data
            }));
        }
        , [state]);

    const sectionToggle = tab => {
        if (sectionTab !== tab) setSectionTab(tab);
    };
    return (
        <Row className="custom-column">
            <Col><h2 className="text-center text-dark">{strings.manage_companies}</h2>
                <div className="form-group">
                    <Nav tabs className="ml-auto mr-auto col-5 justify-content-center col-12">
                        <NavItem>
                            <NavLink
                                className={classnames({active: sectionTab === '1'})}
                                onClick={() => {
                                    sectionToggle('1');
                                }}
                            >
                                {strings.edit_company}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({active: sectionTab === '2'})}
                                onClick={() => {
                                    sectionToggle('2');
                                }}
                            >
                                {strings.company_list}
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={sectionTab} className="tuv-black">
                        <TabPane tabId="1">
                            <div className="col-md-8 mt-5">
                                <div className="card">
                                    <div className="card-header">Edit Company</div>

                                    <div className="card-body">

                                        <div className="alert alert-success" role="alert">
                                            Currently Unavailable
                                        </div>
                                    </div>
                                </div>
                            </div>
{/*                            <CompanyForm/>*/}
                        </TabPane>
                        <TabPane tabId="2">

                            <Row className="col-12">
                                <TableApp onEdit={() => {
                                    sectionToggle('1');
                                }} names={objects} columns={columns} tableWidth={tableWidth}/>
                            </Row>
                        </TabPane>
                    </TabContent>

                </div>
            </Col>
        </Row>
    )
}

export default ManageCompanies;
