import {Card, CardTitle, Col, Row} from "reactstrap";
import React, {useReducer, useContext, useState, useEffect} from "react";
import OrderDetails from "./OrderDetails";
import CheckoutForm from "../Forms/shop/CheckoutForm";
import axios from 'axios';
import strings from "../../app/translations";

import { css } from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";


const override = css`
  border-color: red;
`;


function Orders(props) {

    const [selectedOrder, setSelectedOrder] = useState();
    const [showOrderDetails, setShowOrderDetails] = useState(false);
    const [loading, setLoading] = useState(false);


    const [showOrders, setShowOrders] = useState('none');
    useEffect(() => {
        props.orders.length > 0 ? setShowOrders('block') : setShowOrders('none');
    });



    const getOrderDetails = async (event) => {
        setShowOrderDetails(false);
        setLoading(true);
        axios.get("/get_checkout_order/" + event)
            .then(function (response) {
                //handle success
                setSelectedOrder(response.data);
                setShowOrderDetails(true);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
                setLoading(false);
            });
    };

    return (
        <Row style={{display: "block"}} className="custom-column" id="announcements-component">
            <Col>
                <h2 className="ml-auto mr-auto">{strings.your_orders}</h2>
                <Card body className="card-plain">
                    <div className='announcements' style={{overflowY: "auto", overflowX: "hidden", maxHeight: "20rem", display: showOrders}}>
                        <ul>
                            <Row>
                                <Col lg="4">
                                    <strong><u>Certification Title</u></strong>
                                </Col>
                                <Col lg="2">
                                    <strong><u>Start Date</u></strong>
                                </Col>
                                <Col lg="2">
                                    <strong><u>Status</u></strong>
                                </Col>
                                <Col lg="2">
                                    <strong><u>Amount</u></strong>
                                </Col>
                                <Col lg="2">
                                </Col>
                            </Row>
                            {props.orders.map((order,key) => {
                                return <li key={key} className="py-3 border-bottom" style={{minHeight:"4rem"}}>
                                    <Row>
                                        <Col lg="4">
                                            {order.certification_name}
                                        </Col>
                                        <Col lg="2">
                                            {order.start_date}
                                        </Col>
                                        <Col lg="2">
                                            {(order.order_status) == 1 ?
                                                <span className='badge badge-success'>Paid</span> :
                                                <span className='badge badge-danger'>Unpaid</span>}
                                        </Col>
                                        <Col lg="2">
                                            {order.amount + '\u20AC'}
                                        </Col>
                                        <Col lg="2">
                                            <button className="btn btn-round btn-secondary" onClick={event => getOrderDetails(order.order_id)} disabled={order.order_status}>view</button>
                                        </Col>
                                    </Row>
                                </li>
                            })}
                        </ul>
                    </div>
                </Card>
            </Col>
            <Col>
                <div className="sweet-loading">
                    <BeatLoader
                        css={override}
                        size={30}
                        color={'red'}
                        loading={loading}
                    />
                </div>
                {showOrderDetails ? <OrderDetails details={selectedOrder}/> : ''}
                {showOrderDetails ? <CheckoutForm details={selectedOrder}/> : ''}
            </Col>
        </Row>
    )
}

export default Orders;
