import {Button, FormGroup, Input, Label} from "reactstrap";
import React, {useContext, useEffect} from "react";


import Document from "./Document";
import propTypes from "prop-types";
//context
import ParticipationContext from "../../app/ParticipationContext";
import SelectOkCancel from "../SelectOkCancel";
import strings from "../../app/translations";

function DocumentsArea({profilePage, user, isActive, userFiles, setFiles, index, register, errors, isReadOnly, usersDocs=null}) {
    // console.log('isReadOnly');
    // console.log(isReadOnly);
    const {pState, pDispatch} = useContext(ParticipationContext);
    const [selectStatus, setSelectStatus] = React.useState(true);
    const [previousDocuments, setPreviousDocuments] = React.useState('');
    const userDocs=usersDocs!==null?usersDocs[user.user_id]:null;
    const [count, setCount] = React.useState(1);
    const [documents, setDocuments] = React.useState(userFiles);
    //const [documents, setDocuments] = React.useState(props.userFiles);
    const increment = (/*e*/) => {
        // console.log('what is setFiles');
        documents.map((document, key) => {
            (documents[key] = {id: document.id, isOpen: false, isActive: true})
        });
        documents.push({id: user.birthDate, isOpen: true, isActive: true});
        setDocuments(documents);
        setCount(count + 1);
        setFiles(documents);

    };


    // console.log('user in DocumentArea');
    // console.log(user);
    // console.log('usersDocs in DocumentArea');
    // console.log(usersDocs);
    //
    // console.log('userDocs in DocumentArea');
    // console.log(userDocs);


    // playing around, not really needed as a separate function
    function SubmitButton() {
        return (
            <Button block className="btn-round ml-auto mr-auto col-lg-6 col-md-8 col-sm-8 col-12" color="tuv_red">
                {strings.profile_update_details}
            </Button>
        )

    }
    function showExistingDocs(){
        // console.log('documents in DocumentArea');
        // console.log(documents);
        const docs = [];
        userDocs.map((doc, key)=>{
            docs.push(<option key={key}>{doc.message}</option>)
        });
        return docs;
    }

    // function addFile() {
    //     pDispatch({type: "ADD_FILE", payload: user.files});
    // }
    function appendSelectedDocs(e){
        let docs = [];
        Array.from(e.target.options).map(option => {
            if (option.selected) {
                docs.push({user_id: user.id, value: option.text})
            }
        });
        setPreviousDocuments(JSON.stringify(docs));
    }

    // function showSelectedDocs() {
    //     if (typeof user.files !== "undefined") {
    //         /* document.getElementById("participation_selected_docs_" + user.id).defaultValue = previousDocuments;*/
    //     }
    // }

    // todo: check if this is used in user profile
    // if (pState && pState.users) {
    //     useEffect(function () {
    //         let docs = [];
    //         if (typeof user.files !== "undefined") {
    //             Array.from(user.files).map(option => {
    //                 docs.push({user_id: user.id, value: option.text})
    //             });
    //             setPreviousDocuments(JSON.stringify(docs));
    //         }
    //     }, [pState.users]);
    // }

    // useEffect(function () {
    //     showSelectedDocs()
    // }, [previousDocuments]);

    // console.log('previousDocuments');
    // console.log(previousDocuments);

    return (<div className="form-row" key={index}>
        {(!profilePage && userDocs && userDocs.length>0) && <>
            <FormGroup className="col-md-12" key={index}>
                <Label for={"documentsList_" + user.id}>Select Existing Documents for {user.name}</Label>
                <Input type="select" name={"documentsList_" + user.id} onChange={appendSelectedDocs}
                       id={"documentsList_" + user.id}
                       multiple disabled={!selectStatus}
                       innerRef={register({required: false})}>
                    {showExistingDocs()}
                    {/*<option>testDoc1(File1.pdf,File2.jpg)</option>*/}
                    {/*<option>testDoc2(File4.png)</option>*/}
                    {/*<option>testDoc3(File5.jpg,File6.jpg)</option>*/}

                     {/*{*/}
                     {/*       userDocs.map((doc, key) => {*/}
                     {/*       return (<option key={key}>{doc.message}</option>);*/}
                     {/*   })*/}

                        )

                </Input>
                <input hidden type="text" name={"participation_selected_docs_" + user.id}
                       id={"participation_select_docs_" + user.id} ref={register({required: false})}
                       defaultValue={previousDocuments}/>
                {/*            {errors.hasOwnProperty(`${"participation_select_docs_"+ user.birthDate}`)  &&
            <span className="validation-error">{"This field is required"}</span>
            }*/}
            </FormGroup>
            <SelectOkCancel setSelectStatus={setSelectStatus} selectStatus={selectStatus}/>
        </>
        }
        <FormGroup className="col-12 documentGroup mt-5 text-center" id={user.birthDate}>
            {profilePage && <Label className="font-weight-bolder col-12"> Upload a Document </Label>}
            <Button onClick={increment} className="btn-round col-md-6 my-2" color="primary" outline>
                {/*<i className="nc-icon nc-simple-add"/>*/}
                <span>{strings.add_document.stripAccents()}</span>
            </Button>
            {documents.map((document, key) => {
                if (document.id === user.birthDate) {
                    // console.log('ze document');
                    // console.log(document);

                    return (
                        <Document key={key} title={"Document " + key} isOpen={document.isOpen/* ?? false*/} user={user}
                                  register={register} errors={errors}
                                  userFiles={userFiles} index={key} isReadOnly={isReadOnly}/>)
                }
            })}
            {profilePage && <SubmitButton/>}

        </FormGroup>

    </div>)
}

DocumentsArea.propTypes = {
    userFiles: propTypes.array,
    user: propTypes.object,
    setFiles: propTypes.func,
    index: propTypes.any,
    isReadOnly: propTypes.func
};
export default DocumentsArea;
