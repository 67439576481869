/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useContext, useReducer,useState} from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import ViewVariables from "../../components/Middleware/ViewVariables";
import Footer from "../../components/Footers/Footer";
import AdminEnrollmentMain from "./main/AdminEnrollmentMain";
//CONTEXTAPI
import EntitiesContext from "../../app/context";
import CRUDReducer from "../../app/reducer";
import axios from "axios";
import Cookie from 'universal-cookie';
import AuthoriseAccess from "../../components/AuthoriseAccess";

function MockAdminEnrollmentPage() {

    const cookie = new Cookie;
    const initialState = useContext(EntitiesContext);
    const [state, dispatch] = useReducer(CRUDReducer, initialState);
    const pagePathname = '/admin/enrollment-page';
    const editMode = (window.location.pathname !== pagePathname);
    const [results,setResults] = useState([]);
    const [currentItem,setCurrentItem] = useState(state.currentItem);
    const [language, setLanguage] = useState(getLanguage());

    const isVerified = window.reactInit.verified==='true';

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        /*document.body.classList.add("register-page");
        return function cleanup() {
            document.body.classList.remove("register-page");
        };*/
    });
    const middleware = ViewVariables;


    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        console.log(document.location.pathname);

        document.body.classList.add("register-page");
        return function cleanup() {
            document.body.classList.remove("register-page");
        };
    });
    function getLanguage ()  {
        let lang=cookie.get('language_set')??'en';
        //dispatch({type:"UPDATE_LANGUAGE",payload:lang});
        return lang;
    }

    const getItem = async (url) => {
        const response = await axios.get(url);
        dispatch({type:"UPDATE_CURRENT_ITEM",payload:response.data});
        setCurrentItem(response.data);
        console.log('currentItem');
        console.log(currentItem);
    }
    const getResults = async () => {
        const response = await axios.get("/enrollment/get");
        console.log("data");
        console.log(response.data);
        let temp = [];
        if(typeof response.data.enrollments === "object")
            response.data.enrollments = Object.values(response.data.enrollments);
        dispatch({type:"UPDATE_ENROLLMENTS",payload:response.data});
        setResults(response.data);
    };
    React.useEffect(() => {
            if (isVerified) {
                let url = window.location.pathname;
                url = '/enrollment/get/item' + url.substr(url.lastIndexOf("/"));
                getResults();
                if (editMode)
                    getItem(url);
            }
        }
        , []
    );
    /*const title = "Featured Products";*/
    const title = 'Enrollments';

    return (
        <>
            <EntitiesContext.Provider value={{state, dispatch}}>
                <ExamplesNavbar setLanguage={language=>setLanguage(language)}/>
                <div
                    className="page-header"
                    style={{
                        backgroundImage: "url(" + require("./../../assets/img/mockups/tuv-austria-hellas.jpg") + ")"
                    }}
                >
                    <h1 className="text-center text-white" style={{zIndex: "3"}}>{title}</h1>

                    <div className="filter"/>

                </div>
                <div className="main">
                    <AuthoriseAccess component={<AdminEnrollmentMain getItem={getItem}/>}/>
                    <Footer/>
                </div>
            </EntitiesContext.Provider>
        </>
    );
}

export default MockAdminEnrollmentPage;
