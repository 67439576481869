/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useContext, useReducer,useState} from "react";
// reactstrap components

// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader.js";
import DemoFooter from "../../components/Footers/DemoFooter.js";
import LandingPageMain from "./main/LandingPageMain";
import ViewVariables from "../../components/Middleware/ViewVariables";
//context API
import EntitiesContext from "../../app/context";
import CRUDReducer from "../../app/reducer";
import Footer from "../../components/Footers/Footer";
import SectionLogin from "../index-sections/SectionLogin";
import axios from "axios";
import Cookie from 'universal-cookie';
import strings from "../../app/translations";

function MockLandingPage() {
    const headerImage = ViewVariables.middleware.headerImage;
    const initialState = useContext(EntitiesContext);
    const [state, dispatch] = useReducer(CRUDReducer, initialState);
    const cookie = new Cookie();

    const [results,setResults] = useState([]);
    const [language, setLanguage] = useState(getLanguage());
    const getCertifications = async () => {
        const response = await axios.get("/certification/get");
        // console.log("data");
        // console.log(response.data);
        dispatch({type:"UPDATE_CERTIFICATIONS", payload:response.data});
        setResults(response.data);
    };
    const getCategories = async () => {
        const response = await axios.get("/category/get");
        //console.log("data");
        //console.log(response.data);
        dispatch({
            type: "UPDATE_CATEGORIES",
            payload: response.data
        });
        setResults(response.data);
    };

    function getLanguage ()  {
        let lang=cookie.get('language_set')??'en';
        //dispatch({type:"UPDATE_LANGUAGE",payload:lang});
        return lang;
    }
    // console.log('language in state before dispatch: '+state.language);
    // console.log('language in state after dispatch: '+state.language);

    React.useEffect(() => {
            if (window.location.pathname === "/landing-page" || window.location.pathname === "/index") {
                getCategories();
                getCertifications();
                getLanguage();
            }
        }
        , []
    );
    React.useEffect(() => {
        // console.log('how much updated');
    },[state.categories]);

    // console.log('landing page with lang: '+ language);

    strings.setLanguage(language);
    /* const middleware = ViewVariables;*/
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("profile-page");
        return function cleanup() {
            document.body.classList.remove("profile-page");
        };
    });
    // console.log('rendering: '+ language)
    //images
    return (
        <>
            <EntitiesContext.Provider value={{state, dispatch}}>
                <ExamplesNavbar setLanguage={language=>setLanguage(language)}/>
                <LandingPageHeader/>
                <div className="main">
                    <LandingPageMain/>
                </div>
                <Footer/>
            </EntitiesContext.Provider>
        </>
    );
}

export default MockLandingPage;
