import {Form, FormGroup, Input, Label, Tooltip, Button} from "reactstrap";
import React, {useContext, useState} from "react";
import EntitiesContext from "../../app/context";
import axios from "axios";
import {useForm} from "react-hook-form";
import strings from "../../app/translations";
import TooltipWrap from "./TooltipWrap";
import CustomFilter from "./CustomFilter";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';

export default function ReportFilters() {

    const {state, dispatch} = useContext(EntitiesContext);
    const [results, setResults] = useState([]);
    //const [filterData, setFilterData] = useState([]);
    const url = window.location.pathname;
    const suffix = url.substr(url.lastIndexOf("/"));
    const type = `${suffix.substr(1)}s`;
    const certifications = state.certifications.objects.map(object => object.data);
    const filters = state[type].filters;

    const dateRangeSettings = {
        startDate: moment().startOf('day'),
        endDate: moment().startOf('day').add(1, 'week'),
        autoUpdateInput: false,
        locale: {
            format: 'DD/MM/YYYY',
            cancelLabel: 'Clear',
        },
    };

    const handleDateApply = (event, picker) => {
        picker.element.val(
            picker.startDate.format('DD/MM/YYYY') +
            ' - ' +
            picker.endDate.format('DD/MM/YYYY')
        );
    };
    const handleDateCancel = (event, picker) => {
        picker.element.val('');
    };

    //get all input fields values
    function gatherData() {
        let data = [];
        let inputs = document.getElementById('filters').getElementsByClassName('custom-filter');
        for (let key in inputs) {
            if (!isNaN(key)) {
                // console.log(typeof key);
                // console.log(key, inputs[key]);
                data[inputs[key].name] = inputs[key].value;
            }
        }
        //inputs.forEach(input => data.push(input.value));
        return data;
    }

    // function resendData(e) {
    //     e.preventDefault();
    //     setFilterData(gatherData);
    //     //sendFilters();
    //     // console.log('data resent');
    //     // console.log(filterData);
    // }

    const sendFilters = async () => {
        let url = `${suffix}/filter`;

        // console.log(typeof filterData);
        // if (filterData.certification_id) {
        var data = new FormData();
        let filterData = gatherData();
        for (let key in filterData) {
            data.append(key, filterData[key]);
        }
        // console.log("data before axios");
        // console.log(filterData);
        // console.log(Object.keys(filterData).length);
        if(Object.keys(filterData).length>0) {
            // console.log('before axio');
            axios(
                {
                    method: 'post',
                    url: `${window.location.origin}${url}`,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': window.reactInit.csrf
                    },
                    data: data,
                }).then(function (response) {
                if (typeof response.data[type] === "object")
                    response.data[type] = Object.values(response.data[type]);
                // console.log(`UPDATE_${type.toUpperCase()}`);
                dispatch({type: `UPDATE_${type.toUpperCase()}`, payload: response.data});
                setResults(response.data);

            }).catch(function (response) {
                console.log('There has been an error.');
                console.log(response);
            });
        }

        // }
    };

    // React.useEffect(() => {
    //     sendFilters();
    // }, filterData);

    // console.log('filters');
    // console.log(filters);
    //
    // console.log('type');
    // console.log(type);
    return (
        <div id={'filters'}>
            <div className="form-row">
                {type !== 'users' && <>
                <FormGroup className="col-md-4">
                    <label htmlFor="examination_id">{strings.select_certification}</label>
                    <Input type="select" name="certification_id" id="certification_id" className={'custom-filter'}>

                        <option key={0} value={''}>{strings.choose}</option>
                        {certifications && certifications.map((certification, key) =>
                            <option key={key} name={certification.type}
                                    value={certification.id}>{certification.title}</option>
                        )
                        }
                    </Input>
                </FormGroup>
                < FormGroup className="col-md-4">
                    <label>{strings.date}</label>
                    <DateRangePicker initialSettings={dateRangeSettings} onApply={handleDateApply} onCancel={handleDateCancel}>
                    <input type='text' name='date' id='date' className={'custom-filter form-control'} defaultValue=""/>
                    </DateRangePicker>
                    </FormGroup>
                    <FormGroup className='col-md-4'>
                    <Label for="status">{strings.status}</Label>
                    <Input type="select" name="status" id="status" className={'custom-filter'}>
                    {/*participations, examinations, payments, grades, certificates*/}
                    <option key={0} value={''}>{strings.choose}</option>
                    {['participations', 'examinations', 'certificates'].includes(type) &&
                    <option value="Draft">Draft</option>}
                    {['participations'].includes(type) && <option value="Approved">Approved</option>}
                    {['participations'].includes(type) && <option value="Rejected">Rejected</option>}
                    {['examinations', 'certificates'].includes(type) && <option value="Published">Published</option>}
                    {['grades'].includes(type) && <option value="0">Completed</option>}
                    {['grades'].includes(type) && <option value="1">Completed</option>}
                    {['payments'].includes(type) && <option value="1">Paid</option>}
                    {['payments'].includes(type) && <option value="0">Unpaid</option>}
                    </Input>
                    </FormGroup>
                </>
                }
                {filters.map((filter, key) => {
                    return (<CustomFilter filter={filter} key={key} dateRangeSettings={dateRangeSettings} handleDateApply={handleDateApply} handleDateCancel={handleDateCancel}/>);
                })}
            </div>
            {type !== 'users' &&
            <div className="form-row">
                <Button block className="btn-round col-md-4 ml-auto mr-auto" color="tuv_red" id={'filterButton'}
                        onClick={sendFilters}>
                    {strings.filter}
                </Button>
            </div>}
        </div>);
}
