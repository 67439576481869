import React, {useContext, useReducer, useState} from "react";
// core components
import ExamplesNavbar from "./../../components/Navbars/ExamplesNavbar.js";
import ViewVariables from "../../components/Middleware/ViewVariables";
import AdminCompanyMain from "./main/AdminCompanyMain";
import Footer from "../../components/Footers/Footer";
import Cookie from 'universal-cookie';
//CONTEXTAPI
import EntitiesContext from "../../app/context";
import CRUDReducer from "../../app/reducer";
import strings from "../../app/translations";
import EnrollmentMain from "./main/EnrollmentMain";
import AuthoriseAccess from "../../components/AuthoriseAccess";


function MockAdminCompanyPage() {
    const cookie = new Cookie;
    const headerImage = ViewVariables.middleware.headerImage;
    const initialState = useContext(EntitiesContext);
    const [state, dispatch] = useReducer(CRUDReducer, initialState);
    const [results,setResults] = useState([]);
    const [language, setLanguage] = useState(getLanguage());

    const isLogged = window.reactInit.user_id.length>0;
    const isVerified = window.reactInit.verified==='true';

    const middleware = ViewVariables;
    document.documentElement.classList.remove("nav-open");

    const getItem = async () => {
        let url = window.location.pathname;
        url = '/company/get/item' + url.substr(url.lastIndexOf("/") );
        const response = await axios.get(url);
        dispatch({type:"UPDATE_CURRENT_ITEM",payload:response.data});
        setCurrentItem(response.data);
        console.log(currentItem);
    }
    const getResults = async () => {
        const response = await axios.get("/company/get");
        // console.log("data");
        // console.log(response.data);
        dispatch({type:"UPDATE_COMPANIES",payload:response.data});
        setResults(response.data);
    };
    const [currentItem,setCurrentItem] = React.useState(state.currentItem);
    const title = strings.companies;

    React.useEffect(() => {
            if (isVerified) {
                getResults();
                if (window.location.pathname !== 'admin/company-page')
                    getItem();
            }
        }
        , []
    );
    function getLanguage ()  {
        let lang=cookie.get('language_set')??'en';
        //dispatch({type:"UPDATE_LANGUAGE",payload:lang});
        return lang;
    }
    return (
        <>
            <EntitiesContext.Provider value={{state, dispatch}}>
                <ExamplesNavbar setLanguage={language=>setLanguage(language)}/>
                <div
                    className="page-header"
                    style={{
                        backgroundImage: "url(" + require(`./../../assets/img/mockups/${headerImage}`) + ")"
                    }}
                >
                    <h1 className="text-center text-white" style={{zIndex: "3"}}>{title}</h1>

                    <div className="filter"/>
                </div>
                <div className="main">
                    <AuthoriseAccess component={<AdminCompanyMain middleware={middleware.middleware}/>}/>
                    <Footer/>
                </div>
            </EntitiesContext.Provider>
        </>
    );
}

export default MockAdminCompanyPage;
