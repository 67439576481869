import {Card, CardTitle, Col, Container, Row} from "reactstrap";
import Announcements from "../../../components/Sections/Announcements";
import Orders from "../../../components/Sections/Orders";
import TableApp from "../../../components/Datatable/TableApp";
import React, {useContext, useEffect, useState} from "react";
import EntitiesContext from "../../../app/context";
import CheckoutForm from "../../../components/Forms/shop/CheckoutForm";
import Modal from "../../../components/Modals/Modal";

export default function CheckoutMain({middleware}) {

    const {state/*,dispatch*/} = useContext(EntitiesContext);

    state.orders.objects = Array.from(state.orders.objects);
    const [objects, setObjects] = useState(state.orders.objects.map(object => {
        console.log("object");
        console.log(object);
        object.data.action = state.actionButton(object,false,false,false);
        return  object.data;
    }));

    const columns = state.orders.columns;

    useEffect(() => {
            console.log("state.orders use effect");
            console.log(objects);
            setObjects(state.orders.objects.map(object => {
                console.log(object);
                return object.data
            }));
        }, [state]);


    return (
        <div className="section text-center align-page-header" style={{backgroundColor: "transparent"}}>
            <div className="container-fluid">
                <div className="custom-column row">
                    <Orders orders={objects}/>
                </div>
            </div>
            <div className="section section-cards section-dark text-center" id="enroll-now"
                 style={{paddingTop: "15rem"}}>
                <Container>
                    <Row id="participation-form">
                        <Col className="ml-auto mr-auto " lg="8">

                        </Col>
                    </Row>
                </Container>
                <div className="footer register-footer text-center">
                    <h6>
                        © {new Date().getFullYear()}, made with{" "}
                        <i className="fa fa-heart heart"/> by WIDE Services
                    </h6>
                </div>
            </div>
        </div>)
}
