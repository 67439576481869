// reactstrap components
import {Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import AdminNavbar from "../../../components/Navbars/AdminNavbar";
import TableApp from "../../../components/Datatable/TableApp";
import React, {useContext} from "react";
import CertificationForm from "../../../components/Forms/admin/CertificationForm";
import EntitiesContext from "../../../app/context";
import ManageCertifications from "../../../components/Sections/ManageCertifications";

export default function AdminCertificationMain() {
    const {state,dispatch} = useContext(EntitiesContext);

    // console.log("das state");
    // console.log(state);
    const adminPage = state.certifications.cardTitle;


    return (
        <div className="container-fluid">
            <Row className="mt-5">
            <Col lg="3" xs="12" className="pr-0">
                <AdminNavbar adminPage={adminPage}/>
            </Col>
            <Col className="admin pl-0" id="admin" lg="9" xs="12" >
                <ManageCertifications/>

            </Col>
        </Row>
        </div>
    )
}
