import React, {useContext, useEffect, useState} from "react";
import {Component} from "react";
import Slider from "react-slick";
import {Button} from "reactstrap";
import propTypes from "prop-types";
import NextArrow from "../arrows/NextArrow";
import PrevArrow from "../arrows/PrevArrow";
import CertificationsList from "../Sections/CertificationsList";
import axios from 'axios';
import EntitiesContext from "../../app/context";
import strings from "../../app/translations";
import BeatLoader from "react-spinners/BeatLoader";
import {css} from "@emotion/core";


export default function SimpleSlider({title,numSlides,categoriesAll,certificationsAll}) {
    const {state,dispatch} = useContext(EntitiesContext);
    const certifications = state.certifications.objects.map(obj=>{return obj.data});
    const [slides,setSlides] = useState(certifications);
    const categories = state.categories.objects.map(obj=>{return obj.data});
    const [loading,setLoading] = React.useState(true);
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: numSlides,
        slidesToScroll: Math.abs(numSlides - 1),
        prevArrow: <PrevArrow/>,
        nextArrow: <NextArrow/>,
    };

    // console.log('certifications');
    // console.log(certifications);
    // console.log('slides');
    // console.log(slides);
     function getCatCertifications(category_id){
         setLoading(false);
         // console.log('category_id');
         // console.log(category_id);
         let result = certifications.filter(certification => {return certification.category_id === category_id});
         // console.log('result');
         // console.log(result);
         setSlides(result);
    }
    useEffect(function () {
        // console.log("slides length");
        // console.log(slides.length)
        setSlides(certifications);
    }, [state.certifications]);
        return (
            <div>
                <h1 className="text-white text-center">{title}</h1>
                <Slider {...settings}>
                    {categories && categories.map(function (slide,index) {
                            return (
                                <div key={slide.id} className="info" onClick={() => getCatCertifications(slide.id)}>
                                    <div  className="description" style={{cursor: "pointer"}}>
                                        <h4 className="info-title">{slide.name}</h4>
                                    </div>
                                </div>
                            );
                    })}
                </Slider>
                <div className="sweet-loading" style={{textAlign: "center", marginTop: "20px"}}>
                    <BeatLoader
                        size={30}
                        color={"red"}
                        loading={false}
                    />
                </div>
                {slides.length >0 && slides!== '' && slides!== undefined ?
                    <CertificationsList certificationsList={slides}/>:
                    <div style={{textAlign: "center"}}>{strings.no_certifications}</div>}

            </div>
        );
}

