import React, {useContext,useState,useEffect} from "react";
import strings from "../../app/translations";
import axios from "axios";
import {Button, Col, Form, FormGroup, Input, InputGroup, Label, Row} from "reactstrap";
import EntitiesContext from "../../app/context";
import {useForm} from "react-hook-form";
import Moment from "moment";
import Datetime from "react-datetime";
import OverlayLoader from "../Loaders/OverlayLoader";

export default function PdfGenerator({title,message,participant,certificate,action,status}) {
    const {state, dispatch} = useContext(EntitiesContext);
    const currentItem = state.currentItem;
    const {register, handleSubmit, watch, errors} = useForm();
    const [route,setRoute] = useState('/api/certificate/create');
     const editMode = (certificate !== null);
    const acquisitionDate = (editMode)?certificate.acquisition_date:(currentItem.data)?currentItem.data.date:Moment().format('YYYY-MM-DD');
    const [expirationDate,setExpirationDate] = useState(Moment().format('YYYY-MM-DD'));
    //const [acquisitionDate,setAcquisitionDate] = useState(Moment(currentItem.data.date).format('YYYY-MM-DD'));
    const publishDate = (editMode)?certificate.publish_date:Moment().format('YYYY-MM-DD');
    const genderTitle = (editMode)?certificate.gender_title:"none";
    //const [publishDate,setPublishDate] = useState(Moment().format('YYYY-MM-DD'));
    const [showUpload,setShowUpload] = useState(false);
    console.log('certificate');
    console.log(certificate);
    console.log((certificate !== null));

    const [loading, setLoading] = useState(false);

    const handleUpload = ()=>{
        setShowUpload(!showUpload);
    };
    const [label,setLabel] = React.useState('submit');
    const [labelColor,setLabelColor] = React.useState('tuv_red');
    const [publish,setPublish] = React.useState(false);
    const handleButton = (e)=>{
        if(e.target.id === "publish"){
            setRoute('/api/certificate/publish');
            setLabel('Publish');
            setLabelColor('success');
            setPublish(true);
            setShowUpload(false);
        }
        else{
            setRoute('/api/certificate/create');
            setLabel('Submit');
            setLabelColor('tuv_red');
            setPublish(false);
        }
        /*Array.from(document.querySelectorAll('#form-actions input')).map(child => {
            document.querySelectorAll(`#form-actions input#${child.id}`).checked = child.id === e.target.id;
        });*/

    };

    console.log('participant + certificate');
    console.log(participant);
    console.log(certificate);

    function calculateExpirationDate(target_date){
        var d = new Moment(target_date,'YYYY-MM-DD');
        console.log("before d");
        console.log(d);
        d.add(parseInt(currentItem.data.certification.duration), 'years');
        console.log("the d");
        console.log(d.format('YYYY-MM-DD'));
        let eD = new Moment(d.format('YYYY-MM-DD'),'YYYY-MM-DD').format('YYYY-MM-DD');
        console.log('ED');
        console.log(eD);
        setExpirationDate(eD);
    }
    function newExpirationDate(e){

        let target_date = e.target.value;
        calculateExpirationDate(target_date);
    }

    /*const generate = ()=>{
        toPng(document.getElementById('myPage'), { quality: 0.95 })
            .then(function (dataUrl) {
                var link = document.createElement('a');
                link.download = 'my-image-name.jpeg';
                const pdf = new jsPDF();
                const imgProps= pdf.getImageProperties(dataUrl);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(dataUrl, 'PNG', 0, 0,pdfWidth, pdfHeight,'NONE');


                /!*pdf.save("download.pdf");*!/
                var blob = pdf.output('blob');

                var formData = new FormData();
                formData.append('data', blob);
                axios({
                    method: 'post',
                    url: '/generate_certificate',
                    headers: {
                        /!*'Accept': 'application/json',
                        'Content-Type': 'application/json',*!/
                        'X-CSRF-TOKEN': window.reactInit.csrf
                    },
                    data: formData,
                    processData: false,
                    contentType: false,
                    cache:false,
                }).then(function (response) {
                    //handle success
                    console.log(response);
                })
                    .catch(function (response) {
                        //handle failure
                        console.log(response);
                    });
                /!*axios('/generate_certificate',
                    {
                        method: 'POST',
                        data: formData,
                        processData: false,
                        contentType: false,
                        cache:false,
                        data_type:'json',
                        success: function(data){console.log(data)},
                        error: function(data){console.log(data)}
                    });*!/
            });
    }*/
    function loadCurrentItem() {

        console.log('loaded');

        if (currentItem.data) {
            document.getElementById('certificate_gender_title').value = certificate.gender_title;
            let select = document.getElementById('certificate_gender_title');
            console.log('the element of select');
            console.log(select);
            Array.from(select.options).map((option, key) => {
                if (parseInt(option.value) === parseInt(certificate.gender_title)) {
                    select.selectedIndex = key;
                    console.log('selectedINdex '+ key);
                }
            });
            document.getElementById('certificate_acquisition_date').value = certificate.acquisition_date;
            setAcquisitionDate(certificate.acquisition_date);
            document.getElementById('certificate_expiration_date').value = certificate.expiration_date;
            document.getElementById('certificate_publish_date').value = certificate.publish_date;
            setPublishDate(certificate.publish_date);
        }
        console.log('load end');
        // console.log(currentItem);
    }
    useEffect(function(){
        calculateExpirationDate(Moment().format('YYYY-MM-DD'));
        if (editMode){
            setRoute('/api/certificate/edit');
            if (currentItem.data && editMode) {
                let select = $('input[name="certificate_registration_no"][value="'+ certificate.registration_no +'"]').parents('.modal').find('#certificate_gender_title')[0];
                Array.from(select.options).map((option, key) => {
                    if (option.value === certificate.gender_title) {
                        select.selectedIndex = key;
                    }
                });
            }
        }
    },[currentItem.data,editMode]);

    function showModal(data) {
        let msg = [];
        let modal = $('#enrollmentModal');
        if (typeof data === 'object') {
            if (data.errors) {
                Object.values(data.errors).map(i => i.map(m => {
                    return msg.push(m)
                }));
            } else {
                Object.values(data.records).map((record, key) => {
                    switch (record.data.status_id) {
                        case 1: /*draft status*/
                            msg.push(strings.certificate_save_message);
                            break;
                        case 2: /*waiting approval status*/
                            msg.push(strings.formatString(strings.certificate_edit_message, {registration_no: record.data.registration_no}));
                            break;
                        case 3: /*approved status*/
                            msg.push(strings.certificate_upload_message);
                            break;
                        case 5: /*published status*/
                            msg.push(strings.formatString(strings.certificate_publish_message, {registration_no: record.data.registration_no}));
                            break;
                        default:
                            msg.push(strings.certificate_update_message);
                    }
                    return msg;
                });
            }
        }
        modal.find('.modal-body').html(msg.map((line) => { return '<p>' + line + '</p>'}));
        modal.modal({show: true});
        modal.find('.modal-dialog').css('margin-top', '120px');
        $(".modal-backdrop").css("z-index", 1);
        setLoading(false);
    }

    const onSubmit = (data, event) => {
        console.log('data sent in handlesubmit');
        //console.log(event);
        setLoading(true);
        state.handleSubmit(data, route, showModal, event);
    };
    const onUploadFile = (data, event) => {
        console.log('data sent in handlesubmit');
        //console.log(event);
        state.handleSubmit(data, '/api/certificate/upload', false, event);
    };
    return (<>
        <OverlayLoader active={loading} text={strings.load} margin={'-20px -50px'} padding={'20px 50px'}>
        {currentItem.data &&
        <Form id={"certificate-form"} onSubmit={handleSubmit(onSubmit)}
              method="POST">
            <div className="form-row">
                <FormGroup className='col-4'>
                    <Label for="certificate_gender_title">{strings.certificate_gender_title}</Label>
                    <Input type="select" name="certificate_gender_title" id="certificate_gender_title"
                           innerRef={register({required: true})}>
                        <option value="none">{strings.choose}</option>
                        <option value="mister">{strings.mister}</option>
                        <option value="miss">{strings.miss}</option>
                    </Input>
                    {errors.gender_title && <span className="validation-error">{strings.required_validation}</span>}
                </FormGroup>
                <FormGroup className='col-8'>
                    <Label for="Personal_details">{strings.certificate_personal_details}</Label>
                    <Input id="personal_details"  type="text" name="personal_details" readOnly={true}
                           innerRef={register({required: true})} defaultValue={`For ${participant.user.firstname} ${participant.user.lastname}, ${participant.user.birthdate}`}/>
                </FormGroup>
                <input type="hidden" id="participant_id" name="participant_id" value={participant.id} />
                <input type="hidden" id="enrollment_id" name="enrollment_id" value={currentItem.data.id} />
                <input type="hidden" id="certificate_certification_id" name={"certificate_certification_id"} value={currentItem.data.certification.id} />
            </div>
            <div className="form-row">
                <FormGroup className='col-4'>
                    <Label for="certificate_category_name">{strings.certificate_schema}</Label>
                    <Input id="certificate_category_name"  type="text" name="certificate_category_name" readOnly={true}
                           innerRef={register({required: true})} defaultValue={currentItem.data.category_name}/>
                </FormGroup>
                <FormGroup className='col-4'>
                    <Label for="certificate_title">{strings.certificate_title}</Label>
                    <Input id="certificate_title"  type="text" name="certificate_title" readOnly={true}
                           innerRef={register({required: true})} defaultValue={currentItem.data.certification.title}/>
                </FormGroup>
                <FormGroup className='col-4'>
                    <Label for="certificate_registration_no">{strings.certificate_registration_no}</Label>
                    <Input id="certificate_registration_no"  type="text" name="certificate_registration_no" readOnly={true}
                           innerRef={register({required: false})} defaultValue={(typeof participant.certificate === "object" && participant.certificate !==null)?participant.certificate.registration_no:""}/>
                </FormGroup>

            </div>
            <div className="form-row">
                <FormGroup className="col-4">
                    {/* examination date */}
                    <Label for="certificate_acquisition_date">{strings.initial_certification}</Label>
                    <Input id="certificate_acquisition_date" placeholder={strings.examination_date} type="text" name="certificate_acquisition_date"
                           innerRef={register({required: true})} defaultValue={currentItem.data.date}/>
                    {errors.certificate_initial_certification && <span className="validation-error">{strings.required_validation}</span>}
                </FormGroup>

                <FormGroup className="col-4">
                    <Label for="certificate_expiration_date">{strings.certificate_valid_until}</Label>
                    <Input id="certificate_expiration_date" placeholder={strings.certificate_valid_until} type="text" name="certificate_expiration_date"
                           innerRef={register({required: true})} value={expirationDate} />
                    {errors.certificate_expiration_date && <span className="validation-error">{strings.required_validation}</span>}
                </FormGroup>
                <FormGroup className="col-4">
                    <Label className={'text-nowrap'} for="certificate_publish_date">{strings.certificate_publish_date}</Label>
                    <Input id="certificate_publish_date" placeholder={strings.certificate_publish_date} type="text" name="certificate_publish_date"
                           innerRef={register({required: true})} defaultValue={(publishDate)} onBlur={newExpirationDate}/>
                    {errors.certificate_publish_date && <span className="validation-error">{strings.required_validation}</span>}
                </FormGroup>
            </div>
            {editMode && certificate.status_id === 3 && certificate.status_id < 5 &&
            <div className="form-row" id="form-actions">
                <FormGroup className="offset-md-2 col-md-4" check>
                    <div className="form-check-radio">
                        <label className="form-check-label">



                            <input className="form-check-input" type="radio"
                                   id="submit" name="submit" value="submit" onClick={handleButton} onChange={handleButton} checked={!publish}
                                   ref={register({required: (!publish)})} />

                            {" "}
                            {strings.submit}
                            <span className="form-check-sign">
                                                    <span className="check"></span>
                                                </span>
                        </label>
                    </div>
                    {errors.submit &&
                    <span className="validation-error">{strings.required_validation}</span>
                    }
                </FormGroup>
                <FormGroup className="col-md-4" check>
                    <div className="form-check-radio">
                        <label className="form-check-label">
                            <input className="form-check-input" type="radio"
                                   id="publish" name="publish" value="approve" onClick={handleButton} onChange={handleButton} checked={publish}
                                   ref={register({required: (publish)})} />
                            {strings.publish}
                            <span className="form-check-sign">
                                                    <span className="check"></span>
                                                </span>
                        </label>
                    </div>
                    {errors.group &&
                    <span className="validation-error">{strings.required_validation}</span>
                    }
                </FormGroup>
            </div>
            }
            {/*<Button id="submit" block className="btn btn-round col-4 ml-auto mr-auto" color={labelColor}>
                {label}
            </Button>*/}
            {!editMode &&
            <Button className={`btn-round   btn-round ml-auto mr-auto col-md-6 mt-5`} color="tuv_red">
                {strings.save}
            </Button>
            }
            {editMode &&
            <div className={"ml-auto mr-auto col-12 d-block text-center"}>
                <Button className={`btn-round   col-3 mt-5`} color={labelColor}>
                    {label}
                </Button>
                <a href={`/generate-pdf/${certificate.id}`} target="_blank" className={`btn btn-round  btn-primary offset-1 col-5 mt-5 text-nowrap`} color={"info"}>
                    {strings.certificate_view_button}
                </a>
                {/*<Button className={`btn-round   col-4 mt-5`} color={"accent"}>
                    Import Certificate
                </Button>*/}
                <div className={"col-12 mt-5"}><Button block className="btn btn-round btn-info col-6 ml-auto mr-auto" color="danger"
                                    onClick={handleUpload}>{strings.certificate_import_button}</Button>
                    <Row>{
                        showUpload &&
                        <Col>
                            <Col className={"form-row"}>
                                <FormGroup className={"col-12"}>
                                    <label> {strings.scanned_certificate}</label>
                                    {certificate.status_id > 2 && <span
                                        className="badge badge-secondary mr-2 pr-1"><a
                                        href={`/view/certificate_preview/${certificate.id}`}
                                        target="_blank"
                                        rel="noreferrer noopener">{certificate && certificate.files.pop().name}</a></span>
                                    }
                                    <input style={{maxWidth: "30rem"}} type="file" id="certificate_scanned_file"
                                           name="certificate_scanned_file" ref={register({required: true})}/>
                                    {errors.certificate_scanned_file &&
                                    <span className="validation-error">{strings.required_validation}</span>
                                    }

                                </FormGroup>
                            </Col>
                            <Col className={"form-row"}>
                                <Button block className="btn btn-round btn-tuv_red col-5 offset-1 ml-auto mr-auto"
                                        color="tuv_red">
                                    {strings.certificate_upload_button}
                                </Button>
                                {/*{editMode && certificate.status_id > 2 &&
                                <a href={`/view/certificate_preview/${certificate.id}`} target="_blank"
                                   className={`btn btn-round  btn-primary offset-1 col-5`} color={"info"}>
                                    View Uploaded
                                </a>
                                }*/}
                            </Col>
                        </Col>
                    }</Row>
                </div>
                {/*{certificate.status_id === 2 &&
                <Button className={`btn-round   col-6 mt-5`} color={"success"}>
                    Export Certificate
                </Button>
                }*/}
            </div>
            }
        {/*<div id="myPage"><img src={require('../../../../storage/app/QFo_PCD_023_Rev00_Certificate - Γραμματέας Διοίκησης.png').default} alt='...' /></div>*/}
        </Form>
            }
        </OverlayLoader>
    </>)

}
