import React from "react";
import strings from "../../app/translations";
import PdfGenerator from "../Pdf/PdfGenerator";

export default function PdfMaker({title,message,participant,certificate,action,status,index}) {
// console.log('message');
// console.log(message);
    const redirect = () => window.location.href = action.link;
    return (
        <div className="modal fade mt-5" id={`pdf_maker_${index}`} tabIndex="-1" role="dialog" aria-labelledby="pdf_maker"
             aria-hidden="true">
            <div className="modal-dialog col-12" style={{maxWidth:"50rem"}} role="document">
                <div className="modal-content" style={{width:"50rem"}}>
                    <div className="modal-header">
                        <h5 className="modal-title" id="pdf_title">{title}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <PdfGenerator participant={participant} certificate={certificate}/>
                        {message.map((line,key) => {return <p key={key}>{line}</p>})}
                    </div>
                    <div className="modal-footer">
                        <div className={"left-side"}>
                            <button type="button" className="btn btn-default btn-link" data-dismiss="modal">{strings.close}
                            </button>
                        </div>
                        <div hidden={action ==="error"}><div className="divider"/>
                            <div className="right-side">
                                <button type="button" className="btn btn-danger btn-link" data-dismiss="modal">
                                    {action &&typeof action === 'object' && <a className={"nav-link"} target="_blank" onClick={redirect}>{action.label}</a>}
                                    {action && typeof action !== 'object' && action}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}
