import React, {useContext, useEffect} from "react";
import DocumentsArea from "../DocumentsArea";
import {Col} from "reactstrap";
import ParticipationContext from "../../../app/ParticipationContext";
import DocumentsIndex from "../../Sections/DocumentsIndex";
import strings from "../../../app/translations";

export default function ParticipationFormDocuments({userFiles, setFiles, register, errors, isReadOnly, users, setMessage}) {
    const {pState} = useContext(ParticipationContext);
    // documents of the eligible users
    const usersDocs = pState.user_documents;
    // documents attached to the application
    const documents = pState.currentItem ? pState.currentItem.data.documents : [];
    // status of the participation
    const [statusId, setStatusId] = React.useState();

    useEffect( function () {
        if (pState && pState.currentItem) {
            setStatusId(pState.currentItem.data.status_id);
        }
    }, [pState.currentItem]);
    // console.log('loaded');
    // console.log('users');
    // console.log(users);
    // const users = pState.users;
    return (<Col className="col-12 px-1 " id="participation_form_documents">

        <h5>{strings.documents}</h5>

        {(users.length > 0) ?
            users.map((user, key) => {
                // console.log('documents before document area in user');
                // console.log(documents);
                if (user) {
                    // console.log('user before document area in user');
                    // console.log(user);

                    const user_documents=documents.filter(document => document.request_order===user.user_id);

                    // console.log('user_documents before document area in user');
                    // console.log(user_documents);

                    let isActive = false;

                    // console.log(user);
                    // console.log('pState.users');
                    // console.log(pState.users);
                    let pUser = pState.users.filter((i) => {
                        return i.id === user.id
                    }).pop();
                    // console.log('pUser');
                    // console.log(pUser);
                    // console.log('userDocs before document area in user');
                    // console.log(usersDocs);
                    //debugger;
                    //user.files = Array.from(usersDocs).filter((doc,key) => key== user.user_id);
                    if(usersDocs.hasOwnProperty(user.user_id)){
                        user.files=[];
                        user.files[user.user_id] = Object.values(usersDocs[user.user_id]);
                    }
                    // console.log('the user Files');
                    // console.log(user.files);
                    /*user.files.map((userFile) => {
                        isActive = (userFile.isActive || userFile.test) ? true : false;
                        console.log('user filetest :' + userFile.test);
                    });*/
                    // console.log('isActive :' + isActive);

                    /*console.log('userisActive :'+ user.isActive);*/
                    return (
                        <div key={key} className={'mt-3'}>
                            <DocumentsIndex profilePage={false} user={user} documents={user_documents} setMessage={message => setMessage(message)} status={statusId}/>
                            <DocumentsArea profilePage={false} user={user} isActive={isActive} userFiles={userFiles}
                                           setFiles={setFiles} index={key} register={register}
                                           errors={errors} isReadOnly={isReadOnly} usersDocs={usersDocs}/>
                        </div>
                    )
                }
            }) : strings.no_users_selected
        }
    </Col>)
}
