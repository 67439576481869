import {Card, CardBody, CardHeader, Col, Collapse, FormGroup, Input, Label, Row} from "reactstrap";
import File from "./File";
import React from "react";
import propTypes from "prop-types";
import DownArrow from "../arrows/DownArrow";
import SelectOkCancel from "../SelectOkCancel";
import strings from "../../app/translations";

function Document({user,isOpen,index,register,errors,isReadOnly}) {
    // console.log('document props');

    const [count, setCount] = React.useState(1);
    const [files,setFiles] = React.useState([]);
    const [docName,setDocName] = React.useState("New Document");
    const [fileIsOpen, setFileIsOpen] = React.useState('init');
    const [selectStatus,setSelectStatus] = React.useState(true);
    // console.log(selectStatus);
    const updateOpenState = () => {
        setFileIsOpen(isOpen);
        return (fileIsOpen === 'init') ? (isOpen) : fileIsOpen
    };

    const increment = (/*e*/) => {
        if(!selectStatus){
            return;
        }
        console.log('what is setFiles');
        files.map((file, key) => {
            (files[key] = {id: file.id, isOpen: false, isActive: true})
        });
        files.push({id: user.birthDate, isOpen: true, isActive: true});
        setFiles(files);
        setCount(count + 1);
        updateOpenState();

    };

    const doNothing = (e) => {
        e.preventDefault();
    };

    const toggle = () => {
        setFileIsOpen(!fileIsOpen)
    };
    React.useEffect(function(){
        if(!selectStatus)
            setDocName(document.getElementById("participation_document_name_" + user.id + "_" + index).value);
        else
            setDocName(strings.new_document);
    },[selectStatus]);

    return (
        <Card className="col-md-12 mt-5">
            <CardHeader
                className="btn btn-round /*btn-outline-dark*/ text-dark ml-auto mr-auto col-md-4 col-xs-12 btn-secondary"
                onClick={toggle}
                style={{marginBottom: "1rem"}}>
                <i className="nc-icon nc-single-copy-04"/> {docName} {(!selectStatus) ? "(Ready)" : ""}
                <div style={{display: "inline-block", width: "20px", height: "20px"}}><DownArrow/></div>
            </CardHeader>
            <Collapse isOpen={(fileIsOpen === 'init') ? isOpen : fileIsOpen}>
                <CardBody disabled={!selectStatus}>
                    <div className="form-row ml-auto mr-auto">
                        <Col>
                            <FormGroup>
                                <Label for={"participation_document_name_" + user.id + "_" + index}>
                                    {strings.document_name}
                                    <Input id={"participation_document_name_" + user.id + "_" + index}
                                           name={"participation_document_name_" + user.id + "_" + index} placeholder={strings.new_document}
                                           innerRef={register({required: false})}/>
                                </Label>
                            </FormGroup>
                            <Row className={"form-group"} style={(selectStatus) ? {backgroundColor: "white"} : {
                                backgroundColor: "#e9ecef",
                                color: "grey"
                            }}>
                                {files.map((number, key) => {
                                    return (<File key={key} name={"File_" + user.id + "_" + index + "_" + key} selectStatus={selectStatus} register={register}/>)
                                })}
                                <FormGroup
                                    className="ml-auto mr-auto"
                                    style={{paddingTop: "2.5rem", display: "block", width: "100%"}}>
                                    <button type="button" className="btn btn-round btn-secondary ml-auto mr-auto"
                                            onClick={increment} disabled={!selectStatus}
                                    >{strings.add_file}
                                    </button>
                                </FormGroup>
                            </Row>
                        </Col>
                    </div>
                </CardBody>
                <SelectOkCancel setSelectStatus={setSelectStatus} selectStatus={selectStatus}/>
                {/*<Button id={"save_document_"+props.index} block className="btn btn-round col-4 ml-auto mr-auto mb-5"
                    color="tuv_red" onClick={doNothing}>
                Save
            </Button>*/}
            </Collapse>
        </Card>
    )
}

Document.propTypes = {
    isOpen: propTypes.any,
    title: propTypes.any
}
export default Document;
