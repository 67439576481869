import React, {useEffect} from "react";

import {Button, Form, FormGroup, Input, Label} from "reactstrap";
import EntitiesContext from "../../../app/context";
import Moment from "moment";
import ListGroupItem from "reactstrap/es/ListGroupItem";
import ListGroup from "reactstrap/es/ListGroup";
import AssignToClassrooms from "../AssignToClassrooms";
import {useForm} from 'react-hook-form';
import Modal from "../../Modals/Modal";
import PickExamDate from "../PickExamDate";
import ExamActivities from "../ExamActivities";
import strings from "../../../app/translations";
import PickExaminers from "../PickExaminers";
import PickInvigilators from "../PickInvigilators";
import TooltipWrap from "../TooltipWrap";
import OverlayLoader from "../../Loaders/OverlayLoader";

export default function ExaminationForm({disabledFields}) {
    const [isOpen, setIsOpen] = React.useState(false);
    const toggleIt = () => setIsOpen(!isOpen);
    const {state, dispatch} = React.useContext(EntitiesContext);
    const pagePathname = '/admin/examination-page';
    const editMode = (window.location.pathname !== pagePathname);
    const classrooms = state.classrooms.objects.map(object => object.data);
    const supervisors = state.supervisors.objects.map(object => object.data);
    const participations = state.participations.objects.map(object => object.data);
    const certifications = state.certifications.objects.map(object => object.data);

    const currentItem = state.currentItem;
    const [selectedCertification, setSelectedCertification] = React.useState({});
    const [certificationActivities, setCertificationActivities] = React.useState('');
    const activitiesByType = {B: ["Theoretical", "Practical"], T: ["Theoretical"], P: ["Practical"]};
    const [activities, setActivities] = React.useState(activitiesByType.T);
    const [componentsHeight, setComponentsHeight] = React.useState("25rem");
    /*console.log(activities);*/

    const [isRemote,setIsRemote] = React.useState(false);
    const [openDate,setOpenDate] = React.useState(false);
    const toggleRemote = () =>{
        setIsRemote(!isRemote);
    };
    const toggleOpenDate = () =>{
        setOpenDate(!openDate);
    };
    //Overlay Loader
    const [loading, setLoading] = React.useState(false);
//modal constants and function
    const [modalShow, setModalShow] = React.useState(false);
    const [message, setMessage] = React.useState([]);
    const [action, setAction] = React.useState('');
    const [selectedExaminers, setExaminers] = React.useState([]);
    const [selectedInvigilators, setInvigilators] = React.useState([]);

    // console.log('supervisors');
    // console.log(supervisors);

    const examiners = supervisors.filter(supervisor => supervisor.role === 'examiner');
    const invigilators = supervisors.filter(supervisor => supervisor.role === 'invigilator');

    // console.log('examiners');
    // console.log(examiners);

    // console.log('invigilators');
    // console.log(invigilators);
    const [status,setStatus] = React.useState('');

    const [selectedDate, setSelectedDate] = React.useState(Moment().format('YYYY-MM-DD'));
    // console.log('selectedDAte after re-render');
    // console.log(selectedDate);


    function chooseCertification(e) {
        const certification_id = e.target.value;
        handleCertification(certification_id)
    }

    function handleCertification(certification_id) {

        /*console.log("e.target.getAttribute('id')");
        console.log(certification_id);*/
        certifications.map((certification) => {

            if (parseInt(certification.id) === parseInt(certification_id)) {
                setSelectedCertification(certification);

                setActivities(activitiesByType[certification.type]);

                let displayValue = (certification.type === "B") ? "both" : activitiesByType[certification.type];
                setCertificationActivities(displayValue);
                let height = (certification.type === "B") ? "45rem" : "35rem";
                setComponentsHeight(height);
            }
        })
    }

    const isReadOnly = (name) => {
        if (editMode)
            return disabledFields.indexOf(name) !== false
    }

    function showModal(data) {
        let msg = [];
        if (data.errors) {
            // console.log('data.errors');
            // console.log(data.errors);
            Object.values(data.errors).map(i => i.map(m => {
                return msg.push(m)
            }));
        } else if(data.service_error) {
                msg.push(data.service_error.title);
                msg.push(data.service_error.message);
        } else {
            console.log('data.records')
            console.log(data.records)
            if (typeof data.records === 'object') {
                console.log(typeof data.records);
                console.log(Object.values(data.records));
                Object.values(data.records).map((record, key) => {
                    let objKey = Object.keys(data.records)[key];
                    console.log(objKey);
                    if (objKey === 'exam_activities') {
                        record.data.map(activity => {
                            msg.push(strings.formatString(strings.examination_with_activity, {
                                type: activity.type, start_time: activity.start_time
                            }));
                        })
                    } else if (objKey === 'examination')
                        msg.push(strings.formatString(strings.examination_with_title_and_date, {
                            title: record.data.title, date: record.data.date
                        }));
                    return msg
                });
            } else
                Object.values(data.records).map((record, key) => {
                    console.log(record);
                    // console.log('key');
                    // console.log(Object.keys(data.records)[key]);
                    let type;
                    let objKey = Object.keys(data.records)[key];
                    type = (key.length > 1) ? key : '';
                    if (objKey === "certification_activities") {
                        Object.values(record).map((activity, k) => {
                            // console.log(activity);
                            msg.push(`The ${type} ${activity[k].type} with name ${activity[k].name}`);
                            msg.push(strings.formatString(strings.examination_with_name, {
                                type:type, activity_type: activity[k].type, name: activity[k].name
                            }));
                        })
                    } else if (objKey === "certification_details") {
                        msg.push(strings.formatString(strings.examination_title_gr_detail, {
                            title_gr_1: record.data.title_gr, title_gr_2: record.data.title_gr
                        }));

                        return msg
                    }
                });
            if (editMode) {
                msg.push(strings.edited_success);
            } else
                msg.push(strings.saved_success);
        }
        // console.log('msg');
        // console.log(msg);


        let act = null;
        if (editMode)
            act = {link: pagePathname, label: strings.continue};
        else
            act = (data.errors) ? ('error') : {link: pagePathname, label: strings.continue};

        setAction(act);
        setMessage(msg);
        setModalShow(true);
        $('#appModal').modal({show: true});
        // console.log('show');
        // console.log(msg);
        setLoading(false);
    }


    function loadCurrentItem() {

        // console.log('loaded');

        if (currentItem.data) {
            if(currentItem.data.status_name.toLowerCase() === 'published')
                setStatus('published');
            document.getElementById('examination_title').value = currentItem.data.title;
            let select = document.getElementById('examination_certification_id');
            Array.from(select.options).map((option, key) => {
                if (parseInt(option.value) === parseInt(currentItem.data.certification_id)) {
                    // console.log('certification.id selectedIndex');
                    // console.log(key);
                    select.selectedIndex = key;
                }
            });
            setSelectedCertification(currentItem.data.certification);
            // console.log(currentItem.data);
            // console.log(Moment(currentItem.data.date).format('YYYY-MM-DD'));
            let momentCurrentItemDate = new Moment(currentItem.data.date, 'YYYY-MM-DD');
            let t = momentCurrentItemDate._d;
            setSelectedDate(currentItem.data.date);

            setActivities(activitiesByType[currentItem.data.certification.type]);
            let displayValue = (currentItem.data.certification.type === "B") ? "both" : activitiesByType[currentItem.data.certification.type];
            setCertificationActivities(displayValue);
            let options = document.getElementById("examination_certification_id").options;
            if (options.length > 0 && options.selectedIndex !== -1) {
                let height = (options[options.selectedIndex].getAttribute("name") === "B") ? "45rem" : "35rem";
                setComponentsHeight(height);
            }
            setExaminers(currentItem.data.examiners);
            setInvigilators(currentItem.data.invigilators);
            if(parseInt(currentItem.data.is_remote)>0){
                setIsRemote(true);
                document.getElementById('examination_is_remote').setAttribute('checked','true');
            }
            if(parseInt(currentItem.data.open_date)>0){
                setOpenDate(true);
                document.getElementById('examination_open_date').setAttribute('checked','true');
            }


            document.getElementById('examination_perma_link').value = currentItem.data.perma_link;
            console.log('selectedDate in currentItem');
            console.log(Moment(momentCurrentItemDate._d).format('YYYY-MM-DD'));
            // console.log('typeof momentCurrentItemDate');
            // console.log(momentCurrentItemDate.format('YYYY-MM-DD'));
            document.getElementById('selected_interest_date').value = Moment(momentCurrentItemDate._d).format('YYYY-MM-DD');
            document.getElementById('examination_date').value = currentItem.data.date;
            document.getElementById('activity_type').value = certificationActivities;
            let classroom_ids = (currentItem.data.classrooms !== null) ?
                currentItem.data.classrooms.map(classroom => classroom.id) : [];

            document.getElementById('examination_classrooms').value = JSON.stringify(classroom_ids);
            let participation_ids = (currentItem.data.participations !== null) ?
                currentItem.data.participations.map(participation => participation.id) : [];
            document.getElementById('examination_participations').value = JSON.stringify(participation_ids);

            document.getElementById('examination_examiners').value = JSON.stringify(selectedExaminers);
//           document.getElementById('examination_invigilators').value = JSON.stringify(selectedInvigilators);

            //ExamActivities values are added within their own Component as they do not exist in the DOM.
            //<ExamActivityDate/>
            setLoading(false);
        }
        // console.log('load end');
        // console.log(currentItem);
    }

    const [label, setLabel] = React.useState('submit');
    const [labelColor, setLabelColor] = React.useState('tuv_red');
    const [publish, setPublish] = React.useState(false);
    const handleButton = (e) => {
        if (e.target.id === "publish") {
            setRoute('/api/examination/publish');
            setLabel(strings.publish);
            setLabelColor('success');
            setPublish(true);
        } else {
            setRoute('/api/examination/create');
            setLabel(strings.submit);
            setLabelColor('tuv_red');
            setPublish(false);
        }
        /*Array.from(document.querySelectorAll('#form-actions input')).map(child => {
            document.querySelectorAll(`#form-actions input#${child.id}`).checked = child.id === e.target.id;
        });*/

    };

    useEffect(function () {
        if (editMode) {
            setLoading(true);
            loadCurrentItem();
        }

    }, [currentItem]);
    useEffect(function () {
        // console.log('selectedDate in effect');
        // console.log(selectedDate);
        let res = (typeof selectedDate === 'string') ? selectedDate : 'test'/*Moment(selectedDate).format('YYYY-MM-DD')*/;

        // console.log(res);
        document.getElementById('selected_interest_date').value = res;
        document.getElementById('activity_type').value = certificationActivities;
        if (currentItem && currentItem.data) {

            let classroomParticipations = {};
            if (currentItem.data.classrooms !== null) {
                classroomParticipations.classroomsList = currentItem.data.classrooms;
            }
            if (currentItem.data.participations !== null) {
                classroomParticipations.participationsList = currentItem.data.participations;
            }
            Object.keys(classroomParticipations).map(list => {
                // console.log(document.getElementById("classroomsList"));
                // console.log(document.getElementById("participationsList"));
                /*console.log(list.toString());
                console.log(JSON.parse(list).stringify);*/
                // console.log(document.getElementById(list.toString()));
                Array.from(document.getElementById(list.toString()).options).map((option, key) => {

                    let object = classroomParticipations[list].filter(object => {
                        if (parseInt(object.id) === parseInt(option.id)) {
                            document.getElementById(list.toString()).options[key].selected = true;
                            return object
                        }

                    }).pop();
                });
            })
            if(status==='published'){
                setRoute('/api/examination/publish');
                setLabel(strings.publish);
                setLabelColor('success');
                setPublish(true);
            }
        }
    }, [selectedDate, publish, componentsHeight, state, activities]);
    const [route, setRoute] = React.useState('/api/examination/create');
    const {register, handleSubmit, watch, errors} = useForm();
    const onSubmit = (data, event) => {
        // console.log('data sent in handlesubmit');
        // console.log(data);
        setLoading(true);
        state.handleSubmit(data, route, showModal, event)
    };

    console.log('participations');
    console.log(participations);

    return (<>
        <OverlayLoader text={strings.load} active={loading}>
            <form className="pt-5 pb-3" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row col-md-12">
                    <div className="form-group col-md-6">
                        <div style={{
                            minHeight: `${componentsHeight}`,
                            maxHeight: `${componentsHeight}`,
                            padding: "1rem",
                            border: "1px solid rgba(0,0,0,.125)"
                        }}>
                            <div className="form-group col-12">
                                <label htmlFor="examination_title">{strings.examination_title}</label>
                                <input type="text" className="form-control" id="examination_title" name="examination_title"
                                       placeholder={strings.examination_title} ref={register({required: true})}
                                       readOnly={status === "published"}/>
                                {errors.examination_title &&
                                <span className="validation-error">{strings.required_validation}</span>}
                            </div>
                            <div className="form-group col-12">
                                <label htmlFor="examination_certification_id">{strings.select_certification}</label>
                                <select className="form-control" id="examination_certification_id"
                                        name={"examination_certification_id"}
                                        ref={register({required: true})} onChange={chooseCertification} readOnly={status === "published"}>
                                    <option key={0}>{strings.choose}</option>
                                    {certifications && certifications.map((certification, key) =>
                                        <option key={key} name={certification.type}
                                                value={certification.id}>{certification.title}</option>
                                    )
                                    }
                                </select>
                            </div>
                            <FormGroup className="form-group col-md-12">
                                {
                                    <PickExamDate register={register} errors={errors}
                                                  setComponentsHeight={setComponentsHeight}
                                                  setSelectedDate={setSelectedDate} selectedDate={selectedDate}
                                                  status={status}/>
                                }
                            </FormGroup>
                            <div className="form-group col-12">
                                <div className="form-row">
                                    <label htmlFor="certified_by">{strings.activity_type}</label>
                                    <input type="text" className="form-control" id="activity_type" name="activity_type"
                                           placeholder={strings.activity_type} value={certificationActivities} readOnly/>
                                    {errors.activity_type &&
                                    <span className="validation-error">{strings.required_validation}</span>}
                                </div>
                                <div className="form-row">
                                    <ExamActivities activities={activities} selectedDate={selectedDate}
                                                    activitiesByType={activitiesByType} currentItem={currentItem}
                                                    register={register} errors={errors} status={status}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-6">
                        <div style={{
                            minHeight: `${componentsHeight}`,
                            maxHeight: `${componentsHeight}`,
                            padding: "1rem",
                            border: "1px solid rgba(0,0,0,.125)"
                        }}>
                            <label htmlFor={"interest_dates"}>{strings.dates_of_interest}</label>
                            <div id="interest_dates"
                                 style={{
                                     minHeight: `${parseInt(componentsHeight) - 5}rem`,
                                     maxHeight: `${parseInt(componentsHeight) - 5}rem`,
                                     overflow: "hidden auto",
                                     scrollbarWidth: "thin"
                                 }}>
                                <ListGroup className="  col-md-12 d-md-inline">
                                    {selectedCertification && participations.map((participation, key) => {
                                        if (selectedCertification.id === participation.certification_id) {
                                            return <ListGroupItem key={key}>
                                                <span style={{color: "blue"}}>{participation.participation_form_date}</span>
                                                <span
                                                    style={{color: "blue"}}>({participation.num_of_participants})</span>
                                                {`| ${participation.name}`}
                                                <span style={{ }} className={'text-left'}>{` | ${participation.status_name}`}</span>
                                            </ListGroupItem>
                                        }

                                    })}
                                </ListGroup>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-row col-md-12">

                </div>
                <AssignToClassrooms classrooms={classrooms} toggleIt={toggleIt} register={register} errors={errors}
                                    participations={participations} selectedCertification={selectedCertification} isReadOnly={isReadOnly} isRemote={isRemote}/>
                <div className="form-row col-md-12">
                    <PickExaminers examiners={examiners} selectedExaminers={selectedExaminers} register={register}
                                   errors={errors}/>
                    <PickInvigilators invigilators={invigilators} selectedInvigilators={selectedInvigilators}
                                      register={register} errors={errors}/>
                </div>
                <div className="form-row col-md-12 text-center">
                    <div className="form-group col-md-6">
                        <label htmlFor="examination_perma_link">Permalink</label>
                        <input type="text" name="examination_perma_link" className="form-control"
                               id="examination_perma_link" disabled={false}
                               placeholder="perma-link" ref={register({required: true,
                            pattern: /^[a-zA-Z\d\_\-\#\s]+$/, minLength: 3})}
                        />
                        {errors.examination_perma_link && errors.examination_perma_link.type==='required' && <span className="validation-error">{strings.required_validation}</span>}
                        {errors.examination_perma_link && errors.examination_perma_link.type==='pattern' && <span className="validation-error">{strings.pattern_validation}</span>}
                        {errors.examination_perma_link && errors.examination_perma_link.type==='minLength' && <span className="validation-error">{strings.permalink_minlength_validation}</span>}
                        <TooltipWrap target={"examination_perma_link"} content={
                            <>
                                <p>- Must be alpharithmetic characters.</p>
                                <p>- Must be at least 3 characters.</p>
                                <p>- May contain special characters: dash(-), underscore(_), hash(#).</p>
                            </>
                        }/>
                    </div>
                </div>
                <div className="form-row col-md-12 text-center">
                    <FormGroup className="col-md-6" check>
                        <Label for="examination_is_remote" check>
                            {isRemote &&
                            <Input id="examination_is_remote" name={"examination_is_remote"} onChange={toggleRemote} checked type="checkbox" disabled={status==="published"} />
                            }
                            { isRemote === false &&
                            <Input id="examination_is_remote" name={"examination_is_remote"} onChange={toggleRemote} type="checkbox" disabled={status==="published"} />
                            }

                            {" "}
                            {strings.remote}
                            <span className="form-check-sign">
                                                        <span className="check"></span>
                                                    </span>
                        </Label>
                    </FormGroup>
                    {/*<FormGroup className="col-md-4" check>
                        <Label for="examination_grade_auto" check>
                            <Input id="examination_grade_auto" name={"examination_grade_auto"} type="checkbox"/>{" "}
                            {strings.grade_auto}
                            <span className="form-check-sign">
                                                        <span className="check"></span>
                                                    </span>
                        </Label>
                    </FormGroup>*/}
                    <FormGroup className="col-md-6" check>
                        <Label for="examination_open_date" check>
                            {openDate &&
                            <Input id="examination_open_date" name={"examination_open_date"} onChange={toggleOpenDate} checked
                                   type="checkbox" disabled={status==="published"}/>
                            }
                            { openDate === false &&
                            <Input id="examination_open_date" name={"examination_open_date"} onChange={toggleOpenDate}
                                   type="checkbox" disabled={status==="published"}/>
                            }
                            {" "}
                            {strings.open_date}
                            <span className="form-check-sign">
                                                        <span className="check"></span>
                                                    </span>
                        </Label>
                    </FormGroup>
                </div>
                {editMode &&
                <div className="form-row mt-3 justify-content-center text-center" id="form-actions">
                    <FormGroup className="col-md-4" check>
                        <div className="form-check-radio">
                            <label className="form-check-label">


                                <input className="form-check-input" type="radio"
                                       id="submit" name="submit" value="submit" onClick={handleButton}
                                       onChange={handleButton} checked={(!publish && status!=='published')}
                                       ref={register({required: ((!publish && status!=='published'))})}/>

                                {" "}
                                Submit
                                <span className="form-check-sign">
                                                        <span className="check"></span>
                                                    </span>
                            </label>
                        </div>
                        {errors.submit &&
                        <span className="validation-error">{"This field is required"}</span>
                        }
                    </FormGroup>
                    <FormGroup className="col-md-4" check>
                        <div className="form-check-radio">
                            <label className="form-check-label">
                                <input className="form-check-input" type="radio"
                                       id="publish" name="publish" value="approve" onClick={handleButton}
                                       onChange={handleButton} checked={(publish||status==='published')}
                                       ref={register({required: (publish||status==='published')})}/>
                                Publish
                                <span className="form-check-sign">
                                                        <span className="check"></span>
                                                    </span>
                            </label>
                        </div>
                        {errors.group &&
                        <span className="validation-error">{strings.required_validation}</span>
                        }
                    </FormGroup>
                </div>
                }
                {/*<Button id="submit" block className="btn btn-round col-4 ml-auto mr-auto" color={labelColor}>
                    {label}
                </Button>*/}
                {!editMode &&
                <Button block className="btn-round ml-auto mr-auto col-md-2 mt-5" color="tuv_red">
                    {strings.save}
                </Button>
                }
                {editMode &&
                <div className={"ml-auto mr-auto col-md-6 d-block text-center"}>
                    <Button className={`btn-round   col-md-4 mt-5`} color={labelColor}>
                        {label}
                    </Button>
                    <Button className="btn-round offset-md-1 col-md-4 mt-5" color="secondary"><a href={pagePathname}
                                                                                                 style={{color: "white"}}>
                        Back to Main</a>
                    </Button>
                </div>
                }

            </form>
        </OverlayLoader>
        <Modal action={action} message={message} title={strings.examination_status}/>
    </>)
}
