import {Card, CardTitle, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import TableApp from "../Datatable/TableApp";
import React, {useContext, useEffect, useState} from "react";
import EntitiesContext from "../../app/context";
import classnames from "classnames";
import EnrollmentForm from "../Forms/admin/EnrollmentForm";
import strings from "../../app/translations";
export default function ManageEnrollments({getItem}){

    const {state/*,dispatch*/} = useContext(EntitiesContext);
    console.log(state.enrollments.objects);
    state.enrollments.objects = Array.from(state.enrollments.objects);

    const [objects,setObjects] = useState(state.enrollments.objects.map(object => {
        console.log(object);
        object.data.action = state.actionButton(object,true,false,false);
        return  object.data;
    }));
    const columns = state.enrollments.columns;
    const [sectionTab, setSectionTab] = React.useState('1');
    const sectionToggle = tab => {
        if (sectionTab !== tab) setSectionTab(tab);
    };

    const disabledFields = ['certification_category_id','certification_title','certification_code'];
    useEffect(() => {
            console.log("state.enrollments use effect");
            console.log(objects);
            setObjects(state.enrollments.objects.map(object => {
                console.log(object);
                return object.data
            }));
        }
        , [state]);
    return(<Row className="custom-column"><Col><h2>Manage {state.enrollments.cardTitle}</h2>
            <div className="form-group col-md-12">
                <Nav tabs className="justify-content-center">

                    <NavItem>
                        <NavLink
                            className={classnames({active: sectionTab === '1'})}
                            onClick={() => {
                                sectionToggle('1');
                            }}
                        >
                            {state.enrollments.cardTitle}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({active: sectionTab === '2'})}
                            onClick={() => {
                                sectionToggle('2');
                            }}
                        >
                            Edit {state.enrollments.cardTitle}
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={sectionTab}>
                    <TabPane tabId="1">
                        <Row>
                            <Col className="ml-auto mr-auto offset-lg-1" lg="12">
                                <Row style={{display: "block"}}>
                                    <Col sm="12">
                                        <Card body className="card-plain">
                                            <CardTitle><h3
                                                className="text-danger ml-auto mr-auto">{state.enrollments.cardTitle}</h3>
                                            </CardTitle>
                                            {strings.here_is_a_list} {strings.enrollments}
                                            <div className='criteria'>
                                                <h3 className="text-danger mb-1"></h3>
                                                <Row className="col-md-12">
                                                    <TableApp onEdit={() => {
                                                        sectionToggle('1');
                                                    }}
                                                              names={objects} columns={columns}/>
                                                </Row>
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2">
                        <EnrollmentForm getItem={getItem} disabledFields={disabledFields}/>
                    </TabPane>

                </TabContent>

            </div></Col>
        </Row>
        )
}
