import {Card, CardBody, CardHeader, CardTitle, Collapse, FormGroup, Input, Label} from "reactstrap";
import React from "react";
import strings from "../../app/translations";

export default function AssignToClassrooms({classrooms,participations,toggleIt,selectedCertification,register,errors,isReadOnly,isRemote}) {
    const [participants,setParticipants] = React.useState(0);
    const [capacity,setCapacity] = React.useState(0);
    const [message,setMessage] = React.useState('');
    let firstTime = true;
    //const [seatsRemaining,setRemainingSeats] = React.useState(capacity);

    function checkCapacity(){
        if(capacity<participants){
            return setMessage("participants number exceeds capacity. please choose more classrooms");
        } else {
        return setMessage('');
        }
    }
    function addNumbers(e) {
        multipleSelectValues();
        let options = e.target.options;
        let value = [];
        let sum = 0;
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                console.log(options[i].dataset.content);
                value.push({id:parseInt(options[i].id), quantity:options[i].dataset.content, value: options[i].value});
            }
        }
        value.map((option) =>{
            sum += parseInt(option.quantity);
        });
        if(e.target.id === "selectClassrooms")
            setCapacity(sum);
        else if(e.target.id === "selectParticipations"){
            setParticipants(sum);
        }
        // console.log(sum);
        return value;
    }

    function multipleSelectValues(){
        let classrooms = [];
        Array.from(document.getElementById("classroomsList").options).map(option =>{
            if(option.selected)
                classrooms.push(option.value)
        });
        document.getElementById("examination_classrooms").value = JSON.stringify(classrooms);
        let participations = [];
        Array.from(document.getElementById("participationsList").options).map(option =>{
            if(option.selected)
                participations.push(option.value)
        });
        document.getElementById("examination_participations").value = JSON.stringify(participations);
    }
    React.useEffect(function(){
        checkCapacity();
    },[participants,capacity]);
    return (<div className="form-row col-md-12">
        <Card className="col-md-6 card-plain">
            <CardHeader onClick={toggleIt} style={{marginBottom: "1rem"}}>
                <i className="nc-icon nc-single-copy-04"/> {strings.classrooms}
            </CardHeader>
            <Collapse isOpen={true}>
                <CardBody>
                    <CardTitle>{strings.classrooms}</CardTitle>
                    <FormGroup>
                        <Label for="classroomsList">{strings.available_classrooms}</Label>
                        <Input type="select" name="classroomsList"
                               id="classroomsList"
                               innerRef={register({required: !isRemote})}
                               multiple onChange={addNumbers}  readOnly={isRemote}>
                            {classrooms.map((classroom, key) => {
                                //console.log(classroom);
                                return <option key={key} id={classroom.id} data-content={classroom.capacity} className={"list-item-style"} value={classroom.id}>
                                    {classroom.name}, {strings.seats_remaining}
                                    ({classroom.seats_remaining}), {classroom.address} </option>
                            })}

                        </Input>
                        {message.length>0 &&  <span className="validation-error">{message}</span>}
                        {errors.classroomsList && <span className="validation-error">{strings.required_validation}</span>}
                        <input hidden id="examination_classrooms" ref={register({ required: false })} name="examination_classrooms"/>

                    </FormGroup>
                </CardBody>
            </Collapse>
        </Card>
        <Card className="col-md-6 card-plain">
            <CardHeader onClick={toggleIt} style={{marginBottom: "1rem"}}>
                <i className="nc-icon nc-single-copy-04"/> {strings.participation_forms}
            </CardHeader>
            <Collapse isOpen={true}>
                <CardBody>
                    <CardTitle>{strings.participation_forms}</CardTitle>
                    <FormGroup>

                        <Label for="participationsList">{selectedCertification && participations.map((participation, key) => {

                            if (firstTime && selectedCertification.id === participation.certification_id && participation.enrollment_status === 're_enroll') {
                                firstTime = false;
                                return <span className={"badge badge-info"}>re-enrollments</span>
                            }
                        })}{strings.available_participation_forms}</Label>
                        <Input type="select" name="participationsList"
                               id="participationsList" onChange={addNumbers}
                               innerRef={register({ required: false })}
                               multiple>
                            {selectedCertification && participations.map((participation, key) => {
                                    if (selectedCertification.id === participation.certification_id) {
                                        if(participation.enrollment_status === 're_enroll')
                                            return <><option key={key} id={participation.id} data-content={participation.num_of_participants} className={"list-item-style"} value={participation.id}>
                                                {`re-enroll | ${participation.name}`}
                                            </option></>;
                                        else
                                            return <option key={key} id={participation.id} data-content={participation.num_of_participants} className={"list-item-style"} value={participation.id}>
                                            {/*{`${participation.participation_form_date} (${participation.num_of_participants}) ${strings.participants}*/}
                                            {`${participation.name}`}
                                        </option>
                                    }
                                }
                            )}
                        </Input>
                        {errors.participationsList && <span className="validation-error">{strings.required_validation}</span>}
                        <input hidden id="examination_participations" ref={register({ required: false })} name="examination_participations"/>
                    </FormGroup>
                </CardBody>
            </Collapse>
        </Card>
    </div>)
}
