import {Col, FormGroup, Input, InputGroup, Label, Row} from "reactstrap";
import Datetime from "react-datetime";
import React, {useContext, useState, useEffect} from "react";
import propTypes from "prop-types";
import ParticipationContext from "../../../app/ParticipationContext";
import strings from "../../../app/translations";
import Moment from "moment";

function ParticipationFormDetails({formType, companyPhone, setcompanyPhone, vatNumber, certification, register, errors, isReadOnly}) {
    const auth_company = window.reactInit.company_id;
    const {pState, pDispatch} = useContext(ParticipationContext);
    const [type, setType] = useState('');
    const [dateRange, setDateRange] = useState(true);
    const [startDate,setStartDate] = useState('');
    const [endDate,setEndDate] = useState('');
    const [note,setNote] = useState('');
    const [comment,setComment] = useState('');
    const [discount,setDiscount] = useState('');
    const [participationName,setParticipationName] = useState('');
    const [role,setRole] = useState({});
    const [phone,setPhone] = useState(companyPhone);

    // console.log(pState);
    function getCertificationType() {
        if (certification.type === 'T') {
            return setType('Theoretical');
        } else if (certification.type === 'P') {
            return setType('Practical');
        } else if (certification.type === 'B') {
            return setType('Theoretical & Practical');
        } else {
            return setType('');
        }
    }

    function handleDateRange() {
        setDateRange(!dateRange);
    }

    function changeStartDate(e){
        let d = new Moment(e._d,'LLLL').format('lLT');
        setStartDate(d);
    }

    function changeEndDate(e){
        let d = new Moment(e._d,'LLLL').format('lLT');
        setEndDate(d);
    }

    useEffect(function () {
        getCertificationType();

    });
    useEffect(function () {
        if (!pState.currentItem && !pState.currentItem.data) {
            setPhone(companyPhone);
        }
        if (pState.currentItem && pState.currentItem.data) {
            setStartDate(pState.currentItem.data.start_date);
            setEndDate(pState.currentItem.data.end_date);
            setDateRange(pState.currentItem.data.date_type === 'date_range');
            setDiscount(pState.currentItem.data.discount)
            setNote(pState.currentItem.data.note)
            setComment(pState.currentItem.data.comment)
            setParticipationName(pState.currentItem.data.name)
            setPhone(pState.currentItem.data.phone)
        }
        if (pState.user && pState.user.roles)
            pState.user.roles.map(role => {
                if(role.name === 'moderator')
                    setRole('moderator');
            })

    }, [pState.currentItem, pState.user, companyPhone]);

    return (
        <div id="participation-form-details">
            <div className="form-row">
                <div className="arrow-head"></div>
                <h5>{strings.examination} ({type})</h5>
                <div className="form-row col-12">
                    <FormGroup className="col-4" check>
                        <Label check>
                            <Input type="checkbox" id="single_date" name={"single_date"}
                                   onChange={handleDateRange} autoComplete="off" disabled={isReadOnly("single_date")}/>{" "}
                            {strings.single_date_instead}
                            <span className="form-check-sign">
                                            <span className="check"></span>
                                        </span>
                        </Label>
                    </FormGroup>
                    <FormGroup className="col-4">
                        <label>{strings.start_date} (*)</label>
                        <InputGroup className="text-body">
                            <Datetime
                                inputProps={{
                                    id: "participation_start_date",
                                    name: "participation_start_date",
                                    placeholder: strings.request_date_range,
                                    ref: register({required: (!isReadOnly("participation_exam_start_date"))}),
                                    value:`${startDate}`,
                                    autoComplete: "off",
                                    readOnly: isReadOnly("participation_start_date")
                                }}
                                onChange={changeStartDate}
                            />
                            {errors.participation_start_date &&
                            <span className="validation-error">{strings.required_validation}</span>
                            }
                        </InputGroup>
                    </FormGroup>
                    {dateRange &&
                    <FormGroup className="col-4">
                        <label>{strings.end_date}</label>
                        <InputGroup className="text-body">
                            <Datetime
                                inputProps={{
                                    id: "participation_end_date",
                                    name: "participation_end_date",
                                    placeholder: "Request Date Range",
                                    ref: register({required: dateRange}),
                                    value:`${endDate}`,
                                    autoComplete: "off",
                                    readOnly: isReadOnly("participation_end_date")
                                }}
                                onChange={changeEndDate}
                            />
                            {errors.participation_end_date &&
                            <span className="validation-error">{"This field is required"}</span>
                            }
                        </InputGroup>
                    </FormGroup>}
                </div>
            </div>
            <div className="form-row">
                {/*{auth_company.length > 0 &&
                <FormGroup className={auth_company.length > 0 ? "col-4" : "col-6"}>
                    <label>Company</label>
                    <Input placeholder="Company" type="text" id="participation_company" name="participation_company"
                           innerRef={register({required: (!isReadOnly("participation_company"))})}/>
                    {errors.participation_company &&
                    <span className="validation-error">{"This field is required"}</span>
                    }
                </FormGroup>
                }*/}
                { <>
                <FormGroup className={"col-6"}>
                    <label> {strings.profile_mobile}:</label>
                    <Input placeholder={strings.profile_mobile} type="phone" id="participation_phone" name="participation_phone"
                           readOnly={isReadOnly("participation_phone")}
                           innerRef={register({required: (!isReadOnly("participation_phone"))})} defaultValue={phone}/>
                    {errors.participation_phone &&
                    <span className="validation-error">{"This field is required"}</span>
                    }
                </FormGroup>
                <FormGroup className={"col-6"}>
                    <label>{strings.vat_reg_num}</label>
                    <Input placeholder={strings.vat_reg_num} type="text" id="participation_vat_reg_no"
                           name="participation_vat_reg_no"
                           readOnly={isReadOnly("participation_vat_reg_no")}
                           innerRef={register({required: (!isReadOnly("participation_vat_reg_no"))})} defaultValue={vatNumber}/>
                    {errors.participation_vat_reg_no &&
                    <span className="validation-error">{strings.required_validation}</span>
                    }
                </FormGroup>
                </>
                }
            </div>
            <div className="form-row">
                <FormGroup className={role === 'moderator' ? "col-4" : "col-6"}>
                    <label>{strings.notes}</label>
                    <Input type="textarea" name="text" id="participation_note" name="participation_note"
                           innerRef={register({required: false})} defaultValue={note}
                           readOnly={isReadOnly("participation_note")}
                           placeholder={strings.participation_notes}/>
                    {errors.participation_note &&
                    <span className="validation-error">{"This field is required"}</span>
                    }
                </FormGroup>
                <FormGroup className={role === 'moderator' ? "col-4" : "col-6"}>
                    <label>{strings.comments}</label>
                    <Input type="textarea" name="text" id="participation_comment" name="participation_comment"
                           innerRef={register({required: false})}
                           placeholder={strings.moderator_comments} readOnly={role !== 'moderator' } defaultValue={comment}/>
                    {errors.participation_comment &&
                    <span className="validation-error">{"This field is required"}</span>
                    }
                </FormGroup>
                {
                    (role === "moderator") &&
                    <FormGroup className={"col-4"}>
                        <label>{strings.final_price}</label>
                        <Input type="text" name="text" id="participation_discount" name="participation_discount"
                               innerRef={register({required: true,valueAsNumber:true})}
                               placeholder="Final price for this participation" defaultValue={discount}/>
                        {errors.participation_discount &&
                        <span className="validation-error">{"This field must be a number"}</span>
                        }
                    </FormGroup>
                }
                <Input type="hidden" name="text" id="participation_name" name="participation_name"
                       innerRef={register({required: false})}
                       placeholder="Discount by the moderator" defaultValue={participationName}/>
            </div>
        </div>);

    //todo add different dates per activity
    /*return (
        <Col id="participation-form-details">

            {activities.map((exam, key) => {
                key = key + 1;
                if (activity.type === "theoretical") {
                    return (
                        <Row key={key}>
                            <h5>Activity {key} (Theoretical)</h5>
                            <div className="form-row">
                                <FormGroup className="col-md-4" check>
                                    <Label check>
                                        <Input type="checkbox"/>{" "}
                                        Single Date Instead
                                        <span className="form-check-sign">
                                            <span className="check"></span>
                                        </span>
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-md-4">
                                    <label>Start Date(*)</label>
                                    <InputGroup>
                                        <Datetime
                                            inputProps={{placeholder: "Start Date/Request Date"}}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup className="col-md-4">
                                    <label>End Date</label>
                                    <InputGroup>
                                        <Datetime
                                            inputProps={{placeholder: "Request Date Range"}}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </div>
                        </Row>
                    )
                }
                if (exam.practical) {
                    return (
                        <Row key={key}>
                            <h5>Exam {key} (Practical)</h5>
                            <div className="form-row">
                                <FormGroup className="col-md-4" check>
                                    <Label check>
                                        <Input type="checkbox"/>{" "}
                                        Single Date Instead
                                        <span className="form-check-sign">
                                            <span className="check"></span>
                                        </span>
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-md-4">
                                    <label>Start Date(*)</label>
                                    <InputGroup>
                                        <Datetime
                                            inputProps={{placeholder: "Start Date/Request Date"}}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup className="col-md-4">
                                    <label>End Date</label>
                                    <InputGroup>
                                        <Datetime
                                            inputProps={{placeholder: "Request Date Range"}}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </div>
                        </Row>
                    )
                }
            })}

            <div className="form-row">
                <FormGroup className="col-md-4">
                    <label>Company</label>
                    <Input placeholder="Company" type="text"/>
                </FormGroup>
                <FormGroup className="col-md-4">
                    <label> Τηλέφωνο επικοινωνίας (κινητό) / Mobile:</label>
                    <Input placeholder="mobile" type="phone"/>
                </FormGroup>
                <FormGroup className='col-md-4'>
                    <label> ΑΦΜ / Vat Reg. Number</label>
                    <Input placeholder="Vat Reg Number" type="text"/>
                </FormGroup>
            </div>
            <div className="form-row">
                <FormGroup className="col-md-6">
                    <label>Σημείωση / Notes</label>
                    <Input type="textarea" name="text" id="exampleText"
                           placeholder="Notes for the participation"/>
                </FormGroup>
                <FormGroup className="col-md-6">
                    <label>Σχόλια / Comments</label>
                    <Input type="textarea" name="text" id="exampleText"
                           placeholder="Comments by the moderator"/>
                </FormGroup>
            </div>
        </Col>
    )*/
}

ParticipationFormDetails.propTypes = {
    exams: propTypes.any
};

export default ParticipationFormDetails;
