import React, {useContext} from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Collapse,
    Container,
    Form,
    FormGroup,
    FormText,
    Input,
    Label,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import Announcements from "../../../components/Sections/Announcements";
import classnames from "classnames";
import UserInfo from "../../../components/Sections/UserInfo";
import UserData from "../../../components/Sections/UserData";
import Footer from "../../../components/Footers/Footer";
import {useForm} from "react-hook-form";
// const [route, setRoute] = React.useState('');
import EntitiesContext from "../../../app/context";
import Modal from "../../../components/Modals/Modal";
import strings from "../../../app/translations";

export default function UserProfileMain()
{

    const pagePathname = '/participation-form-page';
    const editMode = (window.location.pathname !== pagePathname);
    const [modalShow,setModalShow] = React.useState(false);
    const [message,setMessage] = React.useState([]);
    const [action,setAction] = React.useState('');
    const disabledFields = []; //array of input names
    const isReadOnly = (name) => {
        if (editMode)
            return disabledFields.indexOf(name) !== false
    };

    function showModal(data){
        let msg = [];
        if(data.errors){
            Object.values(data.errors).map(i=>i.map(m=>{return msg.push(m)}));
        }else if (data.service_error) {
            msg.push(data.service_error.title);
            msg.push(data.service_error.message);
        } else if (data.ws_info && data.ws_info.ws_validation_errors) {
            msg.push('Moodle Error:');
            Object.values(data.ws_info.ws_validation_errors).map(i => i.map(m => { return msg.push(m) }));
        } else {
            console.log('data')
            //console.log(data.records)
            msg.push(strings.profile_success_update);
        }
        // console.log('msg');
        // console.log(msg);
        let act = (data.errors)?('error'):('error');
        setAction(act);
        setMessage(msg);
        setModalShow(true);
        $('#appModal').modal({show:true});
        console.log('show');
        console.log(msg);
    }

    return (<>
        <div className="section text-center align-page-header" style={{backgroundColor: "transparent"}}>

            <div className="container-fluid">
                {/*<h2 className="text-danger"> My Profile </h2>*/}
                <Row>
                    <Col className="ml-auto mr-auto" lg="6">
                    <UserInfo showModal={showModal}/>
                    </Col>
                    <Col className="ml-auto mr-auto offset-lg-1" lg="6">
                        <Row className="">
                        <Announcements/>
                        <UserData isReadOnly={isReadOnly} showModal={showModal} setMessage={message => setMessage(message)}/>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
        <div className="section section-cards section-dark text-center" id="enroll-now"
             style={{paddingTop: "15rem"}}>
            <div className="container-fluid">
                <Row id="participation-form">
                    <Col className="ml-auto mr-auto " lg="8">

                    </Col>
                </Row>
            </div>
            <Footer/>
        </div>
        <Modal action={action} message={message} title={strings.profile_update_modal}/>
    </>)
}
