import {Card, CardTitle, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import Announcements from "../../../components/Sections/Announcements";
import TableApp from "../../../components/Datatable/TableApp";
import React, {useContext, useEffect, useState} from "react";
import EntitiesContext from "../../../app/context";
import EnrollmentInternal from "../EnrollmentInternal";
import CertificationForm from "../../../components/Forms/admin/CertificationForm";
import classnames from "classnames";
import strings from "../../../app/translations";

export default function OrderMain() {
    const user_role = window.reactInit.user_role;
    const {state/*,dispatch*/} = useContext(EntitiesContext);
    // console.log('state.enrollments.objects');
    // console.log(state.enrollments.objects);
    state.orders.objects = Array.from(state.orders.objects);

    const [objects,setObjects] = useState(state.orders.objects.map(object => {
        // console.log(object);
        object.width = "80px";
        return object;
    }));

    const columns = state.orders.columns;
    const currentItem = state.currentItem;

    const [tableWidth, setTableWidth] = useState(state.certifications.tableWidth);
    const [sectionTab, setSectionTab] = React.useState('1');
    const sectionToggle = tab => {
        if (sectionTab !== tab) setSectionTab(tab);
    };

    useEffect(() => {
            setObjects(state.orders.objects.map(object => {
                return object.data
            }));
        }
        , [state]);
    return (
        <div className="section text-center align-page-header" style={{backgroundColor: "transparent"}}>
            <div className="">
                <div className="custom-column row">
                    <Announcements/>
                    <Row className="custom-column">
                        <Col id={"enrollment-participation"}>
                            <div className="form-group col-md-12">
                                <Row className="custom-column">
                                    <Col className="ml-auto mr-auto offset-lg-1" lg="12">
                                        <Row style={{display: "block"}}>
                                            <Col sm="12">
                                                {(typeof currentItem === 'undefined' || currentItem === null || currentItem.length === 0) &&
                                                <Card body className="card-plain">
                                                    <CardTitle><h3
                                                        className="text-danger ml-auto mr-auto">{state.orders.cardTitle}</h3>
                                                    </CardTitle>
                                                    {strings.here_is_a_list} {strings.orders}
                                                    <div className='criteria col-12'>
                                                        <h3 className="text-danger mb-1"></h3>
                                                        <div className="col-md-12">
                                                            {sectionTab === '1' && <TableApp
                                                                names={objects} columns={columns}/>
                                                            }
                                                        </div>
                                                    </div>
                                                </Card>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                    </Row>


                </div>
            </div>
            <div className="section section-cards section-dark text-center" id="enroll-now"
                 style={{paddingTop: "15rem"}}>
                <Container>
                    <Row id="participation-form">
                        <Col className="ml-auto mr-auto " lg="8">

                        </Col>
                    </Row>
                </Container>

                <div className="footer register-footer text-center">
                    <h6>
                        © {new Date().getFullYear()}, made with{" "}
                        <i className="fa fa-heart heart"/> by WIDE Services
                    </h6>
                </div>
            </div>
        </div>)
}
