import React from 'react';
import axios from "axios";
import Cookie from 'universal-cookie';
import strings from "./translations";

const cookie = new Cookie();

const EntitiesContext = React.createContext({
    orders: {
        objects: [
            // {
            //     id: 1,
            //     model: 'order',
            //     data: {
            //             title: 'Υπάλληλος Διοικητικής Υποστήριξης σε Επιχειρήσεις Νέων Τεχνολογιών (Θεωριτικά)',
            //             num_of_participants: '20 participants',
            //             enrollment_status: "<span class='badge badge-danger'>Closed</span>",
            //             start_date: '8 April 2020',
            //             certification_name: 'lalala',
            //             amount: 500,
            //             order_status: 'Something',
            //             participation_form: 'Company1_form1', remote: 'yes', grade_auto: 'yes', open_date: 'yes',
            //             action: "<a href='/mock-landing-page'><button class='btn btn-round btn-secondary'>" + "view" + "</button></a>"
            //         }
            // },
            // {
            //     id: 2,
            //     model: 'order',
            //     data: {
            //             title: 'Titlos Pistopoihshs 2 (Θεωριτικά & Πρακτικά)',
            //             num_of_participants: '1 participant',
            //             enrollment_status: "<span class='badge badge-success'>Open</span>",
            //             date: '18 April 2020',
            //             participation_form: 'Company1_form2', remote: 'no', grade_auto: 'yes', open_date: 'no',
            //             action: "<a href='/admin/mock-certification-page'><button class='btn btn-round btn-secondary'>" + "view" + "</button></a>"
            //         }
            // },
            // {
            //     id: 3,
            //     model: 'order',
            //     data: {
            //             title: 'Titlos Pistopoihshs 3 (Θεωριτικά & Πρακτικά)',
            //             num_of_participants: '15 Participants',
            //             enrollment_status: "<span class='badge badge-success'>Open</span>",
            //             date: '29 June 2020',
            //             participation_form: 'Company1_form15', remote: 'yes', grade_auto: 'yes', open_date: 'no',
            //             action: "<a href='/admin/mock-certification-page'><button class='btn btn-round btn-secondary'>" + "view" + "</button></a>"
            //         }
            // }
        ],
        columns: [
            // {
            //     title: 'Exam Title',
            //     width: 250,
            //     data: 'title'
            // },
            // {
            //     title: 'Number of Participants',
            //     width: 150,
            //     data: 'num_of_participants'
            // }, {
            //     title: 'Exam Status',
            //     width: 120,
            //     data: 'enrollment_status'
            // }, {
            //     title: 'Exam Date',
            //     width: 150,
            //     data: 'date'
            // }, {
            //     title: 'Participation Form',
            //     width: 150,
            //     data: 'participation_form'
            // },
            // {
            //     title: 'Action',
            //     width: 150,
            //     data: 'action'
            // },
            /////////////////////////////////////////
            // {
            //     title: 'Certification',
            //     width: 250,
            //     data: 'title'
            // },
            // {
            //     title: 'No. Participants',
            //     width: 220,
            //     data: 'num_of_participants'
            // },
            // {
            //     title: 'Status',
            //     width: 250,
            //     data: 'status'
            // },
            // {
            //     title: 'Amount',
            //     width: 450,
            //     data: 'amount'
            // },
            ///////////////////////////////////////
            {
                title: strings.certification,
                width: 250,
                data: 'certification_name'
            },
            {
                title: strings.participation,
                width: 250,
                data: 'participation_form_name'
            },
            {
                title: strings.start_date,
                width: 220,
                data: 'start_date'
            },
            {
                title: strings.status,
                width: 250,
                data: 'order_status'
            },
            {
                title: strings.amount,
                width: 450,
                data: 'amount'
            },
            {
                title: strings.action,
                width: 150,
                data: 'action'
            },
        ],
        cardTitle: strings.orders,
        singleTitle: strings.orders
    },
    enrollments: {
        objects: [
            /*{
                id: 1, model: 'enrollment', data:
                    {
                        title: 'Υπάλληλος Διοικητικής Υποστήριξης σε Επιχειρήσεις Νέων Τεχνολογιών (Θεωριτικά)',
                        num_of_participants: '20 participants',
                        enrollment_status: "<span class='badge badge-danger'>Closed</span>",
                        date: '8 April 2020',
                        participation_form: 'Company1_form1', remote: 'yes', grade_auto: 'yes', open_date: 'yes',
                        action: "<a href='/mock-landing-page'><button class='btn btn-round btn-secondary'>" + "view" + "</button></a>"
                    }
            },
            {
                id: 2, model: 'enrollment', data:
                    {
                        title: 'Titlos Pistopoihshs 2 (Θεωριτικά & Πρακτικά)',
                        num_of_participants: '1 participant',
                        enrollment_status: "<span class='badge badge-success'>Open</span>",
                        date: '18 April 2020',
                        participation_form: 'Company1_form2', remote: 'no', grade_auto: 'yes', open_date: 'no',
                        action: "<a href='/admin/mock-certification-page'><button class='btn btn-round btn-secondary'>" + "view" + "</button></a>"
                    }
            },
            {
                id: 3, model: 'enrollment', data:
                    {
                        title: 'Titlos Pistopoihshs 3 (Θεωριτικά & Πρακτικά)',
                        num_of_participants: '15 Participants',
                        enrollment_status: "<span class='badge badge-success'>Open</span>",
                        date: '29 June 2020',
                        participation_form: 'Company1_form15', remote: 'yes', grade_auto: 'yes', open_date: 'no',
                        action: "<a href='/admin/mock-certification-page'><button class='btn btn-round btn-secondary'>" + "view" + "</button></a>"
                    }
            }*/
        ],
        columns: [{
            title: strings.examination_title,
            width: 250,
            data: 'title'
        },
            {
                title: strings.number_of_participants,
                width: 150,
                data: 'num_of_participants'
            }, {
                title: strings.examination_status,
                width: 120,
                data: 'enrollment_status'
            }, {
                title: strings.examination_date,
                width: 150,
                data: 'date'
            }, {
                title: strings.participation_form,
                width: 150,
                data: 'participation_form'
            },
            {
                title: strings.action,
                width: 150,
                data: 'action'
            },
        ],
        cardTitle: strings.enrollments,
        singleTitle: strings.enrollments,
        filters: []
    },
    examinations: {
        objects: [/*
            {
                id: 1, model: 'examination', data:
                    {
                        title: 'Υπάλληλος Διοικητικής Υποστήριξης σε Επιχειρήσεις Νέων Τεχνολογιών (Θεωριτικά)',
                        num_of_participants: '20 participants',
                        enrollment_status: "<span class='badge badge-danger'>Closed</span>",
                        date: '8 April 2020',
                        participations: 'Company1_form1',
                        classrooms: "some classrooms",
                        is_remote: 'yes',
                        grade_auto: 'yes',
                        open_date: 'yes',
                        action: "<a href='/mock-landing-page'><button class='btn btn-round btn-secondary'>" + "view" + "</button></a>"
                    }
            },
            {
                id: 2, model: 'examination', data:
                    {
                        title: 'Titlos Pistopoihshs 2 (Θεωριτικά & Πρακτικά)',
                        num_of_participants: '1 participant',
                        enrollment_status: "<span class='badge badge-success'>Open</span>",
                        date: '18 April 2020',
                        participations: 'Company1_form1',
                        classrooms: "some classrooms",
                        is_remote: 'no',
                        grade_auto: 'yes',
                        open_date: 'no',
                        action: "<a href='/admin/mock-certification-page'><button class='btn btn-round btn-secondary'>" + "view" + "</button></a>"
                    }
            },
            {
                id: 3, model: 'examination', data:
                    {
                        title: 'Titlos Pistopoihshs 3 (Θεωριτικά & Πρακτικά)',
                        num_of_participants: '15 Participants',
                        enrollment_status: "<span class='badge badge-success'>Open</span>",
                        date: '29 June 2020',
                        participations: 'Company1_form1',
                        classrooms: "some classrooms",
                        is_remote: 'yes',
                        grade_auto: 'yes',
                        open_date: 'no',
                        action: "<a href='/admin/mock-certification-page'><button class='btn btn-round btn-secondary'>" + "view" + "</button></a>"
                    }
            }*/
        ],
        columns: [
            {
                title: strings.permalink,
                width: 150,
                data: 'perma_link'
            },
            {
                title: strings.examination_title,
                width: 250,
                data: 'title'
            },
            {
                title: strings.number_of_participants,
                width: 150,
                data: 'num_of_participants'
            },
            /*{
                title: 'Enrollment Status',
                width: 120,
                data: 'enrollment_status'
            }, */
            {
                title: strings.examination_date,
                width: 150,
                data: 'date'
            },
            {
                title: strings.participation_form,
                width: 150,
                data: 'participations'
            },
            {
                title: strings.classrooms,
                width: 150,
                data: 'classrooms'
            },
            {
                title: strings.status,
                width: 100,
                data: 'status_name'
            },
            /*{
                title: 'Remote',
                width: 120,
                data: 'is_remote'
            },
            {
                title: 'Auto Grading',
                width: 120,
                data: 'grade_auto'
            },
            {
                title: 'Open date',
                width: 120,
                data: 'open_date'
            },*/
            {
                title: strings.action,
                width: 150,
                data: 'action'
            },
        ],
        cardTitle: strings.examinations,
        singleTitle: strings.examinations,
        tableWidth: "400px",
        filters: [
            // {
            //     name:'companies',
            //     type:'date',
            //     title:'companies'
            // }
        ]
    },
    certifications: {
        objects: [
            /*{
                id: 1, model: 'certification', data:
                    {
                        title: 'Υπάλληλος Διοικητικής Υποστήριξης σε Επιχειρήσεις Νέων Τεχνολογιών',
                        category: 'Eidikotita 1',
                        type: 'Θεωρητικά',
                        duration: '6 months',
                        number_of_activities: 3,
                        price: '30 euro',
                        remote: 'yes',
                        grade_auto: 'yes',
                        open_date: 'yes',
                        action: "<a href='/admin/mock-certification-page'><button class='btn btn-round btn-secondary'>" + "edit" + "</button></a>"
                    }
            },
            {
                id: 2, model: 'certification', data:
                    {
                        title: 'Υπεύθυνος Διαχείρισης Περιβαλλοντικών Συστημάτων',
                        category: 'Eidikotita 2',
                        type: 'Θεωρητικά & Πρακτικά',
                        duration: '2 years',
                        number_of_activities: 3,
                        price: '100 euro',
                        remote: 'no',
                        grade_auto: 'yes',
                        open_date: 'no',
                        action: "<a href='/admin/mock-certification-page'><button class='btn btn-round btn-secondary'>" + "edit" + "</button></a>"
                    }
            },
            {
                id: 3, model: 'certification', data:
                    {
                        title: 'Στέλεχος Διεκπεραίωσης Διαδικασιών για τη Συμμετοχή σε Δημόσιους Διαγωνισμούς',
                        category: 'Eidikotita 3',
                        type: 'Θεωρητικά',
                        duration: '6 months',
                        number_of_activities: 3,
                        price: '35 euro',
                        remote: 'yes',
                        grade_auto: 'yes',
                        open_date: 'no',
                        action: "<a href='/admin/mock-certification-page'><button class='btn btn-round btn-secondary'>" + "edit" + "</button></a>"
                    }
            }*/
        ],
        columns: [
            {
                title: strings.specialty,
                width: 130,
                data: 'title'
            },
            {
                title: strings.schema,
                width: 130,
                data: 'category'
            },
            {
                title: strings.type,
                width: 100,
                data: 'type'
            },
            {
                title: strings.duration,
                width: 130,
                data: 'duration'
            },
            {
                title: strings.price,
                width: 130,
                data: 'price'
            },
            {
                title: strings.number_of_activities,
                width: 100,
                data: 'number_of_activities'
            },
            {
                title: strings.auto_grading,
                width: 100,
                data: 'grade_auto'
            },
            {
                title: strings.open_date,
                width: 100,
                data: 'open_date'
            },
            {
                title: strings.action,
                width: 100,
                data: 'action'
            },
        ],
        cardTitle: strings.certifications,
        singleTitle: strings.certifications,
        tableWidth: "400px",
    },
    categories: {
        objects: [
            /*{
                data: {id: 1, name: "Eidikotita 1"}
            },
            {
                data: {id: 2, name: "Eidikotita 2"}
            },
            {
                data: {id: 3, name: "Eidikotita 3"}
            }*/
        ]
    },
    relatedCertifications: {
        objects: [
            /*{
                data: {id: 1, name: "Eidikotita 1"}
            },
            {
                data: {id: 2, name: "Eidikotita 2"}
            },
            {
                data: {id: 3, name: "Eidikotita 3"}
            }*/
        ]
    },
    companies: {
        objects: [
            {
                id: 1, model: 'company', data:
                    {
                        name: 'E-learning Center',
                        title: '20 participants',
                        users_count: '10',
                        manager: 'sss',
                        action: "<a href='/landing-page'><button class='btn btn-round btn-secondary'>" + strings.view + "</button></a>"
                    }
            },
            // {
            //     id: 2, model: 'company', data:
            //         {
            //             name: 'LMS for kids',
            //             num_of_participants: '1 participant',
            //             exam_status: "<span class='badge badge-success'>Open</span>",
            //             date: '18 April 2020',
            //             participation_form: 'Company1_form2', remote: 'no', grade_auto: 'yes', open_date: 'no',
            //             action: "<a href='/admin/mock-certification-page'><button class='btn btn-round btn-secondary'>" + "view" + "</button></a>"
            //         }
            // },
            // {
            //     id: 3, model: 'company', data:
            //         {
            //             name: 'Learners online',
            //             num_of_participants: '15 Participants',
            //             exam_status: "<span class='badge badge-success'>Open</span>",
            //             date: '29 June 2020',
            //             participation_form: 'Company1_form15', remote: 'yes', grade_auto: 'yes', open_date: 'no',
            //             action: "<a href='/admin/mock-certification-page'><button class='btn btn-round btn-secondary'>" + "view" + "</button></a>"
            //         }
            // }
        ],
        columns: [
            {
                title: strings.company_name,
                width: 250,
                data: 'name'
            },
            {
                title: strings.company_title,
                width: 150,
                data: 'title'
            },
            {
                title: strings.number_of_users,
                width: 120,
                data: 'users_count'
            },
            {
                title: strings.company_manager,
                width: 150,
                data: 'manager'
            },
            // {
            //     title: 'Participation Form',
            //     width: 150,
            //     data: 'participation_form'
            // },
            {
                title: strings.action,
                width: 150,
                data: 'action'
            },
        ],
        cardTitle: strings.companies,
        singleTitle: strings.companies
    },
    classrooms: {
        objects: [
            {
                id: 1, model: 'classroom', data:
                    {
                        name: 'E-learning Center',
                        address: 'Athens, downtown',
                        capacity: 335,
                        action: "<a href='/landing-page'><button class='btn btn-round btn-secondary'>" + strings.view + "</button></a>"
                    }
            },
            {
                id: 2, model: 'classroom', data:
                    {
                        name: 'LMS for kids',
                        address: 'Athens, Eastside',
                        capacity: 60,
                        action: "<a href='/admin/certification-page'><button class='btn btn-round btn-secondary'>" + strings.view + "</button></a>"
                    }
            },
            {
                id: 3, model: 'classroom', data:
                    {
                        name: 'Learners online',
                        address: 'Korinthos',
                        capacity: 210,
                        action: "<a href='/admin/certification-page'><button class='btn btn-round btn-secondary'>" + strings.view + "</button></a>"
                    }
            }
        ],
        columns: [{
            title: strings.classroom_title,
            width: 250,
            data: 'name'
        },
            {
                title: strings.classroom_address,
                width: 150,
                data: 'address'
            }, {
                title: strings.student_capacity,
                width: 150,
                data: 'capacity'
            },
            {
                title: strings.action,
                width: 150,
                data: 'action'
            },
        ],
        cardTitle: strings.classrooms,
        singleTitle: strings.classrooms
    },
    supervisors: {
        objects: []
    },
    participations: {
        objects: [
            /*{
                id: 1, model: 'participationForm', data:
                    {
                        name: 'E-learning Center',
                        participation_form_date: '2020-10-21',
                        num_of_participants: 35
                    }
            },
            {
                id: 2, model: 'participationForm', data:
                    {
                        name: 'LMS for kids',
                        participation_form_date: '2020-09-15',
                        num_of_participants: 20,
                    }
            },
            {
                id: 3, model: 'participationForm', data:
                    {
                        name: 'Learners online',
                        participation_form_date: '2020-10-11',
                        num_of_participants: 15,
                    }
            }*/
        ],
        columns: [
            {
                title: strings.name,
                width: 250,
                data: 'name'
            },
            {
                title: strings.number_of_participants,
                width: 150,
                data: 'num_of_participants'
            },
            {
                title: strings.start_date,
                width: 150,
                data: 'start_date'
            },
            {
                title: strings.end_date,
                width: 150,
                data: 'end_date'
            },
            {
                title: strings.enrollment_status,
                width: 150,
                data: 'enrollment_status'
            },
            {
                title: strings.action,
                width: 150,
                data: 'action'
            },
        ],
        cardTitle: strings.participations,
        singleTitle: strings.participations,
        filters: [
            // {
            //     name:'companies',
            //     type:'date',
            //     title:'companies'
            // }
        ]
    },
    grades: {
        objects: [],
        columns: [
            {
                title: strings.name,
                width: 250,
                data: 'name'
            },
            {
                title: strings.certification,
                width: 150,
                data: 'certification_name'
            },
            {
                title: strings.date,
                width: 150,
                data: 'date'
            },
            {
                title: strings.grade,
                width: 150,
                data: 'score'
            },
            {
                title: strings.action,
                width: 150,
                data: 'action'
            },
        ],
        cardTitle: strings.grades,
        singleTitle: strings.single_title_grades,
        tableWidth: "400px",
        filters: []
    },
    certificates: {
        objects: [],
        columns: [
            {
                title: strings.name,
                width: 250,
                data: 'name'
            },
            {
                title: strings.certification,
                width: 150,
                data: 'certification_name'
            },
            {
                title: strings.date,
                width: 150,
                data: 'date'
            },
            {
                title: strings.status,
                width: 150,
                data: 'status.name'
            },
            {
                title: strings.action,
                width: 150,
                data: 'action'
            },
        ],
        cardTitle: strings.certificates,
        singleTitle: strings.certificates,
        tableWidth: "400px",
        filters: []
    },
    payments: {
        objects: [],
        columns: [
            {
                title: strings.participation,
                width: 250,
                data: 'participation_form'
            },
            {
                title: strings.certification,
                width: 150,
                data: 'certification_name'
            },
            {
                title: strings.date,
                width: 150,
                data: 'date'
            },
            {
                title: strings.status,
                width: 150,
                data: 'status'
            },
            {
                title: strings.action,
                width: 150,
                data: 'action'
            },
        ],
        cardTitle: strings.payments,
        singleTitle: strings.payments,
        tableWidth: "400px",
        filters: []
    },
    users: {
        objects: [],
        columns: [
            {
                title: strings.lastname,
                width: 150,
                data: 'lastname'
            },
            {
                title: strings.firstname,
                width: 150,
                data: 'firstname'
            },
            {
                title: strings.company,
                width: 150,
                data: 'company'
            },
            {
                title: strings.username,
                width: 150,
                data: 'name'
            },
            {
                title: strings.email,
                width: 150,
                data: 'email'
            },
            {
                title: strings.action,
                width: 150,
                data: 'action'
            },
        ],
        cardTitle: strings.users,
        singleTitle: strings.users,
        tableWidth: "400px",
        filters: []
    },
    announcements: [{
        'date': '23/03/2020',
        'message': strings.no_announcements,
        'badge_type': 'danger',
        'status': 'Draft'
    }],
    actionButton: function addActionButton(object, admin, mock, certification) {
        //case for examinations
        let prefix = (admin) ? "/admin" : "";
        let mock_pref = (mock) ? "/mock-" : "/";
        let href = prefix + mock_pref + object.data.model_type + "-page/";
        // if (object.data.enrollment_status) {
        //     href = "/admin/mock-examination-page/";
        // }
        /*console.log('action button object.data');*/
        // console.log(object);
        if (certification) {
            href = href + object.data.certification_id + "/" + object.data.id
        } else if(object.data.orderer_id){
            href = "checkout-page/" + object.data.order_id;
        }
        else {
            href = href + object.data.id;
        }
        return '<a href=' + href + '>' +
            '<button class="btn btn-round btn-' + object.data.badge + '">' + object.data.label + '</button></a>'
    },
    handleSubmit: function handleSubmit(data, route, modal, event = undefined) {
        if (typeof event !== 'undefined') {
            event.preventDefault();
            data = new FormData(event.target);
        }
        // console.log('data in state\'s handleSubmit');
        // console.log(data);

        axios({
            method: 'post',
            url: route,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': window.reactInit.csrf
            },
            data: data,
        }).then(function (response) {
            //handle success
            console.log(response);
            if(modal!==false)
                modal(response.data);
        })
            .catch(function (response) {
                //handle error
                // console.log('catch error in state\'s handlesubmit');
                let response_data = null;
                Object.values(response).filter(value => {
                    if (typeof value === 'object' && value.data && value.status) {
                        // console.log('value:');
                        // console.log(value.data);
                        response_data = value.data.errors;
                        return value.data.errors;
                    }
                });
                if (typeof response_data === 'object' && response_data !== null && response_data.email) {
                    response = {data: {errors: response_data}};
                }
                console.log(response_data);
                console.log('actual_response');
                console.log(response.data);
                if(modal!==false)
                    modal(response.data);
            });
    },
    currentItem: '',
    currentUser:{},
    countries: [{id: 1, name: 'Greece', code: '1'},{id: 3, name: 'Cyprus', code: '2'},{id: 4, name: 'Slovenia', code: '3'},{id: 5, name: 'Bulgaria', code: '4'},{id: 6, name: 'Spain', code: '5'}],
    accreditors: [{name: "ΕΣΥΔ", id: 1}],

    dummyUsers: [
        {name: "Papadogiannis Ioannis"}, {name: "Stylianaki Ioanna"}, {name: "Papadopoulos Charalambos"}, {name: "Papadakis Adam"}
    ],
    user: {id: window.reactInit.user_id, role: window.reactInit.user_role, company_id: window.reactInit.company_id},

    language: cookie.get('language_set') ?? 'en'

});

export default EntitiesContext;
