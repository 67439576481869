import React from "react";
import strings from "../../app/translations";

export default function InputWithType({input,type,typeValues,updateInput,register}){
    function handleChange(event){
        event.preventDefault();
        const elements = Array.from(event.target.parentNode.childNodes);
        const sibling = event.target.parentNode.nextSibling;
        elements.map(el=>console.log(el.tagName));
        let result = (elements.filter(el=> typeof el.value !== "undefined").map(el=>el.value));
        sibling.value = result.toString().replace(",","");
    }
    return(<><div onChange={handleChange}>
        <label htmlFor="exampleDuration" style={{display: "block"}}>{strings.certification_duration}</label>
        <input type="text" className="form-control col-md-7 d-md-inline" id={input} name={input}
               placeholder={strings.certification_duration_placeholder}/>
        <select className="form-control offset-md-1 col-md-4 d-md-inline" id={type} name={type}>
            {typeValues.map((tV,key)=><option key={key} value={tV.value}>{tV.name}</option>)}
        </select>
    </div>
        <input type="hidden" hidden className="form-control col-md-7 d-md-inline" id={updateInput} name={updateInput}
               ref={register({required: true})}/>

    </>)
}
