/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useContext, useReducer, useState} from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import classnames from "classnames";
//react datetime
import Datetime from "react-datetime";
// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import EntitiesContext from "../../app/context";
import UserProfileMain from "./main/UserProfileMain";
import ViewVariables from "../../components/Middleware/ViewVariables";
import CRUDReducer from "../../app/reducer";
import strings from "../../app/translations";
import Cookie from 'universal-cookie';
import axios from "axios";
import AuthoriseAccess from "../../components/AuthoriseAccess";
import ParticipationForm from "../../components/Forms/shop/ParticipationForm";
import ParticipationContext from "../../app/ParticipationContext";


function MockUserProfilePage() {
    const cookie = new Cookie;

    const headerImage = ViewVariables.middleware.headerImage;
    const initialState = useContext(EntitiesContext);
    const [state, dispatch] = useReducer(CRUDReducer, initialState);
    const [language, setLanguage] = useState(getLanguage());
    const [currentItem, setCurrentItem] = useState(state.currentItem);
    const auth_user = window.reactInit.user_id;

    const isVerified = window.reactInit.verified==='true';

    const getItem = async (url) => {
        const response = await axios.get(url);
        // console.log('response.data');
        // console.log(response.data);
        dispatch({type: "UPDATE_CURRENT_ITEM", payload: response.data});
        // console.log('state.currentItem');
        // console.log(state.currentItem);
        setCurrentItem(response.data);
        // console.log('currentItem');
        // console.log(currentItem);
    };
    const getAnnouncements = async () => {
        // console.log('loading announcements');
        let url = window.location.pathname;
        url = '/get/announcements/user-profile';
        // console.log('url');
        // console.log(url);
        const response = await axios.get(url);
        dispatch({type: "UPDATE_ANNOUNCEMENTS", payload: response});
        //setCurrentItem(response.data);
    };

    function getLanguage() {
        let lang = cookie.get('language_set') ?? 'en';
        //dispatch({type:"UPDATE_LANGUAGE",payload:lang});
        return lang;
    }

    const title = strings.my_profile;

    document.documentElement.classList.remove("nav-open");

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        let user_id = auth_user;
        if (isVerified) {
            let url = '/user/get/item/' + user_id;
            document.body.classList.add("register-page");
            getItem(url);
            getAnnouncements();
        } else {
            window.location.href = '/index';
        }
        return function cleanup() {
            document.body.classList.remove("register-page");
        };
    }, []);

    /*    const title = "Featured Products";*/

    return (
        <>
            <EntitiesContext.Provider value={{state, dispatch}}>
                <ExamplesNavbar setLanguage={language => setLanguage(language)}/>
                <div
                    className="page-header"
                    style={{
                        backgroundImage: "url(" + require("./../../assets/img/mockups/1-1920x1080.png") + ")",
                        backgroundSize: "cover"
                    }}
                ><h1 className="text-center text-white" style={{zIndex: "3"}}>{title}</h1>
                    <h2>{state.currentItem.title}</h2>
                    <h2>{currentItem.title}</h2>

                    <div className="filter"/>

                </div>
                <div className="main">
                    <AuthoriseAccess component={<UserProfileMain/>}/>
                </div>
            </EntitiesContext.Provider>
        </>
    );
}

export default MockUserProfilePage;
