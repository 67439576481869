import strings from "../../app/translations";
import React, {useState} from "react";
import {Row, Col, Button} from 'reactstrap';
import ConfirmModal from "../Modals/ConfirmModal";
import axios from "axios";
// import {useForm} from "react-hook-form";
// import Modal from "../Modals/Modal";

export default function DocumentsIndex({documents, profilePage, user, setMessage, status}) {
    // const {state, dispatch} = useContext(EntitiesContext);
    const [modal, setModal] = useState(false);
    const [docs, setDocs] = useState(documents);
    const [docToBeRemoved, setDocToBeRemoved] = useState(null);
    // const [action,setAction] = React.useState('');

    const toggle = () => setModal(!modal);

    const handleSubmit = (data, route, modal, event = undefined)=> {
        console.log('handleSubmit, documents:');
        console.log(documents.length);
        if (typeof event !== 'undefined') {
            event.preventDefault();
            data = new FormData(event.target);
        }
        // console.log('data in state\'s handleSubmit');
        // console.log(data);

        axios({
            method: 'post',
            url: route,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': window.reactInit.csrf
            },
            data: data,
        }).then(function (response) {
            //handle success
            console.log('response');
            console.log(response);
            if(modal!==false)
                modal(response.data);
        })
            .catch(function (response) {
                //handle error
                // console.log('catch error in state\'s handlesubmit');
                let response_data = null;
                Object.values(response).filter(value => {
                    if (typeof value === 'object' && value.data && value.status) {
                        response_data = value.data.errors;
                        return value.data.errors;
                    }
                });
                if (typeof response_data === 'object' && response_data !== null && response_data.email) {
                    response = {data: {errors: response_data}};
                }
                if(modal!==false)
                    modal(response.data);
            });
    };

    const showModal=(data) => {
        console.log('documents in show modal');
        console.log(documents.length);
        console.log('docs in show modal');
        console.log(docs.length);
        documents = docs;
        let msg = [];
            if(data && data.error===false) {
                msg.push(data.message);
                const index = documents.indexOf(docToBeRemoved);
                if (index > -1) {
                    console.log('in');
                    console.log(docToBeRemoved.message);
                    documents.splice(index, 1);
                    setDocs(documents);
                }
            } else {
                msg.push(strings.error_general_message);
                if(data){
                    msg.push(data.message);
                }
            }
        /*console.log('msg');
        console.log(msg);*/
        setMessage(msg);
        // setModalShow(true);
        $('#appModal').modal({show: true});
        // console.log('show');
        // console.log(msg);
    };
    const triggerModal = (doc) => {
        // set file to remove
        console.log('documents in trigger');
        console.log(documents.length);
        setDocToBeRemoved(doc);
        toggle();
    };
    const processConfirm = () => {
        console.log('confirmed, documents');
        console.log(documents.length);
        toggle();
        // axios call for the set file
        let form = document.createElement("form");
        form.setAttribute("method", "post");
        let data = new FormData(form);
        let firstFile = docToBeRemoved.files[0].id;
        // console.log(firstFile);
        data.append('file', firstFile);
        let route = '/api/document/delete';
        handleSubmit(data, route, showModal);
    };
    React.useEffect(() => {
        console.log('useEffect');
        if(profilePage) {
            setDocs(documents);
        }
    }, [documents]);

    // console.log('rendering documents');
    // console.log(documents);
    // console.log('docs');
    // console.log(docs);
    return (
        <>
            {!profilePage && <h6>{strings.selected_docs_for_user}{user.name}</h6>}
            <ul>
                {docs.map((item, key) => {
                    // console.log(item);
                    // added key to prevent warnings
                    return <li className="py-3 border-bottom" style={{minHeight: "4rem"}}
                               key={item.message}>
                        <Row>
                            <Col lg="11">
                                <Row>
                                    <Col lg="6"><h6>Document {key + 1}: {item.message}</h6>
                                    </Col>
                                    <Col lg="3" className={'text-left '}>
                                        {/*add if clamscan is used*/}
                                        {/*<label
                                    className={"badge badge-" + item.badge_type}>{item.status}</label>*/}
                                    </Col>
                                    <Col lg="2" className={'text-left '}>
                                        <h6>{item.date}</h6>
                                    </Col>
                                    <Col lg="12" className={'text-left py-3 '} style={{minHeight: "4rem"}}>
                                        {item.files.map((file, key) => {
                                                // console.log(file);
                                                return (
                                                    <span className={"badge badge-secondary mr-2 pr-1"} key={file.id}>
                                            <a href={'/downloadFile/' + file.id} target="_blank"
                                               rel="noreferrer noopener">{file.name}</a>
                                        </span>
                                                )
                                            }
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                            { (status !== 3) &&
                                <Col lg="1" className={'align-self-center'}>
                                    <div className={'align-middle'}>
                                        {/*<img alt={'document ' + item.message} width="15rem" className={'my-auto'}*/}
                                        {/*     data-content={item.message}*/}
                                        {/*     style={{background: '#3A6F9A'}}*/}
                                        {/*     src={require(`../../assets/img/mockups/icon-certifications.svg`)}/>*/}
                                        <Button className={'mx-1 py-0 px-1 border-0 my-auto'}
                                                onClick={()=>{triggerModal(item)}}
                                                color='danger'>X
                                        </Button>
                                    </div>
                                </Col>
                            }
                        </Row>
                    </li>
                })}
            </ul>
            <ConfirmModal modal={modal} toggle={toggle} processConfirm={processConfirm} documents={documents}/>
        </>
    )
}
