export default function ParticipationDReducer(state, action) {
    switch (action.type) {
        // case "ADD_FILE":
        //     const newObj = action.payload;
        //     return {...state.users, newObj};
        // case "EDIT_FILES":
        //
        //     return {...state.entities.enrollment, newObj};
        case "SET_FILES":
            const adminUser = {...state.user.files,role:'admin'};
            return {...state,user:adminUser};
        case "UPDATE_LOGGED_USER":
            // console.log('action.payload.user');
            // console.log(action.payload.user);
            return {...state, user:action.payload.user};

        case "UPDATE_USERS":
            // console.log('dispatching UPDATE_USERS');
            // console.log(action.payload.users);
//            return {...state, users:action.payload.users};
            // const newUserFiles = {...state.user_documents, objects:action.payload.userFiles};
            // const updateState = {...state, user_documents:newUserFiles};
            return {...state, users:action.payload.users, user_documents: action.payload.userFiles};

        // return {...state, users:action.payload.users};
    }
}
