import {Form, FormGroup, Input, Label, Tooltip} from "reactstrap";
import React, {useContext} from "react";
import RegistrationFormDetails from "./RegistrationFormDetails";
import EntitiesContext from "../../../app/context";
import axios from "axios";
import {useForm} from "react-hook-form";

import strings from "../../../app/translations";
import TooltipWrap from "../TooltipWrap";

export default function SingleRegistration({setUserRole, register, errors}) {

    const {state, dispatch} = useContext(EntitiesContext);
    //const [isToggled, setToggled] = React.useState('');
    const [toggleManager, setToggleManager] = React.useState(true);
    const [toggleAdmin, setToggleAdmin] = React.useState(true);
    const [route, setRoute] = React.useState('');
    const countries = state.countries;
    const [gdpr, setGdpr] = React.useState(false);

    const [nameTooltipOpen, setNameTooltipOpen] = React.useState(false);
    const [passwordTooltipOpen, setPasswordTooltipOpen] = React.useState(false);
    const toggle = (chosenTooltip, setChosenTooltip) => setChosenTooltip(!chosenTooltip);

    const handleGDPR = () => {
        setGdpr(!gdpr)
    };

    function handleRole() {
        if ((state.user && state.user.role === 'administrator') || (state.state && state.state.user.role === 'administrator')) {
            setToggleAdmin(true);
            setUserRole('admin');
        } else {
            setToggleAdmin(false);
            setUserRole('simple');
            setRoute('/api/registration/single');
        }
        if ((state.user && state.user.role === 'manager') || (state.state && state.state.user.role === 'manager')) {
            setToggleManager(true);
            //setToggled('block');
            setUserRole('manager');
            setRoute('/api/registration/manager');
        } else {
            setToggleManager(false);
            //setToggled('none');
            setRoute('/api/registration/single');
        }
    }


    function switchRole(e) {
        if (e.target.value === 'administrator') {
            dispatch({type: "SET_ADMIN_USER", payload: state.user});
        } else if (e.target.value === 'manager') {
            dispatch({type: "SET_MANAGER_USER", payload: state.user});
        } else {
            dispatch({type: "SET_SIMPLE_USER", payload: state.user});
        }
    }

    // function loadTooltip(e){
    //     $('input[name=name]').tooltip('show');
    // }

    React.useEffect(() => {
        if (state.user) {
            handleRole();
        }
    });
    //
    // console.log('role in:');
    // console.log(state.user);
//console.log(errors);
    return (<>
        <div className="form-row">
            <FormGroup className="col-md-4">
                <Label for="firstName">{strings.firstname}(*)</Label>
                <Input id="firstName" placeholder={strings.firstname} type="text" name="firstname"
                       innerRef={register({required: true})}/>
                {errors.firstname && <span className="validation-error">{strings.required_validation}</span>}
            </FormGroup>
            <FormGroup className="col-md-4">
                <Label for="lastName">{strings.lastname}(*)</Label>
                <Input id="lastName" placeholder={strings.lastname} type="text" name="lastname"
                       innerRef={register({required: true})}/>
                {errors.lastname && <span className="validation-error">{strings.required_validation}</span>}
            </FormGroup>
            <FormGroup className="col-md-4">
                <label>Email(*)</label>
                <Input placeholder="Email" type="text" name="email" innerRef={register({required: true})}/>
                {errors.email && <span className="validation-error">{strings.required_validation}</span>}
            </FormGroup>
        </div>
        <div className="form-row">
            <FormGroup className="col-md-4">
                <label>{strings.username}(*)</label>
                <Input placeholder={strings.username_placeholder} type="text" name="name"
                       innerRef={register({required: true, pattern:/^[a-z0-9\_\-]+$/})} id={"name"}/>
                {errors.name && errors.name.type === "required" && <span className="validation-error">{strings.required_validation}</span>}
                {errors.name && errors.name.type === "pattern" && <span className="validation-error">{strings.name_pattern_validation}</span>}
                <TooltipWrap target={"name"} content={
                    <>
                        <p>- {strings.rule_only_alpha_lower}</p>
                        <p>- {strings.rule_alpha_num}</p>
                        <p>- {strings.rule_special_char}</p>
                    </>
                }/>
            </FormGroup>
            <FormGroup className="col-md-4">
                <label>{strings.password}(*)</label>
                <Input placeholder={strings.password_placeholder} type="password" name="password"
                       innerRef={register({required: true, minLength: 8, maxLength: 14,
                       pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+/})} id={"password"}/>
                {errors.password && errors.password.type === "required" && <span className="validation-error">{strings.required_validation}</span>}
                {errors.password && errors.password.type === "pattern" && <span className="validation-error">{strings.pattern_validation}</span>}
                {errors.password && errors.password.type === "minLength" && <span className="validation-error">{strings.password_minlength_validation}</span>}
                {errors.password && errors.password.type === "maxLength" && <span className="validation-error">{strings.password_maxlength_validation}</span>}
                <TooltipWrap target={"password"} content={
                    <>
                        <p>- {strings.formatString(strings.rule_between_string, { min: 8, max: 14})}</p>
                        <p>- {strings.rule_alpha_lower_required}</p>
                        <p>- {strings.rule_alpha_upper_required}</p>
                        <p>- {strings.rule_number_required}</p>
                        <p>- {strings.rule_special_char_required}</p>
                    </>
                }/>
            </FormGroup>
            <FormGroup className="col-md-4">
                <label>{strings.password_confirmation}(*)</label>
                <Input placeholder={strings.password_placeholder} type="password" name="password_confirmation"
                       innerRef={register({required: true, minLength: 8, maxLength: 14,
                           pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+/})}/>
                {errors.password_confirmation && errors.password_confirmation.type === "required" && <span className="validation-error">{strings.required_validation}</span>}
                {errors.password_confirmation && errors.password_confirmation.type === "pattern" && <span className="validation-error">{strings.pattern_validation}</span>}
                {errors.password_confirmation && errors.password_confirmation.type === "minLength" && <span className="validation-error">{strings.password_minlength_validation}</span>}
                {errors.password_confirmation && errors.password_confirmation.type === "maxLength" && <span className="validation-error">{strings.password_maxlength_validation}</span>}            </FormGroup>
        </div>
        <div className="form-row">
            <FormGroup className="col-md-4">
                <label>{strings.birthdate}(*)</label>
                <Input placeholder={strings.birthdate} type="date" name="birthdate"
                       innerRef={register({required: !toggleManager})}/>
                {errors.birthdate && <span className="validation-error">{strings.required_validation}</span>}
            </FormGroup>
            <FormGroup className="col-md-4">
                <Label for="mobile">{strings.profile_mobile}(*)</Label>
                <Input id="mobile" placeholder={strings.profile_mobile} type="phone" name="mobile"
                       innerRef={register({required: false})}/>
                {errors.mobile && <span className="validation-error">{strings.required_validation}</span>}
                <TooltipWrap target={"mobile"} content={
                    <>
                        <p>{strings.rule_num_spaces}</p>
                    </>
                }/>
            </FormGroup>
            <FormGroup className='col-md-4'>
                <label>{strings.vat_reg_num}</label>
                <Input placeholder={strings.vat_reg_num} type="text" name="vat_reg_no"
                       innerRef={register({required: false})}/>
                {errors.vat_reg_no && <span className="validation-error">{strings.required_validation}</span>}
            </FormGroup>
        </div>
        <div className="form-row">
            <FormGroup className='col-md-6'>
                <Label for="examCountry">{strings.registration_country}(*)</Label>
                <Input type="select" name="country_code" id="country_code" innerRef={register({required: true})}>
                    <option>{strings.choose}</option>
                    {countries.map((country, key) => <option key={key}
                                                             value={country.name}>{strings[country.name.toLowerCase()]}</option>)}
                    {errors.country_code && <span className="validation-error">{strings.required_validation}</span>}
                </Input>
            </FormGroup>
            <FormGroup className='col-md-6'>
                <Label for="role_name">{strings.role}(*)</Label>
                <Input type="select" name="role_name" id="role_name" onLoad={handleRole} onChange={switchRole}
                       innerRef={register({required: true})}>
                    <option value="">{strings.choose}</option>
                    <option value="user">{strings.simple_user}</option>
                    <option value="manager">{strings.company_manager}</option>
                    { toggleAdmin && <option value="examiner">{strings.examiner}</option>}
                    { toggleAdmin && <option value="invigilator">{strings.invigilator}</option>}
                </Input>
                {errors.role_name && <span className="validation-error">{strings.required_validation}</span>}
            </FormGroup>
        </div>
        <div className="form-row">
            <FormGroup className='col-md-6' check>
                <Label className="form-check-label">
                    <Input className="form-check-input" onChange={handleGDPR} innerRef={register({required: true})}
                           type="checkbox" value={gdpr} name="gdpr_consent"/>
                    {strings.gdpr_consent}
                    <span className="form-check-sign">
                        <span className="check"></span>
                    </span>
                </Label>
                <div><a className="text-white" target="_blank" rel="noreferrer noopener" href={`/view/gdpr/MCD_013`}>
                    <span className="ml-3">{strings.view_policy}</span></a></div>
                {errors.gdpr_consent && errors.gdpr_consent.type === "required" && <div><span className="validation-error">{strings.gdpr_validation}</span></div>}
            </FormGroup>
            <FormGroup className='col-md-6' check style={{/*{display: isToggled.toString()}*/}}>
                <Label className="form-check-label">
                    <Input className="form-check-input" type="checkbox" value="" name="newsletter_consent"/>
                    {strings.newsletter_subscribe}
                    <span className="form-check-sign">
                        <span className="check"></span>
                    </span>
                </Label>
            </FormGroup>
        </div>
        <RegistrationFormDetails toggleManager={toggleManager} onLoad={handleRole} register={register} errors={errors}/>
    </>);
}
