import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {useForm} from "react-hook-form";

const ConfirmModal = ({toggle, modal, processConfirm}) => {
    const {register, handleSubmit, watch, errors} = useForm();


    return (
        <div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle} className={''}>Please confirm</ModalHeader>
                <ModalBody className={''}>
                    Are you sure? This action is irreversible.
                </ModalBody>
                <ModalFooter className={'p-2'}>
                    <Button color="primary" onClick={()=>{ handleSubmit(processConfirm()) }}>Yes</Button>{' '}
                    <Button color="secondary" onClick={()=>{toggle()}}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ConfirmModal;
