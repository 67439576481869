import {Card, CardBody, CardHeader, CardTitle, Collapse, FormGroup, Input, Label} from "reactstrap";
import React, {useEffect} from "react";
import strings from "../../app/translations";

export default function PickInvigilators({invigilators, selectedInvigilators, register, errors}) {

    const [message, setMessage] = React.useState('');

    function addNumbers(e) {
        multipleSelectValues();
        let options = e.target.options;
        let value = [];
        let sum = 0;
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                console.log(options[i].dataset.content);
                value.push({id:parseInt(options[i].id), quantity:options[i].dataset.content, value: options[i].value});
            }
        }
        return value;
    }

    function multipleSelectValues(){
        let invigilators = [];
        Array.from(document.getElementById("invigilatorsList").options).map(option =>{
            if(option.selected)
                invigilators.push(option.value)
        });
        document.getElementById("examination_invigilators").value = JSON.stringify(invigilators);
    }

    useEffect(function(){
        selectedInvigilators.map(value => {
            let elem = document.getElementById('invigilatorsList');
            selectItemByValue(elem, value);
            //document.getElementById(list.toString()).options[key].selected = true;
        })
        document.getElementById("examination_invigilators").value = JSON.stringify(selectedInvigilators);
    },[selectedInvigilators]);

    function selectItemByValue(elem, value){
        for(var i=0; i < elem.options.length; i++)
        {
            if(elem.options[i].value == value)
                elem.selectedIndex = i;
        }
    }
    return (<Card className="col-md-6 card-plain">
        <CardHeader style={{marginBottom: "1rem"}}>
            <i className="nc-icon nc-single-copy-04"/> {strings.invigilators}
        </CardHeader>
        <CardBody>
            <CardTitle>{strings.invigilators}</CardTitle>
            <FormGroup>
                <Label for="invigilatorsList">{strings.select_invigilators}</Label>
                <Input type="select" name="invigilatorsList"
                       id="invigilatorsList"
                       innerRef={register({ required: false })}
                       multiple onChange={addNumbers}>
                    {invigilators.map((invigilator, key) => {
                        return <option key={key} id={invigilator.id} className={"list-item-style"} value={invigilator.id}>
                            {invigilator.lastname}, {invigilator.firstname} </option>
                    })}

                </Input>
                {message.length > 0 && <span className="validation-error">{message}</span>}
                {errors.invigilatorsList && <span className="validation-error">{strings.required_validation}</span>}
                <input hidden id="examination_invigilators" ref={register({required: false})}
                       name="examination_invigilators"/>

            </FormGroup>
        </CardBody>
    </Card>)
}
