import React, {useContext} from "react";
import EntitiesContext from "../../app/context";
import strings from "../../app/translations";
import masterCard from '../../assets/img/piraeus/Mastercard.png';
import maestro from '../../assets/img/piraeus/Maestro.png';
import visa from '../../assets/img/piraeus/VisaWhite.png';
import visaSecure from '../../assets/img/piraeus/visa-secure_dkbg_blu_72dpi.jpg';
import masterCardIDCheck from '../../assets/img/piraeus/mc_idcheck_hrz_rev_97px.png';

//
// let strings = new LocalizedStrings({
//     en:{
//         made_with:"made with",
//         by:"by"
//     },
//     el: {
//         made_with:"υλοποιημενο με",
//         by:"απο την"
//     }
// });

export default function Footer() {
    // const {state,dispatch} = useContext(EntitiesContext);

 //   strings.setLanguage(language);

    return (<div className="section section-cards section-dark text-center" style={{paddingTop: "5rem"}}>
        <div className="footer register-footer text-center">
            <h6>
                © {new Date().getFullYear()}, {strings.made_with.stripAccents()}{" "}
                <i className="fa fa-heart heart"/> {strings.by.stripAccents()} WIDE Services
            </h6>
            <div id='cardIcons' style={{paddingTop: "0.25rem"}}>
                <img src={visa} width={45} height={40} title={'Visa'}/>
                <img src={masterCard} title={'MasterCard'}/>
                <img src={maestro} title={'Maestro'}/>
                <img src={visaSecure} title={'Visa Secure'} width={40} height={30}/>
                <img src={masterCardIDCheck} title={'MasterCard ID Check'}/>
            </div>
        </div>
    </div>)

}

