let middleware = {
    externalData: {
        names: [{
            name: 'Υπάλληλος Διοικητικής Υποστήριξης σε Επιχειρήσεις Νέων Τεχνολογιών (Θεωριτικά)',
            num_of_participants: '20 participants',
            exam_status: "<span class='badge badge-danger'>Closed</span>",
            date: '8 April 2020',
            participation_form: 'Company1_form1',
            remote: 'yes',
            grade_auto: 'yes',
            open_date: 'yes',
            action: "<a href='/landing-page'><button class='btn btn-round btn-info'>" + "view" + "</button></a>"
        },
            {
                name: 'Titlos Pistopoihshs 2 (Θεωριτικά & Πρακτικά)',
                num_of_participants: '1 participant',
                exam_status: "<span class='badge badge-success'>Open</span>",
                date: '18 April 2020',
                participation_form: 'Company1_form2',
                remote: 'no',
                grade_auto: 'yes',
                open_date: 'no',
                action: "<a href='/admin/certification-page'><button class='btn btn-round btn-info'>" + "view" + "</button></a>"
            },
            {
                name: 'Titlos Pistopoihshs 3 (Θεωριτικά & Πρακτικά)',
                num_of_participants: '15 Participants',
                exam_status: "<span class='badge badge-success'>Open</span>",
                date: '29 June 2020',
                participation_form: 'Company1_form15',
                remote: 'yes',
                grade_auto: 'yes',
                open_date: 'no',
                action: "<a href='/admin/certification-page'><button class='btn btn-round btn-info'>" + "view" + "</button></a>"
            }],
        columns: [
            {
                title: 'Exam Title',
                width: 250,
                data: 'name'
            },
            {
                title: 'Number of Participants',
                width: 150,
                data: 'num_of_participants'
            },
            {
                title: 'Exam Status',
                width: 120,
                data: 'exam_status'
            },
            {
                title: 'Exam Date',
                width: 150,
                data: 'date'
            },
            {
                title: 'Participation Form',
                width: 150,
                data: 'participation_form'
            },
            {
                title: 'Remote',
                width: 120,
                data: 'remote'
            },
            {
                title: 'Auto Grading',
                width: 120,
                data: 'grade_auto'
            },
            {
                title: 'Open date',
                width: 120,
                data: 'open_date'
            },
            {
                title: 'Action',
                width: 150,
                data: 'action'
            },
        ]
    }, cardTitle: null
};
middleware.tableWidth = "800px";
middleware.headerImage = "tuv-austria-hellas.jpg";
if (document.location.pathname === '/participation-form') {
    middleware.cardTitle = 'Participation Details';
} else if (document.location.pathname === '/enrollment-page') {
    middleware.cardTitle = 'Enrollments';
    middleware.singleTitle = 'Enrollment';
    console.log('within middleware');
} else if (document.location.pathname === '/admin/certification-page') {
    middleware.externalData = {
        names: [
            {
                name: 'Υπάλληλος Διοικητικής Υποστήριξης σε Επιχειρήσεις Νέων Τεχνολογιών',
                category: 'Eidikotita 1',
                type: 'Θεωρητικά',
                duration: '6 months',
                price: '30 euro',
                remote: 'yes',
                grade_auto: 'yes',
                open_date: 'yes',
                action: "<a href='/admin/certification-page'><button class='btn btn-round btn-info'>" + "edit" + "</button></a>"
            },
            {
                name: 'Titlos Pistopoihshs 2',
                category: 'Eidikotita 2',
                type: 'Θεωρητικά & Πρακτικά',
                duration: '2 years',
                price: '100 euro',
                remote: 'no',
                grade_auto: 'yes',
                open_date: 'no',
                action: "<a href='/admin/mock-certification-page'><button class='btn btn-round btn-info'>" + "edit" + "</button></a>"
            },
            {
                name: 'Titlos Pistopoihshs 3',
                category: 'Eidikotita 3',
                type: 'Θεωρητικά',
                duration: '6 months',
                price: '35 euro',
                remote: 'yes',
                grade_auto: 'yes',
                open_date: 'no',
                action: "<a href='/admin/mock-certification-page'><button class='btn btn-round btn-info'>" + "edit" + "</button></a>"
            }],
        columns: [
            {
                title: 'Title',
                width: 400,
                data: 'name'
            },
            {
                title: 'Certification Schema',
                width: 150,
                data: 'category'
            },
            {
                title: 'Type',
                width: 120,
                data: 'type'
            },
            {
                title: 'Duration',
                width: 150,
                data: 'duration'
            },
            {
                title: 'Price',
                width: 180,
                data: 'price'
            },
            {
                title: 'Remote',
                width: 120,
                data: 'remote'
            },
            {
                title: 'number of exams',
                width: 100,
                data: 'number_of_exams'
            },
            {
                title: 'Auto Grading',
                width: 120,
                data: 'grade_auto'
            },
            {
                title: 'Open date',
                width: 120,
                data: 'open_date'
            },
            {
                title: 'Action',
                width: 150,
                data: 'action'
            },
        ]
    };
    middleware.cardTitle = 'Certifications List';
    middleware.singleTitle = 'Certification';
    middleware.tableWidth = "400px";
    middleware.headerImage = "1-1920x1080.png";
    middleware.pageTitle = "Admin";
} else if (document.location.pathname === '/admin/mock-examination-page') {
    middleware.cardTitle = 'Examinations';
    middleware.singleTitle = 'Examination';
    middleware.tableWidth = "400px";
    middleware.headerImage = "1-1920x1080.png";
    middleware.pageTitle = "Admin";
} else if (document.location.pathname === '/admin/mock-company-page') {
    middleware.cardTitle = 'Companies';
    middleware.singleTitle = 'Company';
    middleware.tableWidth = "400px";
    middleware.headerImage = "1-1920x1080.png";
    middleware.pageTitle = "Admin";
} else if (document.location.pathname === '/admin/mock-enrollment-page') {
    middleware.cardTitle = 'Enrollments';
    middleware.singleTitle = 'Enrollment';
    middleware.tableWidth = "400px";
    middleware.headerImage = "1-1920x1080.png";
    middleware.pageTitle = "Admin";
    const excluded_columns = ["open_date", "grade_auto", "remote"];
    //update columns with the ones remaining
    middleware.externalData.columns = middleware.externalData.columns.map((column) => {
        column.width = "60px";
        if (excluded_columns.indexOf(column.data) === -1) {
            return column
        }
    });
    //remove any undefined columns
    middleware.externalData.columns = middleware.externalData.columns.filter(column => typeof column !== "undefined");

    //map datatable objects in the names array
    middleware.externalData.names = middleware.externalData.names.map((object) => {
        let properties = middleware.externalData.columns.map(col => col.data);
        console.log(properties);
        object = properties.reduce((newobj, key) => ({...newobj, [key]: object[key]}), {});
        return object;
    });
    console.log(middleware.externalData.names);
    console.log('ze columns');
    console.log(middleware.externalData.columns);
    middleware.externalData.names[0].action = "<a href='/admin/mock-landing-page'>" +
        "<button class='btn btn-round btn-success'>" + "certify" + "</button></a>"
} else {
    middleware.externalData = {};
}
export default {middleware};
