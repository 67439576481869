import React, {useContext, useReducer} from "react";
import {Button, Form, FormGroup, Input, Label} from "reactstrap";
import RegistrationFormDetails from "./RegistrationFormDetails";
import EntitiesContext from "../../../app/context";
import axios from "axios";
import Document from "../Document";
import SingleRegistration from "./SingleRegistration";
import {useForm} from "react-hook-form";
import strings from "../../../app/translations";

export default function RegistrationForm({setUserRole,route,showModal, buttonEnabled, setButtonEnabled, setLoading}) {
    const {state, dispatch} = useContext(EntitiesContext);
    const {register, handleSubmit, watch, errors} = useForm();

    // data: {firstname: "ads", lastname: "sd", email: "sd", name: "sd", password: "dsd", …}
    // route: /api/registration/single
    // showModal:
    // event:


    const onSubmit = (data, event) => {
         // console.log('data sent in onSubmit');
         //console.log(event);
        setLoading(true);
        setButtonEnabled(false);
        state.handleSubmit(data, route, showModal, event);
    };

    // console.log('role out:');
    // console.log(state.user);


    return (<Form className="registration-form text-white mb-5" id="registration-form" onSubmit={handleSubmit(onSubmit)}
                  method="POST">
            <SingleRegistration setUserRole={setUserRole} register={register} errors={errors}/>
            <Button block className="btn-round col-4 ml-auto mr-auto" color="tuv_red"  disabled={!buttonEnabled}>
                {strings.registration.stripAccents()}
            </Button>
        </Form>
    )
}
