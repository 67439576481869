/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useContext, useReducer, useState} from "react";
// reactstrap components
// core components
import ExamplesNavbar from "./../../components/Navbars/ExamplesNavbar.js";

import RegisterMain from "./main/RegisterMain";
import Footer from "../../components/Footers/Footer";
//context API
import EntitiesContext from "../../app/context";
import CRUDReducer from "../../app/reducer";
import LoginMain from "./main/LoginMain";
import SectionLogin from "../index-sections/SectionLogin";
import Cookie from 'universal-cookie';
import strings from "../../app/translations";

function MockLoginPage() {
    const cookie = new Cookie;
    const initialState = useContext(EntitiesContext);
    const [state,dispatch] = useReducer(CRUDReducer,initialState);
    const [userRole,setUserRole] = React.useState(state.user.role);

    const [language, setLanguage] = useState(getLanguage());
    const title = strings.login;

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        console.log('user_role');
        console.log(userRole);
        document.body.classList.add("register-page");
        return function cleanup() {
            document.body.classList.remove("register-page");
        };
    });
    function getLanguage ()  {
        let lang=cookie.get('language_set')??'en';
        //dispatch({type:"UPDATE_LANGUAGE",payload:lang});
        return lang;
    }
    return (
        <>
            <EntitiesContext.Provider value={{state, dispatch}}>
                <ExamplesNavbar setUserRole={setUserRole} setLanguage={language=>setLanguage(language)}/>
                <div
                    className="page-header"
                    style={{
                        backgroundImage: "url(" + require("./../../assets/img/mockups/1-1920x1080.png") + ")",
                        backgroundSize: "cover"
                    }}
                ><h1 className="text-center text-white" style={{zIndex: "3"}}>{title}</h1>

                    <div className="filter"/>


                </div>
                <div className="main">
                    <LoginMain setUserRole={setUserRole}/>
                    <Footer/>
                </div>
            </EntitiesContext.Provider>
        </>
    );
}

export default MockLoginPage;
