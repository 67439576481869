import React,{useState,useEffect,useContext,useReducer} from "react";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardImg,
    CardText,
    CardTitle,
    Col,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row
} from "reactstrap";
import axios from 'axios';
import Cookie from 'universal-cookie';
import LocalizedStrings from 'react-localization';
import SimpleSlider from "../../../components/Sliders/FeaturedProductsCarousel";
import EntitiesContext from "../../../app/context";
import strings from "../../../app/translations";
import CRUDReducer from "../../../app/reducer";


const title = "Certification Schema";
const headerImage = "1-1920x1080.png";
const landingImage = "component.png";


export default function LandingPageMain(){

    const {state,dispatch} = useContext(EntitiesContext);
    const objs = state.certifications.objects.map(object => object.data); // certifications from db
    const [categoriesAll, setCategoriesAll] = useState(state.categories.objects.map(object => object.data));

    //
    const [certificationsAll,setCertificationsAll] = useState(state.certifications.objects.map(object => object.data));

    // console.log('lengths');
    // console.log(categoriesAll.length);
    // console.log(certificationsAll.length);




    const cookie = new Cookie;
    //const glossa=lang;

        objs.map((obj,key) =>{
            // console.log(key);
            // console.log(categories);
            if(categoriesAll[key]){
                categoriesAll[key].title = obj.title;
                categoriesAll[key].description = obj.category;
                categoriesAll[key].img = "Τεχνίτες Δομικών Έργων Αλουμινίου - Σιδήρου.jpg";
            }
        });


//console.log(objs);return false;    // certifications
//console.log(categories); return false;  // categories

    const [numSlides, setNumSlides] = useState(5);

    //const [language, setLanguage] = useState(getLanguage());
    // const [language, setLanguage] = useState(state.language);
    // const language=language;
    useEffect(function(){
        if(document.documentElement.clientWidth <768){
            setNumSlides(3);
        }
        if(document.documentElement.clientWidth <375){
            setNumSlides(2);
        }
        // if(cookie.get('language_set')==='el'){
        //     console.log('changing language in useeffect: el');
        //     setLanguage('el');
        // } else {
        //     console.log('changing language in useeffect: en');
        //     setLanguage('en');
        // }
        // setLanguage(cookie.get('language_set'));
    },[]);

    // function getLanguage(){
    //     return cookie.get('language_set')??'en';
    // }
    // console.log('landing page with lang: '+ language);

   // strings.setLanguage(language);

    useEffect(function(){
        console.log('updated categories');
        console.log(state.categories);
        setCategoriesAll(state.categories.objects.map(object => object.data))
        setCertificationsAll(state.certifications.objects.map(object => object.data))

    },[state]);

    return(<div className="landing-page">
        <div className="section section-dark text-center" id="certifications" style={{
            backgroundImage: "url(" + require(`../../../assets/img/mockups/${landingImage}`) + ")"
        }}>
            <div className="container-fluid" >
                <Row>
                    <Col className="ml-auto mr-auto" md="8">
                        <h2 className="title">{strings.intro2}</h2>
                        <h5 className="description">
                            {strings.intro3}
                        </h5>
                        <br/>
                        <Button
                            className="btn-round"
                            color="neutral"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                        >
                            {strings.see_details}
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
        <div>
            <div className="container-fluid">
                <SimpleSlider title={strings.schema} numSlides={numSlides}
                              categoriesAll={categoriesAll} certificationsAll={certificationsAll}/>
            </div>
        </div>
        <div className="section landing-section bg-body">
            <div className="container-fluid">
                <Row>
                    <Col className="ml-auto mr-auto" md="8">
                        <h1 className="text-center text-white font-weight-bold">{strings.contact_form_title}</h1>
                        <Form className="contact-form text-white">
                            <Row>
                                <Col md="6">
                                    <label>{strings.contact_form_name}</label>
                                        <Input placeholder={strings.contact_form_name} type="text"/>
                                </Col>
                                <Col md="6">
                                    <label>{strings.email}</label>
                                        <Input placeholder={strings.email} type="text"/>
                                </Col>
                            </Row>
                            <label>{strings.contact_form_message}</label>
                            <Input
                                placeholder={strings.contact_form_message_placeholder}
                                type="textarea"
                                rows="4"
                            />
                            <Row>
                                <Col className="ml-auto mr-auto" md="3">
                                    <Button className="btn btn-round font-weight-light col-12" color="tuv_red" size="lg">
                                        {strings.contact_form_button}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </div>
        </div>
    </div>)
}
