import {Form, FormGroup, Input, Label, Tooltip, Button} from "reactstrap";
import React, {useContext, useState} from "react";
import EntitiesContext from "../../app/context";
import DateRangePicker from 'react-bootstrap-daterangepicker';

import 'bootstrap-daterangepicker/daterangepicker.css';

import strings from "../../app/translations";
import TooltipWrap from "./TooltipWrap";

export default function CustomFilter({filter, dateRangeSettings, handleDateCancel, handleDateApply}) {

    const {state, dispatch} = useContext(EntitiesContext);

    if (filter.type === 'date') {
        return (
            <FormGroup className="col-md-4">
                <label htmlFor={filter.name}>{strings[filter.title]}</label>
                <DateRangePicker initialSettings={dateRangeSettings} onApply={handleDateApply} onCancel={handleDateCancel}>
                    <input type='text' name={filter.name} id={filter.name} className={'custom-filter form-control'}></input>
                </DateRangePicker>
            </FormGroup>
        );
    } else {
        return (
            <FormGroup className="col-md-4">
                <label htmlFor="date">{strings[filter.title]}</label>
                <Input type={filter.type} name={filter.name} id={filter.name} className={'custom-filter'}>
                </Input>
            </FormGroup>
        )
    }

}
