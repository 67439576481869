import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import UserDetails from "../UserDetails";
import ParticipationFormDetails from "./ParticipationFormDetails";
import React, {useContext, useEffect, useState} from "react";
import Container from "reactstrap/es/Container";

import EntitiesContext from "../../../app/context";
import ParticipationContext from "../../../app/ParticipationContext";
import {useForm} from "react-hook-form";
import strings from "../../../app/translations";
import Modal from "../../Modals/Modal";
import OverlayLoader from "../../Loaders/OverlayLoader";

let exams = [{theoretical: 'yes', practical: 'yes'}, {theoretical: 'yes'}, {practical: 'yes'}];
export default function ParticipationForm({}) {
    const {pState,pDispatch} = useContext(ParticipationContext);
    const [formType,setFormType] = React.useState('individual');
    const user_company = window.reactInit.company_id;
    const user_role = window.reactInit.user_role;
    const [companyPhone,setCompanyPhone] = React.useState("");
    const [vatNumber,setVatNumber] = React.useState("");
    const [certification,setCertification] = React.useState({});
    const [modalShow,setModalShow] = React.useState(false);
    const [message,setMessage] = React.useState([]);
    const [action,setAction] = React.useState('');
    const [approve,setApprove] = React.useState(false);
    const pagePathname = '/enrollments-page';
    //edit mode : pathname contains /cert_id/form_id
    const editMode = (window.location.pathname.split('/').length> 3);
    const [statusId, setStatusId] = React.useState();
    // old edit mode
    //const pagePathname = '/participationForm-page';
    // const editMode = (window.location.pathname !== pagePathname)
    const window_parts = window.location.pathname.split("/");
    const product_id = window_parts[2];
    // console.log('product_id');
    // console.log(product_id);
    const [label,setLabel] = React.useState('submit');
    const [labelColor,setLabelColor] = React.useState('tuv_red');

    const disabledFields = ['individual','group','participation_certification_title','participation_certification_number_of_activities','participation_select_users'];
    // Overlay Loader
    const [loading, setLoading] = React.useState(false);
    const [approveLoading, setApproveLoading] = React.useState(false);

    const [role,setRole] = useState({});
    function chooseType(e){
        let type = e.target.id;
        handleType(type)
    }
    function handleType(type) {
        setFormType(type);
        if (!editMode && pState.user) {
            if (type === "individual") {
                setCompanyPhone(pState.user.mobile);
                setVatNumber(pState.user.vat_reg_no);
            } else {
                setCompanyPhone(pState.user.company.phone);
                setVatNumber(pState.user.company.vat_reg_no);
            }
        }else if(editMode){
            setCompanyPhone(currentItem.data.phone);
            setVatNumber(currentItem.data.vat_reg_no);
        }
    }

    function showModal(data) {
        let msg = [];
        if (data.errors) {
            Object.values(data.errors).map(i => i.map(m => {
                return msg.push(m)
            }));
        } else if(data.records) {

            if (editMode)
                msg.push(strings.application_approved_successfully);
            else
                msg.push(strings.application_saved_successfully);
        }else{
            msg.push(strings.unexpected_error)
        }
        /*console.log('msg');
        console.log(msg);*/

        let act = null;
        if (editMode)
            act = {link: pagePathname, label: strings.continue};
        else if(data.errors){
            act = "error"
        }
        else if(data.records){
            act = {link: "/index", label: strings.continue};
        }

        setAction(act);
        setMessage(msg);
        setModalShow(true);
        $('#appModal').modal({show: true});
        // console.log('show');
        // console.log(msg);
        setLoading(false);
        setApproveLoading(false);
    }
    const [route, setRoute] = React.useState('/api/participation/submit');
    const {register, handleSubmit, watch, errors} = useForm();
    const onSubmit = (data, event) => {
        if (route === '/api/participation/approve') {
            setApproveLoading(true);
        }
        // console.log('data sent in handlesubmit');
        // console.log(data);
        // console.log('event.target');
        // console.log(event.target);
        setLoading(true);
        pState.handleSubmit(data, route, showModal, event)
    };

    const isReadOnly = (name) => {
            if (disabledFields.length > 0){
                var edit_statuses = [1, 2]; // 1 - Draft, 2 - Waiting Approval
                if (statusId && !edit_statuses.includes(statusId)) {
                    var additional_fields = ['participation_phone', 'participation_vat_reg_no', 'participation_note',
                        'participation_start_date', 'participation_end_date', 'single_date', 'usersList'];
                    return [...disabledFields, ...additional_fields].indexOf(name) !== -1;
                }
                if (editMode) {
                    return disabledFields.indexOf(name) !== -1;
                }
                return disabledFields.indexOf(name) !== -1;
            }
    };

    const handleButton = (e)=>{
        if(e.target.id === "submit"){
            setRoute('/api/participation/submit');
            setLabel(strings.submit);
            setLabelColor('tuv_red');
            setApprove(false);
        }
        else{
            setRoute('/api/participation/approve');
            setLabel(strings.approve);
            setLabelColor('success');
            setApprove(true);
        }


    }


    const currentItem = pState.currentItem;
    const certifications = pState.certifications.objects.map(object=>object.data);
    // console.log('currentItem');

    function loadCurrentItem() {

        // console.log('loaded');

        if (currentItem && currentItem.data) {
            setStatusId(Number(currentItem.data.status_id));
            certifications.filter(certification => {
                if(parseInt(certification.id)=== parseInt(currentItem.data.certification_id)){
                    // console.log('within loadCurrentItem');
                    // console.log(currentItem.data);
                    setCertification(certification);
                }});
            if (currentItem.data.type && currentItem.data.type.length > 0) {

                document.getElementById(currentItem.data.type).checked = true;
                handleType(currentItem.data.type);
            }

            currentItem.data.participants.map(participant => {
                let select = document.getElementById('usersList');
                let options = Array.from(select.options);
                // console.log('options');
                // console.log(options);
                options.map(option => {
                    if (parseInt(option.id) === parseInt(participant.user_id))
                        option.selected = true;

                });
                let selectDocs = document.getElementById('documentsList' + participant.user_id);
                let optionsDocs = Array.from(select.options);
                optionsDocs.map(option => {
                    /*if(parseInt(option.id) === parseInt(participant.user_id))
                        option.selected = true;*/
                });
            })

            setLoading(false);
            /*document.getElementById('participation_certification_number_of_activities').value = currentItem.data.certification.number_of_activities;*/
        }
    }

    useEffect(function () {
        // console.log('editMode');
        // console.log(editMode);
        if(!editMode && product_id){
            certifications.filter(certification => {
                if(parseInt(certification.id)=== parseInt(product_id)){
                    console.log('within certification');
                setCertification(certification);
            }});
        }

        if (editMode) {
            setLoading(true);
            loadCurrentItem();
        }
        if(pState.user && formType === "individual"){
            setCompanyPhone(pState.user.mobile);
            setVatNumber(pState.user.vat_reg_no);
            pState.user.roles.map(role => {
                if(role.name === 'moderator')
                    setRole('moderator');
            })

        }
        // console.log(certificationType);
    });
    return (
        <Container>
            <Row>
                <Col className="ml-auto mr-auto ">
                    <h3 className="title mx-auto text-white text-center">{strings.participation_form}</h3>
                    <OverlayLoader className={'mx-n3'} active={loading || approveLoading} text={strings.load} background={'rgba(255,255,255,0.2)'}>
                        <Form className="participation-form text-white pb-4 mx-3" id="participation-form" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-row">
                                <FormGroup className="offset-md-2 col-md-4" check>
                                    <div className="form-check-radio">
                                        <label className="form-check-label">


                                        {(user_company === null || user_company.length === 0) &&
                                        <input className="form-check-input" type="radio" name="participation_type"
                                               id="individual" value="individual" onChange={chooseType} defaultChecked={true}
                                               ref={register({required: (!isReadOnly("individual"))})} />
                                        }
                                        {
                                            (user_company !== null && user_company.length > 0) &&
                                            <input className="form-check-input" type="radio" name="participation_type"
                                                   id="individual" value="individual" onChange={chooseType} defaultChecked={formType === 'individual'}
                                                   ref={register({required: (!isReadOnly("individual"))})} />
                                        }
                                        {" "}
                                            {strings.participation_form_individual}
                                            <span className="form-check-sign">
                                                        <span className="check"></span>
                                                    </span>
                                        </label>
                                    </div>
                                    {errors.individual &&
                                    <span className="validation-error">{strings.required_validation}</span>
                                    }
                                </FormGroup>
                                {(user_role !== "" && user_role !== "user" )&&
                                <FormGroup className="col-md-4" check>
                                    <div className="form-check-radio">
                                        <label className="form-check-label">
                                            <input className="form-check-input" type="radio" name="participation_type"
                                                   id="group" value="group" onChange={chooseType}
                                                   defaultChecked={formType === "group"}
                                                   ref={register({required: (!isReadOnly("individual"))})}/>
                                            {strings.participation_form_group}
                                            <span className="form-check-sign">
                                                        <span className="check"></span>
                                                    </span>
                                        </label>
                                    </div>
                                    {errors.group &&
                                    <span className="validation-error">{strings.rule_required}</span>
                                    }
                                </FormGroup>
                                }
                            </div>
                            <UserDetails register={register} errors={errors} isReadOnly={isReadOnly} certification={certification} setCertification={setCertification} formType={formType} setMessage={message => setMessage(message)}/>
                            <ParticipationFormDetails formType={formType} companyPhone={companyPhone} setcompanyPhone={setCompanyPhone} vatNumber={vatNumber} certification={certification} register={register} errors={errors} isReadOnly={isReadOnly}/>
                            {role === "moderator" && editMode && (statusId !== 3) &&
                            <div className="form-row" id="form-actions">
                                <FormGroup className="offset-md-2 col-md-4" check>
                                    <div className="form-check-radio">
                                        <label className="form-check-label">



                                            <input className="form-check-input" type="radio"
                                                   id="submit" name="submit" value="submit" onClick={handleButton} onChange={handleButton} checked={!approve}
                                                   ref={register({required: (!approve)})} />

                                            {" "}
                                            {strings.submit}
                                            <span className="form-check-sign">
                                                        <span className="check"></span>
                                                    </span>
                                        </label>
                                    </div>
                                    {errors.submit &&
                                    <span className="validation-error">{strings.rule_required}</span>
                                    }
                                </FormGroup>
                                <FormGroup className="col-md-4" check>
                                    <div className="form-check-radio">
                                        <label className="form-check-label">
                                            <input className="form-check-input" type="radio"
                                                   id="approve" name="approve" value="approve" onClick={handleButton} onChange={handleButton} checked={approve}
                                                   ref={register({required: (approve)})} />
                                            {strings.approve}
                                            <span className="form-check-sign">
                                                        <span className="check"></span>
                                                    </span>
                                        </label>
                                    </div>
                                    {errors.group &&
                                    <span className="validation-error">{strings.rule_required}</span>
                                    }
                                </FormGroup>
                            </div>
                            }
                            {!editMode &&
                            <Button block className="btn-round ml-auto mr-auto col-md-2 mt-5" color="tuv_red">
                                {strings.save}
                            </Button>
                            }
                            {(statusId !== 3) && editMode &&
                            <div className={"ml-auto mr-auto col-md-6 d-block text-center"}>
                                <Button className={`btn-round   col-md-4 mt-5`} color={labelColor}>
                                    {label}
                                </Button>
                            </div>
                            }
                        </Form>
                    </OverlayLoader>
                    <Modal action={action} message={message} title={strings.submitted_participation_form}/>
                </Col>
            </Row>
        </Container>
)
}
