import React,{useState,useEffect,useContext,useReducer} from "react";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardImg,
    CardText,
    CardTitle,
    Col,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row
} from "reactstrap";
import axios from 'axios';
import strings from "../../app/translations";
import Slider from "react-slick";
import PrevArrow from "../arrows/PrevArrow";
import NextArrow from "../arrows/NextArrow";
// import Cookie from 'universal-cookie';
// import LocalizedStrings from 'react-localization';
// import EntitiesContext from "../../../app/context";
// import CRUDReducer from "../../../app/reducer";



export default function CertificationsList({certificationsList}){
    const certifications = certificationsList;

    useEffect(function(){
        // console.log('In CertificationsList');
        // console.log(certificationsList);
    },[certificationsList]);
    return(
        <Row>
            {certifications.map((certification,key) => {
                // console.log(certification);
                let image = (certification.certification_image)?certification.certification_image.saved_name:"tuv_austria_hellas.jpg";
                let importedImage = null;
                try{
                    importedImage = require(`../../../../storage/app/certification_images/${image}`);
                }catch(err){
                    importedImage = require(`../../../../storage/app/certification_images/tuv_austria_hellas.jpg`)
                }
                return <Col md="4" key={key} style={{padding: "1rem"}}>
                    <Card>
                        <img src={importedImage} alt="..."/>
                        {/*<CardImg top
                                 style={{display: "block",minHeight:"20rem",minWidth:"20rem", background: "url("+ require(`../../assets/img/certification_images/${image}`) +") no-repeat" }}
                                 alt="..."/>*/}
                        <CardBody>
                            <CardTitle><h3>{certification.title}</h3></CardTitle>
                        </CardBody>
                        <Button className="btn-round" href={"/product-page/" + certification.id} color="tuv_red">{strings.see_more.stripAccents()}</Button>
                    </Card>
                </Col>
            })}
        </Row>
    )
}
