import Datetime from "react-datetime";
import Moment from "moment";
import {InputGroup} from "reactstrap";
import React, {useEffect} from "react";
import strings from "../../app/translations";
export default function PickExamDate({register,errors,setSelectedDate,selectedDate,setComponentsHeight,status}){
    // console.log('pickexamdate');
    // console.log(selectedDate);
    // console.log(typeof selectedDate);
    // console.log('endpickexamdate');
    function resetActivityDates(e){

        let d = Moment(e._d).format('YYYY-MM-DD');
        setSelectedDate(d);
        // console.log('new d');
        // console.log(d);
        document.getElementById('selected_interest_date').value = d;
        let options = document.getElementById("examination_certification_id").options;

        let height = (options[options.selectedIndex].getAttribute("name") === "B") ? "39rem" : "35rem";
        // console.log('height');
        // console.log(height);
        setComponentsHeight(height);

    }
    useEffect(function(){
        let options = document.getElementById("examination_certification_id").options;
        if(options.length >0 && options.selectedIndex !== -1) {
            let height = (options[options.selectedIndex].getAttribute("name") === "B") ? "39rem" : "35rem";
            setComponentsHeight(height);
        }
    },[]);
    return(<InputGroup>
        <label className="col-md-12 pl-0" htmlFor="select_interest_date ">{strings.pick_exam_date}</label>
        <Datetime onChange={resetActivityDates}
                  viewMode={"days"} timeFormat={false} dateFormat={'YYYY-MM-DD'}
                  defaultValue={selectedDate}
                  inputProps={{
                      id: "selected_interest_date",
                      name: "select_interest_date",
                      placeholder: strings.selected_date,
                      ref: `${register({required: true})}`,
                      disabled:(status === "published")
                      /*value:{selectedDate}*/
                  }}
        />
        <input type="hidden" id="examination_date" name="examination_date" ref={register({ required: true })} value={selectedDate} />
        {errors.selected_date && <span className="validation-error">{strings.required_validation}</span>}
    </InputGroup>)
}
