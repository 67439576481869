import React, {useState, useEffect, useContext, useReducer} from "react";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardImg,
    CardText,
    CardTitle,
    Container,
    Col,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row
} from "reactstrap";
import axios from 'axios';
import Cookie from 'universal-cookie';
import LocalizedStrings from 'react-localization';
import SimpleSlider from "../../../components/Sliders/FeaturedProductsCarousel";
import EntitiesContext from "../../../app/context";
import strings from "../../../app/translations";
import CRUDReducer from "../../../app/reducer";
import ExamplesNavbar from "../../../components/Navbars/ExamplesNavbar.js";
import Footer from "../../../components/Footers/Footer";
import Orders from "../../../components/Sections/Orders";
import BeatLoader from "react-spinners/BeatLoader";
import OrderDetails from "../../../components/Sections/OrderDetails";
import CheckoutForm from "../../../components/Forms/shop/CheckoutForm";
import Slider from "react-slick";
import PrevArrow from "../../../components/arrows/PrevArrow";
import NextArrow from "../../../components/arrows/NextArrow";
import CertificationsList from "../../../components/Sections/CertificationsList";
import ProductDetail from "../../../components/Sections/ProductDetail";

export default function ProductPageMain({relatedCertifications, language, setLanguage}) {

    const {state, dispatch} = useContext(EntitiesContext);
    const product_details = state.currentItem.data;
    const cookie = new Cookie;
    // console.log('relatedCertifications in');
    // console.log(relatedCertifications);
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: Math.abs(5 - 1),
        prevArrow: <PrevArrow/>,
        nextArrow: <NextArrow/>,
    };

    function sortByKeyDesc(array, key) {
        return array.sort((a, b) => {
            let x = b[key];
            let y = a[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    const [sortedDetails, setSortedDetails] = useState(sortByKeyDesc(product_details.details.filter(detail => detail.lang === language), 'input_name'));
    // console.log('sorted_details');
    // console.log(sortedDetails);
    const url = window.location.pathname;
    const product_id = url.substr(url.lastIndexOf("/"));

    useEffect(function () {
        // console.log('lang_change for product main')
        // console.log(product_details);
        setSortedDetails(sortByKeyDesc(
            product_details.details.filter(detail => detail.lang === language), 'input_name')
        );
    }, [language]);

    /*function testSlides(slides){

        let objects = Object.values(slides.relatedCertifications).map(
            object => {
                console.log(object);
                return object;
            });

            console.log('slides'); console.log(slides);
            console.log('objects'); console.log(objects);
    }*/
    let image = (product_details.certification_image) ? product_details.certification_image.saved_name : "tuv_austria_hellas.jpg";
    let importedImage = null;
    try {
        importedImage = require(`../../../../../storage/app/certification_images/${image}`);
    } catch (err) {
        importedImage = require(`../../../../../storage/app/certification_images/tuv_austria_hellas.jpg`)
    }

    return (
        <>
            <EntitiesContext.Provider value={{state, dispatch}}>
                <ExamplesNavbar setLanguage={language => setLanguage(language)}/>
            </EntitiesContext.Provider>
            <div
                className="page-header"
                style={{
                    backgroundImage: "url(" + require("../../../assets/img/mockups/tuv-austria-hellas.jpg").default + ")"
                }}
            >
                <h1 className="text-center text-white" style={{zIndex: "3"}}>{product_details.title}</h1>
                <div className="filter"/>
                <div className="section text-center align-page-header" style={{backgroundColor: "transparent"}}>

                </div>
            </div>
            <div className="section text-center align-page-header" style={{backgroundColor: "transparent"}}>
                <div className="container-fluid">
                    <div className="custom-column row">
                        <Row style={{display: "block"}} className="custom-column" id="announcements-component">
                            <Col>
                                <h2 className="ml-auto mr-auto">{strings.product_details}</h2>
                                <div className='basic-info' style={{marginTop: "50px"}}>
                                    <Row>
                                        <Col xs={"6"}>
                                            {product_details.certification_image &&
                                            <img style={{width: "40rem"}} src={importedImage} alt="..."/>
                                            }
                                        </Col>
                                        <Col xs={"6"}>
                                            <Row>

                                                <Col xs="4">
                                                    <strong>{strings.specialty}</strong>


                                                </Col>
                                                <Col xs="4" className={'text-justify'}>
                                                    <strong><span
                                                        className='badge badge-info'>{product_details.title}</span></strong>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="4">
                                                    <strong>{strings.schema}</strong>
                                                </Col>
                                                <Col xs="8" className={'text-justify'}>
                                                    <strong><span className='badge badge-info'
                                                                  style={{whiteSpace: "break-spaces"}}>{product_details.category}</span></strong>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Card body className="card-plain" style={{marginTop: "20px"}}>
                                                    <div style={{
                                                        overflowY: "auto",
                                                        overflowX: "hidden",
                                                        maxHeight: "20rem"
                                                    }}>
                                                        <ul>
                                                            <Row>
                                                                <Col lg="2">
                                                                    <strong><u>{strings.duration}</u></strong>
                                                                </Col>
                                                                <Col lg="2">
                                                                    <strong><u>{strings.number_of_activities}</u></strong>
                                                                </Col>
                                                                <Col lg="2">
                                                                    <strong><u>{strings.examination}</u></strong>
                                                                </Col>
                                                                <Col lg="2">
                                                                    <strong><u>{strings.remote}</u></strong>
                                                                </Col>
                                                                <Col lg="2">
                                                                    <strong><u>{strings.price}</u></strong>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="2">
                                                                    {product_details.duration}
                                                                </Col>
                                                                <Col lg="2">
                                                                    {product_details.number_of_activities}
                                                                </Col>
                                                                <Col lg="2">
                                                                    {
                                                                        product_details.type === 'B' ? strings.theoretical_practical :
                                                                            product_details.type === 'T' ? strings.theoretical :
                                                                                strings.practical
                                                                    }
                                                                </Col>
                                                                <Col lg="2">
                                                                    {product_details.is_remote == 0 ? strings.no : strings.yes}
                                                                </Col>
                                                                <Col lg="2">
                                                                    {product_details.price + '\u20AC'}
                                                                </Col>
                                                            </Row>
                                                        </ul>
                                                    </div>
                                                </Card>
                                            </Row>
                                            <Row>{
                                                                    product_details.general_regulation!==0 &&
                                            <Col><a href={`/view/general_regulation/QRc_PCD_001`}>{strings.general_regulation}</a></Col>
                                                                }
                                                {
                                                    product_details.specific_regulation!==0 &&
                                                    <Col><a href={`/view/specific_regulations/${product_details.spec_regulation_code}`}>{strings.specific_regulation}</a></Col>
                                                }
                                            </Row>
                                            <Row>
                                                {sortedDetails &&
                                                < ProductDetail details={sortedDetails}/>
                                                }
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                {/*
                                                <Button className="btn-round" href={"/mock-product-page/" + certification.id} color="tuv_red">*/}
                                <a className={"text-white"} href={`/participationForm-page${product_id}`}>
                                    <Button className="btn-round col-2 ml-auto mr-auto mt-5" color="tuv_red"
                                            style={{marginBottom: "20px"}}>
                                        {strings.apply_now}
                                    </Button>
                                </a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>


            {relatedCertifications !== '' && typeof relatedCertifications !== "undefined" &&
            <div className="text-center align-page-header"
                 style={{backgroundColor: "transparent", paddingBottom: "20px"}}>
                <div className="container-fluid">
                    <div className="custom-column row">
                        <h2 className="ml-auto mr-auto">{strings.related_certifications}</h2>
                        <Row style={{display: "block", paddingBottom: "20px"}} className="custom-column"
                             id="announcements-component">
                            {/*<CertificationsList certificationsList={state.relatedCertifications.objects}/>*/}
                            <Slider {...settings} >


                                {relatedCertifications.map(function (slide, index) {
                                    return (
                                        <div key={index} className="info">
                                            <a href={"/product-page/" + slide.id}>
                                                <div className="description" style={{
                                                    cursor: "pointer", backgroundSize: "cover",
                                                    backgroundImage: "url(" + require(`../../../assets/img/certification_images/tuv_austria_hellas.jpg`) + ")"
                                                }}>
                                                    <h4 className="info-title"
                                                        style={{backgroundColor: "red"}}>{slide.title}</h4>
                                                </div>
                                            </a>
                                        </div>
                                    );
                                })}
                            </Slider>
                        </Row>
                    </div>
                </div>
            </div>}
        </>
    );
}
