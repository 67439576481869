export default function CRUDReducer(state, action) {
    switch (action.type) {
        case "UPDATE_CATEGORIES":
            //console.log('mpikaaa');
            //console.log(action.payload);
            const categories = {...state.categories, objects:action.payload};
            const updateCategories = {...state, categories:categories};
            return {...updateCategories, categories:{objects:action.payload.categories}};
        case "GET_ORDERS":
            const userOrders = {...state.orders, objects:action.payload.orders};
            const userStateWithOrders = {...state, orders:userOrders};
            return {...userStateWithOrders};
        case "GET_RELATED_CERTIFICATIONS":
            // console.log('pppppp');
            // console.log(action.payload);
            const relatedCertifications = {...state.relatedCertifications, objects:action.payload};
            const userStateWithRelated = {...state, relatedCertifications:relatedCertifications};
            return {...userStateWithRelated};
        case "CREATE_ENROLLMENT":
            const newObj = {};
            return {...state.entities.enrollment, newObj};
        case "EDIT_ENROLLMENT":
            return {...state.entities.enrollment, newObj};
        case "SET_ADMIN_USER":
            const adminUser = {...state.user,role:'administrator'};
            return {...state,user:adminUser};
        case "SET_SIMPLE_USER":
            const simpleUser = {...state.user,role:'simple'};
            return {...state,user:simpleUser};
        case "SET_MANAGER_USER":
            const managerUser = {...state.user,role:'manager'};
            return {...state,user:managerUser};
        case "UPDATE_LOGGED_USER":
            console.log('action.payload.user');
            console.log(action.payload.user);
            return {...state, user:action.payload.user};
        case "GET_CERTIFICATIONS":
            const getCertifications = action.payload;
            const certifications = {...state.certifications,objects:getCertifications.objects};
            return {...state.certifications, objects:action.payload.objects};
        case "UPDATE_CERTIFICATIONS":
            const newCertifications = {...state.certifications,objects:action.payload.certifications};
            const updateState = {...state, certifications:newCertifications};
            return {...updateState,categories:{objects:action.payload.categories}};
        case "UPDATE_EXAMINATIONS":
            const newContext = {...state.examinations,objects:action.payload.examinations};
            const updatedExams = {...state, examinations:newContext};
            const examsBeforeClassrooms ={...updatedExams, participations:{objects:action.payload.participations}};
            const examsBeforeSupervisors = {...examsBeforeClassrooms, classrooms:{objects:action.payload.classrooms}};
            return {...examsBeforeSupervisors, supervisors:{objects:action.payload.supervisors}};
        case "UPDATE_ENROLLMENTS":
            const newEnrollments = {...state.enrollments,objects:action.payload.enrollments};
            const stateWithEnrollments = {...state, enrollments:newEnrollments};
            return {...stateWithEnrollments};
        case "UPDATE_PARTICIPATIONS":
            const newParticipations = {...state.participations,objects:action.payload.participations};
            const stateWithParticipations = {...state, participations:newParticipations};
            return {...stateWithParticipations};
        case "UPDATE_ORDERS":
            const newOrders = {...state.orders,objects:action.payload.orders};
            const stateWithOrders = {...state, orders:newOrders};
            return {...stateWithOrders};
        case "UPDATE_COMPANIES":
            const newCompanies = {...state.companies,objects:action.payload.companies};
            const updatedCompanies = {...state, companies:newCompanies};
            return {...updatedCompanies};
        case "UPDATE_CURRENT_ITEM":
            return {...state,currentItem:action.payload.currentItem};
            case "UPDATE_CURRENT_USER":
                console.log(action.payload);
            return {...state,currentUser:action.payload};
        case "UPDATE_ANNOUNCEMENTS":
            return {...state,announcements:action.payload.data};
        case "UPDATE_GRADES":
            const newGrades = {...state.grades,objects:action.payload.grades};
            const stateWithGrades = {...state, grades:newGrades};
            return {...stateWithGrades};
        case "UPDATE_CERTIFICATES":
            const newCertificates = {...state.certificates,objects:action.payload.certificates};
            const stateWithCertificates = {...state, certificates:newCertificates};
            return {...stateWithCertificates};
        case "UPDATE_PAYMENTS":
            const newPayments = {...state.payments,objects:action.payload.payments};
            const stateWithPayments = {...state, payments:newPayments};
            return {...stateWithPayments};
        case "UPDATE_USERS":
            const newUsers = {...state.users,objects:action.payload.users};
            const stateWithUsers = {...state, users:newUsers};
            return {...stateWithUsers};
        case "UPDATE_LANGUAGE":
            const lang=action.payload;
            return {...state,language:lang};
        default:
                return {...state}
    }
}
