import {Card, CardTitle, Col, Row} from "reactstrap";
import React, {useReducer, useContext, useState} from "react";
import axios from 'axios';
import strings from "../../app/translations";

function OrderDetails(props) {


    return (
        <Row style={{display: "block"}} className="custom-column" id="announcements-component">
            <Col>
                <h2 className="ml-auto mr-auto">{strings.order_details}</h2>
                <Card body className="card-plain">
                    <div className='announcements'>
                        <ul>
                            <li className="py-3 border-bottom" style={{minHeight:"4rem"}}>
                                <Row>
                                    <Col lg="4">{strings.certification_title}: <em>{props.details.certification_name}</em></Col>
                                    <Col lg="4">{strings.category}: <em>{props.details.category_name}</em></Col>
                                    <Col lg="4">{strings.examination}: <em>{props.details.examination_name}</em></Col>
                                </Row>
                                <Row style={{marginTop:"15px"}}>
                                    <Col lg="4">{strings.amount}: <em>{props.details.amount  + '\u20AC'}</em></Col>
                                    <Col lg="4">{strings.start_date}: <em>{props.details.start_date}</em></Col>
                                    <Col lg="4">{strings.end_date}: <em>{props.details.end_date}</em></Col>
                                </Row>
                                <Row style={{marginTop:"15px"}}>
                                    <Col lg="4">{strings.participants_no}: <em>{props.details.num_of_participants}</em></Col>
                                    <Col lg="4">{strings.status}: <em>{(props.details.order_status) == 1 ?
                                        <span className='badge badge-success'>Paid</span> :
                                        <span className='badge badge-danger'>Unpaid</span>}</em>
                                    </Col>
                                    <Col lg="4">{strings.duration}: <em>{props.details.duration}</em></Col>
                                </Row>
                            </li>
                        </ul>
                    </div>
                </Card>
            </Col>
        </Row>
    )
}

export default OrderDetails;
