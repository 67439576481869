import React, {useEffect} from "react";
import {Button, FormGroup, Input, Label} from "reactstrap";
import ActivitiesOptions from "./ActivitiesOptions";
import ActivityTag from "./ActivityTag";
import strings from "../../app/translations";
import TooltipWrap from "./TooltipWrap";
import CertificationActivitiesContent from "./CertificationActivitiesContent";


export default function CertificationActivities({lockType,setLockType,currentItem,register,errors,showNumOfActivities, disableActivities}){

    const [numFields,setNumFields] = React.useState(0);
    const [certActivities,setCertActivities] = React.useState([]);
    const [selectStatus,setSelectStatus] = React.useState(false);

    // console.log('certActivities');
    // console.log(certActivities);
    function generateFields(e){
        let certArray = [];
        if((typeof currentItem === "object") && currentItem && currentItem.data) {
            setNumFields(currentItem.data.activities);
            currentItem.data.activities.map(activity => {
                certArray.push({name: activity.name, type: activity.type, system: activity.system,tag:activity.tag})
            });
            // console.log('generate fields: theoretical, practical');
            let typeActivity = null;
            ['theoretical','practical'].map((type,index) =>{
                typeActivity = currentItem.data.activities.filter(activity => {
                    // console.log(activity.type + "," + type);
                    return activity.type === type;
                });

                if(currentItem.data.activities.length < 2 && typeActivity.length === 0){
                    // console.log(typeActivity);
                    certArray.push({name: 'no' + index, type: type, system: "internal",tag:""});
                }
                certArray.sort(function(a, b){
                    if(a.type > b.type) { return -1; }
                    if(a.type < b.type) { return 1; }
                    return 0;
                })

            });
            // console.log('certArray');
            // console.log(certArray);
        }
        else{
            setNumFields(e.target.value);
            for(let i=1; i<=e.target.value; i++){
                certArray.push({name:'no'+i,type:(i<2)?'theoretical':'practical',system:'internal'})
            }
        }
        setCertActivities(certArray);

    }
    function addActivities(e){
        /*e.target.closest("fieldset").setAttribute('disabled','true');*/
        e.target.innerText = selectStatus=== false?"Change":"Add";
        setSelectStatus(!selectStatus);

    }
    function changeActivities(e){
        /*e.target.closest("fieldset").setAttribute('disabled','true');*/
        e.target.innerText = "Add";
        setSelectStatus(false);

    }
    const mapActivities = () =>{
        currentItem.data.activities.map((activity, key) => {
            Object.keys(activity).map(column => {
                    let input_fields = ['name', 'type', 'system'];
                    if (input_fields.indexOf(column) !== -1) {
                        // console.log(`activity_${column}_${key}`);
/*                        document.getElementById(`activity_${column}_${key}`).value = "test";*/
                    }
                }

            )

        });
    }

    useEffect(function(){

            if((typeof currentItem === "object") && currentItem && currentItem.data) {
                // console.log(currentItem.data);
                mapActivities();
                setCertActivities(currentItem.data.activities);
            }

        else{
            setCertActivities([]);
        }

/*        let $target = document.querySelector('#certification_number_of_activities');
        $target.selectedIndex = 0;*/
    },[lockType,currentItem]);

    useEffect(function(){
        if((typeof currentItem === "object") && currentItem && currentItem.data) {
            // console.log(currentItem.data);
            mapActivities();
            // console.log('certActivities in in');
            // console.log(certActivities);
            if(lockType === "B")
                showNumOfActivities(2);
            else
                showNumOfActivities(1);
        }
    },[lockType]);
    useEffect(function(){
        // console.log('test working?');
        certActivities.map((activity,index)=>{
            if((lockType === "P" && activity.type ==='practical')||(lockType === "T" && activity.type ==='theoretical') ||lockType === "B")
            document.getElementById('activity_name_'+index).value = activity.name;
        })
    },[certActivities]);

    return (<>
        <div className="form-row col-md-12">
            <div className="form-group col-md-12">
                <fieldset>
                <label htmlFor="examplePrice">{strings.number_of_activities}</label>
                <div className="form-row col-md-12 mx-n3">
                <div className="col-md-4">
                    <select className="form-control" id="certification_number_of_activities" disabled={disableActivities}
                            ref={register({required: true})}  name="certification_number_of_activities" onChange={generateFields}>
                        <option>{strings.choose}</option>
                        <option key={1} value={1} disabled={lockType === "B"}>1 {lockType === "T"?strings.theoretical:strings.practical}</option>
                        <option key={2} value={2} disabled={lockType !== "B"}>2 {strings.theoretical_practical}</option>
                        {/*<option key={3} value={3}>{"3 Activities"}</option>
                        <option key={4} value={4}>{"4 Activities"}</option>*/}
                    </select>

                </div>
                {/*<input type="text" className="form-control" id="certification_number_of_activities" name="certification_number_of_activities"
                       placeholder="Αριθμος Δραστηριοτήτων"/>*/}
                <div className="col-md-8">
                    {certActivities.length >0 && certActivities
                        .sort((activity => { return (activity.type === 'theoretical') ? -1 : 1 }))
                        .map((activity,key) => {
                            return   <CertificationActivitiesContent key={key} lockType={lockType} setLockType={setLockType} selectStatus={selectStatus} index={key} activity={activity} currentItem={currentItem} register={register} errors={errors} disableActivities={disableActivities}/>
                        })
                    }
                </div>
                </div>
                </fieldset>
                {/*<Button id="addActivities" className="btn-round col-md-12 mt-5" color="dark" onClick={addActivities}>{strings.add}</Button>*/}
            </div>

        </div>
    </>)
}
