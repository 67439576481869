import {Card, CardBody, CardHeader, CardTitle, Collapse, FormGroup, Input, Label} from "reactstrap";
import React, {useEffect} from "react";
import strings from "../../app/translations";

export default function PickExaminers({examiners, selectedExaminers, register, errors}) {

    const [message, setMessage] = React.useState('');

    function addNumbers(e) {
        multipleSelectValues();
        let options = e.target.options;
        let value = [];
        let sum = 0;
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                console.log(options[i].dataset.content);
                value.push({id:parseInt(options[i].id), quantity:options[i].dataset.content, value: options[i].value});
            }
        }
        return value;
    }

    function multipleSelectValues(){
        let examiners = [];
        Array.from(document.getElementById("examinersList").options).map(option =>{
            if(option.selected)
                examiners.push(option.value)
        });
        document.getElementById("examination_examiners").value = JSON.stringify(examiners);
    }

    useEffect(function(){
        selectedExaminers.map(value => {
            let elem = document.getElementById('examinersList');
            selectItemByValue(elem, value);
            //document.getElementById(list.toString()).options[key].selected = true;
        })
        document.getElementById("examination_examiners").value = JSON.stringify(selectedExaminers);
    },[selectedExaminers]);

    function selectItemByValue(elem, value){
        for(var i=0; i < elem.options.length; i++)
        {
            if(elem.options[i].value == value)
                elem.selectedIndex = i;
        }
    }
    return (<Card className="col-md-6 card-plain">
        <CardHeader style={{marginBottom: "1rem"}}>
            <i className="nc-icon nc-single-copy-04"/> {strings.examiners}
        </CardHeader>
        <CardBody>
            <CardTitle>{strings.examiners}</CardTitle>
            <FormGroup>
                <Label for="examinersList">{strings.select_examiners}</Label>
                <Input type="select" name="examinersList"
                       id="examinersList"
                       innerRef={register({ required: true })}
                       multiple onChange={addNumbers}>
                    {examiners.map((examiner, key) => {
                        return <option key={key} id={examiner.id} className={"list-item-style"} value={examiner.id}>
                            {examiner.lastname}, {examiner.firstname} </option>
                    })}

                </Input>
                {message.length > 0 && <span className="validation-error">{message}</span>}
                {errors.examinersList && <span className="validation-error">{strings.required_validation}</span>}
                <input hidden id="examination_examiners" ref={register({required: false})}
                       name="examination_examiners"/>

            </FormGroup>
        </CardBody>
    </Card>)
}
