import {FormGroup, Input, Label} from "reactstrap";
import React,{useEffect} from "react";
import strings from "../../app/translations";

export default function ActivitiesOptions({index,lockType,setLockType,col,register,errors,readOnly}){
    /*useEffect(function(){
        setLockType(lockType);
        console.log('lockType lockType');
        console.log(lockType);
    },[lockType]);*/
    if(lockType!=="B"){
        return (<>
            <FormGroup className={"col-md-"+col} check>
                {lockType === "T" &&
                <div className="form-check-radio">
                    <Label className="form-check-label">
                        <Input type="radio" name={"activity_type_" + index} id="theoretical" value="theoretical"
                               innerRef={register({required: true})}
                               defaultChecked={lockType === "T"} hidden={lockType !== "T"} disabled={lockType !== "T"}
                               readOnly={readOnly}/>
                        {strings.theoretical}
                        <span className="form-check-sign"></span>
                    </Label>
                    {errors.hasOwnProperty(`${"activity_type_" + index} `) &&
                    <span className="validation-error">{strings.required_validation}</span>}
                </div>
                }
                {lockType === "P" &&
                <div className="form-check-radio">
                    <Label className="form-check-label">
                        <Input type="radio" name={"activity_type_" + index} id="practical" value="practical"
                               innerRef={register({required: true})}
                               defaultChecked={lockType === "P"} disabled={lockType !== "P"} readOnly={readOnly}/>
                        {strings.practical}
                        <span className="form-check-sign"></span>
                    </Label>
                    {errors.hasOwnProperty(`${"activity_type_" + index} `) &&
                    <span className="validation-error">{strings.required_validation}</span>}
                </div>
                }
            </FormGroup>
        </>)
    }else{
        return (<>
            <FormGroup className="col-md-4" check>
                {index  % 2 !== 1 &&
                <div className="form-check-radio">
                    <Label className="form-check-label">
                        <Input type="radio" name={"activity_type_" + index} id="theoretical" value="theoretical" innerRef={register({required: true})}
                               defaultChecked={(index  % 2 === 0)}  disabled={index  % 2 === 1} readOnly={readOnly}/>
                        {strings.theoretical}
                        <span className="form-check-sign"></span>
                    </Label>
                    {errors.hasOwnProperty(`${"activity_type_" +  index} `) && <span className="validation-error">{strings.required_validation}</span>}
                </div>
                }
                {index  % 2 !== 0 &&
                <div className="form-check-radio">
                    <Label className="form-check-label">
                        <Input type="radio" name={"activity_type_" + index} id="practical" value="practical" innerRef={register({required: true})}
                               defaultChecked={index  % 2 === 1}  disabled={index  % 2 === 0} readOnly={readOnly}/>
                        {strings.practical}
                        <span className="form-check-sign"></span>
                    </Label>
                    {errors.hasOwnProperty(`${"activity_type_" +  index} `) && <span className="validation-error">{strings.required_validation}</span>}
                </div>
                }
            </FormGroup>
{/*            <FormGroup className="col-md-4" check>
                <Label for={"theoretical_"+index} check>
                    <Input type="checkbox" name={"type_" + index} id={"theoretical_"+index} defaultChecked/>{" "}
                    Theory
                    <span className="form-check-sign">
                                                    <span className="check"></span>
                                                </span>
                </Label>
            </FormGroup>
            <FormGroup className="col-md-4" check>
                <Label for={"practical_"+index} check>
                    <Input type="checkbox" name={"type_" + index} id={"practical_"+index} defaultChecked/>{" "}
                    Practice
                    <span className="form-check-sign">
                                                    <span className="check"></span>
                                                </span>
                </Label>
            </FormGroup>*/}
        </>)
    }

}
