import React from 'react';
import strings from "./translations";

const ParticipationContext = React.createContext({
    documents: [
        {
            id: 1, isOpen: false, isActive: true
        },
    ],
    user_documents: [
        {
            id: 1, isOpen: false, isActive: true
        },
        {
            id: 2, isOpen: false, isActive: true
        },
        {
            id: 3, isOpen: true, isActive: true
        },
        {
            id: 4, isOpen: true, isActive: true
        }],
    selected_users: [
        {
            id: 1, role: 'simple', company_id: 1,
            name: "Papadogiannis Ioannis", birthDate: "10-01-1981",
            files: [
                {
                    id: 1, isOpen: true, isActive: true
                }
            ]
        }
    ],
    users: [
        {
            id: 1, role: 'simple', company_id: 1,
            name: "Papadogiannis Ioannis", birthDate: "10-01-1981",
            files: [
                {
                    id: 1, isOpen: true, isActive: true
                },
                {
                    id: 2, isOpen: true, isActive: true
                }]
        },
        {
            id: 2, name: "Papadopoulos Xaralampos Born:", birthDate: "11-01-1971", role: 'simple', company_id: 2,
            files: [
                {
                    id: 3, isOpen: true, isActive: true
                },
                {
                    id: 4, isOpen: true, isActive: true
                }]
        },
        {
            id: 3, name: "Stylianaki Georgia Born", birthDate: "06-05-1982", role: 'simple', company_id: 2,
            files: [
                {
                    id: 5, isOpen: true, isActive: true
                },
                {
                    id: 6, isOpen: true, isActive: true
                }]
        }
    ],
    user:""
});
export default ParticipationContext;
