/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useReducer, useContext, useState} from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// reactstrap components
import {Card, CardTitle, Col, Container, Row,} from "reactstrap";
// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import ViewVariables from "../../components/Middleware/ViewVariables";
import CheckoutMain from "./main/CheckoutMain";
import EntitiesContext from "../../app/context";
import CRUDReducer from "../../app/reducer";
import Cookie from 'universal-cookie';
import strings from "../../app/translations";
import Modal from "../../components/Modals/Modal";
import UserProfileMain from "./main/UserProfileMain";
import AuthoriseAccess from "../../components/AuthoriseAccess";


function MockCheckoutPage() {
    const cookie = new Cookie;
    const initialState = useContext(EntitiesContext);
    const [state, dispatch] = useReducer(CRUDReducer, initialState);
    const pagePathname = '/enrollment-page';
    const [results, setResults] = useState([]);
    const [currentItem, setCurrentItem] = useState(state.currentItem);
    const [language, setLanguage] = useState(getLanguage());
    const title = strings.checkout;

    const isLogged = window.reactInit.user_id.length>0;
    const isVerified = window.reactInit.verified==='true';

    const middleware = ViewVariables;

    //console.log('the middleware');
    if(!isVerified) {
        console.log('not authorised');
        window.location.href = '/index';
    }

    function getLanguage ()  {
        let lang=cookie.get('language_set')??'en';
        //dispatch({type:"UPDATE_LANGUAGE",payload:lang});
        return lang;
    }

    const getResults = async () => {
        const response = await axios.get("/order/get");
        let temp = [];
        if(typeof response.data.orders === "object")
            response.data.orders = Object.values(response.data.orders);
        //console.log(response.data.orders); return false;
        dispatch({type:"GET_ORDERS", payload:response.data});
        setResults(response.data);
    };


    document.documentElement.classList.remove("nav-open");


    // React.useEffect(() => {
    //     console.log(document.location.pathname);
    //     document.body.classList.add("register-page");
    //     return function cleanup() {
    //         document.body.classList.remove("register-page");
    //     };
    // });


    React.useEffect(() => {
            // let url = window.location.pathname;
            // url = '/enrollment/get/item' + url.substr(url.lastIndexOf("/") );
        if(isVerified) {
            getResults();
        }
            /*if(window.location.pathname!== pagePathname)
                getItem(url);*/
        }
        , []
    );

    return (
        <>
            <EntitiesContext.Provider value={{state, dispatch}}>
                <ExamplesNavbar setLanguage={language=>setLanguage(language)}/>
                <div
                    className="page-header"
                    style={{
                        backgroundImage: "url(" + require("./../../assets/img/mockups/tuv-austria-hellas.jpg") + ")"
                    }}
                ><h1 className="text-center text-white" style={{zIndex: "3"}}>{title}</h1>
                    <div className="filter"/>
                </div>
                <div className="main">
                    <AuthoriseAccess component={<CheckoutMain middleware={middleware}/>}/>
                </div>
            </EntitiesContext.Provider>
        </>
    );
}

export default MockCheckoutPage;
