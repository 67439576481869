import React, {useContext, useEffect} from "react";

import EnrollmentDetails from "./EnrollmentDetails";
import EntitiesContext from "../../../app/context";
import {useForm} from "react-hook-form";
import strings from "../../../app/translations";


function EnrollmentForm(props) {
    const {state/*,dispatch*/} = useContext(EntitiesContext);
    const prepareObjects = state.enrollments.objects.map(object => object.data);
    const currentItem = state.currentItem;
    const pagePathname = '/admin/enrollment-page';
    const editMode = (window.location.pathname !== pagePathname);
    const disabledFields = props.disabledFields;
    const [selected, setSelected] = React.useState('');
    console.log('currentItem');
    console.log(currentItem);
    console.log('prepareObjects');
    console.log(prepareObjects);

    function selectedOption(e) {
        console.log('testChoose');
        console.log(selected);
        var options = e.target.options;
        var value = null;
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                setSelected(options[i].value);
                return value;
            }
        }
        console.log('currentItem');
        console.log(currentItem);
    }

    function showModal(data){
        let msg = [];
        if(data.errors){
            Object.values(data.errors).map(i=>i.map(m=>{return msg.push(m)}));
        }else{

            // console.log('data')
            // console.log(data.records)

            Object.values(data.records).map((record,key) => {
                // console.log('key');
                // console.log(Object.keys(data.records)[key]);
                let type;
                let objKey = Object.keys(data.records)[key];
                type = (key.length>1)?key:'';
                if(objKey === "certification_activities"){
                    Object.values(record).map((activity,k) =>{
                        // console.log(activity);
                        msg.push(`The ${type} ${activity[k].type} with name ${activity[k].name}`);
                    })
                }else if(objKey === "certification_details"){
                    msg.push(`The detail ${record.data.title_gr} with title_gr ${record.data.title_gr}`);
                }else
                    msg.push(`The ${type} Examination with activity ${record.data.start_time}`);
                return msg
            });
            if(editMode)
                msg.push(strings.edited_success);

            else
                msg.push(strings.saved_success);

        }
        // console.log('msg');
        // console.log(msg);


        let act =null;
        if(editMode)
            act = {link:pagePathname,label:"continue"};
        else
            act = (data.errors)?('error'):"continue";

        setAction(act);
        setMessage(msg);
        setModalShow(true);
        $('#appModal').modal({show:true});
        // console.log('show');
        // console.log(msg);
    }

    const [route, setRoute] = React.useState('/api/enrollment/suspend');
    const {register, handleSubmit, watch, errors} = useForm();
    const onSubmit = (data, event) => {
        // console.log('data sent in handlesubmit');
        // console.log(data);
        state.handleSubmit(data, route, showModal, event)
    };

    const isReadOnly = (name) => {
        if (editMode)
            return disabledFields.indexOf(name) !== false
    }

    function loadCurrentItem() {
        if (currentItem.data) {
            console.log(currentItem.data);
        }
    }

    useEffect(function () {
        console.log('selected changed');
        if(selected.length > 0)
            props.getItem( '/enrollment/get/item' + '/' + selected);
    }, [selected]);
    useEffect(function () {

        if (editMode)
            loadCurrentItem();
        console.log('use effect currentItem');
        console.log(state);
        console.log(typeof state.currentItem);
    });
    return (
        <form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
            {
                <div className="form-row col-md-12">
                    <div className="form-group col-md-12">
                        <label htmlFor="examTypeSelect">Select Type</label>
                        <select className="form-control" name="participationForm_name" id="participationForm_name"
                                onChange={selectedOption} ref={register({required: (!isReadOnly("participationForm_name"))})}>
                            <option key="choose">Choose Enrollment ...</option>
                            {prepareObjects.map((enrollment, key) => {
                                let value = (typeof enrollment.participation_form_data !== "undefined") ?
                                    enrollment.id : enrollment.participation_form;
                                return <option key={key} value={value}>{enrollment.participation_form}</option>
                            })}
                        </select>
                    </div>
                </div>
            }


            {currentItem && currentItem.data && currentItem.data.participation_form &&
            <EnrollmentDetails enrollment={currentItem.data} register={register} errors={errors} isReadOnly={isReadOnly}/>
            }


        </form>)
}

export default EnrollmentForm;
