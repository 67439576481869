/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useContext, useReducer} from "react";
import {Link} from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// reactstrap components
import {Collapse, Container, Nav, Navbar, NavbarBrand, NavItem, NavLink} from "reactstrap";
//contextAPI
import EntitiesContext from "../../app/context";
import AdminNavItem from "./AdminNavItem";
import ProfileNavItem from "./ProfileNavItem";
import LanguageNavItem from "./LanguageNavItem";
import strings from "../../app/translations";

function ExamplesNavbar(/*{setUserRole}*/{setLanguage}) {
    const {state, dispatch} = useContext(EntitiesContext);
    const [logo, setLogo] = React.useState("Logo White.svg");
    const [cssFilter, setCssFilter] = React.useState("initial");
    const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
    const [navbarCollapse, setNavbarCollapse] = React.useState(false);
    const [toggleAdmin, setToggleAdmin] = React.useState(true);
    const [toggleManager, setToggleManager] = React.useState(true);
    const [toggleSimpleUser, setToggleSimpleUser] = React.useState(true);
    const [registrationLink, setRegistrationLink] = React.useState("/register-page");
    const toggleNavbarCollapse = () => {
        setNavbarCollapse(!navbarCollapse);
        document.documentElement.classList.toggle("nav-open");
    };

    //strings.setLanguage(language);

    function handleAdminNavItem() {
        if ((state.user && ['administrator','sys_admin'].includes(state.user.role)) || (state.state && ['administrator','sys_admin'].includes(state.state.user.role))) {
            // console.log("case1");
            setToggleAdmin(true)
            /* setUserRole('admin');*/
        } else {
            // console.log("case2");
            // console.log(state.user);
            // console.log(state.state.user);
            setToggleAdmin(false)
        }
    }

    function handleActiveNavItem() {

    }

    const groupRegister = () => {
        if (state.user && ['manager'].indexOf(window.reactInit.user_role)) {
            setToggleManager(true)
            setRegistrationLink("/register-page")
        } else {
            setToggleManager(false)
        }
    }
    const singleRegister = () => {
        if (state.user && (['administrator', 'moderator'].indexOf(window.reactInit.user_role) !== -1 || window.reactInit.user_role === "")) {
            setToggleSimpleUser(true);
            setToggleManager(false);
            setRegistrationLink("/register-page#individual")
        } else if (state.user && (['manager'].indexOf(window.reactInit.user_role) !== -1)) {
            setToggleSimpleUser(true);
            setToggleManager(true)
        } else {
            setToggleSimpleUser(false);
            setToggleManager(false);
        }
    };
    React.useEffect(function () {
        if (state.user && state.user.role !== "undefined") {
            //console.log(state.user);
            //console.log(window.reactInit.user_role);
            handleAdminNavItem();
        }
        groupRegister();
        // console.log('toggleManager');
        // console.log(toggleManager);
        singleRegister();
    }, []);

    React.useEffect(() => {


        const updateNavbarColor = () => {
            if (
                document.documentElement.scrollTop > 299 ||
                document.body.scrollTop > 299
            ) {
                setLogo("tuv-austria-logo.jpg");
                setNavbarColor("");
                setCssFilter('invert(1)');
            } else if (
                document.documentElement.scrollTop < 300 ||
                document.body.scrollTop < 300
            ) {
                setLogo("Logo White.svg");
                setNavbarColor("navbar-transparent");
                setCssFilter('initial');
            }

        };

        window.addEventListener("scroll", updateNavbarColor);

        return function cleanup() {
            window.removeEventListener("scroll", updateNavbarColor);
        };
    });
    // console.log('state.user:');
    // console.log(state.user);
    //
    // console.log('toggleSimpleUser:');
    // console.log(toggleSimpleUser);
    //
    // console.log('toggleManager:');
    // console.log(toggleManager);
    return (
        <Navbar
            className={classnames("fixed-top", navbarColor)}
            color-on-scroll="300"
            expand="lg"
        >
            <Container>
                <div className="navbar-translate">
                    <NavbarBrand
                        data-placement="bottom"
                        to="/index"
                        title="TÜV Austria Hellas"
                        tag={Link}
                    >
                        <img alt="logo" width="100px" style={{marginRight: "2rem"}}
                             src={require(`../../assets/img/mockups/${logo}`)}/>TÜV Austria-Hellas
                    </NavbarBrand>
                    <button
                        aria-expanded={navbarCollapse}
                        className={classnames("navbar-toggler navbar-toggler", {
                            toggled: navbarCollapse
                        })}
                        onClick={toggleNavbarCollapse}
                    >
                        <span className="navbar-toggler-bar bar1"/>
                        <span className="navbar-toggler-bar bar2"/>
                        <span className="navbar-toggler-bar bar3"/>
                    </button>
                </div>
                <Collapse
                    className="justify-content-end"
                    navbar
                    isOpen={navbarCollapse}
                >
                    <Nav navbar>
                        <AdminNavItem adminUser={toggleAdmin} onLoad={handleAdminNavItem}/>
                        <NavItem>
                            <NavLink href="/landing-page#certifications">
                                <img alt="certifications" width="15rem"
                                     style={{marginRight: "0.5rem", filter: cssFilter}}
                                     src={require(`../../assets/img/mockups/icon-certifications.svg`)}/>
                                {/*<i className="nc-icon nc-layout-11"/>*/} {strings.certifications.stripAccents()}
                            </NavLink>
                        </NavItem>
                        {toggleSimpleUser &&
                        <NavItem style={{position: "relative"}}>
                            <NavLink
                                href={registrationLink}
                                target={(toggleManager || toggleAdmin) ? "_blank" : "_self"}
                                id="navbarDropdownMenuLink"
                                data-toggle={(toggleManager || toggleAdmin) ? "dropdown" : "none"}
                                aria-haspopup="true" aria-expanded="false"
                            >
                                <img alt="certifications" width="15rem"
                                     style={{marginRight: "0.5rem", filter: cssFilter}}
                                     src={require(`../../assets/img/mockups/icon-document.svg`)}/>
                                {/*<i className="nc-icon nc-book-bookmark"/>*/}
                                {strings.registration.stripAccents()}
                            </NavLink>
                            {(toggleManager || toggleAdmin) &&
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <a className="dropdown-item"
                                   href="/register-page#individual">{strings.registration_individual}</a>
                                <a className="dropdown-item"
                                   href="/register-page#group">{strings.registration_group}</a>
                            </div>}
                        </NavItem>
                        }
                        {/*<NavItem style={{position: "relative"}}>
                            <NavLink
                                href="/mock-register-page"
                                target="_blank"
                                id="navbarDropdownMenuLink"
                                data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false"
                            ><img alt="role_change" width="15rem" style={{marginRight: "0.5rem",filter:cssFilter}}
                                  src={require(`../../assets/img/mockups/icon-awesome-user.svg`)}/>
                                <i className="nc-icon nc-book-bookmark"/> Role Change
                            </NavLink>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <a className="dropdown-item" onClick={()=>{dispatch({type:"SET_SIMPLE_USER",payload:state.user})}}>Simple User</a>
                                <a className="dropdown-item" onClick={()=>{dispatch({type:"SET_ADMIN_USER",payload:state.user})}}>Admin</a>
                                <a className="dropdown-item" onClick={()=>{dispatch({type:"SET_MANAGER_USER",payload:state.user})}}>Manager</a>
                            </div>
                        </NavItem>*/}
                        {window.reactInit.user_role && window.reactInit.user_role !== 'admin' &&
                        <NavItem>
                            <NavLink href="/enrollment-page">
                                <img alt="certifications" width="15rem"
                                     style={{marginRight: "0.5rem", filter: cssFilter}}
                                     src={require(`../../assets/img/mockups/icon-certifications.svg`)}/>
                                {strings.enrollments}
                            </NavLink>

                        </NavItem>

                        }
                        {window.reactInit.user_role && window.reactInit.user_role !== 'admin' &&
                        <NavItem>
                            <NavLink href="/order-page">
                                <img alt="certifications" width="15rem"
                                     style={{marginRight: "0.5rem", filter: cssFilter}}
                                     src={require(`../../assets/img/mockups/credit-card.svg`)}/>
                                {strings.orders}
                            </NavLink>

                        </NavItem>

                        }
                        {window.reactInit.username &&
                        <NavItem style={{position: "relative"}}>
                            <NavLink
                                href={registrationLink}
                                target="_blank"
                                id="navbarReportDropdownMenuLink"
                                data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false"
                            >
                                <img alt="certifications" width="15rem"
                                     style={{marginRight: "0.5rem", filter: cssFilter}}
                                     src={require(`../../assets/img/mockups/icon-document.svg`)}/>
                                {/*<i className="nc-icon nc-book-bookmark"/>*/}
                                {strings.reports}
                            </NavLink>
                            <div className="dropdown-menu" aria-labelledby="navbarReportDropdownMenuLink">
                                { window.reactInit.user_role && ['administrator', 'manager', 'sys_admin'].includes(window.reactInit.user_role) &&
                                <a className="dropdown-item" href="/reports-page/user">{strings.users}</a>
                                }
                                <a className="dropdown-item"
                                   href="/reports-page/participation">{strings.participation_forms}</a>
                                { window.reactInit.user_role && ['administrator', 'moderator', 'sys_admin'].includes(window.reactInit.user_role) &&
                                <a className="dropdown-item" href="/reports-page/examination">{strings.examinations}</a>
                                }
                                <a className="dropdown-item" href="/reports-page/grade">{strings.grades}</a>
                                <a className="dropdown-item" href="/reports-page/certificate">{strings.certificates}</a>
                                <a className="dropdown-item" href="/reports-page/payment">{strings.payments}</a>
                            </div>
                        </NavItem>
                        }
                        <ProfileNavItem/>

                        <LanguageNavItem cssFilter={cssFilter} setLanguage={language => setLanguage(language)}/>

                        {/*<NavItem>
              <NavLink
                data-placement="bottom"
                href="https://twitter.com/CreativeTim?ref=creativetim"
                target="_blank"
                title="Follow us on Twitter"
              >
                <i className="fa fa-twitter" />
                <p className="d-lg-none">Twitter</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.facebook.com/CreativeTim?ref=creativetim"
                target="_blank"
                title="Like us on Facebook"
              >
                <i className="fa fa-facebook-square" />
                <p className="d-lg-none">Facebook</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.instagram.com/CreativeTimOfficial?ref=creativetim"
                target="_blank"
                title="Follow us on Instagram"
              >
                <i className="fa fa-instagram" />
                <p className="d-lg-none">Instagram</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.github.com/CreativeTimOfficial?ref=creativetim"
                target="_blank"
                title="Star on GitHub"
              >
                <i className="fa fa-github" />
                <p className="d-lg-none">GitHub</p>
              </NavLink>
            </NavItem>*/}
                    </Nav>
                </Collapse>
            </Container>
        </Navbar>
    );
}

export default ExamplesNavbar;
