import React, {Component} from 'react';
import Table from './Table';
import propTypes from 'prop-types'

class TableApp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            names: props.names
        };
    }


    onAddClick(name, category, type) {
        let updated = false;
        const result = this.state.names.map((nameData) => {
            if (nameData.name === name) {
                updated = true;
                return {name, category, type}
            }
            return nameData;
        });
        if (!updated) {
            result.push({name, category, type});
        }

        this.setState({
            names: result
        })
    }

    /*onEdit(){
        console.log('start function');
        const addActions = this.state.names.map((nameData)=>{
            nameData.action.replace("btn-info'","btn-info' onclick='{"+ props.onEdit +"}'");
            console.log(nameData.action);
            return nameData;
        });
        this.setState({
            names: addActions
        })
    }*/

    render() {
        return (
            <div className="App table-responsive-xl mt-5">
                {/* {this.onEdit()}*/}
                {/*<Input onAddClick={(name, category,type) => {
                    this.onAddClick(name, category,type);
                }} />*/}
                <Table names={this.props.names} columns={this.props.columns} tableWidth={this.props.tableWidth}/>
            </div>
        );
    }
}

TableApp.propTypes = {
    names: propTypes.array,
    columns: propTypes.array
}
export default TableApp;
