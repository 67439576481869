import {FormGroup, FormText, Input, Label} from "reactstrap";
import React from "react";
import strings from "../../app/translations";

function File({name,selectStatus,register}) {
    const [filepath,setFilepath] = React.useState('');
    function handleInput(e){
        if(!selectStatus){
            return;
        }
        let inputSibling = e.target.nextElementSibling;
        console.log(inputSibling);
        inputSibling.click();
        /*inputSibling.nextElementSibling.textContent = inputSibling.value;*/
    }
    const newFilepath = (e) => {
        let parts = e.target.value.split('\\');
        if(parts.length <2){
            parts = e.target.value.split('/');
        }
        console.log('parts');
        console.log(parts);
        setFilepath(parts[parts.length -1])
    }
    React.useEffect(function(){

    },[filepath]);
    return (
        <FormGroup className={"col-4"} >
            <Label for="exampleFile" className="col-12 ml-auto mr-auto">File</Label>
            <button type="button" disabled={!selectStatus} className={"btn  btn-round btn-secondary ml-auto mr-auto"} onClick={handleInput}>{strings.choose_file}</button>
            <Input hidden type="file" name={name} id={name} onChange={newFilepath} innerRef={register({required: false})}/>
            <FormText color="muted">
                {filepath}
            </FormText>
        </FormGroup>
    )
}

export default File;
