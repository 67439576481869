import React, {useContext, useReducer, useState} from "react";
// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";

import AdminExaminationMain from "./main/AdminExaminationMain";
import Footer from "../../components/Footers/Footer";
import EntitiesContext from "../../app/context";
import CRUDReducer from "../../app/reducer";


import axios from "axios";
import Cookie from 'universal-cookie';
import strings from "../../app/translations";
import EnrollmentMain from "./main/EnrollmentMain";
import AuthorizeAccess from "../../components/AuthoriseAccess";

function MockAdminExaminationPage() {
    const cookie = new Cookie;
    const initialState = useContext(EntitiesContext);
    const [state, dispatch] = useReducer(CRUDReducer, initialState);

    const pagePathname = '/admin/examination-page';
    const [results,setResults] = useState([]);
    const [currentItem,setCurrentItem] = React.useState(state.currentItem);
    const [language, setLanguage] = useState(getLanguage());

    const isLogged = window.reactInit.user_id.length>0;
    const isVerified = window.reactInit.verified==='true';

    document.documentElement.classList.remove("nav-open");
    const getItem = async () => {
        let url = window.location.pathname;
        url = '/examination/get/item' + url.substr(url.lastIndexOf("/") );
        const response = await axios.get(url);
        dispatch({type:"UPDATE_CURRENT_ITEM",payload:response.data});
        setCurrentItem(response.data);
        // console.log(currentItem);
    }
    const getResults = async () => {
        const response = await axios.get("/examination/get");
        // console.log("data");
        // console.log(response.data);
        dispatch({type:"UPDATE_EXAMINATIONS",payload:response.data});
        setResults(response.data);
    };

    const getCertifications = async () => {
        const response = await axios.get("/certification/get");
        // console.log("data");
        // console.log(response.data);
        dispatch({type:"UPDATE_CERTIFICATIONS",payload:response.data});
        setResults(response.data);
    };

    React.useEffect(() => {
            if (isVerified) {
                getCertifications().then(function () {
                    getResults().then(function () {
                        if (window.location.pathname !== pagePathname)
                            getItem();
                    });
                });
            }
        }
        , []
    );
    function getLanguage ()  {
        let lang=cookie.get('language_set')??'en';
        //dispatch({type:"UPDATE_LANGUAGE",payload:lang});
        return lang;
    }
    const title = strings.examinations;

    return (
        <>
            <EntitiesContext.Provider value={{state, dispatch}}>
                <ExamplesNavbar setLanguage={language=>setLanguage(language)}/>
                <div
                    className="page-header"
                    style={{
                        backgroundImage: "url(" + require("./../../assets/img/mockups/payroll_outsourcing_banner.jpg") + ")"
                    }}
                >
                    <h1 className="text-center text-white" style={{zIndex: "3"}}>{title}</h1>
                    <div className="filter"/>
                </div>
                <div className="main">
{/*                    <div className="text-white"><ul>{
                        results.map(
                            result =>
                        <li key={result.data.id}>{result.data.email}</li>
                    )
                    }</ul>
                    </div>*/}
                    <AuthorizeAccess component={<AdminExaminationMain/>}/>
                    <Footer/>
                </div>
            </EntitiesContext.Provider>
        </>
    );
}

export default MockAdminExaminationPage;
