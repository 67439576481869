import {Button} from "reactstrap";
import React from "react";
import strings from "../app/translations";

export default function SelectOkCancel({setSelectStatus,selectStatus}) {
    function disableChange() {
        setSelectStatus(false);
    }

    function enableChange() {
        setSelectStatus(true);
    }
    function handleChange(){
        if(selectStatus){
            disableChange();
        }else
            enableChange();
        setSelectStatus(!selectStatus);
    }

    return (
        <>
            <div className="form-row col-12">
                <Button id="lockChoice" block className="btn-round col-4 ml-auto mr-auto " color={(selectStatus)?"tuv_red":"secondary"}
                        onClick={handleChange}>
                    {(selectStatus)?"Ok":strings.cancel}
                </Button>
            </div>
        </>
    )
}
