import React,{useEffect} from "react";
import ExamActivityDate from "./ExamActivityDate";
export default function ExamActivities({activities,activitiesByType,currentItem,selectedDate,status,register,errors}){
    // console.log('activities');
    // console.log(activities);
    useEffect(function(){
        // console.log('activities within ExamActivities');
        // console.log(activities)
    },[currentItem,activities]);
    return (<>
    {activities && activities.length > 0 && activities.map((activity, key) =>
        <div className="form-row" key={key} >
            <ExamActivityDate key={key} index={key} label={activity}
                               selectedDate={selectedDate} status={status}
                              currentItem={currentItem} register={register} errors={errors}/>
            {errors.hasOwnProperty(`${"exam_activity_duration_" + key}`) &&
            <span className="validation-error">This field is required</span>}
            {errors.hasOwnProperty(`${"exam_activity_start_date_" + key}`) &&
            <span className="validation-error">This field is required</span>}
            {errors.hasOwnProperty(`${"exam_activity_end_date_" + key}`) &&
            <span className="validation-error">This field is required</span>}
        </div>
    )}
</>)
}
