import React from "react";
import TooltipWrap from "./TooltipWrap";
import strings from "../../app/translations";

export default function ActivityTag({defChecked, type, index, register, tag}) {
    // console.log("activity_tag_" + index);
    return (<div className="form-group col-6" hidden={!defChecked}>
        <input type="text" className="form-control" id={"tag_" + index}
               name={"activity_tag_" + index} defaultValue={tag}
               placeholder={strings.short_description}/>
    </div>)
}
