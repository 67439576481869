import React, {useContext} from "react";
import EntitiesContext from "../../../app/context";
// reactstrap components
import {Card, CardTitle, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
//admin components
import AdminNavbar from "../../../components/Navbars/AdminNavbar";
import Announcements from "../../../components/Sections/Announcements";
//tab components
import TableApp from "../../../components/Datatable/TableApp";
import EnrollmentForm from "../../../components/Forms/admin/EnrollmentForm";
import ManageEnrollments from "../../../components/Sections/ManageEnrollments";


export default function AdminEnrollmentMain({getItem}) {
    const {state} = useContext(EntitiesContext);
    const adminPage = state.enrollments.cardTitle;

    /*const toggleIt = () => setIsOpen(!isOpen);*/


    console.log('the state');
    console.log(state.enrollments);


    return (<div className="section text-center align-page-header" style={{backgroundColor: "transparent"}}>
        <div className="container-fluid">
            <Row>
            <Col lg="4"><AdminNavbar adminPage={adminPage}/></Col>
            <Col id="admin" lg="8"  className="admin pl-0">
                {/*<Announcements/>*/}
                <ManageEnrollments getItem={getItem}/>
            </Col>
        </Row>
        </div>
    </div>)
}
