/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useContext, useReducer, useState} from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// reactstrap components
import {
    Button,
    Card,
    CardText,
    CardTitle,
    Col,
    Container,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import classnames from "classnames";
// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import SimpleSlider from "../../components/Sliders/FeaturedProductsCarousel";
import ParticipationForm from "../../components/Forms/shop/ParticipationForm";

//CONTEXTAPI
import EntitiesContext from "../../app/context";
import CRUDReducer from "../../app/reducer";
import ViewVariables from "../../components/Middleware/ViewVariables";
import Cookie from 'universal-cookie';
import Footer from "../../components/Footers/Footer";
import axios from "axios";
import ProductPageMain from "./main/ProductPageMain";
import CertificationsList from "../../components/Sections/CertificationsList";
import strings from "../../app/translations";

function MockProductPage() {
    const cookie = new Cookie;
    const headerImage = ViewVariables.middleware.headerImage;
    const initialState = useContext(EntitiesContext);
    const [state, dispatch] = useReducer(CRUDReducer, initialState);
    const [currentItem,setCurrentItem] = React.useState(state.currentItem);
    const [relatedCertifications,setRelatedCertifications] = React.useState([]);

    const transformObject = (objectOfObjects) => {
        let result = Object.values(objectOfObjects).map(
            object => {
                console.log(object);
                return object.data;
            });
        console.log('result');
        console.log(result);
        return result;
    }
    const [language, setLanguage] = useState(getLanguage());

    function getLanguage ()  {
        let lang=cookie.get('language_set')??'en';
        return lang;
    }

    const getItem = async () => {
        let url = window.location.pathname;
        url = '/certification/get/item' + url.substr(url.lastIndexOf("/") );
        const response = await axios.get(url);
        dispatch({type:"UPDATE_CURRENT_ITEM",payload:response.data});
        dispatch({type:"GET_RELATED_CERTIFICATIONS",payload:response.data});
        setCurrentItem(response.data.currentItem);
        let $related = transformObject(response.data.relatedCertifications);
        /*console.log(typeof $related);
        console.log(Array.from($related));*/
        setRelatedCertifications($related);
        console.log(currentItem);
    };

    /*const getRelatedCertifications = async () => {
        let url = window.location.pathname;
        url = '/related_certifications/get/item' + url.substr(url.lastIndexOf("/") );
        const response = await axios.get(url);
        dispatch({type:"GET_RELATED_CERTIFICATIONS",payload:response.data});
        setRelatedCertifications(response.data);
    };*/

    React.useEffect(() => {
        getItem();
    }, []);


    // console.log('666666666666666666666666');
    // console.log(relatedCertifications);



    return (
        <>
            <EntitiesContext.Provider value={{state, dispatch}}>
                <ExamplesNavbar setLanguage={language=>setLanguage(language)}/>
                    {currentItem !== '' && typeof currentItem !== "undefined" &&
                        <ProductPageMain relatedCertifications={relatedCertifications} language={language} setLanguage={language=>setLanguage(language)}/>
                    }
                <Footer/>
            </EntitiesContext.Provider>
        </>
    );
}

export default MockProductPage;
