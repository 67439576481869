import React, {useContext} from "react";
import {Button, Form, FormGroup, Input, Label} from "reactstrap";
import {useForm} from "react-hook-form";
import EntitiesContext from "../../../app/context";
import strings from "../../../app/translations";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import {getDataSet, reduce, getRegionsFor} from "iso3166-2-db";
import Modal from "../../Modals/Modal";
import Cookie from 'universal-cookie';


const listOfCountries = reduce(getDataSet(), "en");


export default function CheckoutForm(props) {

    // functionality
    const {state, dispatch} = useContext(EntitiesContext);
    const [route, setRoute] = React.useState('/api/checkout/order');
    const {register, handleSubmit, watch, errors} = useForm();
    const [countryStates, setCountryStates] = React.useState('');
    const [countryName, setCountryName] = React.useState('');
    // errors modal
    const [modalShow, setModalShow] = React.useState(false);
    const [message, setMessage] = React.useState([]);
    const [action, setAction] = React.useState('');
    //
    const cookie = new Cookie;


    // Pireaus Redirection Credentials
    const pireaus_credentials = {
        'AcquirerId': props.details.pireaus_credentials.acquirer_id,
        'MerchantId': props.details.pireaus_credentials.merchant_id,
        'PosId': props.details.pireaus_credentials.pos_id,
        'User': props.details.pireaus_credentials.user,
        'ParamBackLink': ''
    };

    // Selected Language
    let lang = cookie.get('language_set') ?? 'en';


    const onSubmit = (data, event) => {
        state.handleSubmit(data, route, showModal, event)
    };

    const onCountryChange = (e) => {
        let t = Object.entries(listOfCountries);
        for (let i = 0; i < t.length; i++) {
            if (t[i][1]['numeric'] == e.target.value) {
                var iso_code = t[i][0];
                console.log('country');
                setCountryName(t[i][1]['name']);
            }
        }
        const regions = listOfCountries[String(iso_code)].regions;
        setCountryStates(regions);
    };

    function showModal(data) {

        let msg = [];
        if (data.errors) {               // if VALIDATION ERRORS
            Object.values(data.errors).map(i => i.map(m => {
                return msg.push(m)
            }));

            // OPENS THE MODAL
            // let act = (data.errors)?('error'):{link:"/mock-checkout-page",label:"continue"};
            // setAction(act);
            // setMessage(msg);
            // setModalShow(true);
            // console.log('show');
            // $('#appModal').modal({show:true});
            // console.log(msg);

            console.log('VALIDATION ERRORS');
            console.log(msg);
            alert(msg);
        } else if (data.ticketing === 'failed') {                      // if failed TICKETING
            console.log('TICKETING FAILED');
            alert('Something went wrong! Please try again later!!!');
        } else if (data.ticketing === 'succeed') {                   // if succeed TICKETING
            //alert('Succeed Ticketing');

            let getUrl = window.location;
            let ticketingUrl = getUrl.protocol + "//" + getUrl.host + "/foo";


            document.getElementById('hiddenCheckoutForm').submit();
        } // else {
        //     msg=['Success checkout'];
        // }
    }


    return (
        <>
            <h2 className="ml-auto mr-auto">{strings.proceed_to_checkout}</h2>
            <br/>
            <div className={"container"}>
                <form className="announcements" onSubmit={handleSubmit(onSubmit)} method="POST">
                    <div className="form-row">

                        <FormGroup tag="fieldset" className="col-md-8">
                            <label style={{display: "inline-block"}} className="mr-4">{strings.receipt_type}</label>
                                <Label check className="m-3">
                                    <Input type="radio" name="checkout_has_invoice" innerRef={register({required: true})}
                                    value="0" defaultChecked />{strings.simple_receipt}
                                </Label>
                                <Label check className="m-3">
                                    <Input type="radio" name="checkout_has_invoice" innerRef={register({required: true})}
                                    value="1" />{strings.invoice}
                                </Label>
                            {errors.checkout_has_invoice && <span className="validation-error">{strings.required_validation}</span>}
                        </FormGroup>


                    </div>
                    <div className="form-row">
                        <FormGroup className="col-md-8">
                            <Label for="checkout_full_name">{strings.checkout_full_name}</Label>
                            <Input id="checkout_full_name" placeholder={strings.checkout_full_name} type="text" name="checkout_full_name"
                                   innerRef={register({required: true})}/>
                            {errors.checkout_full_name &&
                            <span className="validation-error">{strings.required_validation}</span>}
                        </FormGroup>
                        <FormGroup className="col-md-4">
                            <Label for="checkout_email">{strings.checkout_email}</Label>
                            <Input id="checkout_email" placeholder={strings.checkout_email} type="email" name="checkout_email"
                                   innerRef={register({required: true})}/>
                            {errors.checkout_email &&
                            <span className="validation-error">{strings.required_validation}</span>}
                        </FormGroup>
                    </div>
                    <div className="form-row">
                        <FormGroup className="col-md-6">
                            <Label for="checkout_country">{strings.checkout_country}</Label>
                            <Input id="checkout_country" placeholder="" type="select" name="checkout_country"
                                   innerRef={register({required: true})} onChange={onCountryChange}>
                                <option></option>
                                {Object.keys(listOfCountries)
                                    .sort(
                                        (a, b) =>
                                            listOfCountries[a].name > listOfCountries[b].name ? 1 : -1
                                    )
                                    .map(isoCode =>
                                        <option key={isoCode} value={listOfCountries[isoCode].numeric}>
                                            {listOfCountries[isoCode].name}
                                        </option>
                                    )}
                            </Input>
                            {errors.checkout_country &&
                            <span className="validation-error">{strings.required_validation}</span>}
                            <Input type={'hidden'} id="checkout_country_name" name="checkout_country_name"
                                   innerRef={register({required: true})} value={countryName}/>
                        </FormGroup>
                        <FormGroup className="col-md-6">
                            <Label for="checkout_region">{strings.checkout_region}</Label>
                            <Input id="checkout_region" placeholder="" type="select" name="checkout_region"
                                   innerRef={register({required: true})}>
                                {Object.keys(countryStates)
                                    .sort(
                                        (a, b) =>
                                            countryStates[a].name > countryStates[b].name ? 1 : -1
                                    )
                                    .map(iso =>
                                        <option key={iso} value={countryStates[iso].iso}>
                                            {countryStates[iso].name}
                                        </option>
                                    )}
                            </Input>
                            {errors.checkout_region &&
                            <span className="validation-error">{strings.required_validation}</span>}
                        </FormGroup>
                    </div>
                    <div className="form-row">
                        <FormGroup className="col-md-4">
                            <Label for="checkout_city">{strings.checkout_city}</Label>
                            <Input id="checkout_city" placeholder={strings.checkout_city} type="text" name="checkout_city"
                                   innerRef={register({required: true})}/>
                            {errors.checkout_city &&
                            <span className="validation-error">{strings.required_validation}</span>}
                        </FormGroup>
                        <FormGroup className="col-md-4">
                            <Label for="checkout_addr">{strings.checkout_addr}</Label>
                            <Input id="checkout_addr" placeholder={strings.checkout_addr} type="text" name="checkout_addr"
                                   innerRef={register({required: true})}/>
                            {errors.checkout_addr &&
                            <span className="validation-error">{strings.required_validation}</span>}
                        </FormGroup>
                        <FormGroup className="col-md-4">
                            <Label for="checkout_post_code">{strings.checkout_post_code}</Label>
                            <Input id="checkout_post_code" placeholder={strings.checkout_post_code} type="text" name="checkout_post_code"
                                   innerRef={register({required: true})}/>
                            {errors.checkout_post_code &&
                            <span className="validation-error">{strings.required_validation}</span>}
                        </FormGroup>
                    </div>
                    <div className="form-row">
                        <FormGroup className="col-md-4">
                            <Label for="checkout_home_phone">{strings.checkout_home_phone}</Label>
                            <Input id="checkout_home_phone" placeholder={strings.checkout_home_phone} type="text" name="checkout_home_phone"
                                   innerRef={register({required: true})}/>
                            {errors.checkout_home_phone &&
                            <span className="validation-error">{strings.required_validation}</span>}
                        </FormGroup>
                        <FormGroup className="form-group col-md-4">
                            <label>{strings.checkout_mobile_phone}</label>
                            <Input id="checkout_mobile_phone" placeholder={strings.checkout_mobile_phone} type="text" name="checkout_mobile_phone"
                                   innerRef={register({required: true})}/>
                            {errors.checkout_mobile_phone &&
                            <span className="validation-error">{strings.required_validation}</span>}
                        </FormGroup>
                        <FormGroup className="col-md-4">
                            <Label for="checkout_home_phone">{strings.checkout_work_phone}</Label>
                            <Input id="checkout_work_phone" placeholder={strings.checkout_work_phone} type="text" name="checkout_work_phone"
                                   innerRef={register({required: true})}/>
                            {errors.checkout_work_phone &&
                            <span className="validation-error">{strings.required_validation}</span>}
                        </FormGroup>
                    </div>

                    <Input type="hidden" name="checkout_merchant_reference"
                           defaultValue={props.details.merchant_reference}/>
                    <Input type="hidden" name="checkout_amount" defaultValue={props.details.amount}/>
                    <Input type="hidden" name="order_id" defaultValue={props.details.order_id}/>

                    <Button block className="btn-round col-4 ml-auto mr-auto" color="tuv_red">
                        {strings.checkout}
                    </Button>
                    <br/>
                </form>
            </div>
            <div><Modal action={action} message={message} title={"Registration Status"}/></div>

            <form id='hiddenCheckoutForm' action="https://paycenter.piraeusbank.gr/redirection/pay.aspx"
                  method="POST">
                <input name="AcquirerId" type="hidden" value={pireaus_credentials.AcquirerId}/>
                <input name="MerchantId" type="hidden" value={pireaus_credentials.MerchantId}/>
                <input name="PosId" type="hidden" value={pireaus_credentials.PosId}/>
                <input name="User" type="hidden" value={pireaus_credentials.User}/>
                <input name="LanguageCode" type="hidden" value={(lang == 'en') ? 'en-US' : 'el-GR'}/>
                <input name="MerchantReference" type="hidden" value={props.details.merchant_reference}/>
                <input name="ParamBackLink" type="hidden" value=""/>
            </form>
        </>
    )
}
