import {Button, Card, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import UserDetails from "../UserDetails";
import ParticipationFormDetails from "./ParticipationFormDetails";
import React, {useContext} from "react";
import Container from "reactstrap/es/Container";
import EntitiesContext from "../../../app/context";
import Document from "../Document";
import File from "../File";
import axios from "axios";
import {useForm} from "react-hook-form";
import SingleRegistration from "./SingleRegistration";
import strings from "../../../app/translations";

export default function BulkRegistrationForm({route, showModal, buttonEnabled, setButtonEnabled, setLoading}) {

    /*function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);
        console.log('data in handleSubmit')
        console.log(data);
        console.log(route);
        axios({
            method: 'post',
            url: route,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': window.reactInit.csrf
            },
            data: data,
        }).then(function (response) {
            //handle success
            console.log(response);
            if (typeof response.data.errors !== 'undefined') {
                showModal(response.data);
            } else {
                console.log(response.data)
                showModal(response.data);

            }


        })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    }*/
    const {state, dispatch} = useContext(EntitiesContext);
    const {register, handleSubmit, watch, errors} = useForm();
    const onSubmit = (data, event) => {
        // console.log('data sent in handlesubmit');
        // console.log(data);
        // console.log(route);
        setLoading(true);
        setButtonEnabled(false);
        state.handleSubmit(data, route, showModal, event)
    };

    const name = "users_file";
    return (
        <Container>
            <Row>
                <Col className="ml-auto mr-auto ">
                    <h3 className="title mx-auto text-white text-center">{strings.bulk_registration}</h3>
                    <Form className="registration-form text-white mb-5" id="registration-form"
                          onSubmit={handleSubmit(onSubmit)}
                          method="POST">
                        <div className="form-row">
                            <FormGroup className="col-md-4 documentGroup">
                                <Label
                                    className="font-weight-bolder col-md-6">{strings.bulk_registration_upload}</Label>
                                <File key="1" name={name} id={name} title={"File"} selectStatus={true}
                                      register={register}/>
                            </FormGroup>
                            <div className="col-md-8 documentGroup">
                                <ul><h5>{strings.bulk_fields}</h5>
                                    <li>{strings.bulk_username}</li>
                                    <li>{strings.bulk_firstname}</li>
                                    <li>{strings.bulk_lastname}</li>
                                    <li>{strings.bulk_email}</li>
                                    <li>{strings.bulk_password}</li>
                                    <li>{strings.bulk_birthdate}</li>
                                    <li>{strings.bulk_mobile}</li>
                                    <li>{strings.bulk_vat_reg_no}</li>
                                </ul>

                            </div>
                            <FormGroup>
                                <h5>{strings.template_file}<a className={"text-white"}
                                                href={'/view/import_users_template/users.xlsx'}>{"users.xlsx"}</a></h5>
                            </FormGroup>
                        </div>
                        <Button block className="btn-round" color="dark" disabled={!buttonEnabled}>
                            {strings.submit}
                        </Button>
                    </Form>
                </Col>

            </Row>
        </Container>
    )
}
