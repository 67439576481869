import React,{useEffect} from "react";
import {Col} from "reactstrap";
import strings from "../../app/translations";
export default function ProductDetail({details}){
    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    return (<>
            {details.map((detail, key) =>
                <Col key={key} xs="12">
                    <Col xs="12" className={'mt-2 mb-2'}>
                        <strong>{strings[detail.input_name]}</strong>
                    </Col>
                    <Col xs="12">
                        {detail.input_name === "title" && <strong><span>{detail.value}</span></strong>}
                        {detail.input_name !== "title" &&
                        <textarea readOnly={true} defaultValue={detail.value} rows={4} className={'p-2 col-8 rad-76 rounded text-center'}></textarea>}
                    </Col>
                </Col>
            )}
        </>
    );
}
