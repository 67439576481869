import DocumentsArea from "./DocumentsArea";
import React, {useContext,useEffect} from "react";
import SelectMultipleUsers from "./SelectMultipleUsers";
import {Col, FormGroup, Input, Label} from "reactstrap";
//import context
import ParticipationContext from "../../app/ParticipationContext";
import CRUDReducer from "../../app/reducer";
import EntitiesContext from "../../app/context";
import Row from "reactstrap/es/Row";
import strings from "../../app/translations";
import ParticipationFormDocuments from "./shop/ParticipationFormDocuments";

function UserDetails({register,errors,isReadOnly,certification,setCertification,formType, setMessage}) {
    const {pState,pDispatch} = useContext(ParticipationContext);
    // console.log(pState.users)

    const [users, setUsers] = React.useState([]);
    const [userFiles, setUserFiles] = React.useState([]);
    const setFiles = (value) => {
        setUserFiles(value);
    };
    const currentItem = pState.currentItem;

    function chooseMulitpleSelectValues(e){
        let options = e.target.options;
        multipleSelectValues(options);
    }
    function multipleSelectValues(options){
        console.log('run multiple select values');
        let values = [];
            Array.from(options).map(option =>{
                if(option.selected)
                    values.push({id:parseInt(option.id), name: option.value, user_id:parseInt(option.id)});
            });
            if(values.length >5){
                errors['participation_selected_users'] = 'You can select up to 5 users for a Participation Form!';

                console.log('errors');
                console.log(errors);
                return errors;
            }

            document.getElementById('participation_selected_users').value = JSON.stringify(values);
            setUsers(values);
            return values;
    }


    const certifications = pState.certifications.objects.map(object => object.data);
    const [numOfActivities,setNumOfActivities] = React.useState(0);

    const changeNumOfActivities = (e) =>{

        let options = e.target.id;
        let selectedCertification = certifications.filter(certification => parseInt(e.target.value) === parseInt(certification.id)).pop();
        setNumOfActivities(selectedCertification.number_of_activities);
        setCertification(selectedCertification);
    };

    const newChangeNumOfActivities = () =>{
        setNumOfActivities(certification.number_of_activities);
    };
    useEffect(function(){
        newChangeNumOfActivities();
        /*setCertification(certification)
        var select = document.getElementById('participation_certification_title');
        var options = select.options;


         Array.from(options).map((option,key)=>{
            if(parseInt(option.value) === parseInt(certification.id)){
                console.log('certification.id selectedIndex');
                console.log(key);
                select.selectedIndex =key;
            }
        });

        setNumOfActivities(certification.number_of_activities);*/

    });
    useEffect(function () {
        let values = [];
        Array.from("usersList").map(option => {
            if (option.selected)
                values.push({id: parseInt(option.id), name: option.value});
        });
        // console.log('pState.users');
        // console.log(pState.users);
        if (pState.currentItem && pState.currentItem.data) {
            // console.log('pState.currentItem.participants');
            // console.log(typeof pState.currentItem.data.participants);
            // console.log(pState.currentItem.data.participants);
            let participants = currentItem.data.participants/*.map(participant => {
                let user = pState.users.filter(user=>user.id === participant.user_id).pop();
                if(typeof user !== "undefined")
                    return {id: user.id, name: user.name}
            })*/;
            // console.log(typeof participants);
            // console.log('in useEffect UserDetails');
            // console.log(typeof pState.users);
            if (pState.users.length > 0) {
                document.getElementById('participation_selected_users').value = JSON.stringify(participants);
                setUsers(participants);
            }
        }

        /*multipleSelectValues(values)*/

    }, [pState.users, pState.currentItem]);

    // console.log('users in UserDetails');
    // console.log(users);
    // console.log('rendering UserData');

    return (
        <>
            <div className="form-row">
                <Col className="col-6">
                    <FormGroup>
                        <Label for="participation_certification_title">{strings.certification_title}</Label>
                        <Input className="form-control"
                               placeholder="The chosen certification"
                               readOnly={isReadOnly("participation_certification_title")}
                               type="text" name="participation_certification_title"
                               id="participation_certification_title"
                               defaultValue={certification.title}
                               innerRef={register({required: (!isReadOnly("participation_certification_title"))})}>
                        </Input>
                        {/*<select className="form-control" name="participation_certification_title"
                                id="participation_certification_title"
                                onChange={changeNumOfActivities}
                                ref={register({required: (!isReadOnly("participation_certification_title"))})}>
                            <option key={0}>Choose a Certification ...</option>
                            {certifications.map((certification, key) => <option
                                id={certification.id} value={certification.id}
                                key={key}>{certification.title}</option>
                            )}
                        </select>*/}
                        {errors.participation_certification_title &&
                        <span className="validation-error">{strings.required_validation}</span>
                        }
                    </FormGroup>
                </Col>
                <Col className="col-6">
                    <FormGroup>
                        <Label for="participation_certification_number_of_activities">{strings.certification_activities}</Label>
                        <Input className="form-control"
                               placeholder={strings.certification_exam_amount}
                               readOnly={true}
                               type="text" name="participation_certification_number_of_activities"
                               id="participation_certification_number_of_activities"
                               value={(numOfActivities > 0) ? numOfActivities : ""}
                               innerRef={register({required: (!isReadOnly("participation_certification_number_of_activities"))})}>
                        </Input>
                        {errors.participation_certification_number_of_activities &&
                        <span className="validation-error">{"This field is required"}</span>
                        }
                    </FormGroup>
                </Col>

                <div className="col-12">
                    <SelectMultipleUsers selectedUsers={chooseMulitpleSelectValues} register={register} errors={errors}
                                         isReadOnly={isReadOnly} formType={formType}/>
                </div>
            </div>
            <div  className="form-row">
                <ParticipationFormDocuments userFiles={userFiles} setFiles={setFiles} register={register}
                                            errors={errors} isReadOnly={isReadOnly} users={users} setMessage={message => setMessage(message)}/>
            </div>
        </>
    )
}

export default UserDetails;
