import React, {useContext, useState} from "react";
import {Button, Card, CardFooter, CardTitle, Form, FormGroup, Input, InputGroup, Label, Row} from "reactstrap";
import Datetime from "react-datetime";
import strings from "../../app/translations";
import EntitiesContext from "../../app/context";
import {useForm} from "react-hook-form";
import TooltipWrap from "../Forms/TooltipWrap";

function UserInfo({showModal}){

    const {state/*,dispatch*/} = useContext(EntitiesContext);
    const [currentItem, setCurrentItem] = useState(state.currentItem);
    const [formUnlocked, setFormUnlocked] = useState(false);
    const [firstname,setFirstname] = useState('');
    const [lastname,setLastname] = useState('');
    const [email,setEmail] = useState('');
    const [birthdate,setBirthdate] = useState('');
    const [vat_reg_no,setVat_reg_no] = useState('');
    const [mobile,setMobile] = useState('');
    const [country,setCountry] = useState('');
    const [company,setCompany] = useState('');
    const [user_id,setUser_id] = useState('');

    const [showPassword, setShowPassword] = useState(false);
    /*
    * const firstname = currentItem.data?currentItem.data.firstname:'';
    const lastname = currentItem.data?currentItem.data.lastname:'';
    const email = currentItem.data?currentItem.data.email:'';
    const birthdate = currentItem.data?currentItem.data.birthdate:'';
    const vat_reg_no = currentItem.data?currentItem.data.vat_reg_no:'';
    const mobile = currentItem.data?currentItem.data.mobile:'';
    const country = currentItem.data?currentItem.data.country:'';
    const company = currentItem.data?currentItem.data.company:'';
    const user_id = currentItem.data?state.currentItem.data.id:'';
    */
    const {register, handleSubmit, watch, errors} = useForm();

    const route = "api/profile/update_info";

    React.useEffect(() => {
        if(state.currentItem && state.currentItem.data){
            setCurrentItem(state.currentItem);
            setFirstname(state.currentItem.data.firstname);
            setLastname(state.currentItem.data.lastname);
            setBirthdate(state.currentItem.data.birthdate);
            setVat_reg_no(state.currentItem.data.vat_reg_no);
            setEmail(state.currentItem.data.email);
            setMobile(state.currentItem.data.mobile);
            setCountry(state.currentItem.data.country);
            setCompany(state.currentItem.data.company);
            setUser_id(state.currentItem.data.id);
        }
    },[state.currentItem]);

    // console.log('current item in userinfo');
    // console.log(currentItem);

    function toggleForm(event){
        event.preventDefault();
        setFormUnlocked(!formUnlocked);
    }
    // function submitInfo(event){
    //     event.preventDefault();
    //     const data = new FormData(event.target);
    //     console.log(data);
    //
    //     axios({method:'post',
    //         url:"api/profile/update_info",
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json',
    //             'X-CSRF-TOKEN': window.reactInit.csrf
    //         },
    //         data: data,
    //     }).then(function (response) {
    //         //handle success
    //         console.log(response);
    //     })
    //         .catch(function (response) {
    //             //handle error
    //             console.log('error');
    //             console.log(response);
    //         });
    // }
    function handleChange(event){
        if (event.target.value !== "") {
            setShowPassword(true);
        } else {
            setShowPassword(false);
        }
    }

    const onSubmit = (data, event) => {
        // console.log('data sent in handlesubmit');
        // console.log(data);
        state.handleSubmit(data, route, showModal, event)
    };

    return (<Row className="custom-column">
        <h3 className="ml-auto mr-auto">{strings.personal_info}</h3>
        <Card className="card-profile card-plain mx-auto mt-5">
            {/*<div className="card-avatar">*/}
            {/*    <a href="#pablo" onClick={e => e.preventDefault()}>*/}
            {/*        <img*/}
            {/*            alt="..."*/}
            {/*            src={require("../../assets/img/faces/erik-lucatero-2.jpg")}*/}
            {/*        />*/}
            {/*    </a>*/}
            {/*</div>*/}
            {/*<a href="#pablo" onClick={e => e.preventDefault()}>*/}
            {/*    <div className="author">*/}
            {/*        <CardTitle tag="h4">{lastname} {firstname}</CardTitle>*/}
            {/*        /!*<h6 className="card-category">{title}</h6>*!/*/}
            {/*    </div>*/}
            {/*</a>*/}
            {/*<p className="card-description text-left ml-3 mr-3">*/}
            {/*    /!*But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.*!/*/}
            {/*</p>*/}
            {/*<Form className="personal-data-form ml-3 mr-3" id="personal-data-form" onSubmit={submitInfo} method="POST">*/}
            <Form className="personal-data-form ml-3 mr-3" id="personal-data-form" onSubmit={handleSubmit(onSubmit)} method="POST">
                <fieldset disabled={!formUnlocked}>
                    <div className="form-row">
                        <FormGroup className="col-md-6">
                            <Label for="firstName">{strings.firstname}</Label>
                            <Input id="firstName" placeholder={strings.firstname} defaultValue={firstname} type="text" innerRef={register({required: true})} name='firstname'/>
                        </FormGroup>
                        <FormGroup className="col-md-6">
                            <Label for="lastName">{strings.lastname}</Label>
                            <Input id="lastName" placeholder={strings.lastname} defaultValue={lastname} innerRef={register({required: true})} type="text"  name='lastname'/>
                        </FormGroup>
                    </div>
                    <div className="form-row">
                        <FormGroup className="col-md-6">
                            <label>{strings.birthdate}</label>
                            <InputGroup>
                                <Input
                                   type="date" placeholder={strings.birthdate} defaultValue={birthdate} innerRef={register({required: true})} name='birthdate'
                                />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className="col-md-6">
                            <label>{strings.company}</label>
                            <Input placeholder={strings.company} type="text" defaultValue={company} disabled/>
                        </FormGroup>
                    </div>
                    <div className="form-row">
                        <FormGroup className="col-md-6">
                            <label>{strings.profile_mobile}</label>
                            <Input placeholder={strings.profile_mobile} type="text" defaultValue={mobile} innerRef={register({required: true})} name='mobile'/>
                        </FormGroup>
                        <FormGroup className="col-md-6">
                            <label>Email</label>
                            <Input placeholder='Email' type="text" value={email} name='email' innerRef={register({required: true})} readOnly={true}/>
                        </FormGroup>
                        <FormGroup className='col-md-6'>
                            <label>{strings.vat_reg_num}</label>
                            <Input placeholder={strings.vat_reg_num} type="text" defaultValue={vat_reg_no} innerRef={register({required: true})} name='vat_reg_no'/>
                        </FormGroup>
                        <FormGroup className='col-md-6'>
                            <label>{strings.country}</label>
                            <Input placeholder={strings.country} type="text" defaultValue={country} disabled/>
                        </FormGroup>
                    </div>
                    <div className="form-row">
                        <FormGroup className='col-md-6'>
                            <label>{strings.profile_new_password}</label>
                            <Input placeholder={strings.profile_new_password} type="password" name={'new_password'} id={'new_password'} onChange={handleChange}/>
                            <TooltipWrap target={"new_password"} content={
                                <>
                                    <p>- {strings.formatString(strings.rule_between_string, { min: 8, max: 14})}</p>
                                    <p>- {strings.rule_alpha_lower_required}</p>
                                    <p>- {strings.rule_alpha_upper_required}</p>
                                    <p>- {strings.rule_number_required}</p>
                                    <p>- {strings.rule_special_char_required}</p>
                                </>
                            }/>
                        </FormGroup>
                        <FormGroup className='col-md-6'>
                            <label>{strings.profile_password_confirmation}</label>
                            <Input placeholder={strings.profile_password_confirmation} type="password" name={'password_confirmation'}/>
                        </FormGroup>
                    </div>
                    {showPassword && <div className="form-row">
                        <FormGroup className='col-md-12'>
                            <label>{strings.profile_old_password}</label>
                            <Input placeholder={strings.profile_old_password} type="password" name={'old_password'} id={'old_password'}/>
                            <TooltipWrap target={"old_password"} content={
                                <>
                                    <p>- {strings.formatString(strings.rule_between_string, { min: 8, max: 14})}</p>
                                    <p>- {strings.rule_alpha_lower_required}</p>
                                    <p>- {strings.rule_alpha_upper_required}</p>
                                    <p>- {strings.rule_number_required}</p>
                                    <p>- {strings.rule_special_char_required}</p>
                                </>
                            }/>
                        </FormGroup>
                    </div>}
                </fieldset>
                <Input name="user_id" type="hidden" value={user_id} innerRef={register({required: true})}/>
                <Button block className="btn-round ml-auto mr-auto mt-4 col-lg-4 col-md-4 col-sm-4 col-6" color={formUnlocked? 'gray' : 'tuv_red'} onClick={toggleForm}>
                    {formUnlocked? strings.cancel : strings.profile_update_details}
                </Button>
                <Button block className="btn-round ml-auto mr-auto col-lg-6 col-md-8 col-sm-8 col-12 fade-in" color="tuv_red"
                        hidden={!formUnlocked}>
                    {strings.submit}
                </Button>
            </Form>
            <CardFooter className="text-center">
                {/*<Button
                                            className="btn-just-icon btn-outline-primary"
                                            color="link"
                                            href="#pablo"
                                            onClick={e => e.preventDefault()}
                                        >
                                            <i className="fa fa-twitter" />
                                        </Button>
                                        <Button
                                            className="btn-just-icon btn-outline-primary ml-1"
                                            color="link"
                                            href="#pablo"
                                            onClick={e => e.preventDefault()}
                                        >
                                            <i className="fa fa-google-plus" />
                                        </Button>
                                        <Button
                                            className="btn-just-icon btn-outline-primary ml-1"
                                            color="link"
                                            href="#pablo"
                                            onClick={e => e.preventDefault()}
                                        >
                                            <i className="fa fa-linkedin" />
                                        </Button>*/}
            </CardFooter>
        </Card>
    </Row>);
}

export default UserInfo;
