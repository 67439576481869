/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
// styles
import "../assets/css/bootstrap.min.css";
import "../assets/scss/paper-kit.scss";
import "../assets/scss/custom.scss";
import "../assets/demo/demo.css";
// pages
import NucleoIcons from "../views/NucleoIcons.js";
import MockRegisterPage from "../views/mockups/RegisterPage.js";
import MockLandingPage from "../views/mockups/LandingPage.js";
import MockProductPage from "../views/mockups/ProductPage.js";
import MockUserProfilePage from "../views/mockups/UserProfilePage.js";
import MockEnrollmentPage from "../views/mockups/EnrollmentPage.js";
import MockCheckoutPage from "../views/mockups/CheckoutPage.js";
import MockAdminCertificationPage from "../views/mockups/AdminCertificationPage";
import MockAdminExaminationPage from "../views/mockups/AdminExaminationPage";
import MockAdminEnrollmentPage from "../views/mockups/AdminEnrollmentPage";
import MockParticipationFormPage from "../views/mockups/ParticipationFormPage";
import MockAdminCompanyPage from "../views/mockups/AdminCompanyPage";
import MockLoginPage from "../views/mockups/LoginPage";
import MockReportsPage from "../views/mockups/reports/ReportsPage";
import OrderPage from "../views/mockups/OrderPage";
// others

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path="/index" render={props => <MockLandingPage {...props} />}/>
            <Route
                path="/nucleo-icons"
                render={props => <NucleoIcons {...props} />}
            />
            {/*<Route
            path="/landing-page"
            render={props => <LandingPage {...props} />}
        />
        <Route
            path="/profile-page"
            render={props => <ProfilePage {...props} />}
        />
        <Route
            path="/register-page"
            render={props => <RegisterPage {...props} />}
        />*/}
            <Route
                path="/user-profile"
                render={props => <MockUserProfilePage {...props} />}
            />
            <Route
                path="/enrollment-page"
                render={props => <MockEnrollmentPage {...props} />}
            />
            <Route
                path="/enrollment-page/reject"
                render={props => <MockEnrollmentPage {...props} />}
            />
            <Route
                path="/order-page"
                render={props => <OrderPage {...props} />}
            />
            <Route
                path="/checkout-page"
                render={props => <MockCheckoutPage {...props} />}
            />
            <Route
                path="/register-page"
                render={props => <MockRegisterPage {...props} />}
            />
            <Route
                path="/landing-page"
                render={props => <MockLandingPage {...props} />}
            />
            <Route
                path="/product-page"
                render={props => <MockProductPage {...props} />}
            />
            <Route
                path="/login-page"
                render={props => <MockLoginPage {...props} />}
            />
            <Route
                path="/participationForm-page"
                render={props => <MockParticipationFormPage {...props} />}
            />
            <Route
                path="/admin/company-page"
                render={props => <MockAdminCompanyPage {...props} />}
            />
            <Route
                path="/admin/certification-page"
                render={props => <MockAdminCertificationPage {...props} />}
            />
            <Route
                path="/admin/examination-page"
                render={props => <MockAdminExaminationPage {...props} />}
            />
            <Route
                path="/admin/enrollment-page"
                render={props => <MockAdminEnrollmentPage {...props} />}
            />
            <Route
                path="/reports-page"
                render={props => <MockReportsPage {...props} />}
            />
            <Redirect to="/index"/>

        </Switch>
    </BrowserRouter>,
    document.getElementById("root")
);
function ClearState(){
    localStorage.clear();
}
