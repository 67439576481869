import {NavItem, NavLink} from "reactstrap";
import React,{useContext, useReducer} from "react";
import EntitiesContext from "../../app/context";
import CRUDReducer from "../../app/reducer";
import strings from "../../app/translations";
export default function AdminNavItem({adminUser}) {
    const initialState = useContext(EntitiesContext);
    const [state/*,dispatch*/] = useReducer(CRUDReducer,initialState);
    if(adminUser){
        return(
            <NavItem>
                <NavLink href="/admin/certification-page">
                    <i className="nc-icon nc-layout-11"/> {strings.menu_admin.stripAccents()}
                </NavLink>
            </NavItem>
        )
    } else {
        return (<></>)
    }
}
