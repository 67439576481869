import {Button} from "reactstrap";
import React, {useState, useEffect, useContext} from "react";
import Modal from "../Modals/Modal";
import PdfMaker from "../Modals/PdfMaker";
import EntitiesContext from "../../app/context";
import strings from "../../app/translations";

export default function ManageCertificate({certificate,enrollment_id,certification_id,participant,showModal,index}) {

    const user_role = window.reactInit.user_role;
    const [label,setLabel ] = useState(strings.create_certificate_label);
    const [title,setTitle] = useState('');
    const [buttonColor,setButtonColor] = useState('primary');


    const generatePDF = () =>{

    };
    const createCertificate = () =>{
        console.log('Creating Certificate');
        console.log(participant);

        $('#pdf_maker_'+index).modal({show: true});
        $(".modal-backdrop").css("z-index",1);

    };
    const editCertificate = () =>{
        alert('edit clicked');
    };
    const ExportCertificate = () =>{
        alert('export clicked')
    };
    const UploadCertificate = () =>{
        setTitle(strings.publish_certificate_title);
        $('#pdf_maker_'+index).modal({show: true});
        $(".modal-backdrop").css("z-index",1);
    };
    const ViewUploadedCertificate = () =>{
        setTitle(strings.view_certificate_title);
        let newPageURL = `/certificates/${certification_id}/${certificate.id}`;
        window.open(newPageURL, "_blank") //to open new page
    };
    const SendCertificate = () =>{};
    const handleCertificate = () =>{
        /*selectCurrentUser(participant);*/
        console.log('handle certificate');
        console.log(certificate);
        if(certificate){
            if(certificate.status_id === 1){ /*draft status*/
                setLabel(strings.edit_certificate_label);
                setTitle(strings.edit_certificate_title);
                createCertificate();
            }
            else if(certificate.status_id === 2){ /*waiting approval status*/
                setLabel(strings.upload_certificate_label);
                createCertificate();
            }
            else if(certificate.status_id === 3){ /*approved status*/
                setLabel(strings.publish_certificate_label);
                UploadCertificate();
            }
            else if(certificate.status_id === 5){ /*published status*/
                setLabel(strings.view_certificate_label);
                ViewUploadedCertificate(certificate);
            }else{
                setTitle(strings.create_certificate_title);
                createCertificate();
            }

        }else{
            setTitle(strings.create_certificate_title);
            createCertificate();
        }
    };

    const init = () =>{
        if(certificate){
            if(certificate.status_id === 1){ /*draft status*/
                setButtonColor('success');
                setLabel(strings.edit_certificate_label);
            }
            else if(certificate.status_id === 2){ /*waiting approval status*/
                setButtonColor('danger');
                setLabel(strings.upload_certificate_label);
            }
            else if(certificate.status_id === 3){ /*approved status*/
                setButtonColor('success');
                setLabel(strings.publish_certificate_label);
            }
            else if(certificate.status_id === 5){ /*published status*/
                setButtonColor('secondary');
                setLabel(strings.view_certificate_label);
            }
        }
    };
    useEffect(function(){
        init();
    });
    return(<>{((participant.certificate && participant.certificate.status_id === 5 && participant.user.id === Number(window.reactInit.user_id)) || user_role === "moderator") && <>
        <button className={`btn btn-round col-4 offset-1 ml-auto mr-auto btn-${buttonColor} `}
                                                   onClick={handleCertificate}>
        {label}
    </button>
    <PdfMaker title={title} certificate={certificate} participant={participant} message={[]} index={index} action={""}/>
    </>

    }
    </>);
}
