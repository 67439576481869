import React from "react";
export default function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block",minHeight:"1.5rem",minWidth:"1.5rem", background: "url("+ require(`../../assets/img/mockups/ionic-ios-arrow-back.svg`) +") no-repeat" }}
            onClick={onClick}
        />
    );
}
