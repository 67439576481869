/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

function NucleoIcons() {
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("demo-icons");
        return function cleanup() {
            document.body.classList.remove("demo-icons");
        };
    });
    return (
        <>
            <header>
                <h1>Paper Kit React Icons</h1>
                <p>
                    Built with{" "}
                    <a href="https://nucleoapp.com/?ref=1712" target="_blank">
                        nucleoapp.com
                    </a>
                </p>
            </header>
            <div id="icons-wrapper">
                <section>
                    <ul>
                        <li>
                            <i class="nc-icon nc-air-baloon"/>
                            <p>nc-air-baloon</p>
                            <em>\ea01</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-album-2"/>
                            <p>nc-album-2</p>
                            <em>\ea02</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-alert-circle-i"/>
                            <p>nc-alert-circle-i</p>
                            <em>\ea04</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-align-center"/>
                            <p>nc-align-center</p>
                            <em>\ea03</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-align-left-2"/>
                            <p>nc-align-left-2</p>
                            <em>\ea05</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-ambulance"/>
                            <p>nc-ambulance</p>
                            <em>\ea06</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-app"/>
                            <p>nc-app</p>
                            <em>\ea07</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-atom"/>
                            <p>nc-atom</p>
                            <em>\ea08</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-badge"/>
                            <p>nc-badge</p>
                            <em>\ea09</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-bag-16"/>
                            <p>nc-bag-16</p>
                            <em>\ea0a</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-bank"/>
                            <p>nc-bank</p>
                            <em>\ea0b</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-basket"/>
                            <p>nc-basket</p>
                            <em>\ea0c</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-bell-55"/>
                            <p>nc-bell-55</p>
                            <em>\ea0d</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-bold"/>
                            <p>nc-bold</p>
                            <em>\ea0e</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-book-bookmark"/>
                            <p>nc-book-bookmark</p>
                            <em>\ea0f</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-bookmark-2"/>
                            <p>nc-bookmark-2</p>
                            <em>\ea10</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-box-2"/>
                            <p>nc-box-2</p>
                            <em>\ea11</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-box"/>
                            <p>nc-box</p>
                            <em>\ea12</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-briefcase-24"/>
                            <p>nc-briefcase-24</p>
                            <em>\ea13</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-bulb-63"/>
                            <p>nc-bulb-63</p>
                            <em>\ea14</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-bullet-list-67"/>
                            <p>nc-bullet-list-67</p>
                            <em>\ea15</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-bus-front-12"/>
                            <p>nc-bus-front-12</p>
                            <em>\ea16</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-button-pause"/>
                            <p>nc-button-pause</p>
                            <em>\ea17</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-button-play"/>
                            <p>nc-button-play</p>
                            <em>\ea18</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-button-power"/>
                            <p>nc-button-power</p>
                            <em>\ea19</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-calendar-60"/>
                            <p>nc-calendar-60</p>
                            <em>\ea1a</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-camera-compact"/>
                            <p>nc-camera-compact</p>
                            <em>\ea1b</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-caps-small"/>
                            <p>nc-caps-small</p>
                            <em>\ea1c</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-cart-simple"/>
                            <p>nc-cart-simple</p>
                            <em>\ea1d</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-chart-bar-32"/>
                            <p>nc-chart-bar-32</p>
                            <em>\ea1e</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-chart-pie-36"/>
                            <p>nc-chart-pie-36</p>
                            <em>\ea1f</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-chat-33"/>
                            <p>nc-chat-33</p>
                            <em>\ea20</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-check-2"/>
                            <p>nc-check-2</p>
                            <em>\ea21</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-circle-10"/>
                            <p>nc-circle-10</p>
                            <em>\ea22</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-cloud-download-93"/>
                            <p>nc-cloud-download-93</p>
                            <em>\ea23</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-cloud-upload-94"/>
                            <p>nc-cloud-upload-94</p>
                            <em>\ea24</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-compass-05"/>
                            <p>nc-compass-05</p>
                            <em>\ea25</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-controller-modern"/>
                            <p>nc-controller-modern</p>
                            <em>\ea26</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-credit-card"/>
                            <p>nc-credit-card</p>
                            <em>\ea27</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-delivery-fast"/>
                            <p>nc-delivery-fast</p>
                            <em>\ea28</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-diamond"/>
                            <p>nc-diamond</p>
                            <em>\ea29</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-email-85"/>
                            <p>nc-email-85</p>
                            <em>\ea2a</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-favourite-28"/>
                            <p>nc-favourite-28</p>
                            <em>\ea2b</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-glasses-2"/>
                            <p>nc-glasses-2</p>
                            <em>\ea2c</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-globe-2"/>
                            <p>nc-globe-2</p>
                            <em>\ea2d</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-globe"/>
                            <p>nc-globe</p>
                            <em>\ea2e</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-hat-3"/>
                            <p>nc-hat-3</p>
                            <em>\ea2f</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-headphones"/>
                            <p>nc-headphones</p>
                            <em>\ea30</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-html5"/>
                            <p>nc-html5</p>
                            <em>\ea31</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-image"/>
                            <p>nc-image</p>
                            <em>\ea32</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-istanbul"/>
                            <p>nc-istanbul</p>
                            <em>\ea33</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-key-25"/>
                            <p>nc-key-25</p>
                            <em>\ea34</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-laptop"/>
                            <p>nc-laptop</p>
                            <em>\ea35</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-layout-11"/>
                            <p>nc-layout-11</p>
                            <em>\ea36</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-lock-circle-open"/>
                            <p>nc-lock-circle-open</p>
                            <em>\ea37</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-map-big"/>
                            <p>nc-map-big</p>
                            <em>\ea38</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-minimal-down"/>
                            <p>nc-minimal-down</p>
                            <em>\ea39</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-minimal-left"/>
                            <p>nc-minimal-left</p>
                            <em>\ea3a</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-minimal-right"/>
                            <p>nc-minimal-right</p>
                            <em>\ea3b</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-minimal-up"/>
                            <p>nc-minimal-up</p>
                            <em>\ea3c</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-mobile"/>
                            <p>nc-mobile</p>
                            <em>\ea3d</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-money-coins"/>
                            <p>nc-money-coins</p>
                            <em>\ea3e</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-note-03"/>
                            <p>nc-note-03</p>
                            <em>\ea3f</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-palette"/>
                            <p>nc-palette</p>
                            <em>\ea40</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-paper"/>
                            <p>nc-paper</p>
                            <em>\ea41</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-pin-3"/>
                            <p>nc-pin-3</p>
                            <em>\ea42</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-planet"/>
                            <p>nc-planet</p>
                            <em>\ea43</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-refresh-69"/>
                            <p>nc-refresh-69</p>
                            <em>\ea44</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-ruler-pencil"/>
                            <p>nc-ruler-pencil</p>
                            <em>\ea45</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-satisfied"/>
                            <p>nc-satisfied</p>
                            <em>\ea46</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-scissors"/>
                            <p>nc-scissors</p>
                            <em>\ea47</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-send"/>
                            <p>nc-send</p>
                            <em>\ea48</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-settings-gear-65"/>
                            <p>nc-settings-gear-65</p>
                            <em>\ea49</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-settings"/>
                            <p>nc-settings</p>
                            <em>\ea4a</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-share-66"/>
                            <p>nc-share-66</p>
                            <em>\ea4b</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-shop"/>
                            <p>nc-shop</p>
                            <em>\ea4c</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-simple-add"/>
                            <p>nc-simple-add</p>
                            <em>\ea4d</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-simple-delete"/>
                            <p>nc-simple-delete</p>
                            <em>\ea4e</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-simple-remove"/>
                            <p>nc-simple-remove</p>
                            <em>\ea4f</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-single-02"/>
                            <p>nc-single-02</p>
                            <em>\ea50</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-single-copy-04"/>
                            <p>nc-single-copy-04</p>
                            <em>\ea51</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-sound-wave"/>
                            <p>nc-sound-wave</p>
                            <em>\ea52</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-spaceship"/>
                            <p>nc-spaceship</p>
                            <em>\ea53</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-sun-fog-29"/>
                            <p>nc-sun-fog-29</p>
                            <em>\ea54</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-support-17"/>
                            <p>nc-support-17</p>
                            <em>\ea55</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-tablet-2"/>
                            <p>nc-tablet-2</p>
                            <em>\ea56</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-tag-content"/>
                            <p>nc-tag-content</p>
                            <em>\ea57</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-tap-01"/>
                            <p>nc-tap-01</p>
                            <em>\ea58</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-tie-bow"/>
                            <p>nc-tie-bow</p>
                            <em>\ea59</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-tile-56"/>
                            <p>nc-tile-56</p>
                            <em>\ea5a</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-time-alarm"/>
                            <p>nc-time-alarm</p>
                            <em>\ea5b</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-touch-id"/>
                            <p>nc-touch-id</p>
                            <em>\ea5c</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-trophy"/>
                            <p>nc-trophy</p>
                            <em>\ea5d</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-tv-2"/>
                            <p>nc-tv-2</p>
                            <em>\ea5e</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-umbrella-13"/>
                            <p>nc-umbrella-13</p>
                            <em>\ea5f</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-user-run"/>
                            <p>nc-user-run</p>
                            <em>\ea60</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-vector"/>
                            <p>nc-vector</p>
                            <em>\ea61</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-watch-time"/>
                            <p>nc-watch-time</p>
                            <em>\ea62</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-world-2"/>
                            <p>nc-world-2</p>
                            <em>\ea63</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                        <li>
                            <i class="nc-icon nc-zoom-split"/>
                            <p>nc-zoom-split</p>
                            <em>\ea64</em>
                            <input type="text" maxlength="1" readonly="true"/>
                        </li>
                    </ul>
                </section>
            </div>
        </>
    );
}

export default NucleoIcons;
