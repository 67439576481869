import React, {Component, useContext} from 'react';
import strings from "../../app/translations";
import Cookie from 'universal-cookie';
import './datatables.css';
import propTypes from "prop-types";
import ViewVariables from "../Middleware/ViewVariables";

import 'datatables.net-buttons/js/dataTables.buttons.min';
import 'datatables.net-buttons/js/buttons.html5.min';
import 'datatables.net-buttons-dt/css/buttons.dataTables.min.css'
window.JSZip = require('jszip');

const $ = require('jquery');
$.DataTable = require('datatables.net');

const cookie=new Cookie();

function reloadTableData(names) {
    const table = $('.data-table-wrapper').find('table').DataTable();
    table.clear();
    table.rows.add(names);
    table.draw();
}

function updateTable(names) {
    const table = $('.data-table-wrapper').find('table').DataTable();
    let dataChanged = false;
    table.rows().every(function () {
        const oldNameData = this.data();
        const newNameData = names.find((nameData) => {
            return nameData.name === oldNameData.name;
        });
        if (oldNameData.nickname !== newNameData.nickname) {
            dataChanged = true;
            this.data(newNameData);
        }
        return true;
    });

    if (dataChanged) {
        table.draw();
    }
}

/* Formatting function for row details - modify as you need */
export function format ( d ) {
    // `d` is the original data object for the row
    return '<table cellpadding="5" cellspacing="10" border="" style="padding-left:50px;">'+
        '<tr class="more-options">'+
        '<td><a href="/admin/enrollment-page/3"><button class="btn btn-round btn-secondary">Re-enroll</button></a></td>'+
        '<td><a href="/admin/enrollment-page/3"><button class="btn btn-round btn-secondary">Edit Certificate</button></a></td>'+
        '<td><a href="/admin/enrollment-page/3"><button class="btn btn-round btn-secondary">Order checkout</button></a></td>'+
        '</tr>'+
        '</table>';
}

var buttons = [];
if(window.location.pathname.indexOf('reports-page') !== -1){
    buttons.push(
        {
            extend: 'csv',
            text: strings.export_to + ' CSV',
            filename: 'csv_file',
            charset: 'utf-8',
            bom: true
        },
        {
            extend: 'excel',
            text: strings.export_to + ' Excel',
            filename: 'excel_file',
            title: null
        }
    )
}

class Table extends Component {

    componentDidMount() {
        let language = cookie.get('language_set') === 'el' ? 'Greek' : 'English';
        let columns = this.props.columns;
        // console.log('this.props.columns');
        // console.log(this.props.columns);

        let myDataTable = $(this.refs.main).DataTable({
            dom: '<"data-table-wrapper"Bfitlp>',
            //dom: "fltip",
            data: this.props.names,
            columns,
            //pageLength: 10,
            ordering: true,
            pagingType: 'full',
            language: {
                url: 'https://cdn.datatables.net/plug-ins/1.10.25/i18n/' + language + '.json',
            },
            buttons: buttons,
            responsive:{details:{type:"column"}},
            columnDefs: [ {
                className: 'dtr-control',
                orderable: false,
                targets:   3
            } ],
            initComplete: function () {
                if(window.location.pathname.indexOf('reports-page') !== -1){
                    // console.log("window.location.pathname.indexOf('reports-page')");
                    // console.log(window.location.pathname.indexOf('reports-page'));
                    // Setup - add a text input to each footer cell
                    $('table thead th').each( function () {
                        var title = $(this).text();
                        // console.log('title');
                        // console.log(this);
                        $(this).html( '<div style="display: inline-grid"><span>'+ title +'</span><input type="text" style="max-width:10rem" placeholder="'+ strings.search +title+'"  /></div>' );
                    } );
                    // Apply the search
                    this.api().columns().every( function () {
                        var that = this;

                        $( 'input', this.header() ).on( 'keyup change clear', function () {
                            // console.log('inside')
                            if ( that.search() !== this.value ) {
                                that
                                    .search( this.value )
                                    .draw();
                            }
                        } );
                    } );
                }
            }
        });

        /*let moreOptions = columns.filter((column) => column.className === "details-control");
        if(moreOptions.length>0){
            // Add event listener for opening and closing details
            $('tbody').on('click', 'td.details-control', function () {
                var tr = $(this).closest('tr');
                var row = myDataTable.row( tr );
                console.log('test');
                if ( row.child.isShown() ) {
                    // This row is already open - close it
                    row.child.hide();
                    tr.removeClass('shown');
                }
                else {
                    // Open this row
                    row.child( format(row.data()) ).show();
                    tr.addClass('shown');
                }
            } );
        }*/


    }

    componentWillUnmount() {
        $('.data-table-wrapper').find('table').DataTable().destroy(true);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.names.length !== this.props.names.length) {
            reloadTableData(nextProps.names);
        } else {
            updateTable(nextProps.names);
        }
        return false;
    }


    render() {
        return (
            <div>
                <table ref="main" style={{
                    minWidth:"400px",
                    maxWidth: "400px!important",
                }}/>
            </div>);
    }
}

Table.propTypes = {
    names: propTypes.array
};

export default Table;
