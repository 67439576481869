import React, {useContext} from "react";
import {Col, Container, Row} from "reactstrap";
import RegistrationForm from "../../../components/Forms/shop/RegistrationForm";
import EntitiesContext from "../../../app/context";
import BulkRegistrationForm from "../../../components/Forms/shop/BulkRegistration";
import Modal from "../../../components/Modals/Modal";
import strings from "../../../app/translations";
import OverlayLoader from "../../../components/Loaders/OverlayLoader";

function RegisterMain({setUserRole}) {

    const {state, dispatch} = useContext(EntitiesContext);
    const [route, setRoute] = React.useState('');
    const [modalShow,setModalShow] = React.useState(false);
    const [message,setMessage] = React.useState([]);
    const [action,setAction] = React.useState('');
    const [buttonEnabled,setButtonEnabled] = React.useState(true);
    const [loading, setLoading] = React.useState(false);

    function handleRoute(){
        // console.log('in handleroute');
        // console.log(state.user);
        if ((state.user && ['administrator', 'manager'].includes(state.user.role)) || (state.state && ['administrator', 'manager'].includes(state.state.user.role))) {
            if (window.location.hash === '#group') {
                setRoute('/api/registration/group');
                return;
            }
        }
        setRoute('/api/registration/single');
    }

    function showModal(data) {
        // console.log('data in showModal');
        // console.log(data);
        setButtonEnabled(true);
        if (data) {
            let msg = [];
            if (data.errors) {
                // console.log('errors');
                // console.log(data.errors);
                Object.values(data.errors).map(i => i.map(m => {
                    return msg.push(m)
                }));
            } else if (data.import_errors) {
                msg.push(strings.import_invalid_info_msg);
                let keys = Object.keys(data.import_errors).map(key => key);
                Object.values(data.import_errors).map((i, index) => i.map(m => {
                    return msg.push(`${keys[index]} : ${m}`)
                }));
            } else if (data.service_error) {
                msg.push(data.service_error.title);
                msg.push(data.service_error.message);
            } else {
                msg.push(strings.the_following_users);
                // console.log('data');
                // console.log(data.records);
                Object.values(data.records).map((record, key) => {
                    let type;
                    type = (key.length > 1) ? key : '';
                    // console.log('typeof key');
                    // console.log(typeof key);
                    type = (typeof key === 'string') ? key : Object.keys(data.records)[0];
                    msg.push(`${record.data.name} with email: ${record.data.email}`);
                    return msg
                });

                if (route === '/api/registration/single')
                    msg.push(strings.was_saved_successfully);
                else
                    msg.push(strings.were_imported_successfully);
            }
            // console.log('msg');
            // console.log(msg);


            let act = (data.errors) ? ('error') : {link: "/index", label: strings.continue};
            setAction(act);
            setMessage(msg);
            setModalShow(true);
            $('#appModal').modal({show: true});
            setLoading(false);
            // console.log('show');
            // console.log(msg);
        }
    }


    React.useEffect(()=>{
        handleRoute();
    },[]);
    if (window.location.hash === '#group' &&(state.user && ['administrator', 'manager'].includes(state.user.role))) {
        return (
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto mt-5" lg="12" >
                        <OverlayLoader active={loading} text={strings.load} background={'rgba(255,255,255,0.3)'} className={'p-3'}>
                            <BulkRegistrationForm  route={route} showModal={showModal} buttonEnabled={buttonEnabled} setButtonEnabled={setButtonEnabled} setLoading={setLoading}/>
                        </OverlayLoader>
                        <Modal action={action} message={message} title={strings.registration_status}/>
                    </Col>
                </Row>
            </Container>
        )
    } else if ((window.location.hash === '#individual')){
        // console.log('in individual');
        return (
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto mt-5 p-0" lg="12" style={{marginTop: '15em'}}>
                        <OverlayLoader active={loading} text={strings.load} background={'rgba(255,255,255,0.3)'} className={'p-3'}>
                            <RegistrationForm setUserRole={setUserRole} route={route} showModal={showModal} buttonEnabled={buttonEnabled} setButtonEnabled={setButtonEnabled} setLoading={setLoading}/>
                        </OverlayLoader>
                        <Modal action={action} message={message} title={strings.registration_status}/>
                    </Col>
                </Row>
            </Container>
        )
    } else {
        return (<></>)
    }
}
export default RegisterMain;
