import React from "react";
import {Tooltip} from "reactstrap";


export default function TooltipWrap({target, content}) {

    const [tooltipOpen, setTooltipOpen] = React.useState(false);

    return (
        <Tooltip
            placement="right"
            isOpen={tooltipOpen}
            target={target}
            toggle={() => setTooltipOpen(!tooltipOpen)}
        >
            {content}
        </Tooltip>
    )
}
