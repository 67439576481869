import React, {useContext, useEffect, useState} from "react";
import {Button, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import Datetime from "react-datetime";
import TableApp from "../Datatable/TableApp";

import EntitiesContext from "../../app/context";
import ExaminationForm from "../Forms/admin/ExaminationForm";
import strings from "../../app/translations";

import Moment from "moment";

function ManageExaminations() {
    const {state,dispatch} = useContext(EntitiesContext);
    // console.log("state examinations");
    // console.log(state.examinations);
    const [tableWidth ,setTableWidth ] = useState(state.examinations.tableWidth);
    const objs = state.examinations.objects.map(object => object.data);
    // console.log("state objs");
    // console.log(objs);

    const pagePathname = '/admin/examination-page';
    const editMode = window.location.pathname !== pagePathname;

    const [objects,setObjects] = useState(state.examinations.objects.map(object => {
    // console.log('object');
    // console.log(object);
        // console.log('object.data.classrooms');
        // console.log(object.data.classrooms);
        if(object.data.classrooms && object.data.classrooms.length > 0){
            if(typeof object.data.classrooms === "string")
                object.data.classrooms = JSON.parse(object.data.classrooms);
            // console.log(object.data.classrooms.length + typeof object.data.classrooms);
            object.data.classrooms = object.data.classrooms.map(classroom => `${classroom} <br>`);
        }
        // console.log('object.data');
        // console.log(object.data);
        if(object.data.supervisors && object.data.supervisors.length > 0){
            if(typeof object.data.supervisors === "string")
                object.data.supervisors = JSON.parse(object.data.supervisors);
            object.data.supervisors = object.data.supervisors.map(supervisor => `${supervisor} <br>`);
        }

     object.data.action = state.actionButton(object,true,false,false);
        return  object.data;
    }));
    const disabledFields = ['classroomsList'];

    const columns = state.examinations.columns;
    // console.log("objects");
    // console.log(objects);

    useEffect(() => {
        // console.log("state.examinations use effect");
       /* console.log(objects);*/
            setObjects(state.examinations.objects.map(object => {
                return object.data
            }));
        }
        , [state]);

    const [sectionTab, setSectionTab] = React.useState('1');
    const sectionToggle = tab => {
        if (sectionTab !== tab) setSectionTab(tab);
    };
    return (
        <Row className="custom-column">
            <Col><h2 className="text-center text-dark">{strings.manage_examinations}</h2>
                <div className="form-group col-md-12">
                    {editMode &&
                    <Button block className="btn-round ml-auto col-md-2 mt-5" color="secondary"><a href={pagePathname} style={{color:"white"}}>
                        {strings.back_to_main}</a>
                    </Button>}
                    <Nav tabs className="justify-content-center">
                        <NavItem>
                            <NavLink
                                className={classnames({active: sectionTab === '1'})}
                                onClick={() => {
                                    sectionToggle('1');
                                }}
                            >
                                {(editMode)?"Edit Examination":strings.create_examination}
                            </NavLink>
                        </NavItem>
                        {!editMode && <NavItem>
                            <NavLink
                                className={classnames({active: sectionTab === '2'})}
                                onClick={() => {
                                    sectionToggle('2');
                                }}
                            >
                                {strings.examination_list}
                            </NavLink>
                        </NavItem>}
                    </Nav>

                    <TabContent activeTab={sectionTab}>
                        <TabPane tabId="1">
                            <ExaminationForm disabledFields={disabledFields}/>
                        </TabPane>
                        <TabPane tabId="2">

                            <Row className="col-md-12">
                                <TableApp onEdit={() => {
                                    sectionToggle('1');
                                }} names={objects} columns={columns} tableWidth={tableWidth}/>
                            </Row>
                        </TabPane>
                    </TabContent>

                </div>
            </Col>
        </Row>
    )
}
export default ManageExaminations;
