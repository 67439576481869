import React, {useContext} from "react";
import {Button, FormGroup, Input, InputGroup, Label} from "reactstrap";
import Datetime from "react-datetime";
import propTypes from "prop-types";
import EntitiesContext from "../../../app/context";


function EnrollmentDetails({enrollment,register,errors,isReadOnly}) {
    const {state/*,dispatch*/} = useContext(EntitiesContext)
    const prepareObjects = state.enrollments.objects.map(object => object.data);

    let buttonDetails = {};

    function renderButton(exam_status) {
        if (exam_status === 'Closed') {
            buttonDetails.color = "success";
            buttonDetails.action = "Certify";
        } else {
            buttonDetails.color = "dark";
            buttonDetails.action = "Save";
        }
        console.log(enrollment);
    }

    return (
        <div>
            <div className="form-row col-md-12">
                <div className="form-row col-md-12">
                    <FormGroup className="form-group col-md-6">
                        <InputGroup>
                            <label className="col-md-12"
                                   htmlFor="selectedInterestDate ">Selected
                                Date</label>
                            <Datetime  readOnly value={enrollment.date}
                                      inputProps={{id:"selectedInterestDate", name:"selectedInterestDate",
                                          placeholder: "Start Date/Request Date",
                                          ref:register({required:(!isReadOnly("selectedInterestDate"))})}}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className="form-group col-md-6">
                        <label className="col-md-12" htmlFor="selectMulti ">Dates of
                            Interest</label>
                        <select className="form-control" name="selectMulti" id="selectMulti"
                                multiple
                                ref={register({required: (!isReadOnly("selectMulti"))})}>
                            {<option value={enrollment.date}>{enrollment.date}</option>}
                        </select>
                        <input type="text" className="form-control" readOnly value={enrollment.name}
                               id="certificationName"
                               placeholder="Όνομα Πιστοποίησης"
                               ref={register({required: (!isReadOnly("certificationName"))})}
                        />
                    </FormGroup>
                </div>
                <div className="form-row col-md-12">
                    <FormGroup className="form-group col-md-6">
                        <label className="col-md-12" htmlFor="number_of_participants ">Number of Participants
                            Interest</label>
                        <input type="text" className="form-control" readOnly value={enrollment.num_of_participants}
                               id="number_of_participants"
                               placeholder="Number of Participants"
                               ref={register({required: (!isReadOnly("number_of_participants"))})}
                        />
                        <ul style={{
                            listStyle: "none", border: "1px solid #dddddd",
                            background: "aliceblue"
                        }}>{state.dummyUsers.map((user,key)=>{
                           return <li key={key}>{user.name}</li>
                        })}
                        </ul>
                    </FormGroup>
                    <FormGroup className="form-group col-md-6">
                        <label className="col-md-12" htmlFor="durationTypeSelect ">Exam Status
                        </label>

                        <input type="text" className="form-control" readOnly
                               value={enrollment.enrollment_status.replace(/<[^>]*>?/gm, '')} id="durationTypeSelect"
                               placeholder="Όνομα Πιστοποίησης"
                               ref={register({required: (!isReadOnly("durationTypeSelect"))})}
                        />
                    </FormGroup>
                </div>
                <div className="form-row col-md-12">
                    <FormGroup className="col-md-4" check>
                        <Label check>
                            <Input type="checkbox" id={"remote"} name={"remote"} readOnly defaultChecked={(enrollment.remote === "te")}
                                   ref={register({required: (!isReadOnly("remote"))})}/>{" "}
                            Remote
                            <span className="form-check-sign">
                                                    <span className="check"></span>
                                                </span>
                        </Label>
                    </FormGroup>
                    <FormGroup className="col-md-4" check>
                        <Label check>
                            <Input type="checkbox" readOnly id={"grade_auto"} name={"grade_auto"}  defaultChecked={(enrollment.grade_auto === "yes")}/>{" "}
                            Automatic Grade
                            <span className="form-check-sign">
                                                    <span className="check"></span>
                                                </span>
                        </Label>
                    </FormGroup>
                    <FormGroup className="col-md-4" check>
                        <Label check>
                            <Input type="checkbox" id={"open_date"} name={"open_date"} defaultChecked={(enrollment.open_date === "yes")}/>{" "}
                            Open Date
                            <span className="form-check-sign">
                                                    <span className="check"></span>
                                                </span>
                        </Label>
                    </FormGroup>
                </div>

                <Button block className="btn-round offset-md-2 col-md-8 mt-5"
                        onLoad={renderButton(enrollment.enrollment_status.replace(/<[^>]*>?/gm, ''))}
                        color={buttonDetails.color}>Suspend
{/*                    {buttonDetails.action}*/}
                </Button>
            </div>
        </div>)
}

EnrollmentDetails.propTypes = {
    enrollment: propTypes.object
};

export default EnrollmentDetails;
