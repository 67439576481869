import React, {useContext, useState} from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Collapse,
    Form,
    FormGroup,
    FormText,
    Input,
    Label,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import classnames from "classnames";
import {useForm} from "react-hook-form";


import DownArrow from "../../components/arrows/DownArrow";
import NextArrow from "../../components/arrows/NextArrow";
import Document from "../Forms/Document";
import strings from "../../app/translations";
import DocumentsArea from "../Forms/DocumentsArea";
import EntitiesContext from "../../app/context";
import DocumentsIndex from "./DocumentsIndex";

function UserData(/*added props none existed*/{isReadOnly, showModal, setMessage}) {
    const [isOpen, setIsOpen] = React.useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const [activeTab, setActiveTab] = React.useState("1");

    const {state/*,dispatch*/} = useContext(EntitiesContext);
    const [currentItem, setCurrentItem] = useState(state.currentItem);
    const {register, handleSubmit, watch, errors} = useForm();
    const firstname = currentItem.data ? currentItem.data.firstname : '';
    const certificates = currentItem.data ? currentItem.data.certificates : [];
    const documents = currentItem.data ? currentItem.data.documents : [];
    const exams = currentItem.data ? currentItem.data.forthcoming_exams : [];
    const route = '/api/files/upload';
    const user_id = currentItem.data ? state.currentItem.data.id : '';

    const toggleTab = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    React.useEffect(() => {
        setCurrentItem(state.currentItem);
    }, [state.currentItem]);

    function handleActiveNav(e) {
        let navItems = e.target.parentNode.parentNode.children;
        // console.log('the items')
        // console.log(navItems);
        for (let i = 0; i < navItems.length; i++) {
            navItems[i].childNodes[0].style.fontWeight = '400';
            navItems[i].childNodes[0].style.opacity = "0.8";
            navItems[i].childNodes[0].parentNode.style.borderBottom = "none";
        }
        if (e.target.attributes.class.value.indexOf('active') !== false) {
            e.target.style.fontWeight = '600';
            e.target.style.opacity = "1";
            e.target.parentNode.style.borderBottom = "thick solid #ED1C24";


        }
    }

    const newDoc = [{}];


    // const exams = [{
    //     heading: 'April 12 ', message: "11:00 AM - 12:00AM",
    //     badge_type: "danger",
    //     status: "closed",
    //     date: "16-04-2020"
    // }, {
    //     heading: 'June 26 ', message: "1:00 PM - 2:00 PM",
    //     badge_type: "info",
    //     status: "open",
    //     date: "16-04-2020"
    // }, {
    //     heading: 'September 4 ', message: "2:00 PM - 3:00 PM",
    //     badge_type: "info",
    //     status: "open",
    //     date: "16-04-2020"
    // }];


    const onSubmit = (data, event) => {
        // console.log('data sent in handlesubmit');
        // console.log(data);
        state.handleSubmit(data, route, showModal, event)
    };
    // function handleSubmitDocs(event) {
    //     event.preventDefault();
    //     let route = '/api/files/upload';
    //     let data = new FormData(event.target);
    //     // var model_id = new Blob([
    //     //     state.currentItem.data.id
    //     // ], { type: 'text' });
    //     // data.append('model_id', model_id);
    //     console.log('sending docs with data:');
    //     console.log(data);
    //     console.log('userFiles');
    //     console.log(userFiles);
    //     axios({
    //         method: 'post',
    //         url: route,
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'text/plain',
    //             'X-CSRF-TOKEN': window.reactInit.csrf
    //         },
    //         data: data,
    //     }).then(function (response) {
    //         //handle success
    //         console.log(response);
    //         if (typeof response.data.errors !== 'undefined') {
    //             showModal(response.data);
    //         } else {
    //             console.log(response.data)
    //             showModal(response.data);
    //
    //         }
    //     })
    //         .catch(function (response) {
    //             //handle error
    //             console.log(response);
    //         });
    // }


    //new added code
    const user = {id: user_id, name: "Placeholder Name", birthDate: "01-01-1901"};
    const [userFiles, setUserFiles] = React.useState([]);
    const setFiles = (value) => {
        setUserFiles(value);
    };

    return (<Row style={{display: "block", minHeight: "32rem"}} className="custom-column" id="user-data">
        <nav className="navbar navbar-expand bg-dark">
            <ul className="navbar-nav col-lg-12">
                <NavItem className={"nav-item active col-lg-4"}>
                    <NavLink
                        className={classnames({active: activeTab === "1"})}
                        onClick={(e) => {
                            handleActiveNav(e);
                            toggleTab("1");
                        }}
                    >
                        {strings.my_certificates}
                    </NavLink>
                </NavItem>
                <NavItem className={"nav-item col-lg-4"}>
                    <NavLink
                        className={classnames({active: activeTab === "2"})}
                        onClick={(e) => {
                            handleActiveNav(e);
                            toggleTab("2");
                        }}
                    >
                        {strings.my_documents}
                    </NavLink>
                </NavItem>
                <NavItem className={"nav-item col-lg-4"}>
                    <NavLink
                        className={classnames({active: activeTab === "3"})}
                        onClick={(e) => {
                            handleActiveNav(e);
                            toggleTab("3");
                        }}
                    >
                        {strings.my_forthcoming_exams}
                    </NavLink>
                </NavItem>
            </ul>
        </nav>
        <TabContent activeTab={activeTab} className="productTabs">
            <TabPane tabId="1">
                <Row>
                    <Col sm="12">
                        <Card body className="card-plain">
                            <h4>{strings.certificates}</h4>
                            {/*<CardText>*/}
                            <div className='criteria'
                                 style={{overflowY: "auto", overflowX: "hidden", maxHeight: "12rem"}}>
                                <ul>
                                    {certificates.map((certificate) => {
                                        console.log('Certificate Data');
                                        console.log(certificate);
                                        console.log(currentItem);
                                        // added key to prevent warnings
                                        return <li className="py-3 border-bottom" style={{minHeight: "4rem"}}
                                                   key={certificate.date}>
                                            <Row className={'align-items-center'}>
                                                <Col lg="5">{certificate.message}
                                                </Col>
                                                <Col lg="3">
                                                    <label
                                                        className={"badge badge-" + certificate.badge_type}>{certificate.status}</label>
                                                </Col>
                                                <Col lg="2">
                                                    {certificate.date}
                                                </Col>
                                                <Col lg="2">
                                                    <Button href={certificate.link}
                                                    className="btn-round btn-outline-dark text-dark ml-auto mr-auto">{strings.view}</Button>
                                                </Col>
                                            </Row>
                                        </li>
                                    })}
                                </ul>
                            </div>
                            {/*</CardText>*/}
                            <Button href="/landing-page#certification"
                                    className=" btn-round btn-outline-dark text-dark ml-auto mr-auto col-4">{strings.enroll_now}</Button>
                        </Card>
                    </Col>
                </Row>
            </TabPane>
            <TabPane tabId="2">
                <Row>
                    <Col sm="12">
                        <Card body className="card-plain">
                            <CardTitle><h4>{strings.documents}</h4></CardTitle>
                            {/*<CardText>There are several criteria needed:*/}
                            <div className='criteria'>
                                <DocumentsIndex documents={documents} profilePage={true} user={user} setMessage={message => setMessage(message)}/>
                                {/*<Form className="upload-document-form border-dark" onSubmit={handleSubmitDocs}>*/}
                                <Form className="upload-document-form border-dark" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-row">
                                        {state.currentItem.data &&
                                        <Input name="model_id" type="hidden" value={state.currentItem.data.id}
                                               innerRef={register({required: true})}/>
                                        }
                                        {/*<FormGroup>
                                        <Button className="btn-just-icon btn btn-link" color="success" outline>
                                            <i className="nc-icon nc-simple-add" color="success" />
                                        </Button>
                                    </FormGroup>*/}
                                        <FormGroup className="col-md-12 documentGroup border-dark">
                                            <DocumentsArea profilePage={true} user={user} isActive={true}
                                                           userFiles={userFiles}
                                                           setFiles={setFiles} index={0} register={register}
                                                           errors={errors} isReadOnly={isReadOnly}/>
                                        </FormGroup>
                                    </div>

                                </Form>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </TabPane>
            <TabPane tabId="3">
                <Row>
                    <Col sm="12">
                        <Card body className="card-plain">
                            <CardTitle><h4>{strings.forthcoming_exams}</h4></CardTitle>
                            {/*<span>Schedule</span>*/}
                            <div className='schedule'>
                                <ul>
                                    {exams.map((item, key) => {
                                        // added key to prevent warnings
                                        return <li className="py-3 border-bottom" style={{minHeight: "4rem"}}
                                                   key={item.id}>
                                            <Row>
                                                <Col lg="3">{item.title}</Col>
                                                <Col lg="3">{item.date} <h6>{item.start_time}</h6></Col>
                                                <Col lg="4" className={'text-left '}>
                                                    <label
                                                        className={"badge badge-" + item.badge_type}>{item.status}</label>
                                                </Col>
                                                <Col lg="2" className={'text-left '}>
                                                    <a target={'_blank'} href={'/enrollment-page/'+item.enrollment_id}>
                                                        <button color={"tuv_red"}
                                                                className={'btn-round'}>{strings.view_item}
                                                        </button>
                                                    </a>
                                                </Col>
                                            </Row>
                                        </li>
                                    })}
                                </ul>
                            </div>
                            <Button href="#enroll-now">Go somewhere</Button>
                        </Card>
                    </Col>
                </Row>
            </TabPane>
        </TabContent>
    </Row>);
}

export default UserData;
