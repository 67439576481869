import {Button, Col, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import React, {useContext, useEffect} from "react";
import EntitiesContext from "../../../app/context";
import InputWithType from "../InputWithType";
import CertificationActivities from "../CertificationActivities.js";
import CertificationType from "../CertificationType";
import {useForm} from "react-hook-form";
import Modal from "../../Modals/Modal";
import File from "../File";
import strings from "../../../app/translations";
import TooltipWrap from "../TooltipWrap";
import OverlayLoader from "../../Loaders/OverlayLoader";

export default function CertificationForm({disabledFields}) {
    const {state, dispatch} = useContext(EntitiesContext);
    const pagePathname = '/admin/certification-page';
    const editMode = (window.location.pathname !== pagePathname)
    const [activeTab, setActiveTab] = React.useState('1');
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    const disableActivities = editMode && !['sys_admin'].includes(window.reactInit.user_role);

    disabledFields.map(field => {

    })
    const inputWithType = {
        input: "durationQuantity",
        type: "durationTypeSelect",
        typeValues: [{name: strings.duration_months, value: "m"}, {name: strings.duration_years, value: "y"}, {
            name: strings.duration_unlimited,
            value: "n/a"
        }],
        updateInput: "certification_duration"
    };
    const accreditors = state.accreditors;
    const categories = state.categories.objects.map(object => object.data);
    // console.log("categories");
    // console.log(categories);
    const countries = state.countries;
    const [certificationType, setCertificationType] = React.useState('theoretical');
    // console.log(certificationType);
    // Overlay Loader
    const [loading, setLoading] = React.useState(false);
    //modal constants and function
    const [modalShow, setModalShow] = React.useState(false);
    const [message, setMessage] = React.useState([]);
    const [action, setAction] = React.useState('');

    function showModal(data) {
        let msg = [];
        if (data.errors) {
            Object.values(data.errors).map(i => i.map(m => {
                return msg.push(m)
            }));
        } else if (data.service_error) {
            msg.push(data.service_error.title);
            msg.push(data.service_error.message);
        } else {
            // console.log('data')
            // console.log(data.records)
            Object.values(data.records).map((record, key) => {
                // console.log('key');
                // console.log(Object.keys(data.records)[key]);
                let type;
                let objKey = Object.keys(data.records)[key];
                type = (key.length > 1) ? key : '';
                if (objKey === "certification_activities") {
                    // debugger;
                    msg.push(strings.and_activities);
                    console.log(record);
                    record.data.map((activity, k) => {
                        // console.log('activity');
                        // console.log(activity);
                        msg.push(strings.formatString(strings.activity_with_name, {
                            type: activity.type, name: activity.name
                        }));
                    })
                } else if (objKey === "certification_details") {
                    // msg.push(`The detail ${record.data.title_el} with title_gr ${record.data.title_el}`);
                    msg.push('');
                } else
                    msg.push(strings.formatString(strings.certification_title_with_code, {
                        title: record.data.title, code: record.data.code
                    }));
                return msg
            });
            if (editMode)
                msg.push(strings.edited_success);

            else
                msg.push(strings.saved_success);
            }

        /*console.log('msg');
        console.log(msg);*/


        let act = null;
        if (editMode)
            act = {link: pagePathname, label: strings.continue};
        else
            act = (data.errors) ? ('error') : {link: pagePathname, label: strings.continue};

        setAction(act);
        setMessage(msg);
        setModalShow(true);
        $('#appModal').modal({show: true});
        setLoading(false);
        // console.log('show');
        // console.log(msg);
    }


    const [route, setRoute] = React.useState('/api/certification/create');
    const {register, handleSubmit, watch, errors} = useForm();
    const onSubmit = (data, event) => {
        // console.log('data sent in handlesubmit');
        // console.log(data);
        setLoading(true);
        state.handleSubmit(data, route, showModal, event)
    };

    const isReadOnly = (name) => {
        if (editMode)
            return disabledFields.indexOf(name) !== false
    }
    const currentItem = state.currentItem;
    // console.log('currentItem');

    function showNumOfActivities(activitiesNumber){

        let number_of_activities = (activitiesNumber > 2) ? 2 : activitiesNumber;
        let options = document.getElementById('certification_number_of_activities').options;
        for (let i = 0; i < options.length; i++) {
            // console.log('number_of_activities');
            // console.log(number_of_activities);

            // console.log(options[i].value);
            if (parseInt(options[i].value) === parseInt(number_of_activities)) {
                // console.log('selectedIndex' +i);
                document.getElementById('certification_number_of_activities').selectedIndex = i;
            }
        }
    }

    function loadCurrentItem() {

        if (currentItem.data) {
            console.log('loaded item');
            console.log(currentItem.data);
            console.log(currentItem.data.category_id);
            var select = document.getElementById('certification_category_id');
            Array.from(select.options).map((option,key)=>{
                if(parseInt(option.value) === parseInt(currentItem.data.category_id)){
                    select.selectedIndex =key;
                }
            });
            document.getElementById('certification_title').value = currentItem.data.title;
            document.getElementById('certification_type').value = currentItem.data.type;
            document.getElementById('certification_price').value = currentItem.data.price;
            document.getElementById('open_date').checked = currentItem.data.open_date;
            document.getElementById('general_regulation').checked = currentItem.data.general_regulation;
            document.getElementById('specific_regulation').checked = currentItem.data.specific_regulation;
            document.getElementById('certification_certified_by').value = currentItem.data.accreditor;
            document.getElementById('is_remote').checked = currentItem.data.is_remote;
            document.getElementById('certification_country').value = currentItem.data.country;
            let countrySelect = document.getElementById('certification_country');
            Array.from(countrySelect.options).map((option,key)=>{
                if(option.value === currentItem.data.country){
                    countrySelect.selectedIndex =key;
                }
            });
            //num of activities
            showNumOfActivities(currentItem.data.number_of_activities);

            //duration inputs
            var durationSelect = document.getElementById('durationTypeSelect');
            Array.from(durationSelect.options).map((option,key)=>{
                if(option.value === currentItem.data.duration.replace(/\d/g,'')){
                    durationSelect.selectedIndex =key;
                }
            });
            // console.log(currentItem.data);
            document.getElementById('durationQuantity').value = parseInt(currentItem.data.duration);
            let duration_split = currentItem.data.duration.split(' ');
            let durationType = '';
            if (duration_split.length > 1)
                durationType = duration_split[duration_split.length - 1].charAt(0);
            else
                durationType = currentItem.data.duration.charAt(currentItem.data.duration - 1);

            let durationTypeOptions = document.getElementById('durationTypeSelect').options;
            for (let i = 0; i < durationTypeOptions.length; i++) {
                // console.log(durationTypeOptions[i].value);
                // console.log(durationType);
                if (durationTypeOptions[i].value === durationType) {
                    document.getElementById('durationTypeSelect').selectedIndex = i;
                }
                document.getElementById('certification_duration').value = currentItem.data.duration
            }
            //
            currentItem.data.details.map(detail => {
                console.log(detail.input_name);
                document.getElementById(`certDetail_${detail.input_name}_${detail.lang}`).value = detail.value;
            });
            document.getElementById('certification_code').value = currentItem.data.code;

            setCertificationType(currentItem.data.type);
            document.getElementById('certification_certified_by').value = currentItem.data.certified_by;
            setLoading(false);
        }
        // console.log('load end');
        // console.log(currentItem);
    }

    useEffect(function () {

        if (editMode){
            setLoading(true);
            loadCurrentItem();
            setRoute('/api/certification/edit');
        }

        // console.log(certificationType);
    },[currentItem]);
    // useEffect(function () {
    //     // console.log('update certification type');
    //     setCertificationType(certificationType);
    //     // console.log(certificationType);
    // }, [certificationType]);
    return (
        <>
        <OverlayLoader active={loading} text={strings.load}>
            <form className="pt-5 pb-3" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row col-md-12">

                    <div className="form-group col-md-6">
                        <label htmlFor="certification_category">{strings.schema}</label>
                        {/*<input type="text" className="form-control" id="category"
                           placeholder="Σχήμα Πιστοποίησης"/>*/}
                        <select className="form-control" id="certification_category_id" name="certification_category_id"
                                aria-readonly={isReadOnly("certification_category_id")}
                                ref={register({required: (!isReadOnly("certification_category_id"))})}>
                            {currentItem && currentItem.data.category_id &&
                            categories.filter(category => category.id === currentItem.data.category_id).map((category, key) => {
                                return <option key={key} value={category.id}>{category.name}</option>
                            })}
                            }
                            {!currentItem && <option>{strings.choose}</option>}
                            {!currentItem &&  categories.map((category, key) => {
                                return <option key={key} value={category.id}>{category.name}</option>
                            })}
                        </select>
                        {errors.certification_category_id &&
                        <span className="validation-error">{strings.required_validation}</span>}
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="certification_title">{strings.specialty}</label>
                        <input type="text" className="form-control" id="certification_title" name="certification_title"
                               readOnly={(isReadOnly("certification_title"))}
                               ref={register({required: (!isReadOnly("certification_title"))})}
                               placeholder={strings.specialty}/>
                    </div>
                    {errors.certification_title && <span className="validation-error">{strings.required_validation}</span>}
                </div>
                <div className="form-row col-md-12">
                    <CertificationType setType={setCertificationType} register={register} disableActivities={disableActivities}/>
                    <div className="form-group col-md-3">
                        <label htmlFor="certification_country">{strings.country}</label>
                        <select className="form-control" id="certification_country" name="certification_country"
                                ref={register({required: true})}>
                            <option>{strings.choose}</option>
                            {countries.map((country, key) => {
                                return <option key={key} value={country.name}>{strings[country.name.toLowerCase()]}</option>
                            })}
                        </select>
                        {errors.certification_country &&
                        <span className="validation-error">{strings.required_validation}</span>}
                    </div>
                    <div className="form-group col-md-3">
                        <label htmlFor="certification_price">{strings.price}</label>
                        <input type="text" className="form-control" id="certification_price" name="certification_price"
                               ref={register({required: true})}
                               placeholder={strings.price_in_euro}/>
                        {errors.certification_price && <span className="validation-error">{strings.required_validation}</span>}
                        {currentItem && <TooltipWrap target={"certification_price"} content={
                            <>
                                <p style={{color:"red"}}>{ strings.warning_price_update }</p>
                            </>
                        }/>}
                    </div>
                    {/*<div className="form-row col-md-2 text-left">
                        <FormGroup className="col-md-12" check>
                            <Label for="general_regulation" check>
                                <Input id="general_regulation" type="checkbox"/>{" "}
                                {strings.general_regulation}
                                <span className="form-check-sign">
                                                    <span className="check"></span>
                                                </span>
                            </Label>
                        </FormGroup>
                        <FormGroup className="col-md-12" check>
                            <Label for="specific_regulation" check>
                                <Input id="specific_regulation" type="checkbox"/>{" "}
                                {strings.specific_regulation}
                                <span className="form-check-sign">
                                                    <span className="check"></span>
                                                </span>
                            </Label>
                        </FormGroup>
                    </div>*/}
                    <div className="form-group col-md-4">
                        <InputWithType input={inputWithType.input} type={inputWithType.type} register={register}
                                       typeValues={inputWithType.typeValues} updateInput={inputWithType.updateInput}/>
                        {errors.hasOwnProperty(`${inputWithType.updateInput}`) &&
                        <span className="validation-error">{strings.required_validation}</span>}

                    </div>
                </div>
                <CertificationActivities lockType={certificationType} setLockType={setCertificationType}
                                         currentItem={currentItem} register={register} showNumOfActivities={showNumOfActivities}
                                         errors={errors} disableActivities={disableActivities}/>
                {errors.certification_number_of_activities && <span className="validation-error">{strings.required_validation}</span>}
                <div className="form-row col-md-12">
                    <div className="form-group col-md-12">
                        <Nav tabs className="justify-content-center">
                            <NavItem>
                                <NavLink
                                    className={classnames({active: activeTab === '1'})}
                                    onClick={() => {
                                        toggle('1');
                                    }}
                                >
                                    {strings.details} GR
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({active: activeTab === '2'})}
                                    onClick={() => {
                                        toggle('2');
                                    }}
                                >
                                    {strings.details} EN
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1" className={"mt-5"}>
                                <Row>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="title_el">{strings.title}</label>
                                        <input type="text" className="form-control" id="certDetail_title_el"
                                               name="certDetail_title_el" ref={register({required: true})}
                                               placeholder={strings.specialty} defaultValue="Τίτλος" />
                                        {errors.certDetail_title_el &&
                                        <span className="validation-error">{strings.required_validation}</span>}
                                    </div>
                                    <Col md="12">
                                        <label htmlFor="audience_el">{strings.audience}</label>
                                        <textarea className="col-md-12" id="certDetail_audience_el"
                                                  name="certDetail_audience_el" defaultValue="Απευθυνόμενοι"
                                                  ref={register({required: true})}></textarea>
                                        {errors.certDetail_audience_el &&
                                        <span className="validation-error">{strings.required_validation}</span>}
                                    </Col>
                                    <Col md="12">
                                        <label htmlFor="criteria_el">{strings.criteria}</label>
                                        <textarea className="col-md-12" id="certDetail_criteria_el"
                                                  name="certDetail_criteria_el" defaultValue="Κριτήρια"
                                                  ref={register({required: true})}></textarea>
                                        {errors.certDetail_criteria_el &&
                                        <span className="validation-error">{strings.required_validation}</span>}
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2" className={"mt-5"}>
                                <Row>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="exampleFormControlInput1">{strings.title}</label>
                                        <input type="text" className="form-control" id="certDetail_title_en"
                                               name="certDetail_title_en" ref={register({required: false})}
                                               placeholder={strings.title} defaultValue="Certification Title"/>
                                        {errors.certDetail_title_en &&
                                        <span className="validation-error">{strings.required_validation}</span>}
                                    </div>
                                    <Col md="12">
                                        <label htmlFor="audience_en">{strings.audience}</label>
                                        <textarea className="col-md-12" id="certDetail_audience_en"
                                                  name="certDetail_audience_en" ref={register({required: false})}
                                                  defaultValue="This is about many things"></textarea>
                                        {errors.certDetail_audience_en &&
                                        <span className="validation-error">{strings.required_validation}</span>}
                                    </Col>
                                    <Col md="12">
                                        <label htmlFor="criteria_en">{strings.criteria}</label>
                                        <textarea className="col-md-12" id="certDetail_criteria_en"
                                                  name={"certDetail_criteria_en"} defaultValue="Criteria"
                                                  ref={register({required: false})}></textarea>
                                        {errors.certDetail_criteria_en &&
                                        <span className="validation-error">{strings.required_validation}</span>}
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>

                    </div>
                </div>
                <div className="form-row col-md-12 text-center">


                        <FormGroup className="col-md-12 form-row">
                            <label htmlFor="certImage_file">{strings.certification_image}</label>
                            <input type="file" className="form-control" name="certImage_file" id="certImage_file"
                                   ref={register({required: false })} />
                            {errors.certImage_file}
                        </FormGroup>


                    <div className="form-group col-md-6">
                        <label htmlFor="certification_certified_by">{strings.certification_certified_by}</label>
                        <select className="form-control" id="certification_certified_by"
                                name="certification_certified_by"
                                ref={register({required: true})}>
                            <option value="-1">{strings.accreditation_body}</option>
                            {accreditors.map((accreditor, key) => {
                                return <option key={key} value={accreditor.name}>{accreditor.name}</option>
                            })}
                        </select>
                        {errors.certification_certified_by &&
                        <span className="validation-error">{strings.required_validation}</span>}
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="certification_code">Permalink</label>
                        <input type="text" className="form-control" name="certification_code" id="certification_code"
                               ref={register({required: (!isReadOnly("certification_code")),
                               pattern: /^[0-9][0-9][0-9]$/, minLength: 3,maxLength:3})}
                               /*readOnly={(isReadOnly("certification_code"))}*/
                               placeholder="perma-link"/>
                        {errors.certification_code && errors.certification_code.type==='required' && <span className="validation-error">{strings.required_validation}</span>}
                        {errors.certification_code && errors.certification_code.type==='pattern' && <span className="validation-error">{strings.pattern_validation}</span>}
                        {errors.certification_code && errors.certification_code.type==='minLength' && <span className="validation-error">{strings.permalink_size_validation}</span>}
                        {errors.certification_code && errors.certification_code.type==='maxLength' && <span className="validation-error">{strings.permalink_size_validation}</span>}
                        <TooltipWrap target={"certification_code"} content={
                            <>
                                <p>- Must be numeric characters.</p>
                                <p>- Must be exactly 3 characters.</p>
                                <p>- May contain 3 digits examples: 001,123,321.</p>
                            </>
                        }/>
                    </div>
                </div>
                <div className="form-row col-md-12 text-center">
                    <FormGroup className="col-3" check>
                        <Label for="is_remote" check>
                            <Input id="is_remote" name="is_remote" className="form-check-input" type="checkbox"
                                   innerRef={register({required: false})}
                            />{" "}
                            {strings.remote}
                            <span className="form-check-sign">
                                                    <span className="check"></span>
                                                </span>
                        </Label>
                    </FormGroup>
                    <FormGroup className="col-3" check>
                        <Label for="open_date" check>
                            <Input id="open_date" name="open_date" type="checkbox" innerRef={register({required: false})}/>{" "}
                            {strings.open_date}
                            <span className="form-check-sign">
                                                    <span className="check"></span>
                                                </span>
                        </Label>
                    </FormGroup>
                    <FormGroup className="col-3" check>
                        <Label for="general_regulation" check>
                            <Input id="general_regulation" name="general_regulation" type="checkbox" innerRef={register({required: false})}/>{" "}
                            {strings.general_regulation}
                            <span className="form-check-sign">
                                                    <span className="check"></span>
                                                </span>
                        </Label>
                    </FormGroup>
                    <FormGroup className="col-3" check>
                        <Label for="specific_regulation" check>
                            <Input id="specific_regulation" name="specific_regulation" type="checkbox" innerRef={register({required: false})}/>{" "}
                            {strings.specific_regulation}
                            <span className="form-check-sign">
                                                    <span className="check"></span>
                                                </span>
                        </Label>
                    </FormGroup>
                </div>
                <Button block className="btn-round offset-md-2 col-md-8 mt-5" color="tuv_red">
                    {strings.save}
                </Button>
            </form>
        </OverlayLoader>
            <Modal action={action} message={message} title={strings.certification_status}/>
        </>
    )
}
