import {NavItem, NavLink} from "reactstrap";
import React,{useContext, useReducer} from "react";
import EntitiesContext from "../../app/context";
import CRUDReducer from "../../app/reducer";
import strings from "../../app/translations";
export default function ProfileNavItem() {
    const initialState = useContext(EntitiesContext);
    const [state/*,dispatch*/] = useReducer(CRUDReducer,initialState);
    const [cssFilter,setCssFilter] = React.useState("initial");

    if(window.reactInit.username){
        return(
            <NavItem style={{position: "relative"}}>
                <NavLink href="/user-profile"
                         target="_blank"
                         id="navbarDropdownMenuLink"
                         data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                    <img alt="profile" width="15rem" style={{marginRight: "0.5rem",filter:cssFilter}}
                         src={require(`../../assets/img/mockups/icon-awesome-user.svg`)}/>
                        {window.reactInit.username}
                </NavLink>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <a className="dropdown-item" href="/user-profile">{strings.profile}</a>
                    <a className="dropdown-item" href="/logout">{strings.logout}</a>
                </div>
            </NavItem>
        )
    } else {
        return (
            <NavItem>
                <NavLink href="/login-page">
                    <img alt="login" width="15rem" style={{marginRight: "0.5rem",filter:cssFilter}}
                         src={require(`../../assets/img/mockups/credit-card.svg`)}/>
                    {/*<i className="nc-icon nc-layout-11"/>*/} {strings.login.stripAccents()}
                </NavLink>
            </NavItem>
        )
    }
}
