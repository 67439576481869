import React, {useEffect} from "react";
import {Button, FormGroup, Input, Label} from "reactstrap";
import ActivitiesOptions from "./ActivitiesOptions";
import ActivityTag from "./ActivityTag";
import strings from "../../app/translations";
import TooltipWrap from "./TooltipWrap";


export default function CertificationActivitiesContent({lockType,setLockType,selectStatus, currentItem,activity,register,errors, index, disableActivities}) {
    //const [defChecked,setDefChecked] = React.useState(true);
    const [defCheckedInternal,setDefCheckedInternal] = React.useState(true);
    const [defCheckedExternal,setDefCheckedExternal] = React.useState(false);

    const toggleChecked = (e)=> {
        if(e.target.value=== 'internal'){
            setDefCheckedInternal(true);
            setDefCheckedExternal(false)
        }
        else if(e.target.value === 'external'){
            setDefCheckedInternal(false);
            setDefCheckedExternal(true)
        }

    };

    if ((lockType === "P" && activity.type === 'practical') || (lockType === "T" && activity.type === 'theoretical') || lockType === "B" || activity.type === null){
        return (<div key={index} className="form-row">
            <input type="text" className="form-control col-4" id={"activity_name_" + index}
                   name={"activity_name_" + index} defaultValue={activity.name} ref={register({required: true})}
                   placeholder={activity.name} readOnly={selectStatus}/>
            {errors.hasOwnProperty(`${"activity_name_" + index} `) &&
            <span className="validation-error">{strings.required_validation}</span>}
            <TooltipWrap target={"activity_name_" + index} key={index} content={
                <>
                    <p>- {strings.tooltip_activity_name_1}</p>
                    <p>- {strings.tooltip_activity_name_2}</p>
                </>
            }/>
            <div className="form-row col-8 text-center">

                <ActivitiesOptions col="4" index={index} lockType={lockType} setLockType={setLockType} register={register}
                                   errors={errors} readOnly={selectStatus}/>
                <FormGroup className="col-md-8" check id={"tags_div_" + index}>
                    <div className="form-row form-check-radio">
                        <Label className="form-check-label" style={{height: "3rem"}}>
                            <Input type="radio" name={"activity_system_" + index} id={"internal_" + index}
                                   innerRef={register({required: true})} disabled={disableActivities}
                                   value="internal" onClick={toggleChecked}
                                   defaultChecked={activity.system === 'internal'}/>
                            Internal
                            <span className="form-check-sign"></span>
                        </Label>
                        {defCheckedInternal === true && <ActivityTag key={index} index={index} type={activity.system}
                                                                     defChecked={defCheckedInternal || activity.system === 'internal'}
                                                                     register={register} tag={(activity.tag)?activity.tag:""}/>}
                    </div>
                    <div className="form-row form-check-radio">
                        <Label className="form-check-label" style={{height: "3rem"}}>
                            <Input type="radio" name={"activity_system_" + index} id={"external" + index}
                                   innerRef={register({required: true})}  disabled={disableActivities}
                                   value="external" onClick={toggleChecked}
                                   defaultChecked={activity.system === 'external'}/>
                            External
                            <span className="form-check-sign"></span>
                        </Label>
                        {defCheckedExternal === true &&
                        <ActivityTag index={index} defChecked={defCheckedExternal || activity.system === 'external'} type={activity.system}
                                     register={register} tag={activity.tag}/>}
                    </div>
                    <TooltipWrap target={"tags_div_" + index} content={
                        <>
                            <p>{strings.tooltip_activity_tag_1}</p>
                            <p>{strings.tooltip_activity_tag_2}</p>
                        </>
                    }/>
                </FormGroup>
            </div>
        </div>)}
    else {
            // console.log('lockType');
            // console.log(lockType);
            // console.log('activity.type');
            // console.log(activity.type);
        return (<></>)};
}
