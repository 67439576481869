/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {Col,Row,Container, Nav, Navbar, NavItem, NavLink} from "reactstrap";

//icon components
import NextArrow from "../arrows/NextArrow";
import strings from "../../app/translations";


// nodejs library that concatenates strings

const navItems = [
    {id:"company-nav",href:"/admin/company-page", label:strings.companies, icon:"team.svg"},
    {id:"certification-nav",href:"/admin/certification-page", label:strings.certifications, icon:"icon-certifications.svg"},
    {id:"examination-nav",href:"/admin/examination-page", label:strings.examinations, icon:"checklist.svg"},
    /*{id:"enrollment-nav",href:"/admin/enrollment-page", label:strings.enrollments, icon:"clipboard.svg"},
    {id:"payments-nav",href:"/admin/payment-page", label:strings.payments, icon:"credit-card.svg"},*/
];


function AdminNavbar(props) {
    const {adminPage} = props;
    const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
    const [cssFilter,setCssFilter] = React.useState("invert(1)");
    /*const [navbarCollapse, setNavbarCollapse] = React.useState(false);

    const toggleNavbarCollapse = () => {
        setNavbarCollapse(!navbarCollapse);
        document.documentElement.classList.toggle("nav-open");
    };*/

    React.useEffect(() => {
        const updateNavbarColor = () => {
            if (
                document.documentElement.scrollTop > 299 ||
                document.body.scrollTop > 299
            ) {
                setNavbarColor("");

            } else if (
                document.documentElement.scrollTop < 300 ||
                document.body.scrollTop < 300
            ) {
                setNavbarColor(navbarColor);
            }
        };

        window.addEventListener("scroll", updateNavbarColor);

        return function cleanup() {
            window.removeEventListener("scroll", updateNavbarColor);
        };
    });
    return (
        <Col className="px-0">
        <Navbar
            color-on-scroll="300"
            /*expand="lg"*/
            className="bg-body"
            xs="12"
            style={{minHeight:"240px"}}
        >
            <Col xs="12"  className="px-0 mt-1">
                <Nav navbar vertical  xs="12">
                    {navItems.map((item,key)=>{
                        // console.log(adminPage + " " + item.label);
                        let activeClass = (adminPage.toLowerCase() === item.label.toLowerCase())?"active":"";
                        return (<NavItem key={key}>
                            <NavLink href={item.href} id={item.id} className={`btn btn-round btn-admin ${activeClass}`}>
                                <img alt={item.label} width="15rem" style={{marginRight: "0.5rem",filter:cssFilter}}
                                     src={require(`../../assets/img/mockups/${item.icon}`)}/> {item.label}
                                <span className="float-right">
                                     <NextArrow />
                                </span>
                            </NavLink>
                        </NavItem>)
                    })
                }
                </Nav>

            </Col>
        </Navbar>
        </Col>
    );
}

export default AdminNavbar;
