import {Card, CardTitle, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import Announcements from "../../../components/Sections/Announcements";
import TableApp from "../../../components/Datatable/TableApp";
import React, {useContext, useEffect, useState} from "react";
import EntitiesContext from "../../../app/context";
import EnrollmentInternal from "../EnrollmentInternal";
import CertificationForm from "../../../components/Forms/admin/CertificationForm";
import classnames from "classnames";
import strings from "../../../app/translations";

export default function EnrollmentMain() {
    const user_role = window.reactInit.user_role;
    const {state/*,dispatch*/} = useContext(EntitiesContext);
    // console.log('state.enrollments.objects');
    // console.log(state.enrollments.objects);
    state.enrollments.objects = Array.from(state.enrollments.objects);

    const [objects,setObjects] = useState(state.enrollments.objects.map(object => {
        // console.log(object);
        object.width = "80px";
        if(object.data){
            object.data.action = state.actionButton(object,false,false,false);
            return  object.data;
        }
        return object;
    }));
    const [participations,setParticipations] = useState(state.participations.objects.map(object => {
        object.width = "80px";
        if(object.data){
            object.data.action = state.actionButton(object,false,false,true);
            return  object.data;
        }
        return object;
    }));
    // console.log('objects');
    // console.log(objects);
    // console.log('participations');
    // console.log(participations);
    const participationColumns = state.participations.columns;
    // console.log('participationColumns');
    // console.log(participationColumns);
    /*state.enrollments.columns.push({
        title: 'More...',
        width: 150,
        className: 'details-control',
        orderable: false,
        data: null,
        defaultContent: ''
    });*/





    const columns =  state.enrollments.columns;
    const currentItem = state.currentItem;

    const [tableWidth ,setTableWidth ] = useState(state.certifications.tableWidth);
    const [sectionTab, setSectionTab] = React.useState('1');
    const sectionToggle = tab => {
        if (sectionTab !== tab) setSectionTab(tab);
    };

    useEffect(() => {
            setObjects(state.enrollments.objects.map(object => {
                // console.log(object);
                return object.data
            }));
            setParticipations(state.participations.objects.map(object => {
                    return  object.data;
            }));
        }
        , [state]);
    return (

        <div className="section text-center align-page-header" style={{backgroundColor: "transparent"}}>
            <div className="">
                <div className="custom-column row">
                    <Announcements/>
                    <Row className="custom-column">
                        <Col id={"enrollment-participation"}>
                            <div className="form-group col-md-12">
                                {user_role !== ['administrator'] && (typeof currentItem === 'undefined'||currentItem === null || currentItem.length === 0) &&
                                <Nav tabs className="justify-content-center">
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: sectionTab === '1'})}
                                            onClick={() => {
                                                sectionToggle('1');
                                            }}><h4>{strings.enrollments}</h4>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: sectionTab === '2'})}
                                            onClick={() => {
                                                sectionToggle('2');
                                            }}><h4>{strings.participation_forms}</h4>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                }
                                <TabContent activeTab={sectionTab}>
                                    <TabPane tabId="1">
                                        <Row className="custom-column">
                                            <Col className="ml-auto mr-auto offset-lg-1" lg="12">
                                                <Row style={{display: "block"}}>
                                                    <Col sm="12">
                                                        {(typeof currentItem === 'undefined' || currentItem === null || currentItem.length === 0) &&
                                                        <Card body className="card-plain">
                                                            <CardTitle><h3
                                                                className="text-danger ml-auto mr-auto">{state.enrollments.cardTitle}</h3>
                                                            </CardTitle>
                                                            {strings.here_is_a_list} {strings.enrollments}
                                                            <div className='criteria col-12'>
                                                                <h3 className="text-danger mb-1"></h3>
                                                                <div className="col-md-12">
                                                                    {sectionTab === '1' && <TableApp
                                                                        names={objects} columns={columns}/>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Card>
                                                        }
                                                        <EnrollmentInternal currentItem={currentItem}/>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <Row className="custom-column">
                                            <Col className="ml-auto mr-auto offset-lg-1" lg="12">
                                                <Row style={{display: "block"}}>
                                                    <Col sm="12">
                                                        {(typeof currentItem === 'undefined'  || currentItem === null ||currentItem.length === 0) &&
                                                        <Card body className="card-plain">
                                                            <CardTitle><h3
                                                                className="text-danger ml-auto mr-auto">Participations</h3>
                                                            </CardTitle>
                                                            Participations for Approval
                                                            <div className='criteria'>
                                                                <h3 className="text-danger mb-1"></h3>
                                                                <div className="col-md-12">
                                                                    {sectionTab === '2' &&
                                                                    <TableApp
                                                                        names={participations} columns={participationColumns}/>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Card>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                    </TabPane>
                                </TabContent>

                            </div>
                        </Col>

                    </Row>


                </div>
            </div>
            <div className="section section-cards section-dark text-center" id="enroll-now"
                 style={{paddingTop: "15rem"}}>
                <Container>
                    <Row id="participation-form">
                        <Col className="ml-auto mr-auto " lg="8">

                        </Col>
                    </Row>
                </Container>

                <div className="footer register-footer text-center">
                    <h6>
                        © {new Date().getFullYear()}, made with{" "}
                        <i className="fa fa-heart heart"/> by WIDE Services
                    </h6>
                </div>
            </div>
        </div>)
}
