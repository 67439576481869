/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useReducer, useContext, useState} from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// reactstrap components
import {Card, CardTitle, Col, Container, Row,} from "reactstrap";
// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import ViewVariables from "../../components/Middleware/ViewVariables";
import OrderMain from "./main/OrderMain";
import EntitiesContext from "../../app/context";
import CRUDReducer from "../../app/reducer";
import Cookie from 'universal-cookie';
import axios from "axios";
import UserProfileMain from "./main/UserProfileMain";
import strings from "../../app/translations";
import AuthoriseAccess from "../../components/AuthoriseAccess";


function OrderPage() {

    const isVerified = window.reactInit.verified === 'true';

    const cookie = new Cookie;
    const initialState = useContext(EntitiesContext);
    const [state, dispatch] = useReducer(CRUDReducer, initialState);
    const pagePathname = '/order-page';
    const editMode = (window.location.pathname !== pagePathname);
    const [results, setResults] = useState([]);
    //const [currentItem,setCurrentItem] = useState(state.currentItem);
    const [language, setLanguage] = useState(getLanguage());
    const [objects, setObjects] = useState(state.orders.objects.map(object => {
        // if(window.reactInit.user_id===object.data.orderer_id) {
        //     object.data.action = state.actionButton(object, false, false, false);
        // } else {
        console.log(object.data);
        if(object.data.orderer_id===parseInt(window.reactInit.user_id) && object.data.order_status===0) {
                object.data.action = state.actionButton(object, false, false, false);
        } else {
            object.data.action = '';
        }
        object.data.order_status = object.data.order_status===0?strings.order_unpaid:strings.order_paid;
        return object.data;
    }));


    function getLanguage() {
        let lang = cookie.get('language_set') ?? 'en';
        //dispatch({type:"UPDATE_LANGUAGE",payload:lang});
        return lang;
    }

    const getResults = async () => {
        const response = await axios.get("/order/get");
        //console.log("data");
        // console.log(response.data);
        let temp = [];
        if (typeof response.data.enrollments === "object")
            response.data.orders = Object.values(response.data.orders);
        dispatch({type: "GET_ORDERS", payload: response.data});
    };
    // const getItem = async () => {
    //     let url = window.location.pathname;
    //     url = '/order/get/item' + url.substr(url.lastIndexOf("/") );
    //     const response = await axios.get(url);
    //     dispatch({type:"UPDATE_CURRENT_ITEM",payload:response.data});
    //     setCurrentItem(response.data);
    //     // console.log(currentItem);
    // };
    const getAnnouncements = async () => {
        // console.log('loading announcements');
        let url = window.location.pathname;
        url = '/get/announcements/order-page';
        // console.log('url');
        // console.log(url);
        const response = await axios.get(url);
        dispatch({type: "UPDATE_ANNOUNCEMENTS", payload: response});
        //setCurrentItem(response.data);
    };

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        // console.log(document.location.pathname);

        document.body.classList.add("register-page");
        return function cleanup() {
            document.body.classList.remove("register-page");
        };
    });
    React.useEffect(() => {
            if (isVerified || window.reactInit.user_role === 'admin') {
                getResults();
                getAnnouncements();
                //if (editMode)
                //  getItem();
                /*if(window.location.pathname!== pagePathname)
                    getItem(url);*/
            }
        }
        , []
    );
    /*const title = "Featured Products";*/

    return (
        <>
            <EntitiesContext.Provider value={{state, dispatch}}>
                <ExamplesNavbar setLanguage={language => setLanguage(language)}/>
                <div
                    className="page-header"
                    style={{
                        backgroundImage: "url(" + require("./../../assets/img/mockups/tuv-austria-hellas.jpg") + ")"
                    }}
                ><h1 className="text-center text-white" style={{zIndex: "3"}}>{state.orders.cardTitle}</h1>

                    <div className="filter"/>

                </div>
                <div className="main">
                    <AuthoriseAccess component={<OrderMain/>}/>
                </div>
            </EntitiesContext.Provider>
        </>
    );
}

export default OrderPage;
