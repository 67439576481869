import LocalizedStrings from 'react-localization';
import Cookie from 'universal-cookie';

const cookie=new Cookie();
let language=cookie.get('language_set')??'en';
let strings = new LocalizedStrings({
    en:{
        // Landing Page
        intro1:"Certifications for a variety of occupations.",
        intro2:"Let's talk about our certifications",
        intro3:"We have a wide variety of Personnel certifications.",
        see_details: "See Details",
        schema:"Certification Schema",
        made_with:"made with",
        by:"by",
        country: "Country",
        action: "Action",
        apply_now: "Apply Now",
        menu_admin: "Admin",
        // Navigation
        certifications: "Certifications",
        registration: "Registration",
        registration_individual: "Individual",
        registration_group: "Group",
        login: "Login",
        logout: "Logout",
        profile: "Profile",
        language: "Language",
        greek: "Greek",
        english: "English",
        // Landing Page Contact form
        contact_form_title: "Keep in touch?",
        contact_form_name: "Name",
        contact_form_message: "Message",
        contact_form_message_placeholder: "Tell us your thoughts and feelings...",
        contact_form_button: "Send Message",
        // Profile
        my_profile: "My Profile",
        personal_info: "Personal Info",
        firstname:"First Name",
        lastname:"Last Name",
        birthdate:"Date OF Birth",
        company:"Company",
        vat_reg_num:"VAT Reg. Number",
        profile_mobile:"Mobile Phone",
        profile_update_details:"Update Details",
        announcements: "Announcements",
        certificates: "Certificates",
        my_certificates: "My Certificates",
        orders: "Orders",
        your_orders: "Your Orders",
        order_details: "Order Details",
        proceed_to_checkout: "Proceed To Checkout",
        my_certifications: "My Certifications",
        my_documents: "My Documents",
        my_forthcoming_exams: "My Forthcoming Exams",
        documents: "Documents",
        forthcoming_exams: "Forthcoming Exams",
        profile_success_update: "The profile was updated successfully.",
        profile_update_modal: "Profile Update",
        enroll_now: "Enroll Now",
        // Profile change password
        profile_change_password: "Change Password",
        profile_old_password: "Old Password",
        profile_new_password: "New Password",
        profile_password_confirmation: "Password Confirmation",
        choose: "Choose...",
        submit: "Submit",
        bulk_registration_upload: "Upload a File:",
        bulk_registration: "Bulk Registration",
        individual_registration: "Individual Registration",
        close: "Close",
        requirements: "Requirements",
        required_validation: "This field is required.",
        pattern_validation: "This field has wrong format.",
        name_pattern_validation: "Must be lowercase, alphanumeric, or contain dash (-) and underscore (_).",
        password_minlength_validation: "The field must have at least 8 characters.",
        password_maxlength_validation: "The field must have at most 14 characters.",
        permalink_minlength_validation: "The field must have at least 3 characters.",
        permalink_size_validation: "The field must have exactly 3 characters.",
        username: "Username",
        username_placeholder: "e.g. thomasM_21, timothy",
        password: "Password",
        password_confirmation: "Password Confirmation",
        password_placeholder: "e.g: abcdeF4#",
        registration_country: "Country of Examinations",
        Austria: "Austria",
        greece: "Greece",
        slovenia: "Slovenia",
        bulgaria: "Bulgaria",
        cyprus: "Cyprus",
        spain: "Spain",
        role: "Role",
        gdpr_consent: "GDPR Consent",
        newsletter_subscribe: "Subscribe to the newsletter of TUV Austria Hellas",
        view_policy: "View the policy",
        company_name:"Company Name",
        company_title:"Company Title",
        address:"Address",
        city:"City",
        zip_code:"Zip Code",
        company_email:"Company Email",
        phone:"Phone",
        fax:"Fax",
        company_vat_reg_num:"Company VAT Reg. Number",
        tax_office:"Tax Office",
        welcome: "Welcome",
        forgot_password: 'Forgot Password?',
        payments: "Payments",
        examinations: "Examinations",
        enrollments: "Enrollments",
        companies: "Companies",
        manage_certifications: "Manage Certifications",
        category: "Category",
        title: "Title",
        type: "Type",
        price: "Price",
        certification_duration: "Certification Duration",
        number_of_activities: "Number of Activities",
        add: "Add",
        create_certification: "Create Certification",
        certification_list: "Certification List",
        edit_certification: "Edit Certification",
        specialty: "Specialty",
        certification_title: "Certification Title",
        price_in_euro: "in euro(00.00)",
        activity_single: "Activity",
        activity_plural: "Activities",
        theoretical: "Theoretical",
        practical: "Practical",
        theoretical_practical: "Theoretical & Practical",
        certification_duration_placeholder: "Duration in Number (e.g. 1, 3, 5)",
        duration_months: "Month(s)",
        duration_years: "Year(s)",
        duration_unlimited: "Unlimited",
        system: "System",
        edited_success: "Edited successfully",
        saved_success: "Saved successfully",
        details: "Details",
        about: "About",
        criteria: "Participation Prerequisites",
        certification_image: "Certification Image",
        certification_certified_by: "Certified By",
        accreditation_body: "Accreditation Body",
        general_regulation: "Gen. regulation",
        specific_regulation: "Spec. regulation",
        remote: "Remote",
        grade_auto: "Grade Auto",
        open_date: "Open Date",
        save: "Save",
        audience: "Addressed to",
        certification_status: "Certification Status",
        manage_companies: "Manage Companies",
        create_company: "Create Company",
        edit_company: "Edit Company",
        company_list: "Company List",
        participation_forms: "Participation Forms",
        company_manager: "Company Representative",
        change_manager: "Change Representative",
        assign_role: "Assign Role",
        company_info: "Company Information",
        other_info: "Other info",
        manage_examinations: "Manage Examinations",
        create_examination: "Create Examination",
        edit_examination: "Edit Examination",
        examination_list: "Examination List",
        examination_title: "Examination Title",
        select_certification: "Select Certification",
        pick_exam_date: "Pick Examination Date",
        selected_date: "Selected Date",
        activity_type: "Activity Type",
        date: "Date",
        start_time: "Start Time",
        end_time: "End Time",
        dates_of_interest: "Dates of Interest",
        classrooms: "Classrooms",
        classroom: "Classroom",
        available_classrooms: "Available Classrooms",
        available_participation_forms: "Available Participation Forms",
        participants: "Participants",
        examination_status: "Examination Status",
        enrollment_status_closed: "Closed",
        enrollment_status_success: "Success",
        enrollment_status_pending_payment: "Pending Payment",
        enrollment_status_suspended: "Suspended",
        management: "Management",
        checkout_full_name: "Full Name",
        checkout_surname: "Surname",
        checkout_name: "Name",
        checkout_email: "Email",
        checkout_country: "Country",
        checkout_region: "Region",
        checkout_city: "City",
        checkout_addr: "Address",
        checkout_post_code: "Post Code",
        checkout_home_phone: "Home Phone",
        checkout_mobile_phone: "Mobile Phone",
        checkout_work_phone: "Work Phone",
        checkout: "Checkout",
        participation_form:"Participation Form",
        participation_form_individual: "Self",
        participation_form_group: "Group",
        certification_activities: "Activities for Certification",
        certification_exam_amount: "Amount of Exams for the certification",
        new_document: "New Document",
        add_file: "Add File",
        document_name: 'Document Name',
        choose_file: "Choose File",
        or_upload_new: "or upload new",
        add_document: "Add Document",
        select: 'Select',
        cancel: 'Cancel',
        select_existing_docs_for: "Select existing documents for:",
        select_users: "Select users (up to 5)",
        no_users_selected: "No users selected",
        single_date_instead: "Single Date Instead",
        examination: "Examination",
        start_date: "Start Date",
        end_date: "End Date",
        request_date_range:"Request Date Range",
        notes: "Notes",
        comments: "Comments",
        participation_notes:"Notes about the participation",
        moderator_comments:"Comments by the moderator",
        see_more: "See more",
        no_certifications: "There are currently no certifications for this category",
        product_details : "Product Details",
        certification_code: "Code",
        duration: "Duration",
        related_certifications : "Related Certifications",
        simple_user: "Simple user",
        examiner: "Examiner",
        examiners: "Examiners",
        invigilator: "Invigilator",
        invigilators: "Invigilators",
        select_examiners: "Select examiners",
        select_invigilators: "Select invigilators",
        seats_remaining: "Remaining seats",
        capacity: "Capacity",
        login_to_continue: 'Please login with your account in order to continue.',
        verify_to_continue: 'Please verify your email account in order to continue.',
        order_paid: "Paid",
        order_unpaid: "Unpaid",
        final_price: "Final price",
        bulk_fields: "Fields to be filled",
        bulk_username: "username: Required, may contain lowercase letters, numbers and the special characters: '-', '_'",
        bulk_firstname: "firstname: Required, may contain letters and spaces",
        bulk_lastname: "lastname: Required, may contain letters and spaces",
        bulk_email: "email: Required, must have the correct format",
        bulk_password: "password: Required, must include a lowercase letter, an uppercase letter, a number and a special character. It must be between 8 and 14 characters",
        bulk_birthdate: "birthdate: Required, must have the format: YYYY-MM-DD",
        bulk_mobile: "mobile: The user's mobile phone number, optional, it may contain numbers and spaces",
        bulk_vat_reg_no: "vat_reg_no: The user's VAT number, optional. It may only contain numbers",
        template_file: "Template file for completion:",
        tooltip_activity_name_1: "Name of the activity",
        tooltip_activity_name_2: "May include letters, numbers, spaces and the symbols: ( ) & - . , : @ ! / \\",
        tooltip_activity_tag_1:"Internal: The examination takes place in Moodle LMS",
        tooltip_activity_tag_2:"External: The examination takes place outside Moodle LMS",
        short_description: "Short description",
        view_item: 'View',
        gdpr_validation: 'You need to consent to the GDPR Policy in order to proceed.',
        gdpr_read: 'Read the Policy',
        examination_results: 'Examination Results',
        examination_date: 'Examination Date',
        examination_starting_date: 'Examination Starting Date',
        warning_price_update: 'Warning: Any update in the price will not affect the existing participation forms for this certification.',
        selected_docs_for_user: "Selected documents for user: ",
        error_general_message: "There has been an error, the action was not completed.",
        users: 'Users',
        email: "Email",
        yes: 'Yes',
        no: 'No',
        back_to_main: "Back to Main",
        receipt_type: "Receipt type",
        simple_receipt: "Simple receipt",
        invoice: "Invoice",
        amount: "Amount",
        status: "Status",
        participants_no: "Participants No.",
        here_is_a_list: "Here is a list of",
        // Registration Modal
        registration_status: "Registration Status",
        import_invalid_info_msg: "Some users were not imported due to invalid information:",
        the_following_users: "The following user(s):",
        was_saved_successfully: "was saved successfully",
        were_imported_successfully: "were imported successfully",
        continue: "Continue",
        // Login Modal
        login_success: "Successfully Logged in",
        login_status: "Login Status",
        // Participation Modal
        application_approved_successfully: "The application was approved successfully",
        application_saved_successfully: "The application was saved successfully",
        unexpected_error: "Something unexpected occurred!",
        // Enrollment Modal
        enrollment_rejected_successfully: "The enrollment was rejected successfully",
        enrollment_approved_successfully: "The enrollment was approved successfully",
        re_enrollment_added_fee: "The Fee for the re enrollment has been added!",
        // Rules
        rule_num_spaces: "May contain only numbers and spaces.",
        rule_between_string: "Must be between {min} and {max} characters.",
        rule_alpha_lower_required: "Must contain a lowercase character.",
        rule_alpha_upper_required: "Must contain an uppercase character.",
        rule_number_required: "Must contain a number.",
        rule_special_char_required: "Must contain a special character.",
        rule_only_alpha_lower: "Must be lowercase.",
        rule_alpha_num: "Must be alphanumeric characters.",
        rule_special_char: "May contain special characters: dash (-) and underscore (_).",
        rule_required: "This field is required.",
        // Datatables
        search: "Search ",
        certification: "Certification",
        participation: "Participation",
        participations: "Participations",
        grades: "Grades",
        single_title_grades: "Grades",
        name: "Name",
        grade: "Grade",
        number_of_participants: "Number of Participants",
        enrollment_status: "Enrollment Status",
        classroom_title: "Classroom Title",
        classroom_address: "Classroom Address",
        student_capacity: "Student Capacity",
        view: "View",
        number_of_users: "Number of users",
        auto_grading: "Auto Grading",
        permalink: "Permalink",
        no_announcements: "No announcements",
        export_to: "Export to",
        // Filters
        approved: "Approved",
        rejected: "Rejected",
        draft: "Draft",
        published: "Published",
        completed: "Completed",
        paid: "Paid",
        unpaid :"Unpaid",
        filter: "Filter",
        // Enrollment
        approve: "Approve",
        re_enroll_now: "Re-enroll Now",
        confirm_re_enroll: "Confirm Re-enroll",
        select_new_re_enroll_date: "Select next available date range for Enrollment",
        click_here: "click here",
        submit_new_dates: "Submit New Dates",
        status_update: "Status Update",
        re_enrollment_price: "Re-enrollment price",
        // Participation Form
        submitted_participation_form: "Submitted Participation Form",
        load: "Loading...",
        error: 'Error',
        succeeded: "succeeded",
        failed: "failed",
        not_available: "not available",
        participation_name: "Participation Name",
        reports: "Reports",
        // Certification Modal
        and_activities: "and activities:",
        certification_title_with_code: "The certification with title: {title} and code: {code}",
        was_edited_successfully: "was edited successfully",
        activity_with_name: "{type} with name {name}",
        // Examination Modal
        examination_with_activity: "The {type} Examination with activity start {start_time}",
        examination_with_title_and_date: "The examination with title {title} and date {date}",
        examination_with_name: "The {type} {activity_type} with name {name}",
        examination_title_gr_detail: "The detail {title_gr_1} with title_gr {title_gr_2}",
        publish: "Publish",
        // Certificate Creation Modal
        create_certificate_label: "Create Certificate",
        create_certificate_title: "Creating Certificate",
        edit_certificate_label: "Edit Certificate",
        edit_certificate_title: "Editing Certificate",
        upload_certificate_label: "Upload Certificate",
        view_certificate_label: "View Certificate",
        view_certificate_title: "View Certificate",
        publish_certificate_label: "Publish Certificate",
        publish_certificate_title: "Publish Certificate",
        //Certificate Creation Modal Form (PDF Generator)
        certificate_gender_title: "Mr/Ms",
        mister: "Mr",
        miss: "Ms",
        certificate_personal_details: "Personal Details",
        certificate_schema: "Certificate Schema",
        certificate_title: "Certificate Title",
        certificate_registration_no: "Registration No",
        initial_certification: "Initial Certification",
        certificate_valid_until: "Valid Until",
        certificate_publish_date: "Publish Date",
        certificate_view_button: "View Certificate",
        certificate_import_button: "Import Certificate",
        scanned_certificate: "Scanned certificate",
        certificate_upload_button: "Upload",
        certificate_save_message: "The certificate was saved successfully",
        certificate_edit_message: "The certificate with registration no: {registration_no} was edited successfully",
        certificate_publish_message: "The certificate with registration no: {registration_no} was published successfully",
        certificate_update_message: "The certificate was updated successfully",
        certificate_upload_message: "The file was uploaded successfully"
    },
    el: {
        // Landing Page
        intro1: "Πιστοποιήσεις για ποικιλία επαγγελμάτων",
        intro2: "Ας μιλήσουμε για τις πιστοποιήσεις μας",
        intro3:"Διαθέτουμε μια μεγάλη ποικιλία από πιστοποιήσεις προσωπικού",
        see_details: "Δείτε λεπτομέρειες",
        schema:"Σχήματα Πιστοποίησης",
        made_with:"υλοποιημένο με",
        by:"από την",
        country: "Χώρα",
        action: "Ενέργεια",
        apply_now: "Επιλογή",
        menu_admin: "Διαχείριση",
        // Navigation
        certifications: "Πιστοποιήσεις",
        registration: "Εγγραφή",
        registration_individual: "Ατομική",
        registration_group: "Ομαδική",
        login: "Σύνδεση",
        logout: "Αποσύνδεση",
        profile: "Προφίλ",
        language: "Γλώσσα",
        greek: "Ελληνικά",
        english: "Αγγλικά",
        // Landing Page Contact form
        contact_form_title: "Επικοινωνείστε μαζί μας",
        contact_form_name: "Ονοματεπώνυμο",
        contact_form_message: "Μήνυμα",
        contact_form_message_placeholder: "Πείτε μας τις σκέψεις και τα συναισθήματά σας...",
        contact_form_button: "Αποστολή Μηνύματος",
        // Profile
        my_profile: "Το προφίλ μου",
        personal_info: "Πληροφορίες",
        firstname:"Όνομα",
        lastname:"Επώνυμο",
        birthdate:"Ημερομηνία Γέννησης",
        company:"Εταιρεία",
        vat_reg_num:"ΑΦΜ",
        profile_mobile:"Τηλέφωνο Επικοινωνίας (κινητό)",
        profile_update_details:"Ενημέρωση",
        announcements: "Ανακοινώσεις",
        certificates: "Πιστοποιητικά",
        my_certificates: "Τα Πιστοποιητικά μου",
        orders: "Παραγγελίες",
        your_orders: "Οι Παραγγελίες Σας",
        order_details: "Λεπτομέρειες Παραγγελίας",
        proceed_to_checkout: "Ολοκλήρωση Aγοράς",
        my_certifications: "Οι Πιστοποιήσεις μου",
        my_documents: "Τα έγγραφα μου",
        my_forthcoming_exams: "Οι Προσεχείς Εξετάσεις μου",
        documents: "Έγγραφα",
        forthcoming_exams: "Προσεχείς Εξετάσεις",
        profile_success_update: "Το προφίλ ενημερώθηκε με επιτυχία.",
        profile_update_modal: "Ενημέρωση προφίλ",
        enroll_now: "Συμμετοχή",
        // Profile change password
        profile_change_password: "Αλλαγή κωδικού πρόσβασης",
        profile_old_password: "Παλιός κωδικός πρόσβασης",
        profile_new_password: "Νέος κωδικός πρόσβασης",
        profile_password_confirmation: "Επιβεβαίωση κωδικού πρόσβασης",
        choose: "Επιλέξτε...",
        submit: "Υποβολή",
        bulk_registration_upload: "Ανεβάστε ένα αρχείο:",
        bulk_registration: "Ομαδική Εγγραφή",
        individual_registration: "Ατομική Εγγραφή",
        close: "Κλείσιμο",
        requirements: "Απαιτήσεις",
        required_validation: "Αυτό το πεδίο είναι απαραίτητο.",
        pattern_validation: 'Η μορφοποίηση αυτού του πεδίου είναι λανθασμένη.',
        name_pattern_validation: 'Επιστρέπονται μόνο πεζοί χαρακτήρες, αριθμοί και τα σύμβολα (-) και (_).',
        password_minlength_validation: "Το πεδίο πρέπει να έχει τουλάχιστον 8 χαρακτήρες.",
        password_maxlength_validation: "Tο πεδίο πρέπει να έχει το πολύ 14 χαρακτήρες.",
        permalink_minlength_validation: "Το πεδίο πρέπει να έχει τουλάχιστον 3 χαρακτήρες.",
        permalink_size_validation: "Το πεδίο πρέπει να έχει ακριβώς 3 χαρακτήρες.",
        username: "Όνομα Χρήστη",
        username_placeholder: "π.χ. giorgos32, nikpapas",
        password: "Συνθηματικό Χρήστη",
        password_confirmation: "Επιβεβαίωση Συνθηματικού",
        password_placeholder: "π.χ. : abcdeF4#",
        registration_country: "Χώρα διεξαγωγής εξετάσεων",
        austria: "Αυστρία",
        Austria: "Αυστρία",
        Greece: "Ελλάδα",
        greece: "Ελλάδα",
        slovenia: "Σλοβενία",
        bulgaria: "Βουλγαρία",
        cyprus: "Κύπρος",
        spain: "Ισπανία",
        role: "Ρόλος",
        gdpr_consent: "Συναίνεση για τη χρήση προσωπικών δεδομένων",
        newsletter_subscribe: "Επιθυμώ να λαμβάνω το ενημερωτικό δελτίο της TÜV Austria Hellas",
        view_policy: "Προβολή πολιτικής",
        company_name:"Ονομασία Εταιρείας",
        company_title:"Τίτλος Εταιρείας",
        address:"Διεύθυνση",
        city:"Πόλη",
        zip_code:"Ταχ. Κώδικας",
        company_email:"Email Εταιρείας",
        phone:"Τηλέφωνο",
        fax:"Fax",
        company_vat_reg_num:"ΑΦΜ Εταιρείας",
        tax_office:"ΔΟΥ",
        welcome: "Καλωσήρθατε",
        forgot_password: 'Ξεχάσατε το συνθηματικό σας;',
        payments: "Πληρωμές",
        examinations: "Εξετάσεις",
        enrollments: "Συμμετοχές",
        companies: "Εταιρείες",
        manage_certifications: "Πιστοποιήσεις: Διαχείριση",
        category: "Κατηγορία",
        title: "Τίτλος",
        type: "Τύπος",
        price: "Τιμή",
        certification_duration: "Διάρκεια Πιστοποιητικού",
        number_of_activities: "Αριθμός Δραστηριοτήτων",
        add: "Προσθήκη",
        create_certification: "Δημιουργία Πιστοποίησης",
        certification_list: "Πιστοποιήσεις",
        edit_certification: "Επεξεργασία Πιστοποίησης",
        specialty: "Ειδικότητα",
        certification_title: "Τίτλος πιστοποίησης",
        price_in_euro: "σε ευρώ(00.00)",
        activity_single: "Δραστηριότητα",
        activity_plural: "Δραστηριότητες",
        theoretical: "Θεωρητική",
        practical: "Πρακτική",
        theoretical_practical: "Θεωρητική & Πρακτική",
        certification_duration_placeholder: "Διάρκεια σε αριθμό (π.χ. 1, 3, 5)",
        duration_months: "Μήνας/Μήνες",
        duration_years: "Έτος/Έτη",
        duration_unlimited: "Απεριόριστη",
        system: "Σύστημα",
        edited_success: "Επιτυχής επεξεργασία",
        saved_success: "Επιτυχής αποθήκευση",
        details: "Πληροφορίες",
        about: "Απευθυνόμενη σε",
        criteria: "Προϋποθέσεις Συμμετοχής",
        certification_image: "Εικόνα Πιστοποίησης",
        certification_certified_by: "Πιστοποιημένη από",
        accreditation_body: "Φορέας Διαπίστευσης",
        general_regulation: "Γεν. κανονισμός",
        specific_regulation: "Ειδ. κανονισμός",
        remote: "Απομακρυσμένα",
        grade_auto: "Αυτόματη Βαθμολόγηση",
        open_date: "Ανοικτή Ημερομηνία",
        save: "Αποθήκευση",
        audience: "Απευθύνεται σε",
        certification_status: "Κατάσταση Πιστοποίησης",
        manage_companies: "Εταιρείες: Διαχείριση",
        create_company: "Δημιουργία Εταιρείας",
        edit_company: "Επεξεργασία Εταιρείας",
        company_list: "Εταιρείες",
        participation_forms: "Αιτήσεις Συμμετοχής",
        company_manager: "Εκπρόσωπος Εταιρείας",
        change_manager: "Αλλάξτε Εκπρόσωπο",
        assign_role: "Ορισμός Ρόλου",
        company_info: "Πληροφορίες Εταιρείας",
        other_info: "Άλλα Στοιχεία",
        manage_examinations: "Εξετάσεις: Διαχείριση",
        create_examination: "Δημιουργία Εξέτασης",
        edit_examination: "Επεξεργασία Εξέτασης",
        examination_list: "Εξετάσεις",
        examination_title: "Τίτλος εξέτασης",
        select_certification: "Επιλέξτε Πιστοποίηση",
        pick_exam_date: "Επιλέξτε Ημερομηνία Εξέτασης",
        selected_date: "Ορισμένη Ημερομηνία",
        activity_type: "Τύπος Δραστηριότητας",
        date: "Ημερομηνία",
        start_time: "Ώρα Έναρξης",
        end_time: "Ώρα Λήξης",
        dates_of_interest: "Προτιμούμενες Ημερομηνίες",
        classroom: "Αίθουσα",
        classrooms: "Αίθουσες",
        available_classrooms: "Διαθέσιμες Αίθουσες",
        available_participation_forms: "Διαθέσιμες Αιτήσεις Συμμετοχής",
        participants: "Συμμετέχοντες",
        examination_status: "Κατάσταση Εξέτασης",
        enrollment_status_closed: "Κλειστή",
        enrollment_status_success: "Επίτυχης",
        enrollment_status_pending_payment: "Εκκρεμεί Πληρωμή",
        enrollment_status_suspended: "Σε αναστολή",
        management: "Διαχείριση",
        checkout_full_name: "Ονοματεπώνυμο",
        checkout_surname: "Eπώνυμο",
        checkout_name: "Όνομα",
        checkout_email: "Email",
        checkout_country: "Χώρα",
        checkout_region: "Περιφέρεια",
        checkout_city: "Πόλη",
        checkout_addr: "Διεύθυνση",
        checkout_post_code: "Τ/Κ",
        checkout_home_phone: "Τηλέφωνο Οικίας",
        checkout_mobile_phone : "Κινητό Tηλέφωνο",
        checkout_work_phone: "Τηλέφωνο Εργασίας",
        checkout: "Πληρωμή",
        participation_form:"Αίτηση Συμμετοχής",
        participation_form_individual: "Ατομική",
        participation_form_group: "Ομαδική",
        certification_activities: "Δραστηριότητες Πιστοποίησης",
        certification_exam_amount: "Πλήθος εξετάσεων για τη πιστοποίηση",
        new_document: "Νέο Έγγραφο",
        add_file: "Προσθήκη Αρχείου",
        document_name: 'Όνομα Εγγράφου',
        choose_file: "Επιλέξτε Αρχείο",
        or_upload_new: "ή μεταφόρτωση νέου",
        add_document: "Προσθήκη Εγγράφου",
        select: 'Επιλογή',
        cancel: 'Ακύρωση',
        select_existing_docs_for: "Επιλέξτε διαθέσιμα έγγραφα για:",
        select_users: "Επιλέξτε Χρήστες (Μέχρι 5)",
        no_users_selected: "Δεν έχουν επιλεχθεί χρήστες",
        single_date_instead: "Επιλογή μονής ημερομηνίας",
        examination: "Εξέταση",
        start_date: "Ημερομηνία Έναρξης",
        end_date: "Ημερομηνία Λήξης",
        request_date_range:"Διάστημα ημερομηνίας ενδιαφέροντος",
        notes: "Σημειώσεις",
        comments: "Σχόλια",
        participation_notes:"Σημειώσεις για την αίτηση",
        moderator_comments:"Σχόλια από τον Διαχειριστή",
        see_more: "Περισσότερα",
        no_certifications: "Πρός το παρόν δεν υπάρχουν πιστοποιήσεις γι αυτή την κατηγορία",
        product_details : "Περιγραφή προϊόντος",
        certification_code: "Κωδικός",
        duration: "Διάρκεια",
        related_certifications : "Σχετικές Πιστοποιήσεις",
        simple_user: "Απλός χρήστης",
        examiner: "Εξεταστής",
        examiners: "Εξεταστές",
        invigilator: "Επιτηρητής",
        invigilators: "Επιτηρητές",
        select_examiners: "Επιλέξτε εξεταστές",
        select_invigilators: "Επιλέξτε επιτηρητές",
        seats_remaining: "Διαθέσιμες θέσεις",
        capacity: "Χωρητικότητα",
        login_to_continue: 'Παρακαλούμε συνδεθείτε με το λογαριασμό σας για να συνεχίσετε.',
        verify_to_continue: 'Παρακαλούμε επιβεβαιώστε το λογαριασμό του email σας για να συνεχίσετε.',
        order_paid: "Μη Πληρωμένη",
        order_unpaid: "Πληρωμένη",
        final_price: "Τελική τιμή",
        bulk_fields: "Πεδία προς συμπλήρωση",
        bulk_username: "username: Υποχρεωτικό, μπορεί να περιέχει μικρά γράμματα, αριθμούς και τους ειδικούς χαρακτήρες: '-', '_'",
        bulk_firstname: "firstname: Όνομα, υποχρεωτικό. Μπορεί να περιέχει γράμματα και κενά",
        bulk_lastname: "lastname: Επώνυμο, υποχρεωτικό. Μπορεί να περιέχει γράμματα και κενά",
        bulk_email: "email: Υποχρεωτικό, πρέπει να αποτελεί email",
        bulk_password: "password: Υποχρεωτικό, πρέπει να περιλαμβάνει ένα μικρό γράμμα, ένα κεφαλαίο γράμμα, έναν αριθμό και έναν ειδικό χαρακτήρα",
        bulk_birthdate: "birthdate: Ημερομηνία γέννησης, υποχρεωτικό. Πρέπει να έχει την δομή: YYYY-MM-DD",
        bulk_mobile: "mobile: Κινητό τηλέφωνο, προαιρετικό. Μπορεί να περιέχει αριθμούς και κενά",
        bulk_vat_reg_no: "vat_reg_no: ΑΦΜ, προεραιτικό. Μπορεί να περιέχει μόνο αριθμούς",
        template_file: "Πρότυπο αρχείο προς συμπλήρωση:",
        tooltip_activity_name_1: "Όνομα δραστηριότητας",
        tooltip_activity_name_2: "Μπορεί να περιλαμβάνει γράμματα, αριθμούς, κενά και τους χαρακτήρες: ( ) & - . , : @ ! / \\",
        tooltip_activity_tag_1:"Internal: H εξέταση λαμβάνει χώρα στο περιβάλλον του Moodle LMS",
        tooltip_activity_tag_2:"External: Η εξέταση λαμβάνει χώρα εκτός Moodle LMS",
        short_description: "Σύντομη περιγραφή",
        view_item: 'Προβολή',
        gdpr_validation: 'Χρειάζεται συναίνεση στην Πολιτική GDPR για να συνεχίσετε.',
        gdpr_read: 'Δείτε την Πολιτική',
        examination_results: 'Αποτελέσματα Εξέτασης',
        examination_date: 'Ημερομηνία Εξέτασης',
        examination_starting_date: 'Ημερομηνία Έναρξης Εξέτασης',
        warning_price_update: 'Προσοχή: Τυχόν αλλαγή στην τιμή δεν θα επηρεάσει τις αιτήσεις που έχουν δημιουργηθεί ήδη για αυτή την ειδικότητα.',
        selected_docs_for_user: "Επιλεγμένα έγγραφα για τον χρήστη: ",
        error_general_message: "Υπήρξε ένα σφάλμα, η ενέργεια δεν ολοκληρώθηκε.",
        users: 'Χρήστες',
        email: "Email",
        yes: 'Ναι',
        no: 'Όχι',
        back_to_main: 'Επιστροφή στην Κεντρική',
        receipt_type: "Τύπος απόδειξης",
        simple_receipt: "Απλή απόδειξη",
        invoice: "Τιμολόγιο",
        amount: "Ποσό",
        status: "Κατάσταση",
        participants_no: "Συμμετέχοντες Αριθ.",
        here_is_a_list: "Ακολουθεί μία λίστα με",
        // Registration Modal
        registration_status: "Κατάσταση Εγγραφής",
        import_invalid_info_msg: "Ορισμένοι χρήστες δεν εισήχθησαν λόγω μη έγκυρων πληροφοριών:",
        the_following_users: "Οι ακόλουθοι χρήστες:",
        was_saved_successfully: "αποθηκεύτηκε επιτυχώς",
        were_imported_successfully: "εισήχθησαν επιτυχώς",
        continue: "Συνέχεια",
        // Login Modal
        login_success: "Συνδεθήκατε Επιτυχώς",
        login_status: "Κατάσταση Σύνδεσης",
        // Participation Modal
        application_approved_successfully: "Η αίτηση εγκρίθηκε επιτυχώς",
        application_saved_successfully: "Η αίτηση αποθηκεύτηκε επιτυχώς",
        unexpected_error: "Κάτι απροσδόκητο συνέβη!",
        // Enrollment Modal
        enrollment_rejected_successfully: "Η συμμετοχή απορρίφθηκε επιτυχώς",
        enrollment_approved_successfully: "Η συμμετοχή εγκρίθηκε επιτυχώς",
        re_enrollment_added_fee: "Το κόστος για την επανεξέταση έχει προστεθεί!",
        // Rules
        rule_num_spaces: "Μπορεί να περιέχει μόνο αριθμούς και κενά.",
        rule_between_string: "Πρέπει να είναι μεταξύ {min} και {max} χαρακτήρες.",
        rule_alpha_lower_required: "Πρέπει να περιέχει έναν πεζό χαρακτήρα.",
        rule_alpha_upper_required: "Πρέπει να περιέχει έναν κεφαλαίο χαρακτήρα.",
        rule_number_required: "Πρέπει να περιέχει έναν αριθμό.",
        rule_special_char_required: "Πρέπει να περιέχει έναν ειδικό χαρακτήρα.",
        rule_only_alpha_lower: "Πρέπει να είναι πεζοί χαρακτήρες.",
        rule_alpha_num: "Πρέπει να είναι αλφαριθμητικοί χαρακτήρες.",
        rule_special_char: "Μπορεί να περιέχει ειδικούς χαρακτήρες: dash (-) και underscore (_).",
        rule_required: "Αυτό το πεδίο είναι υποχρεωτικό.",
        // Datatables
        search: "Αναζήτηση ",
        certification: "Πιστοποίηση",
        participation: "Συμμετοχή",
        participations: "Συμμετοχές",
        grades: "Βαθμοί",
        single_title_grades: "Βαθμούς",
        name: "Ονοματεπώνυμο",
        grade: "Βαθμός",
        number_of_participants: "Αριθμός Συμμετεχόντων",
        enrollment_status: "Κατάσταση Συμμετοχής",
        classroom_title: "Τίτλος Αίθουσας",
        classroom_address: "Διεύθυνση Αίθουσας",
        student_capacity: "Χωρητικότητα Σπουδαστών",
        view: "Προβολή",
        number_of_users: "Αριθμός Χρηστών",
        auto_grading: "Αυτόματη Βαθμολόγηση",
        permalink: "Permalink",
        no_announcements: "Δεν υπάρχουν ανακοινώσεις",
        export_to: "Εξαγωγή σε",
        // Filters
        approved: "Εγκεκριμένο",
        rejected: "Απορριφθέντα",
        draft: "Πρόχειρο",
        published: "Δημοσιευμένο",
        completed: "Ολοκληρωμένο",
        paid: "Πληρωμένο",
        unpaid :"Απλήρωτο",
        filter: "Φιλτράρισμα",
        approve: "Έγκριση",
        re_enroll_now: "Επανεγγραφή τώρα",
        confirm_re_enroll: "Επιβεβαίωση Επανεγγραφής",
        select_new_re_enroll_date: "Επιλέξτε το επόμενο διαθέσιμο εύρος ημερομηνιών για συμμετοχή",
        click_here: "πατήστε εδώ",
        submit_new_dates: "Υποβολή νέων ημερομηνιών",
        status_update: "Ενημέρωση κατάστασης",
        re_enrollment_price: "Τιμή επανεγγραφής",
        // Participation Form
        submitted_participation_form: "Υποβαλλόμενη Αίτηση Συμμετοχής",
        load: "Φόρτωση...",
        error: "Σφάλμα",
        succeeded: "Επιτυχής",
        failed: "Ανεπιτυχής",
        not_available: "Μη διαθέσιμο",
        participation_name: "Ονομασία συμμετοχής",
        reports: "Reports",
        // Certification Modal
        and_activities: "και δραστηριότητες:",
        certification_title_with_code: "Η πιστοποίηση με τίτλο: {title} και κωδικό: {code}",
        was_edited_successfully: "Επεξεργάστηκε επιτυχώς",
        activity_with_name: "{type} με όνομα {name}",
        // Examination Modal
        examination_with_activity: "Η {type} Εξέταση με έναρξη δραστηριότητας {start_time}",
        examination_with_title_and_date: "Η εξέταση με τίτλο {title} και ημερομηνία {date}.",
        examination_with_name: "Η {type} {activity_type} με όνομα {name}",
        examination_title_gr_detail: "Η πληροφοριεα {title_gr_1} από title_gr {title_gr_2}",
        publish: "Δημοσίευση",
        // Certificate Creation Modal
        create_certificate_label: "Δημιουργία πιστοποιητικού",
        create_certificate_title: "Δημιουργία πιστοποιητικού",
        edit_certificate_label: "Επεξεργασία πιστοποιητικού",
        edit_certificate_title: "Επεξεργασία πιστοποιητικού",
        upload_certificate_label: "Ανάρτηση πιστοποιητικού",
        view_certificate_label: "Προβολή πιστοποίησης",
        view_certificate_title: "Προβολή πιστοποίησης",
        publish_certificate_label: "Δημοσίευση πιστοποίησης",
        publish_certificate_title: "Δημοσίευση πιστοποίησης",
        //Certificate Creation Modal Form (PDF Generator)
        certificate_gender_title: "Mr/Ms",
        mister: "Mr",
        miss: "Ms",
        certificate_personal_details: "Προσωπικά στοιχεία",
        certificate_schema: "Σχήμα πιστοποίησης",
        certificate_title: "Τίτλος πιστοποίησης",
        certificate_registration_no: "Registration No",
        initial_certification: "Αρχική Πιστοποιητικού",
        certificate_valid_until: "Ισχύει έως",
        certificate_publish_date: "Ημερομηνία δημοσίευσης",
        certificate_view_button: "Προβολή Πιστοποιητικού",
        certificate_import_button: "Εισαγωγή Πιστοποιητικού",
        scanned_certificate: "Σάρωση πιστοποιητικού",
        certificate_upload_button: "Ανάρτηση",
        certificate_save_message: "Το πιστοποιητικό αποθηκεύτηκε με επιτυχία",
        certificate_edit_message: "Το πιστοποιητικό με Registration No: {registration_no} επεξεργάστηκε με επιτυχία",
        certificate_publish_message: "Το πιστοποιητικό με Registration No: {registration_no} δημοσιεύθηκε με επιτυχία",
        certificate_update_message: "Το πιστοποιητικό ενημερώθηκε με επιτυχία",
        certificate_upload_message: "Το αρχείο αναρτήθηκε με επιτυχία"
    },
},
    {
        pseudo: false,
        pseudoMultipleLanguages: false
    });

// function to strip accents from greek text, in case upper case is used
String.prototype.stripAccents = function(){
    return this.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
};

strings.setLanguage(language);

export default strings;
