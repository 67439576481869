import React from "react";
import strings from "../../app/translations";
export default function CertificationType({setType,register, disableActivities}){
    function getType(e){
        setType(e.target.value)
    }
    return(<div className="form-group col-md-2">
        <label htmlFor="exampleFormControlSelect1">{strings.type}</label>
        <select className="form-control" id="certification_type" name="certification_type" onChange={getType} disabled={disableActivities}
                ref={register({required: true})}>
            <option>{strings.choose}</option>
            <option value="T">{strings.theoretical}</option>
            <option value="P">{strings.practical}</option>
            <option value="B">{strings.theoretical_practical}</option>
        </select>
    </div>)
}
