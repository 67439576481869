import React, {useContext, useEffect, useState} from "react";
import {Col,Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import CertificationForm from "../Forms/admin/CertificationForm";
import TableApp from "../Datatable/TableApp";

import EntitiesContext from "../../app/context";
import strings from "../../app/translations";

function ManageCertifications() {
    const {state,dispatch} = useContext(EntitiesContext);
    // console.log("state certifications");
    // console.log(state.certifications);
    const [tableWidth ,setTableWidth ] = useState(state.certifications.tableWidth);
    //const objs = state.certifications.objects.map(object => object.data);
    //console.log("state objs");
   // console.log(objs);
    const [objects,setObjects] = useState(state.certifications.objects.map(object => {
      //  console.log(object);
        object.data.action = state.actionButton(object,true,false,false);
        return  object.data;
    }));

    const columns = state.certifications.columns;
    // console.log("objects");
    // console.log(objects);

    const disabledFields = ['certification_category_id','certification_title','certification_code'];


    useEffect(() => {
            // console.log("state.certifications use effect");
            // console.log(objects);
            setObjects(state.certifications.objects.map(object => {
                // console.log(object);
                return object.data
            }));
        }
        , [state]);

    const [sectionTab, setSectionTab] = React.useState('1');
    const sectionToggle = tab => {
        if (sectionTab !== tab) setSectionTab(tab);
    };
    return (
        <Row className="custom-column">
            {window.location.pathname === "/admin/certification-page" &&
            <Col>
                <h2 className="text-center text-dark">{strings.manage_certifications}</h2>
                <div className="form-group col-md-12">
                    <Nav tabs className="justify-content-center">
                        <NavItem>
                            <NavLink
                                className={classnames({active: sectionTab === '1'})}
                                onClick={() => {
                                    sectionToggle('1');
                                }}>{strings.create_certification}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({active: sectionTab === '2'})}
                                onClick={() => {
                                    sectionToggle('2');
                                }}>{strings.certification_list}
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={sectionTab}>
                        <TabPane tabId="1">
                            <CertificationForm disabledFields={[]}/>
                        </TabPane>
                        <TabPane tabId="2">

                            <Row className="col-md-12">
                                <TableApp onEdit={() => {
                                    sectionToggle('1');
                                }} names={objects} columns={columns} tableWidth={tableWidth}/>
                            </Row>
                        </TabPane>
                    </TabContent>

                </div>
            </Col>
            }
            {window.location.pathname !== "/admin/certification-page" && <Col>
                <h2 className="text-center text-dark">{strings.edit_certification}</h2>
                <div className="form-group col-md-12">
                    <CertificationForm disabledFields={disabledFields}/>
                </div>
            </Col>}
        </Row>
    )
}
export default ManageCertifications;
