import React from "react";
import strings from "../../app/translations";

export default function Modal({title,message,action,status,ModalId}) {
// console.log('message');
// console.log(message);
//     console.log('action.link');
//     console.log(action);
const redirect = () => window.location.href = action.link;
    return (
        <div className="modal fade" id={ModalId || "appModal"} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {message.map((line,key) => {return <p key={key}>{line}</p>})}
                    </div>
                    <div className="modal-footer">
                        <div className={"left-side"}>
                            <button type="button" className="btn btn-default btn-link" data-dismiss="modal">{strings.close}
                            </button>
                        </div>
                        <div hidden={action ==="error"}><div className="divider"/>
                            <div className="right-side">
                            <button type="button" className="btn btn-danger btn-link" data-dismiss="modal">
                                {action &&typeof action === 'object' && <a className={"nav-link"} target="_blank" onClick={redirect}>{action.label}</a>}
                                {action && typeof action !== 'object' && action}
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}
