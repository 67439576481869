import React, {useContext, useEffect, useState} from "react";
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Collapse,
    Container,
    FormGroup,
    FormText,
    Input,
    InputGroup,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import classnames from "classnames";

import AdminNavbar from "../../../components/Navbars/AdminNavbar";

import EntitiesContext from "../../../app/context";
import ManageExaminations from "../../../components/Sections/ManageExaminations";

export default function AdminExaminationMain() {
    const {state,dispatch} = useContext(EntitiesContext);
    // console.log("state.examinations");
    // console.log(state);
    const adminPage = (state.examinations)?state.examinations.cardTitle:state.cardTitle;
    /*const [adminPage,setAdminPage] = useState(state.examinations.cardTitle);*/
    /*const [objects,setObjects] = useState(state.examinations.objects.map(object => object.data));
    const [columns,setColumns] = useState(state.examinations.columns);*/

    const [sectionTab, setSectionTab] = React.useState('1');
    const sectionToggle = tab => {
        if (sectionTab !== tab) setSectionTab(tab);
    };

    /*useEffect(() => {
            console.log("state.examinations");
            console.log(state.certifications);
            setAdminPage(state.certifications.cardTitle);
            const objs = state.certifications.objects.map(object => object.data);
            setObjects(objs);
            setColumns(state.certifications.columns);
        }
        , []
    );*/

    return (
        <div className="container-fluid">
            <Row className="pt-2">
                <Col lg="3" xs="12" className="pr-0">
                    <AdminNavbar adminPage={adminPage}/>
                </Col>
                <Col id="admin" lg="9" xs="12" className="admin pl-0">
                    <ManageExaminations/>
                </Col>
            </Row>
        </div>
    );
}
