/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useReducer, useContext, useState} from "react";

// reactstrap components
import {Card, CardTitle, Col, Container, Row,} from "reactstrap";
// core components
import EntitiesContext from "../../../app/context";
import CRUDReducer from "../../../app/reducer";
import Cookie from 'universal-cookie';
import axios from "axios";
import ExamplesNavbar from "../../../components/Navbars/ExamplesNavbar";
import ReportsMain from "../main/ReportsMain";
import AuthoriseAccess from "../../../components/AuthoriseAccess";


function MockReportsPage() {
    const cookie = new Cookie;
    const initialState = useContext(EntitiesContext);
    const [state, dispatch] = useReducer(CRUDReducer, initialState);
    const pagePathname = '/reports-page';
    const url = window.location.pathname;
    const suffix =  url.substr(url.lastIndexOf("/") );
    // console.log('suffix');
    // console.log(suffix);
    const type = `${suffix.substr(1)}s`;
    const [results,setResults] = useState([]);
    const [currentItem,setCurrentItem] = useState(state.currentItem);
    const [language, setLanguage] = useState(getLanguage());

    const isVerified = window.reactInit.verified==='true';

    function getLanguage ()  {
        let lang=cookie.get('language_set')??'en';
        //dispatch({type:"UPDATE_LANGUAGE",payload:lang});
        return lang;
    }
    const getResults = async () => {
        let url = `${suffix}/get`;
        const response = await axios.get(`${window.location.origin}${url}`);
        // console.log("data");
        // console.log(response.data);
        let temp = [];
        if(typeof response.data[type] === "object")
            response.data[type] = Object.values(response.data[type]);
        console.log(`UPDATE_${type.toUpperCase()}`);
        dispatch({type:`UPDATE_${type.toUpperCase()}`,payload:response.data});
        setResults(response.data);
    };

    const getCertifications = async () => {
        const response = await axios.get("/certification/get");
        // console.log("data");
        // console.log(response.data);
        dispatch({type:"UPDATE_CERTIFICATIONS",payload:response.data});
        setResults(response.data);
    };

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        if(isVerified) {
            // console.log(document.location.pathname);
            document.body.classList.add("register-page");
            return function cleanup() {
                document.body.classList.remove("register-page");
            };
        }
    });
    React.useEffect(() => {
            if (isVerified) {
                if (pagePathname !== suffix)
                    getCertifications().then(function () {
                        getResults()
                    });
            }
        }
        , []
    );

    return (
        <>
            <EntitiesContext.Provider value={{state, dispatch}}>
                <ExamplesNavbar setLanguage={language=>setLanguage(language)}/>
                <div
                    className="page-header"
                    style={{
                        backgroundImage: "url(" + require("./../../../assets/img/mockups/tuv-austria-hellas.jpg") + ")"
                    }}
                ><h1 className="text-center text-white" style={{zIndex: "3"}}>{state[type].cardTitle}</h1>

                    <div className="filter"/>

                </div>
                <div className="main">
                    <AuthoriseAccess component={<ReportsMain type={type}/>}/>
                </div>
            </EntitiesContext.Provider>
        </>
    );
}

export default MockReportsPage;
