import {Card, CardTitle, Col, Row} from "reactstrap";
import React, {useContext, useState} from "react";
import strings from "../../app/translations";
import EntitiesContext from "../../app/context";

function Announcements() {
    const {state, dispatch} = useContext(EntitiesContext);
    //const [currentItem, setCurrentItem] = useState(state.currentItem);
    const [announcements, setAnnouncements] = useState(state.announcements);
    // const announcements = (currentItem.data && typeof currentItem.data.announcements !=="undefined")?currentItem.data.announcements:[];


    React.useEffect(() => {
        //setCurrentItem(state.currentItem);
        setAnnouncements(state.announcements);
    }, [state.announcements]);

    // console.log(currentItem);

    // const announcements = [{
    //     message: "There is a pending payment for : Technical Example of Certification for specialized fields",
    //     badge_type: "danger",
    //     status: "pending_payment",
    //     date: "11-03-2020"
    // },
    //     {
    //         message: "Enrollments for this examination are closed",
    //         badge_type: "danger",
    //         status: "closed",
    //         date: "16-04-2020"
    //     },
    //     {
    //         message: "There is a pending payment for : Technical Example of Certification for specialized fields",
    //         badge_type: "info",
    //         status: "pending_payment",
    //         date: "16-05-2020"
    //     },
    //     {
    //         message: "Exam Completed : Motor Machine Operator – Construction Machine Operator with expertise.",
    //         badge_type: "success",
    //         status: "success",
    //         date: "16-05-2020"
    //     }
    // ];
    // console.log('Announcements');
    // console.log(announcements);
    //
    // console.log('current data');
    // console.log(currentItem);
    return (
        <Row style={{display: "block"}} className="custom-column" id="announcements-component">
            <Col>
                <h2 className="ml-auto mr-auto">{strings.announcements}</h2>
                <Card body className="card-plain">
                    <div className='announcements' style={{overflowY: "auto", overflowX: "hidden", maxHeight: "12rem"}}>
                        <ul>
                            {announcements.map((announcement, key) => {
                                return <li key={key} className="py-3 border-bottom" style={{minHeight: "4rem"}}>
                                    <Row>
                                        <Col lg="6">{announcement.message}
                                        </Col>
                                        <Col lg="2" className={'text-left '}>
                                            <label
                                                className={"badge badge-" + announcement.badge_type}>{announcement.status}</label>
                                        </Col>

                                        <Col lg="2">
                                            {announcement.date}
                                        </Col>
                                        <Col lg="2" className={'text-left '}>
                                            <a target={'_blank'} href={announcement.button_url}>
                                                <button color={"tuv_red"}
                                                        className={'btn-round'}>{announcement.button_text}
                                                </button>
                                            </a>
                                        </Col>
                                    </Row>
                                </li>
                            })}
                        </ul>
                    </div>
                </Card>
            </Col>
        </Row>
    )
}

export default Announcements;
