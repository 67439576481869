/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useContext, useReducer, useState} from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// reactstrap components
// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import ParticipationForm from "../../components/Forms/shop/ParticipationForm";
//CONTEXTAPI
import EntitiesContext from "../../app/context";
import CRUDReducer from "../../app/reducer";
import axios from "axios";

import ParticipationContext from "../../app/ParticipationContext";
import ParticipationReducer from "../../app/ParticipationReducer";
import Cookie from 'universal-cookie';
import strings from "../../app/translations";

import ViewVariables from "../../components/Middleware/ViewVariables";
import Footer from "../../components/Footers/Footer";
import AuthoriseAccess from "../../components/AuthoriseAccess";

function MockParticipationFormPage() {
    const isLogged = window.reactInit.user_id.length>0;
    const isVerified = window.reactInit.verified==='true';

    const cookie = new Cookie;
    const headerImage = ViewVariables.middleware.headerImage;
    const initialState = useContext(EntitiesContext);
    const [state, dispatch] = useReducer(CRUDReducer, initialState);
    const [results, setResults] = useState([]);
    const [language, setLanguage] = useState(getLanguage());
    //edit mode : pathname contains /cert_id/form_id
    const editMode = (window.location.pathname.split('/').length> 3);

    const title = "Participation";
    const participationState = useContext(ParticipationContext);
    const [pState,pDispatch] = useReducer(ParticipationReducer, participationState);
    pState['certifications'] = state.certifications;
    // console.log({pState:pState, state:state});
    pState['currentItem'] = state.currentItem;
    pState['handleSubmit'] = state.handleSubmit;
    // console.log({pState:pState,state:state});
    const [currentItem,setCurrentItem] = React.useState(state.currentItem);
    const getItem = async () => {
        let url = window.location.pathname;
        url = '/participation/get/item' + url.substr(url.lastIndexOf("/") );
        const response = await axios.get(url);
        dispatch({type:"UPDATE_CURRENT_ITEM",payload:response.data});
        setCurrentItem(response.data);
        pState['currentItem'] = response.data;
        // console.log(currentItem);
    };
    const getResults = async () => {
        let url = window.location.pathname;
        if(editMode)
            url = '/participation/user' + url.substr(url.lastIndexOf("/") );
        else
            url = '/participation/user';
        const response = await axios.get(url);
        // console.log("data");
        // console.log(response.data);
        pDispatch({type:"UPDATE_USERS",payload:response.data});
        const certifications = await axios.get('/certification/get');
        dispatch({type:"UPDATE_CERTIFICATIONS",payload:certifications.data});
        setResults(response.data);
    };

    const getAuthDetails = async () => {
        const response =await axios.get("/logged-user");
        // console.log("data");
        // console.log(response.data);
        pDispatch({type:"UPDATE_LOGGED_USER",payload:response.data});
        pState['user'] = response.data.user;
    };

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("register-page");
        return function cleanup() {
            document.body.classList.remove("register-page");
        };
    });
    function getLanguage ()  {
        let lang=cookie.get('language_set')??'en';
        //dispatch({type:"UPDATE_LANGUAGE",payload:lang});
        return lang;
    }

    React.useEffect(() => {
        // console.log('get results');
        if (isVerified){
            getResults().then(function(){
                if(editMode)
                    getItem();
            });
            getAuthDetails();
        }


    }, []);
// console.log('user_verified');
// console.log(user_verified);
    return (
        <>
            <EntitiesContext.Provider value={{state, dispatch}}>
            <ExamplesNavbar setLanguage={language=>setLanguage(language)}/>
            </EntitiesContext.Provider>
            <div
                className="page-header"
                style={{
                    backgroundImage: "url(" + require("./../../assets/img/mockups/tuv-austria-hellas.jpg") + ")"
                }}
            ><h1 className="text-center text-white" style={{zIndex: "3"}}>{title}</h1>

                <div className="filter"/>

            </div>
            <div className="main">
                <ParticipationContext.Provider value={{pState, pDispatch}}>
                    <AuthoriseAccess component={<ParticipationForm/>}/>
                </ParticipationContext.Provider>
                <Footer/>
            </div>
        </>
    );
}

export default MockParticipationFormPage;
