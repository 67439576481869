import {Button} from "reactstrap";
import React from "react";
import strings from "../app/translations";
import UserProfileMain from "../views/mockups/main/UserProfileMain";



export default function AuthorizeAccess({component}) {
    const isLogged = window.reactInit.user_id.length>0;
    const isVerified = window.reactInit.verified==='true';

    if(!isVerified) {
        console.log('not authorised');
        window.location.href = '/index';
    }

    return (
        <>
            {(!isLogged) && <div className="text-center text-white mt-5 mb-5">{strings.login_to_continue}</div>}
            {(isLogged && !isVerified) && <div className="text-center text-white mt-5 mb-5">{strings.verify_to_continue}</div>}
            {(isLogged && isVerified) && component}
        </>
    )
}
