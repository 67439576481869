import React, {useContext, useState} from "react";
import {
    Button,
    Card,
    CardTitle,
    Col,
    FormGroup,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane, Form
} from "reactstrap";
import strings from "../../app/translations";
import CertificationType from "../../components/Forms/CertificationType";
import InputWithType from "../../components/Forms/InputWithType";
import CertificationActivities from "../../components/Forms/CertificationActivities";
import classnames from "classnames";
import {useForm} from "react-hook-form";
import EnrollmentDetails from "../../components/Forms/admin/EnrollmentDetails";
import ManageCertificate from "../../components/Sections/ManageCertificate";
import axios from "axios";
import EntitiesContext from "../../app/context";
import Modal from "../../components/Modals/Modal";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import OverlayLoader from "../../components/Loaders/OverlayLoader";

export default function EnrollmentInternal({currentItem}) {
    const {state/*,dispatch*/} = useContext(EntitiesContext);
    const [route, setRoute] = useState('/api/approve');

    //modal constants
    const pagePathname = '/index';
    const editMode = (window.location.pathname.split('/').length > 3);
    const [modalShow, setModalShow] = React.useState(false);
    const [message, setMessage] = React.useState([]);
    const [action, setAction] = React.useState('');
    //
    const [showFee, setShowFee] = React.useState(false);
    const {register, handleSubmit, watch, errors} = useForm();
    const handleFee = () => {
        setShowFee(true);
        setRoute('/api/re-enroll')
    };
    // Loading Overlay
    const [loading, setLoading] = React.useState(false);
    //
    function showModal(data) {
        let msg = [];
        if (data.errors) {
            Object.values(data.errors).map(i => i.map(m => {
                return msg.push(m)
            }));
        } else if (data.records) {

            if (window.location.pathname.indexOf('enrollment-page/reject') > 0)
                msg.push(strings.enrollment_rejected_successfully);
            else if (editMode)
                msg.push(strings.enrollment_approved_successfully);
            else if (showFee)
                msg.push(strings.re_enrollment_added_fee);
            else
                msg.push(strings.application_approved_successfully);
        } else {
            msg.push(strings.unexpected_error)
        }

        let act = null;
        if (editMode)
            act = {link: pagePathname, label: strings.continue};
        else
            act = (data.errors) ? ('error') : "error";

        setAction(act);
        setMessage(msg);

        $('#appModal').modal({show: true});
        $('.modal-dialog').css('margin-top', '120px');
        $(".modal-backdrop").css("z-index", 1);
        // console.log('show');
        // console.log(msg);
        setLoading(false);
    }

    const approveEnrollment = async () => {
        setLoading(true);
        const data = {id: currentItem.data.id, model: 'enrollment'};
        const response = await axios.post(`/api/approve`, {
            id: currentItem.data.id,
            model: 'enrollment'
        }).then(function (response) {
            //handle success
            console.log("data");
            console.log(response);
            showModal(response.data);

        }).catch(function (response) {
            console.log('error');
            console.log(response);
        });

        /*dispatch({type:"UPDATE_CURRENT_ENROLLMENT",payload:response.data});
        setResults(response.data);*/
    }
    const transDate = (date) => {
        return (new Date(date)).toLocaleDateString();
    };

    const gradeLabel = (participant, score = false) => {
        return participant.grades.sort((grade) => (grade.activity_type === 'theoretical') ? -1 : 1).map(grade => {
            if (grade) {
                let activity = grade.activity_type.charAt(0).toUpperCase() + grade.activity_type.slice(1) + ': ';
                let grade_value = (score) ? ' (' + grade.score + ')' : '';
                grade_value += (grade.activity_type === 'theoretical' && participant.grades.length > 1) ? ', ' :'';

                if (grade.completed > 0) {
                    return activity + strings.succeeded + grade_value;
                } else
                    return activity + strings.failed + grade_value;

            } else
                return strings.not_available;
        });
    };

    const gradeResult = (participant) => {
        if (currentItem.data.activities.length === participant.grades.length) {
            let fail = false;
            participant.grades.map(grade => {
                if (grade.completed === 0)
                    fail = true;
            });
            if (fail === false)
                return 'succeeded';
        } else
            return 'missing grades';
    };

    const [pickerDate, setPickerDate] = useState(null);
    const dateRangeSettings = {
        startDate: moment().startOf('day'),
        endDate: moment().startOf('day').add(1, 'week'),
        autoUpdateInput: false,
        locale: {
            format: 'DD/MM/YYYY',
            cancelLabel: 'Clear',
        },
    };

    const handleDateApply = (event, picker) => {
        picker.element.val(
            picker.startDate.format('DD/MM/YYYY') +
            ' - ' +
            picker.endDate.format('DD/MM/YYYY')
        );
        setPickerDate(picker.startDate.format('DD/MM/YYYY') +
            ' - ' +
            picker.endDate.format('DD/MM/YYYY'))

    };
    const handleDateCancel = (event, picker) => {
        picker.element.val('');
    };
    const sendNewDates = async () => {
        const data2 = {id: currentItem.data.id, date_range: pickerDate};
        const test = await axios.post(`/api/reject`, data2).then(function (response) {
            //handle success
            console.log("data");
            console.log(response);
            showModal(response.data);


        }).catch(function (response) {
            console.log('error');
            console.log(response);
        });
    }

    const onSubmit = (data, event) => {
        // console.log('data sent in handlesubmit');
        // console.log(data);
        // console.log('event.target');
        // console.log(event.target);
        // console.log(data);
        state.handleSubmit(data, route, showModal, event)
    };
    // console.log(window.reactInit.user_role);
    return (
        <>
            {currentItem &&
            <OverlayLoader active={loading} text={strings.load}>
                <Card body className="card-plain">
                    <div className='announcements'>
                        <ul className="text-left">
                            <li>
                                <Row>
                                    {!['examiner', 'invigilator'].includes(window.reactInit.user_role) &&
                                    <Col xs='6'>Participation Name
                                        : <em>{currentItem.data.participation_form_data.name}</em><a
                                            className="ml-3 btn-round btn btn-secondary"
                                            href={"/participationForm-page/" + currentItem.data.certification_id + "/" + currentItem.data.participation_form_data.id}>View</a></Col>}
                                    <Col xs="6">{strings.specialty}: <em>{currentItem.data.certification.title}</em></Col>
                                </Row>

                            </li>
                            <hr></hr>
                            <li>
                                <Row>
                                    <Col xs="6">
                                        <ul className="pl-0">
                                            <li>{strings.examination_status} : <em>{currentItem.data.enrollment_status}</em></li>
                                            <li>{strings.examination_starting_date} : <em>{currentItem.data.open_date === 1?strings.open_date:
                                                transDate(currentItem.data.examination_date)}</em></li>
                                        </ul>
                                    </Col>
                                    {currentItem.data.enrollment_classroom !== null &&
                                    <Col xs="6">
                                        <ol>
                                            {currentItem.data.enrollment_classroom.map((classroom) => {
                                                return <li>
                                                    <ul className="pl-0">
                                                        <li>{strings.classroom} : <em>{classroom.name}</em></li>
                                                        <li>{strings.address} : <em>{classroom.address}</em>
                                                        </li>
                                                    </ul>
                                                </li>
                                            })}
                                        </ol>
                                    </Col>
                                    }
                                </Row>
                            </li>
                            <hr></hr>
                            <li>
                                <Row>
                                    <div className={"col-6"}>{strings.activity_plural} :</div>
                                </Row>
                                <Row>
                                    {currentItem.data.activities[0] &&
                                    <Col xs="6">
                                        <ul className="pl-0">
                                            <li>1. <em>{strings[currentItem.data.activities[0].type]} : {currentItem.data.open_date === 1 ? strings.open_date :
                                                transDate(currentItem.data.activities[0].date) + " " +
                                                currentItem.data.activities[0].start_time+" - "+
                                                currentItem.data.activities[0].end_time }
                                            </em>
                                            </li>
                                        </ul>
                                    </Col>}
                                    {currentItem.data.activities[1] &&
                                    <Col xs="6">
                                        <ul className="pl-0">
                                            <li>2. <em>{strings[currentItem.data.activities[1].type]} : {currentItem.data.open_date === 1 ? strings.open_date :
                                                transDate(currentItem.data.activities[1].date) + " " +
                                                currentItem.data.activities[1].start_time+" - "+
                                                currentItem.data.activities[1].end_time }</em>
                                            </li>
                                        </ul>
                                    </Col>}
                                </Row>
                            </li>
                            <hr></hr>
                            <li>
                                <Row>
                                    <div className={"col-6"}>{strings.participants} :</div>
                                    <div className={"col-6"}>{strings.examination_results} :</div>
                                </Row>
                                <ul>{currentItem.data.participants.map((participant, key) => {
                                    return <li key={key} className={"row mb-2"}>
                                        <div className={"col-6"}>
                                            <em>{participant.user.firstname} {participant.user.lastname}</em>
                                        </div>
                                        <div className={"col-6"}>
                                            <Row>
                                                <div className={"col-6"}>
                                                    <em>{['manager'].includes(window.reactInit.user_role) && gradeLabel(participant)}
                                                        {((participant.user && participant.user.id.toString() === window.reactInit.user_id)
                                                            || window.reactInit.user_role === 'moderator') && gradeLabel(participant, true)}
                                                    </em>
                                                </div>
                                                {gradeResult(participant) === 'succeeded' &&
                                                <ManageCertificate certificate={participant.certificate} index={key}
                                                                   enrollment_id={currentItem.data.id}
                                                                   certification_id={currentItem.data.certification_id}
                                                                   participant={participant}/>
                                                }
                                            </Row>
                                        </div>
                                    </li>
                                })
                                }
                                </ul>
                            </li>
                        </ul>
                    </div>
                    {(window.location.pathname.indexOf('enrollment-page/reject') < 0) &&
                    <Row className={"col-8 ml-auto mr-auto"}>
                        {
                            currentItem && currentItem.data.participation_form_data.applicant_id === window.reactInit.user_id &&
                            <Col xs="4"><a href={"/checkout-page"} className={"btn btn-round btn-tuv_red ml-auto mr-auto"}>{strings.checkout}</a></Col>
                        }{
                        currentItem && currentItem.data.participation_form_data.applicant_id === window.reactInit.user_id && currentItem.data.enrollment_status === 'Waiting Approval' &&
                        <Col xs="4"><Button block className="btn btn-round btn-tuv_red col-10 offset-1 ml-auto mr-auto"
                                            color="tuv_red"
                                            onClick={approveEnrollment}>
                            {strings.approve}
                        </Button></Col>
                    }
                        {
                            window.reactInit.user_role === "moderator" && currentItem.data.participation_form_data.enrollment_status === 'retry' &&
                            <Col xs="4"><Button block className="btn btn-round btn-info col-12 ml-auto mr-auto" color="info"
                                                onClick={handleFee}>{strings.re_enroll_now}</Button>
                                <Row>{
                                    showFee &&
                                    <Form name={"re_enroll_form"} id={"re_enroll_form"} className={'mt-3'} onSubmit={handleSubmit(onSubmit)}>
                                        <div className={"form-row col-12 mx-auto"}><FormGroup className={"col-6 align-self-center"}>
                                            <label> {strings.re_enrollment_price}:</label>
                                            <Input placeholder={strings.re_enrollment_price} type="text" id="re_enroll_amount"
                                                   name="re_enroll_amount"
                                                   innerRef={register({required: true})} defaultValue={0}/>
                                            {errors.re_enroll_amount &&
                                            <span className="validation-error">{strings.rule_required}</span>
                                            }
                                            <input type="hidden" id="model" name="model" value="enrollment"/>
                                            <input type="hidden" id="model_id" name="model_id" value={currentItem.data.id}/>
                                        </FormGroup>
                                            <Button block
                                                    className="btn btn-round btn-tuv_red col-5 my-3 offset-1 ml-auto mr-auto"
                                                    color="tuv_red">
                                                {strings.confirm_re_enroll}
                                            </Button>

                                        </div>
                                    </Form>
                                }</Row>
                            </Col>
                        }
                    </Row>
                    }
                    {(window.location.pathname.indexOf('enrollment-page/reject') > 0) &&
                    currentItem && currentItem.data.participation_form_data.applicant_id === window.reactInit.user_id &&
                    <>
                        <FormGroup className="col-md-4">
                            <label>{strings.select_new_re_enroll_date}</label>
                            <DateRangePicker initialSettings={dateRangeSettings} onApply={handleDateApply}
                                             onCancel={handleDateCancel}>
                                <input type='text' placeholder={strings.click_here} name='date' id='date'
                                       className={'custom-filter form-control'} defaultValue=""/>
                            </DateRangePicker>
                        </FormGroup>
                        <Button block className="btn btn-round btn-tuv_red col-4 offset-1 ml-auto mr-auto"
                                onClick={sendNewDates} color="tuv_red">
                            {strings.submit_new_dates}
                        </Button>
                    </>
                    }
                    <Modal action={action} message={message} title={strings.status_update}/>
                    <Modal ModalId={'enrollmentModal'} message={[]} title={strings.status_update}/>
                </Card>
            </OverlayLoader>}
        </>
    )
}
