import {Button, FormGroup, Label} from "reactstrap";
import React, {useContext} from "react";
import propTypes from "prop-types";
//import context
import ParticipationContext from "../../app/ParticipationContext";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import SelectOkCancel from "../SelectOkCancel";
import strings from "../../app/translations";

function SelectMultipleUsers({selectedUsers,register,errors,isReadOnly,formType}) {
    const {pState,pDispatch} = useContext(ParticipationContext);
        const applicant = pState.user;
        const [isModerator,setIsModerator] = React.useState(false);
        const users = pState.users;

    const [selectStatus, setSelectStatus] = React.useState(true);

    React.useEffect(function(){
        if(typeof pState.user.roles!== "undefined")
        pState.user.roles.map(role => {
            if (role.name === 'moderator')
                setIsModerator(true);
            // console.log(pState.users);
        });
    },[pState.user]);

    return (
        <Col>
            <FormGroup>
                <Label for="exampleSelectMulti">{strings.select_users}</Label>
                <select className="form-control" name="usersList" id="usersList"
                        multiple onChange={(selectStatus) ? (selectedUsers) : (() => users[0])
                } disabled={!selectStatus || isReadOnly('usersList')}
                        ref={register({required: true})}
                style={{height: (formType === 'individual'?'auto':300)}}>
                    {(formType === 'group' ||isModerator) && users.map((user, key) =>
                        <option id={user.id} key={key}>
                            {user.name} Born: {user.birthdate} {user.firstname} {user.lastname}
                        </option>
                    )}
                    {(formType === 'individual' && !isModerator) &&
                        <option id={applicant.id}>
                            {applicant.name} Born: {applicant.birthdate} {applicant.firstname} {applicant.lastname}
                        </option>
                    }
                </select>
                <input hidden type="text" id="participation_selected_users" name="participation_selected_users"/>
                {errors.usersList &&
                <span className="validation-error">{'This field is required'}</span>
                }
                <SelectOkCancel setSelectStatus={setSelectStatus} selectStatus={selectStatus}/>
            </FormGroup>
        </Col>
    )
}

SelectMultipleUsers.propTypes = {
    selectedUsers: propTypes.any
}
export default SelectMultipleUsers;
