import {InputGroup} from "reactstrap";
import Datetime from "react-datetime";
import React from "react";
import Moment from "moment";
import strings from "../../app/translations";

export default function ExamActivityDate({index, label, selectedDate, register, currentItem, status}) {

    const [startDate, setStartDate] = React.useState(new Moment(selectedDate).format('LT'));

    const [endDate, setEndDate] = React.useState(0);
    const [showEndDate, setShowEndDate] = React.useState(false);
    const [activityDate, setActivityDate] = React.useState(selectedDate);
    const [examDuration, setExamDuration] = React.useState(0);

    const [showDates, setShowDates] = React.useState(false);
    const datesVisible = () => {
        if (examDuration > 0) {
            setShowDates(true)

        }
    };
    // disable past dates
    const yesterday = Moment().subtract(1, 'day');
    const disablePastDt = current => {
        return current.isAfter(yesterday);
    };

    const disablePastSelectedDt = current => {
        return current.isAfter(selectedDate);
    };

    // disable future dates
    const today = Moment();
    const disableFutureDt = (current) => {
        return current.isBefore(today)
    }
    const disabledDates = (current) => {

        return !!(disablePastDt(current) && disableFutureDt(current));
        /*let theNow = new Moment(selectedDate);
        console.log(theNow.format('LLLL'));
        console.log(theNow.format('YYYY-MM-DD'));
        console.log(current.format('YYYY-MM-DD'));
        return current.format('YYYY-MM-DD') === theNow.format('YYYY-MM-DD');*/
    }

    /*function updateStartDate(){
        console.log('update start date');
        console.log(startDate);
        document.querySelectorAll("#selected_start_date_" + index)[0].value = startDate;
    }*/
    function handleEndDate() {
        let target_date = document.querySelectorAll("#selected_start_time_" + index)[0].value;
        // console.log("target_date");
        setStartDate(target_date);
        let target_date2 = new Moment(target_date, 'lLT').format('LT');
        // console.log(target_date);
        // console.log(target_date2);
        calculateEndDate(target_date);
        let t = new Moment(target_date, 'lLT').format('LT');
        // console.log('t');
        // console.log(t);
    }

    function calculateEndDate(target_date) {
        var d = new Moment(target_date, 'LT');

        d.add(examDuration, 'minutes');
        // console.log("the d");
        // console.log(d.format('LT'));
        let eD = new Moment(d.format('LT'), 'LT').format('LT');
        // console.log('ED');
        // console.log(eD);
        setEndDate(eD);
        setShowEndDate(true);
        document.getElementById("selected_start_time_" + index).value = startDate;
    }

    const newExamDuration = (e) => {
        // console.log(e.target.getAttribute("name"));
        // console.log(e.target.value);
        if (parseInt(e.target.value)) {
            setExamDuration(e.target.value)
        }
        calculateEndDate(startDate);
    };

    React.useEffect(function () {
        calculateEndDate(startDate);
        datesVisible();
    }, [startDate, examDuration]);

    React.useEffect(function () {
        // console.log('currentITem');
        if (currentItem && currentItem.data && currentItem.data.activities) {
            // console.log('examDuration');
            let examActivity = {};
            if (currentItem.data.activities.length > 1) {
                examActivity = currentItem.data.activities.filter((examActivity, key) => {
                    /*if (key === index) return examActivity*/
                    // console.log(examActivity.type);
                    // console.log(label);
                    return examActivity.type.toLowerCase() === label.toLowerCase()
                }).pop();
            } else
                examActivity = currentItem.data.activities.pop();

            // console.log('examactivity in current item');
            // console.log(examActivity);
            if (typeof examActivity !== "undefined") {
                setStartDate(examActivity.start_time);
                setActivityDate(examActivity.date);
                setExamDuration(examActivity.duration);
                console.log(examActivity.date);
                console.log(examActivity.duration);
                // document.getElementById("exam_activity_date_" + index).value = examActivity.date;
                document.getElementById("exam_activity_duration_" + index).value = examActivity.duration;

                document.getElementById("date_inputs_" + index).hidden = false;
                /*setExamDuration(examActivity.duration);
                setStartDate(examActivity.start_time);*/
                document.getElementById("exam_activity_start_time_" + index).readonly = false;
                document.getElementById("exam_activity_start_time_" + index).value = examActivity.start_time;
                // console.log('now should show correct start time for ' + "selected_start_time_" + index);
                // console.log(examActivity.start_time);
                document.getElementById("selected_start_time_" + index).value = examActivity.start_time;
            }
        }
    }, [currentItem]);

    React.useEffect(function () {
        document.getElementById("selected_start_time_" + index).value = startDate;
        document.getElementById('exam_activity_date_' + index).value = activityDate;
    });
    //
    // React.useEffect(function () {
    //     console.log('changed: '+ activityDate);
    //     document.getElementById('exam_activity_date_'+index).value = '2022-01-01'
    // }, [activityDate]);
    //
    React.useEffect(function () {
        if (index === 0) {
            setActivityDate(selectedDate);
        }
    }, [selectedDate]);

    if (selectedDate.length > 0 || (currentItem && currentItem.data)) {
        return (<div className={"col-12"}>
            <h3>{label}</h3>
            <div key={"date_" + index} className="form-row col-12 mx-0 px-1 align-items-end">
                <input hidden name={"exam_activity_type_" + index} id={"exam_activity_type_" + index} value={label}
                       ref={register({required: true})}/>

                <div className="col-4 input-group">
                    <label className={"col-md-12 px-0"} htmlFor={"exam_activity_date_" + index}>{strings.date}</label>
                    <Datetime viewMode={"days"} timeFormat={false} dateFormat={'YYYY-MM-DD'}
                              defaultValue={activityDate}
                              inputProps={{
                                  id: "exam_activity_date_" + index,
                                  name: "exam_activity_date_" + index,
                                  placeholder: strings.selected_date,
                                  ref: `${register({required: true})}`,
                                  disabled: (status === "published" || index === 0)
                                  /*value:{selectedDate}*/
                              }}
                              readOnly={(status === "published" || index === 0)}
                              isValidDate={ disablePastSelectedDt }
                    />
                </div>

                <div className="col-2">
                    <label className={"col-md-12 px-0"} htmlFor={"exam_activity_duration_" + index}>{strings.duration}</label>
                    <input type="text" className="form-control" onChange={newExamDuration}
                           name={"exam_activity_duration_" + index} id={"exam_activity_duration_" + index}
                           ref={register({required: true, min: 1})} placeholder={strings.duration} defaultValue={examDuration}
                           readOnly={status === "published"}/>
                </div>

                <div hidden={!showDates} id={"date_inputs_" + index} className="col-6 form-row mx-0 align-items-end">
                    <InputGroup className={"col-6"}>
                        <label className={"col-md-12 px-0"} htmlFor={"selected_start_date_" + index}>{strings.start_time}</label>
                        <Datetime id={"selected_start_date_" + index} name={"selected_start_date_" + index}
                                  viewMode={"time"} timeFormat={true} dateFormat={false} onChange={handleEndDate}
                                  defaultValue={startDate}
                                  isValidDate={disabledDates} inputProps={{
                            id: "selected_start_time_" + index,
                            placeholder: strings.start_time,
                            name: "selected_start_time_" + index,
                            ref: `${register({required: true})}`,
                            className: 'form-control pl-2'
                        }}
                                  readOnly={status === "published"}
                                  style={"width: 100%;"}
                        />
                        <input type="hidden" id={"exam_activity_start_time_" + index}
                               name={"exam_activity_start_time_" + index} ref={register({required: true})}
                               value={startDate}/>
                    </InputGroup>
                    <InputGroup className={"col-6"} hidden={!showEndDate}>
                        <label className={"col-md-12 px-0"} htmlFor={"selected_end_date_" + index}>{strings.end_time}</label>
                        <input id={"exam_activity_end_time_" + index} className="form-control pl-2"
                               name={"exam_activity_end_time_" + index} value={endDate} placeholder={strings.end_time}
                               ref={register({required: true})}
                               readOnly={true}/>
                    </InputGroup>
                </div>
            </div>
        </div>)
    } else {
        return (<></>)
    }
}
