/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {Button, Container} from "reactstrap";
import strings from "../../app/translations";

// core components

function LandingPageHeader() {
    let pageHeader = React.createRef();
    const headerImage = "1-1920x1080.png";
  //  strings.setLanguage(language);
    React.useEffect(() => {
        if (window.innerWidth < 991) {
            const updateScroll = () => {
                let windowScrollTop = window.pageYOffset / 3;
                pageHeader.current.style.transform =
                    "translate3d(0," + windowScrollTop + "px,0)";
            };
            window.addEventListener("scroll", updateScroll);
            return function cleanup() {
                window.removeEventListener("scroll", updateScroll);
            };
        }
    });
    //console.log(`../../storage/app/${headerImage}`);
    //console.log(require(`../../../../storage/app/${headerImage}`));
    return (
        <>
            <div
                style={{
                    backgroundImage: "url(" + require(`../../assets/img/mockups/${headerImage}`) + ")"
                }}
                className="page-header"
                data-parallax={true}
                ref={pageHeader}
            >
                <div className="filter"/>
                <Container>
                    <div className="motto text-center">
                        <h1>TUV Austria</h1>
                        <h3>{strings.intro1}</h3>
                        <br/>
                        {/*<Button
                            href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                            className="btn-round mr-1"
                            color="neutral"
                            target="_blank"
                            outline
                        >
                            <i className="fa fa-play"/>
                            Watch video
                        </Button>
                        <Button className="btn-round" color="neutral" type="button" outline>
                            Download
                        </Button>*/}
                    </div>
                </Container>
            </div>
        </>
    );
}

export default LandingPageHeader;
