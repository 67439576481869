import React from "react";
import {FormGroup, Input, Label} from "reactstrap";
import strings from "../../../app/translations";

export default function RegistrationFormDetails({toggleManager,register,errors}){
    if(toggleManager){
        return (
            <fieldset className='roleManager' >
                <div className="form-row">
                    <FormGroup className='col-md-12'>
                        <Label for="companyName">{strings.company_name}(*)</Label>
                        <Input type="text" id="companyName" name="company_name" innerRef={register({required: true})} placeholder={strings.company_name}/>
                        {errors.company_name && <span className="validation-error">{strings.required_validation}</span>}
                    </FormGroup>
                    {/*<FormGroup className='col-md-6'>
                        <Label for="companyTitle">{strings.company_title}</Label>
                        <Input type="text" id="companyTitle" name="company_title" innerRef={register({required: true})}/>
                        {errors.company_title && <span className="validation-error">{strings.required_validation}</span>}
                    </FormGroup>*/}
                </div>
                <div className="form-row">
                    <FormGroup className='col-md-4'>
                        <Label for="companyAddress">{strings.address}(*)</Label>
                        <Input type="text" id="companyAddress" name="company_address" innerRef={register({required: true})} placeholder={strings.address}/>
                        {errors.company_address && <span className="validation-error">{strings.required_validation}</span>}
                    </FormGroup>
                    <FormGroup className='col-md-4'>
                        <Label for="companyCity">{strings.city}(*)</Label>
                        <Input type="text" id="companyCity" name="company_city" innerRef={register({required: true})} placeholder={strings.city}/>
                        {errors.company_city && <span className="validation-error">{strings.required_validation}</span>}
                    </FormGroup>
                    <FormGroup className='col-md-4'>
                        <Label for="companyZipCode">{strings.zip_code}(*)</Label>
                        <Input type="text" id="companyZipCode" name="company_zip_code" innerRef={register({required: true})} placeholder={strings.zip_code}/>
                        {errors.company_zip_code && <span className="validation-error">{strings.required_validation}</span>}
                    </FormGroup>
                </div>
                <div className="form-row">
                    <FormGroup className='col-md-4'>
                        <Label for="companyAddress">{strings.company_email}(*)</Label>
                        <Input type="text" id="companyAddress" name="company_email" innerRef={register({required: true})} placeholder={strings.company_email}/>
                        {errors.company_email && <span className="validation-error">{strings.required_validation}</span>}
                    </FormGroup>
                    <FormGroup className='col-md-4'>
                        <Label for="companyPhone">{strings.phone}(*)</Label>
                        <Input type="text" id="companyPhone" name="company_phone" innerRef={register({required: true})} placeholder={strings.phone}/>
                        {errors.company_phone && <span className="validation-error">{strings.required_validation}</span>}
                    </FormGroup>
                    <FormGroup className='col-md-4'>
                        <Label for="fax">{strings.fax}(*)</Label>
                        <Input type="text" id="fax" name="company_fax" placeholder={strings.fax}/>
                    </FormGroup>
                </div>
                <div className="form-row">
                    <FormGroup className="col-md-6">
                        <Label>{strings.company_vat_reg_num}(*)</Label>
                        <Input placeholder={strings.company_vat_reg_num} type="text" name="company_vat_reg_no" innerRef={register({required: true})}/>
                        {errors.company_vat_reg_no && <span className="validation-error">{strings.required_validation}</span>}
                    </FormGroup>
                    <FormGroup className="col-md-6">
                        <Label>{strings.tax_office}(*)</Label>
                        <Input placeholder={strings.tax_office} type="text" name="company_tax_office" innerRef={register({required: true})}/>
                        {errors.company_tax_office && <span className="validation-error">{strings.required_validation}</span>}
                    </FormGroup>
                </div>
            </fieldset>
        )
    }else {
        return (<></>)
    }

}
