import {NavItem, NavLink} from "reactstrap";
import React, {useContext, useReducer} from "react";
import EntitiesContext from "../../app/context";
import CRUDReducer from "../../app/reducer";
import Cookie from 'universal-cookie';
import strings from "../../app/translations";

export default function LanguageNavItem({cssFilter, setLanguage}) {
    const cookie = new Cookie();
    const initialState = useContext(EntitiesContext);
    const [state, dispatch] = useReducer(CRUDReducer, initialState);

    function switchLanguage(language) {
        // console.log('setting language: ' + language);
        cookie.set('language_set', language, {path: '/', expires: new Date(Date.now() + 25920000), sameSite:'lax' });
        //dispatch({type:"UPDATE_LANGUAGE",payload:language});
        setLanguage(language);
        strings.setLanguage(language);
        //state.language = language;
        //console.log("state language after switch: " + state.language);
    }

    return (
        <NavItem style={{position: "relative"}}>
            <NavLink
                href="#"
                target="_blank"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false"
            >
                <img alt="certifications" width="15rem" style={{marginRight: "0.5rem", filter: cssFilter}}
                     src={require(`../../assets/img/mockups/icon-document.svg`)}/>
                {/*<i className="nc-icon nc-book-bookmark"/>*/}
                {strings.language.stripAccents()}
            </NavLink>
            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <p className="dropdown-item" onClick={() => {
                    switchLanguage('el')
                }}>{strings.greek}</p>
                <p className="dropdown-item" onClick={() => {
                    switchLanguage('en')
                }}>{strings.english}</p>
            </div>
        </NavItem>
    )
}
