// reactstrap components
import {Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import AdminNavbar from "../../../components/Navbars/AdminNavbar";
import React, {useContext} from "react";
import EntitiesContext from "../../../app/context";
import ManageCompanies from "../../../components/Sections/ManageCompanies";

export default function AdminCompanyMain() {
    const {state/*,dispatch*/} = useContext(EntitiesContext);
    const adminPage = state.companies.cardTitle;

    // const prepareObjects = state.companies.objects.map(object => object.data);
    // const prepareColumns = state.companies.columns;
    //const [sectionTab, setSectionTab] = React.useState('1');
    // const sectionToggle = tab => {
    //     if (sectionTab !== tab) setSectionTab(tab);
    // };

    return (
        <div className="container-fluid">
            <Row className="pt-2">
                <Col lg="3" xs="12" className="pr-0">
                    <AdminNavbar adminPage={adminPage}/>
                </Col>
                <Col id="admin" lg="9" xs="12" className="admin pl-0">
                    <ManageCompanies/>
                </Col>
            </Row>
        </div>
    )
}
